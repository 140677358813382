import { EventIcon } from "../icons";
import List from "./List";
import Show from "./show";

export default {
  options: {
    group: "dashboard",
    mergeFilters: true,
    accessible: ({ user }) => user.permissions.indexOf("event/list") !== -1,
  },
  icon: EventIcon,
  list: List,
  show: Show,
};
