import { Drawer, withStyles } from "@material-ui/core";
import React, { useMemo } from "react";

import Device from "./content/Device";
import Sensor from "./content/Sensor";

const Sidebar = withStyles((theme) => ({
  paper: {
    zIndex: 1000,
    marginTop: 64,
    marginBottom: -64,
    height: "calc(100% - 64px)",
    overflowX: "hidden",
  },
}))(
  ({
    classes,
    open,
    devices,
    events,
    deviceId,
    sensorId,
    onClose,
    onSensorClick,
  }) => {
    const device = useMemo(
      () =>
        devices && deviceId !== null
          ? devices.find((device) => device.id === deviceId, [devices])
          : null,
      [devices, deviceId]
    );
    const sensor = useMemo(
      () =>
        devices && sensorId !== null
          ? devices
              .reduce((sensors, device) => sensors.concat(device.sensors), [])
              .find((sensor) => sensor.id === sensorId)
          : null,
      [devices, sensorId]
    );

    const relatedEvents = useMemo(
      () =>
        events.filter(
          (e) =>
            ((deviceId !== null || sensorId !== null) &&
              (deviceId === null || e.device.id === deviceId) &&
              sensorId === null) ||
            e.sensor.id === sensorId
        ),

      [events, deviceId, sensorId]
    );
    return (
      <Drawer
        variant="persistent"
        open={open}
        anchor="right"
        classes={{
          paper: classes.paper,
        }}
      >
        {device && sensor === null && (
          <Device
            device={device}
            events={relatedEvents}
            onClose={onClose}
            onSensorClick={onSensorClick}
          />
        )}
        {sensor && (
          <Sensor
            device={device}
            sensor={sensor}
            events={relatedEvents}
            onClose={onClose}
          />
        )}
      </Drawer>
    );
  }
);
export default Sidebar;
