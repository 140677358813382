import { BloodBlastIcon } from "../icons";
import List from "./List";

export default {
  icon: BloodBlastIcon,
  options: {
    group: "blood",
    mergeFilters: false,
    accessible: ({ user }) =>
      user.permissions.indexOf("blood-blast/list") !== -1,
  },
  list: List,
};
