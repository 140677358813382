import React, { Component, Fragment } from "react";

import { API_URL } from "../../config";
import { Button } from "react-admin";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { getToken } from "../../authBody";

class PrintVerbalButton extends Component {
  handleClick = () => {
    const { selectedIds } = this.props;
    const token = getToken();
    window.open(
      `${API_URL}/device/printVerbal/${token}/doc.pdf?ids=${JSON.stringify(
        selectedIds
      )}`,
      "_blank"
    );
  };

  render() {
    return (
      <Fragment>
        <Button
          label={"resources.device.buttons.print_verbal"}
          onClick={this.handleClick}
        >
          <PictureAsPdfIcon />
        </Button>
      </Fragment>
    );
  }
}
export default PrintVerbalButton;
