import { GroupDocIcon } from "../icons";
import List from "./List";

export default {
  options: {
    group: "dashboard",
    mergeFilters: false,

    accessible: ({ user }) => user.permissions.indexOf("group-doc/list") !== -1,
  },
  icon: GroupDocIcon,
  list: List,
};
