import { DeleteButton, Toolbar as RaToolbar, SaveButton } from "react-admin";

import BackButton from "../components/buttons/BackButton";
import React from "react";
import SaveAndSendCredentialsButton from "./buttons/SaveAndSendCredentialsButton";
import { compose } from "recompose";
import { useResourcePermissions } from "../usePermissions";
import withToolbarStyles from "../components/forms/withToolbarStyles";

const Toolbar = ({ classes, ...props }) => {
  const { edit, create, remove } = useResourcePermissions(props);
  return (
    <RaToolbar {...props} className={classes.toolbar}>
      <SaveButton {...props} disabled={!edit || !create} />
      <SaveAndSendCredentialsButton
        disabled={!edit || !create}
        redirect="list"
        submitOnEnter={false}
      />
      <DeleteButton disabled={!remove} />
      <BackButton href="#/user" />
    </RaToolbar>
  );
};
export default compose(withToolbarStyles)(Toolbar);
