import Create from "./Create";
import Edit from "./Edit";
import { EmailTemplateIcon } from "../icons";
import List from "./List";

export default {
  options: {
    group: "admin",
    accessible: ({ user }) =>
      user.role === "admin" &&
      user.permissions.indexOf("email-template/list") !== -1,
  },
  icon: EmailTemplateIcon,
  list: List,
  create: Create,
  edit: Edit,
};
