import {
  FileField,
  FileInput as RaFileInput,
  withTranslate,
} from "react-admin";

import React from "react";
import { compose } from "recompose";

const FileInput = ({ translate, ...props }) => {
  return (
    <RaFileInput {...props} multiple={false}>
      <FileField source="src" title="title" />
    </RaFileInput>
  );
};
export default compose(withTranslate)(FileInput);
