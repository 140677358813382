import {
  Button,
  Divider,
  FormHelperText,
  Tooltip,
  Typography,
  createStyles,
  withStyles,
} from "@material-ui/core";
import {
  EXEC_CODING_SEND_EMAIL_TEMPLATE_PREVIEW,
  EXEC_CODING_TEMPLATE_PREVIEW,
} from "../../addCodingFeature";
import { Labeled, REDUX_FORM_NAME, addField, translate } from "react-admin";
import React, { Fragment, useState } from "react";
import { grey, red } from "@material-ui/core/colors";
import { highlight, languages } from "prismjs/components/prism-core";
import { showNotification, withDataProvider } from "ra-core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Editor from "react-simple-code-editor";
import PageviewIcon from "@material-ui/icons/Pageview";
import SendIcon from "@material-ui/icons/Send";
import { change } from "redux-form";
import compose from "recompose/compose";
import { connect } from "react-redux";

require("prismjs/components/prism-markup-templating.js");
require("prismjs/components/prism-clike.js");
require("prismjs/components/prism-php.js");
require("prismjs/themes/prism.css");

const styles = (theme) =>
  createStyles({
    root: {
      "& > div:first-child": {
        width: "100%",
      },
    },
    editorRoot: {
      maxHeight: 55 * 10,
      overflow: "auto",
      width: "100%",
      display: "block",
      "& textarea": {
        outline: 0,
      },
    },
    errorDivider: {
      backgroundColor: red[500],
      height: 2,
    },
    divider: {
      height: 1,
      backgroundColor: grey[500],
    },
    console: {
      clear: "both",
      paddingTop: theme.spacing.unit * 1,
    },
    consoleTimestamp: {
      marginRight: theme.spacing.unit * 1,
    },
    errorLine: {
      color: red[500],
      fontFamily: "Fira Code, Consolas, Courier New",
    },
    infoLine: {
      fontFamily: "Fira Code, Consolas, Courier New",
    },
    toolbar: {
      paddingTop: theme.spacing.unit * 1,
    },
    toolbarButton: {
      marginTop: theme.spacing.unit * 1,
      marginRight: theme.spacing.unit * 1,
    },
  });

const handleToolbarItemClick = (
  dataProvider,
  { resource, action, callback, code = undefined, record = undefined }
) =>
  dataProvider(action, resource, { code, record }).then((response) =>
    callback(response)
  );

const ButtonGroup = ({ children, ...props }) => <div>{children}</div>;

const SubjectContent = ({ classes, resource, translate, subject }) => {
  return (
    <Fragment>
      <Typography variant="body1">
        {translate(`resources.${resource}.fields.subject_template`)}:{" "}
        <b> {subject} </b>
      </Typography>
      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
    </Fragment>
  );
};

const HtmlEditorToolbar = ({
  classes,
  translate,
  resource,
  dataProvider,
  code,
  record,
  onPreview,
  onSendPreview,
}) => (
  <ButtonGroup className={classes.toolbar}>
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={translate(`components.code_editor.actions.preview`)}
    >
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.toolbarButton}
        onClick={handleToolbarItemClick.bind(this, dataProvider, {
          resource,
          action: EXEC_CODING_TEMPLATE_PREVIEW,
          callback: onPreview,
          code: code,
          record,
        })}
      >
        <PageviewIcon />
      </Button>
    </Tooltip>
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={translate(`components.code_editor.actions.send`)}
    >
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.toolbarButton}
        onClick={handleToolbarItemClick.bind(this, dataProvider, {
          resource,
          action: EXEC_CODING_SEND_EMAIL_TEMPLATE_PREVIEW,
          callback: onSendPreview,
          code: code,
          record,
        })}
      >
        <SendIcon />
      </Button>
    </Tooltip>
  </ButtonGroup>
);

const HtmlEditorInput = ({
  record,
  source,
  translate,
  resource,
  classes,
  meta: { touched, error },
  dataProvider,
  dispatch,
  template = null,
  hasPreview = false,
}) => {
  const [code, setCode] = useState(
    template !== null ? template[source] || "" : record[source] || ""
  );
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");

  const handleChange = (code) => {
    setCode(code);
    dispatch(change(REDUX_FORM_NAME, source, code));
  };

  const openDialog = (data) => {
    setOpen(true);
    setData(data);
  };
  const closeDialog = () => {
    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <Labeled
        meta={{ touched, error }}
        label={translate(`resources.${resource}.fields.${source}`)}
      >
        <div className={classes.editorRoot}>
          <Editor
            value={code}
            onValueChange={handleChange}
            highlight={(code) => highlight(code, languages.php)}
            padding={0}
            style={{
              fontFamily: "Fira Code, Consolas, Courier New",
              fontSize: 15,
              padding: 0,
              margin: 0,
              border: "white",
              outline: "none",
              width: "100%",
            }}
          />
        </div>
      </Labeled>
      <Divider
        className={
          (!!touched && error && classes.errorDivider) || classes.divider
        }
      />
      {!!touched && error && <FormHelperText error>{error}</FormHelperText>}

      {/* Toolbar    */}
      {hasPreview === true && (
        <HtmlEditorToolbar
          dataProvider={dataProvider}
          resource={resource}
          translate={translate}
          classes={classes}
          code={code}
          record={template}
          onPreview={({ data }) =>
            data.error
              ? dispatch(
                  showNotification(JSON.stringify(data.message, "warning"))
                )
              : openDialog(data)
          }
          onSendPreview={({ data }) =>
            dispatch(
              showNotification(
                JSON.stringify(
                  data.message,
                  data.error !== false ? "warning" : "success"
                )
              )
            )
          }
        />
      )}
      {!!touched && error && <FormHelperText error>{error}</FormHelperText>}
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={closeDialog}
      >
        <DialogContent>
          {(data && data.subject != null && (
            <SubjectContent
              classes={classes}
              resource={resource}
              translate={translate}
              subject={data.subject}
            />
          )) ||
            ""}
          <div
            style={{
              padding: 0,
            }}
            dangerouslySetInnerHTML={{ __html: data.preview }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            {translate(`components.code_editor.actions.close`)}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default compose(
  addField,
  translate,
  withStyles(styles),
  withDataProvider,
  connect()
)(HtmlEditorInput);
