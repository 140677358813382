import { Filter, SearchInput } from "react-admin";

import DateRangeInput from "../components/inputs/DateRangeInput";
import { DateTimeInput } from "../components/inputs/DateTimeInput";
import React from "react";
import StatusInput from "./inputs/StatusInput";
import TreeFilter from "../components/tree/TreeFilter";
import { compose } from "recompose";
import withFiltersStyles from "../components/list/withFiltersStyles";

const Filters = ({ classes, ...props }) => {
  return (
    <div className={classes.root}>
      <Filter {...props}>
        <SearchInput source="keyword" alwaysOn style={{ width: "80%" }} />
        <DateRangeInput source="view" alwaysOn />
        <DateTimeInput source="start_at" alwaysOn />
        <DateTimeInput source="end_at" alwaysOn />
      </Filter>

      <StatusInput {...props} alwaysOn />
      <TreeFilter visible />
    </div>
  );
};
export default compose(withFiltersStyles)(Filters);
