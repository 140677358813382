import { DateField, EditButton, TextField } from "react-admin";

import Card from "./Card";
import Filters from "./Filters";
import React from "react";
import ResponsiveGrid from "../components/list/ResponsiveGrid";
import ResponsiveList from "../components/list/ResponsiveList";

const List = (props) => (
  <ResponsiveList {...props} split type="table" filters={<Filters />}>
    <ResponsiveGrid rowBreakpoint="md" cardContent={<Card />}>
      <TextField source="id" />
      <TextField source="local_ip_address" />
      <TextField source="name" />
      <TextField source="client_version" />
      <DateField source="modified" showTime />
      <EditButton />
    </ResponsiveGrid>
  </ResponsiveList>
);
export default List;
