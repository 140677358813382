import ChipField from "../../components/fields/ChipField";
import React from "react";
import { ReferenceField } from "react-admin";
import { translate } from "react-admin";
import useFieldLabel from "../../components/useFieldLabel";

const GroupField = ({ translate, ...props }) => {
  const fieldLabel = useFieldLabel({ translate, ...props });
  return (
    <ReferenceField
      {...props}
      linkType={false}
      reference="group"
      color="primary"
      label={fieldLabel("group")}
    >
      <ChipField {...props} source="name" />
    </ReferenceField>
  );
};
export default translate(GroupField);
