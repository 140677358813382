import HelpBlock from "../components/HelpBlock";
import PropTypes from "prop-types";
import React from "react";
import WarningIcon from "@material-ui/icons/Warning";

const EmptyMessage = ({ measurements, loading }) =>
  !loading &&
  (!measurements || measurements === null || measurements.length === 0) ? (
    <HelpBlock
      Icon={WarningIcon}
      title="pages.measurements.errors.no_data_title"
      message="pages.measurements.errors.no_data_message"
    />
  ) : null;

EmptyMessage.propTypes = {
  loading: PropTypes.bool,
  measurements: PropTypes.array,
};

export default EmptyMessage;
