import { CardActions, CardContent, CardHeader } from "@material-ui/core";
import { EditButton, FormInput, TextField } from "react-admin";
import React, { Fragment } from "react";

import DurationField from "./fields/DurationField";
import LastResultField from "./fields/LastResultField";
import NameField from "./fields/NameField";
import StatusField from "./fields/StatusField";
import { compose } from "recompose";
import withCardStyles from "../components/list/withCardStyles";

const Card = ({ classes, ...props }) => (
  <Fragment>
    <CardHeader
      className={classes.header}
      title={<NameField source="name" {...props} variant="subheading" />}
    />
    <CardContent className={classes.content}>
      <FormInput
        {...props}
        source="last_run"
        input={<DurationField addLabel />}
      />
      <FormInput {...props} source="description" input={<TextField />} />
      <FormInput {...props} source="status" input={<StatusField addLabel />} />
      <FormInput
        {...props}
        source="last_result"
        input={<LastResultField addLabel />}
      />
    </CardContent>
    <CardActions className={classes.actions}>
      <EditButton {...props} />
    </CardActions>
  </Fragment>
);
export default compose(withCardStyles)(Card);
