import { CALLBACK_TYPE, Connection } from "./Connection";
import { pushEvent, pushMeasurement } from "./actions";
import { useEffect, useState } from "react";

import usePermissions from "../usePermissions";

const useWebsocket = ({ dispatch }) => {
  const permissions = usePermissions();
  const [initial, setInitial] = useState(false);
  useEffect(() => {
    if (!initial) {
      setInitial(true);
      return;
    }
    if (permissions === null) {
      return;
    }
    const connection = new Connection(permissions);
    connection.setCallback(CALLBACK_TYPE.MEASUREMENTS, (payload) => {
      dispatch(pushMeasurement(payload));
    });
    connection.setCallback(CALLBACK_TYPE.EVENTS, (payload) => {
      dispatch(pushEvent(payload));
    });
    connection.open();
    return () => {
      connection.close();
    };
  }, [permissions, initial, dispatch]);
};

export default useWebsocket;
