import { Toolbar as RaToolbar, SaveButton } from "react-admin";

import React from "react";

const Toolbar = (props) => (
  <RaToolbar {...props}>
    <SaveButton />
  </RaToolbar>
);
export default Toolbar;
