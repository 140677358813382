import {
  Edit as RaEdit,
  SimpleForm,
  TextInput,
  email,
  required,
} from "react-admin";

import React from "react";
import Toolbar from "./Toolbar";

const Edit = (props) => (
  <RaEdit {...props}>
    <SimpleForm redirect={false} toolbar={<Toolbar />}>
      <TextInput source="profile.name" validate={required()} />
      <TextInput source="profile.surname" validate={required()} />
      <TextInput source="email" type="email" validate={[required(), email()]} />
    </SimpleForm>
  </RaEdit>
);

export default Edit;
