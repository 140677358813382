import { Polygon, Rectangle, Tooltip } from "react-leaflet";
import React, { useMemo } from "react";

import { get } from "lodash";
import makeid from "../../../utils/makeid";

const SubLocationLayer = ({ location, index, error, onLayerClick }) => {
  const shapeType = get(location, "shape_type");
  const id = useMemo(() => makeid(5), []);
  if (!location.coords) {
    return null;
  }

  switch (shapeType) {
    case "rectangle":
      return (
        <Rectangle
          key={id}
          bounds={location.coords}
          errors={error ? error.errors : []}
          id={location.id}
          code={location.id || location.code}
          //   color={err ? red[500] : "#3A78FF"}
          onClick={onLayerClick.bind(this, index)}
        >
          <Tooltip>{location.name || "Click to edit location"}</Tooltip>
        </Rectangle>
      );
    case "polygon":
      return (
        <Polygon
          key={id}
          positions={location.coords}
          errors={error ? error.errors : []}
          id={location.id}
          code={location.id || location.code}
          //   color={err ? red[500] : "#3A78FF"}
          onClick={onLayerClick.bind(this, index)}
        >
          <Tooltip>{location?.name || "Click to edit location"}</Tooltip>
        </Polygon>
      );
    default:
      return null;
  }
};

export default SubLocationLayer;
