import Link from "../../components/Link";
import React from "react";
import compose from "recompose/compose";
import { grey } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    color: grey[900],
  },
});

const DeviceField = ({
  record,
  classes,
  basePath,
  resource,
  sortBy,
  ...props
}) => {
  const stopPropagation = (e) => e.stopPropagation();
  return (
    <Link
      {...props}
      className={classes.root}
      onClick={stopPropagation}
      target={"_blank"}
      href={`#/device/${record.device.id}`}
      variant="body1"
    >
      {record.device.code}
    </Link>
  );
};
export default compose(withStyles(styles))(DeviceField);
