import {
  FormDataConsumer,
  FormTab,
  TabbedForm,
  TextInput,
  required,
  translate,
} from "react-admin";

import AttrsInput from "./inputs/AttrsInput";
import ClassIcon from "@material-ui/icons/Class";
import IconFileInput from "./inputs/IconFileInput";
import IconMuiInput from "./inputs/IconMuiInput";
import IconTypeInput from "./inputs/IconTypeInput";
import React from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import { compose } from "recompose";
import { get } from "lodash";
import useFieldLabel from "../components/useFieldLabel";
import withFormStyles from "../components/forms/withFormStyles";

const Form = ({ classes, ...props }) => {
  const fieldLabel = useFieldLabel(props);
  return (
    <TabbedForm
      {...props}
      redirect="list"
      className={classes.root}
      defaultValue={{ icon_type: "mui" }}
    >
      <FormTab label="resources.thing-type.tabs.config" icon={<SettingsIcon />}>
        <TextInput source="code" />
        <TextInput source="name" />
        <IconTypeInput source="icon_type" validate={required()} />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            get(formData, "icon_type", "mui") === "mui" ? (
              <IconMuiInput {...rest} source="icon_mui" validate={required()} />
            ) : (
              <IconFileInput
                {...rest}
                source="icon"
                validate={required()}
                helperText={fieldLabel("icon_file.help")}
              />
            )
          }
        </FormDataConsumer>
      </FormTab>
      <FormTab label="resources.thing-type.tabs.attrs" icon={<ClassIcon />}>
        <AttrsInput source="attrs" fullWidth />
      </FormTab>
    </TabbedForm>
  );
};
export default compose(translate, withFormStyles)(Form);
