import {
  FormDataConsumer,
  LongTextInput,
  TabbedForm,
  TextInput,
} from "react-admin";
import React, { useCallback } from "react";

import FormTab from "../components/forms/FormTab";
import GroupsInput from "../components/inputs/GroupsInput";
import { LocationIcon } from "../icons";
import MapContentInput from "./input/MapContentInput";
import MapCoordsInput from "./input/MapCoordsInput";
import PropTypes from "prop-types";
import SettingsIcon from "@material-ui/icons/Settings";
import Toolbar from "./Toolbar";
import TypeInput from "./input/TypeInput";

const Form = ({ location, edit, ...props }) => {
  const handleRedirect = useCallback(
    (basePath, id, data) =>
      edit
        ? `${location.pathname}${location.search}`
        : `${basePath}?filter=${JSON.stringify({
            location_id: data?.parent_id,
          })}`,
    [edit, location.pathname, location.search]
  );
  return (
    <TabbedForm
      {...props}
      redirect={handleRedirect}
      submitOnEnter={false}
      toolbar={<Toolbar />}
      defaultValue={{ configure: false }}
    >
      <FormTab
        icon={<SettingsIcon />}
        label="resources.location.tabs.config"
        search={location?.search}
      >
        <TypeInput source="type" />
        <TextInput source="name" fullWidth />
        <LongTextInput source="notes" />
        <GroupsInput source="groups" />
      </FormTab>
      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) => {
          if (formData?.type === "location") {
            return null;
          }
          return (
            <FormTab
              {...rest}
              icon={<LocationIcon />}
              label="resources.location.tabs.map"
              search={location?.search}
            >
              {formData?.type === "floor" && (
                <MapCoordsInput
                  source="coords"
                  formData={formData}
                  dispatch={dispatch}
                  fullWidth
                />
              )}

              <MapContentInput
                source="locations"
                formData={formData}
                dispatch={dispatch}
                fullWidth
              />
            </FormTab>
          );
        }}
      </FormDataConsumer>
    </TabbedForm>
  );
};
Form.propTypes = {
  location: PropTypes.object.isRequired,
};
export default Form;
