import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";

import { FeatureGroup, ImageOverlay, Map, TileLayer } from "react-leaflet";
import { Paper, withStyles } from "@material-ui/core";
import React, { Fragment, useCallback, useRef, useState } from "react";

import { EditControl } from "react-leaflet-draw";
import LocationLayer from "./MapContentInput/SubLocationLayer";
import { REDUX_FORM_NAME } from "react-admin";
import Search from "./MapContentInput/Search";
import SubLocationEditor from "./MapContentInput/SubLocationEditor";
import { change } from "redux-form";
import classnames from "classnames";
import { compose } from "recompose";
import { connect } from "react-redux";
import { get } from "lodash";
import makeid from "../../utils/makeid";
import useData from "./MapContentInput/useData";

const styles = withStyles((theme) => ({
  map: {
    marginTop: theme.spacing.unit * 2,
    height: "calc(100vh - 424px)",
    width: "100%",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 464px)",
    },
  },
  mapHighest: {
    height: "calc(100vh - 324px)",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 396px)",
    },
  },
}));

const MapContentInput = ({
  classes,
  formData,
  dispatch,
  locations,
  record,
  ...props
}) => {
  const featureGroupRef = useRef(null);
  const editControlRef = useRef(null);
  const mapRef = useRef(null);
  const [prefs, setPrefs] = useState({ zoom: null, center: null, src: null });
  const [subLocation, setSubLocation] = useState(-1);

  const {
    center: defaultCenter,
    zoom: defaultZoom,
    maxZoom,
    overlay,
  } = useData(formData);
  const handleLayerClick = useCallback((index) => setSubLocation(index), []);
  const handleMove = useCallback(
    (event) => {
      const zoom = event.target.getZoom();
      const center = event.target.getCenter();
      setPrefs({ zoom, center });
      dispatch(change(REDUX_FORM_NAME, "map_zoom", zoom));
      dispatch(change(REDUX_FORM_NAME, "map_center_lat", center.lat));
      dispatch(change(REDUX_FORM_NAME, "map_center_lng", center.lng));
    },
    [dispatch]
  );

  const handleCreated = useCallback(
    (event) => {
      let location = {
        shape_type: event.layerType,
        code: makeid(5),
        type: "room",
        parent_id: record?.id,
        name: makeid(5),
        groups: [...(formData?.groups || [])],
      };
      let coords = event.layer.getLatLngs()[0].map((latLng) => ({
        lat: latLng.lat,
        lng: latLng.lng,
      }));
      location.coords = coords;

      locations.push(location);
      dispatch(change(REDUX_FORM_NAME, "locations", locations));
      const editControl = editControlRef.current.leafletElement;
      const featureGroup = editControl.options.edit.featureGroup;
      featureGroup.removeLayer(event.layer);
    },
    [dispatch, record.id, locations, formData.groups]
  );

  const handleClose = useCallback(() => setSubLocation(-1), []);
  const handleDelete = useCallback(
    (index) => {
      setSubLocation(-1);
      const newLocations = locations.filter((l, i) => i !== index);
      dispatch(change(REDUX_FORM_NAME, "locations", newLocations));
    },
    [dispatch, locations]
  );
  return (
    <Fragment>
      <Paper elevation={0}>
        <Map
          ref={mapRef}
          maxZoom={maxZoom}
          onMoveEnd={handleMove}
          center={defaultCenter}
          zoom={prefs?.zoom !== null ? prefs?.zoom : defaultZoom}
          className={classnames(
            classes.map,
            formData?.type !== "floor" && classes.mapHighest
          )}
        >
          <FeatureGroup ref={featureGroupRef}>
            <EditControl
              ref={editControlRef}
              position="topright"
              onCreated={handleCreated}
              edit={{ edit: false, remove: false }}
              draw={{
                circle: false,
                polyline: false,
                marker: false,
                circlemarker: false,
              }}
            />
            {locations.map((location, index) => (
              <LocationLayer
                index={index}
                key={index}
                location={location}
                onLayerClick={handleLayerClick}
              />
            ))}
          </FeatureGroup>
          {defaultZoom <= 19 && (
            <TileLayer
              maxZoom={19}
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          )}

          <Search
            position="topleft"
            provider="OpenStreetMap"
            zoom={16}
            showPopup={false}
            showMarker={false}
          />

          {overlay && (
            <ImageOverlay
              url={overlay.url}
              bounds={overlay.bounds}
              zIndex={100}
              opacity={0.5}
            />
          )}
        </Map>
      </Paper>
      <SubLocationEditor
        {...props}
        record={record}
        index={subLocation}
        parentType={formData?.type}
        onClose={handleClose}
        onDelete={handleDelete}
      />
    </Fragment>
  );
};
export default compose(
  styles,
  connect((state) => {
    // A causa di un problema nella gestione dello stato di redux,
    // devo effettuare un merge tra "initial" e "values" per ottenere
    // l'elenco corretto delle locations con le relative coordinate.

    // Se si naviga da lista a form e si va nel tab "Mappa" senza applicare
    // questo cambiamento, il form non visualizza le coordinate dei vari poligoni registrati.
    const initialValue = get(state, "form.record-form.initial.locations", []);
    const values = get(state, "form.record-form.values.locations", []);
    const locations = values.map((v) => ({
      ...v,
      coords: v.coords || initialValue.find((c) => c.id === v.id)?.coords || [],
    }));
    return {
      locations,
    };
  })
)(MapContentInput);
