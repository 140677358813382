import { CLOSED, ONGOING } from "../colors";
import { Typography, withStyles } from "@material-ui/core";

import Link from "../../components/Link";
import { LocationIcon } from "../../icons";
import React from "react";
import { compose } from "recompose";
import { get } from "lodash";
import { grey } from "@material-ui/core/colors";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    display: "flex",
    flexDirection: "column",
    maxWidth: 300,
  },
  sub: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  sub1: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,
  },
  link: {
    fontWeight: "bold",
    fontSize: 15,
    color: grey[900],
  },
  location: {
    fontSize: 16,
  },
  tree_name: {
    marginTop: 10,
    fontSize: 14,
  },
  locationIcon: {
    color: CLOSED,
    fontSize: 45,
  },
  locationWarningIcon: {
    color: ONGOING,
    fontSize: 45,
  },
});

const InfoField = ({ classes, record, sortBy }) => {
  const stopPropagation = (e) => e.stopPropagation();
  return (
    <div className={classes.root}>
      <div className={classes.sub}>
        <LocationIcon
          classes={{
            root: get(record, "closed")
              ? classes.locationIcon
              : classes.locationWarningIcon,
          }}
        />
        <div className={classes.sub1}>
          <Link
            target={"_blank"}
            href={`#/sensor/${get(record, "sensor.id")}`}
            onClick={stopPropagation}
            className={classes.link}
            variant="body1"
          >
            {get(record, "sensor.name")}
          </Link>
          <Typography className={classes.location}>
            {get(record, "location.name")}
          </Typography>
        </div>
      </div>
      <Typography className={classes.tree_name}>
        {get(record, "location.tree_name")}
      </Typography>
    </div>
  );
};
export default compose(withStyles(styles))(InfoField);
