import { SelectInput, translate } from "react-admin";

import React from "react";
import useFieldLabel from "../../components/useFieldLabel";

const EquipmentEnumInput = ({ ...props }) => {
  const fieldLabel = useFieldLabel(props);
  return (
    <SelectInput
      {...props}
      label={fieldLabel("equipment_enum")}
      choices={[
        { id: "all", name: "resources.sensor.equipment_enum.all" },
        { id: "distinct", name: "resources.sensor.equipment_enum.distinct" },
      ]}
    />
  );
};
export default translate(EquipmentEnumInput);
