import { FormDataConsumer, ReferenceInput } from "react-admin";

import AutocompleteInput from "../../components/inputs/AutocompleteInput";
import React from "react";
import { get } from "lodash";

const SensorInput = (props) => (
  <FormDataConsumer {...props}>
    {({ formData }) => (
      <ReferenceInput
        {...props}
        reference="sensor"
        perPage={10}
        filter={{ devices: get(formData, "device_id", null) }}
        options={{
          disabled: get(formData, "device_id", null) === null,
        }}
        allowEmpty
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    )}
  </FormDataConsumer>
);
export default SensorInput;
