import { createStyles } from "@material-ui/core/styles";

export const styles = createStyles((theme) => ({
  title: {
    marginLeft: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 1,
    color: theme.palette.text.secondary,
  },
  messageBox: {
    paddingLeft: theme.spacing.unit * 3,
  },
  filters: {
    marginTop: theme.spacing.unit * 1,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
  resetChip: {
    marginRight: theme.spacing.unit * 1,
  },
  root: {
    flexGrow: 1,
    marginLeft: theme.spacing.unit * 1.1,
    color: theme.palette.text.secondary,
  },
  divider: {
    marginTop: theme.spacing.unit * 1,
    marginLeft: theme.spacing.unit * 0,
    marginRight: theme.spacing.unit * 0,
  },
  loader: {
    margin: theme.spacing.unit * 0,
    padding: theme.spacing.unit * 0,
  },
  loaderIcon: {
    marginRight: theme.spacing.unit * 1,
  },

  locationNode: {},
  content: {
    borderRadius: theme.spacing.unit * 2,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    paddingTop: theme.spacing.unit * 0.5,
    paddingBottom: theme.spacing.unit * 0.5,
    marginBottom: theme.spacing.unit * 0.5,
  },
  expanded: {},
  nodeLabel: {
    color: theme.palette.secondary.light,
  },
  nodeCheckbox: {
    height: 20,
    width: 28,
    marginRight: theme.spacing.unit * 0.5,
  },
  nodeIcon: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
  },
  nodeLabelTypo: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 170,
  },
  sensorName: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 100,
    fontSize: 14,
    lineHeight: "1.2em",
  },
}));
