import { ArrayInput, SimpleFormIterator, withTranslate } from "react-admin";
import React, { Fragment } from "react";

import SensorRowInput from "./SensorRowInput";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core";

const SensorsInput = withStyles((theme) => ({
  root: {
    width: "100%",
    "& li > p:first-child": {
      display: "none",
      visibility: "hidden",
    },
    [theme.breakpoints.down("sm")]: {
      "& li": {
        display: "flex",
        flexDirection: "column",
        paddingBottom: theme.spacing.unit * 2,
      },
      "& .ra-input": {
        display: "block",
        clear: "both",
        width: "100%",
      },
    },
  },
  hidden: {
    display: "none",
    visibility: "hidden",
  },
}))(({ classes, ...props }) => {
  return (
    <Fragment>
      <ArrayInput {...props}>
        <SimpleFormIterator classes={{ root: classes.root }}>
          <SensorRowInput fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
    </Fragment>
  );
});
export default compose(withTranslate)(SensorsInput);
