import React from "react";
import { Chip } from "@material-ui/core";
import { translate } from "react-admin";
import { compose } from "recompose";
import { green, grey } from "@material-ui/core/colors";
import { get } from "lodash";

const StatusField = ({ record, translate, sortable = false }) => {
  const code = get(record, "code");
  const name = get(record, "name");
  const assigned = code !== name;
  let label;
  if (assigned) {
    label = translate("resources.sensor.status.assigned");
  } else {
    label = translate("resources.sensor.status.not_assigned");
  }
  return (
    <Chip
      label={label}
      style={{
        backgroundColor: assigned ? green[500] : grey[500],
        color: "white",
      }}
    />
  );
};

export default compose(translate)(StatusField);
