import PropTypes from "prop-types";
import React from "react";
import SnackAlert from "../components/SnackAlert";
import { Snackbar } from "@material-ui/core";
import { translate } from "react-admin";

const SearchHelper = ({ translate, warning }) => (
  <Snackbar
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    open={warning !== null}
  >
    <SnackAlert
      variant="warning"
      message={
        (warning !== null && translate(warning.message, warning.args)) || ""
      }
    />
  </Snackbar>
);
SearchHelper.propTypes = {
  warning: PropTypes.shape({
    message: PropTypes.string,
    args: PropTypes.any,
  }),
};
export default translate(SearchHelper);
