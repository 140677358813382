import { ReferenceArrayInput, SelectArrayInput } from "react-admin";

import React from "react";

const RolesArrayInput = (props) => (
  <ReferenceArrayInput {...props} reference="role">
    <SelectArrayInput optionText="name" />
  </ReferenceArrayInput>
);
export default RolesArrayInput;
