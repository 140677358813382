import React, { Component, Fragment, isValidElement } from "react";

import { Card } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import DatagridCell from "./DatagridCell";
import ExpandRowButton from "./ExpandRowButton";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import { connect } from "react-redux";
import { linkToRecord } from "ra-core";
import { push } from "react-router-redux";

const sanitizeRestProps = ({
  basePath,
  children,
  classes,
  className,
  rowClick,
  id,
  isLoading,
  onToggleItem,
  push,
  record,
  resource,
  selected,
  style,
  styles,
  rowClassnames,
  ...rest
}) => rest;

const CardTr = (props) => <Card {...props} component="tr" />;

class DatagridRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      colSpan: this.computeColSpan(props),
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    const colSpan = this.computeColSpan(this.props);
    if (colSpan !== prevState.colSpan) {
      this.setState({ colSpan });
    }
  };

  handleToggleExpanded = (event) => {
    this.setState((state) => ({ expanded: !state.expanded }));
    event.stopPropagation();
  };

  handleToggle = (event) => {
    this.props.onToggleItem(this.props.id);
    event.stopPropagation();
  };

  handleClick = async (event) => {
    const { basePath, rowClick, id, record } = this.props;

    if (!rowClick) return;

    if (typeof rowClick === "function") {
      const path = await rowClick(id, basePath, record);
      this.handleRedirection(path, event);
      return;
    }

    this.handleRedirection(rowClick, event);
  };

  handleRedirection = (path, event) => {
    const { basePath, id, push } = this.props;

    if (path === "edit") {
      push(linkToRecord(basePath, id));
      return;
    }
    if (path === "show") {
      push(linkToRecord(basePath, id, "show"));
      return;
    }
    if (path === "expand") {
      this.handleToggleExpanded(event);
      return;
    }
    if (!path) return;

    push(path);
  };

  computeColSpan = (props) => {
    const { children, hasBulkActions } = props;
    return (
      1 + // show expand button
      (hasBulkActions ? 1 : 0) + // checkbox column
      React.Children.toArray(children).filter((child) => !!child).length // non-null children
    );
  };

  render() {
    const {
      basePath,
      children,
      classes,
      className,
      expand,
      expandAsRow,
      hasBulkActions,
      hover,
      id,
      record,
      resource,
      selected,
      style,
      styles,
      rowClickTarget,
      rowClasses,
      type,
      split,
      websocket,
      ...rest
    } = this.props;
    const { expanded, colSpan } = this.state;
    const RowComponent = type === "card" ? CardTr : "tr";
    return (
      <Fragment>
        <TableRow
          className={classNames(
            `datagrid-card-row ${className}`,
            typeof rowClasses === "function" && rowClasses(record)
          )}
          key={id}
          style={style}
          hover={hover}
          onClick={this.handleClick}
          component={RowComponent}
          {...sanitizeRestProps(rest)}
        >
          {expand && (
            <TableCell padding="none" className={classes.expandIconCell}>
              <ExpandRowButton
                classes={classes}
                expanded={expanded}
                expandContentId={`${id}-expand`}
                onClick={this.handleToggleExpanded}
              />
            </TableCell>
          )}
          {hasBulkActions && (
            <TableCell padding="none">
              <Checkbox
                color="primary"
                className={`select-item ${classes.checkbox}`}
                checked={selected}
                onClick={this.handleToggle}
              />
            </TableCell>
          )}
          {React.Children.map(children, (field, index) => {
            return isValidElement(field) ? (
              <DatagridCell
                key={`${id}-${field.props.source || index}`}
                className={classNames(
                  `column-${field.props.source}`,
                  classes.rowCell
                )}
                websocket={
                  field.props.useWebsocket === true ? websocket : undefined
                }
                record={record}
                {...{
                  field,
                  basePath,
                  resource,
                }}
              />
            ) : null;
          })}
        </TableRow>
        {expand &&
          expanded &&
          expandAsRow &&
          React.cloneElement(expand, {
            record,
            basePath,
            resource,
            id: String(id),
            websocket,
            classes,
          })}
        {expand && expanded && expandAsRow === false && (
          <TableRow key={`${id}-expand`} id={`${id}-expand`}>
            <TableCell colSpan={colSpan}>
              {React.cloneElement(expand, {
                record,
                basePath,
                resource,
                id: String(id),
                websocket,
              })}
            </TableCell>
          </TableRow>
        )}
      </Fragment>
    );
  }
}

DatagridRow.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  expand: PropTypes.node,
  hasBulkActions: PropTypes.bool.isRequired,
  hover: PropTypes.bool,
  id: PropTypes.any,
  onToggleItem: PropTypes.func,
  push: PropTypes.func,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string,
  rowClass: PropTypes.func,
  rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowClickTarget: PropTypes.string,
  selected: PropTypes.bool,
  style: PropTypes.object,
  styles: PropTypes.object,
};

DatagridRow.defaultProps = {
  hasBulkActions: false,
  hover: true,
  record: {},
  selected: false,
  expandAsRow: false,
};

// wat? TypeScript looses the displayName if we don't set it explicitly
DatagridRow.displayName = "DatagridRow";

export default connect(null, { push })(DatagridRow);
