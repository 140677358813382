import { CSSTransition } from "react-transition-group";
import GoToEventPageLink from "./GoToEventPageLink";
import { Grid } from "@material-ui/core";
import ListItem from "./ListItem";
import Loader from "./Loader";
import React from "react";
import Title from "./Title";
import compose from "recompose/compose";
import { withTranslate } from "react-admin";

const List = ({ loading, events }) => {
  return (
    <Grid container style={{ marginTop: 30 }}>
      <Title />

      <Loader loading={loading} noEvents={events?.length === 0} />

      <CSSTransition in={!loading} timeout={500} classNames="events">
        <Grid container item spacing={16}>
          {!loading &&
            events?.map((e) => (
              <Grid key={Math.random()} item xl={3} lg={4} md={6} xs={12}>
                <ListItem event={e} />
              </Grid>
            ))}
        </Grid>
      </CSSTransition>
      {events.length > 0 && !loading && <GoToEventPageLink fullWidth />}
    </Grid>
  );
};

export default compose(withTranslate)(List);
