import React from "react";
import compose from "recompose/compose";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { translate } from "react-admin";
import Slide from "@material-ui/core/Slide";
import { instanceOf } from "prop-types";

import { withCookies, Cookies } from "react-cookie";
import { red } from "@material-ui/core/colors";

const styles = (theme) => ({
  cookieLaw: {
    backgroundColor: red[500],
    position: "fixed",
    bottom: 0,
    color: "#fff",
    padding: theme.spacing.unit * 1,
    textAlign: "center",
  },
  text: {
    color: "white",
  },
  link: {
    textDecoration: "underline",
    color: "white",
    paddingRight: theme.spacing.unit * 0.5,
  },
  button: {
    marginLeft: theme.spacing.unit * 1,
    backgroundColor: "white",
    color: theme.palette.primary[500],
    textDecoration: "none",
    padding: theme.spacing.unit * 0.5,
    borderRadius: theme.spacing.unit * 0.5,
    fontWeight: "bold",
  },
});
const COOKIE_POLICY_ACCEPTED = "cookie_policy_accepted";
const CookiePolicy = ({ translate, classes, ...props }) => {
  const { cookies } = props;
  const cookiePolicyAccepted = cookies.get(COOKIE_POLICY_ACCEPTED) || false;
  const handleAcceptCookiePolicy = (e) => {
    e.stopPropagation();
    e.preventDefault();
    cookies.set(COOKIE_POLICY_ACCEPTED, true);
    return false;
  };
  if (cookiePolicyAccepted) {
    return null;
  }
  return (
    <Slide direction="up" in={!cookiePolicyAccepted}>
      <Grid container spacing={8} className={classes.cookieLaw}>
        <Grid item xs={12}>
          <Typography variant="subheading" style={{ color: "white" }}>
            {translate("website.cookies.title")}
          </Typography>
          <Typography variant="caption" className={classes.text}>
            <a
              className={classes.link}
              href="https://www.its.engineering/home/cookies-policy"
              alt="Cookie Policy"
            >
              {translate("website.cookies.message_link")}
            </a>
            {translate("website.cookies.message_text")}
            <a
              className={classes.button}
              href="https://www.its.engineering/home/cookie-policy/accept"
              alt="Accept"
              onClick={handleAcceptCookiePolicy}
            >
              {translate("website.cookies.accept_button")}
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Slide>
  );
};
CookiePolicy.propTypes = {
  cookies: instanceOf(Cookies).isRequired,
};
export default compose(
  withStyles(styles),
  translate,
  withCookies
)(CookiePolicy);
