import { ChipField as RaChipField } from "react-admin";
import React from "react";
import { withStyles } from "@material-ui/core";

/**
 * Versione rivisita del ChipField originale di react-admin in cui
 * è stato rimosso il margine laterale sinistro fastidiosissimo in tabella.
 */
const ChipField = withStyles((theme) => ({
  root: {
    marginLeft: theme.spacing.unit * 0,
  },
}))(({ classes, ...props }) => (
  <RaChipField className={classes.root} {...props} />
));
export default ChipField;
