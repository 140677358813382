import moment from "moment";

export const DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss";
export const RANGES = {
  RealTime: {
    l60m: () => ({
      start: moment().subtract(60, "minute").format(DATE_FORMAT),
      end: moment().format(DATE_FORMAT),
    }),
    l30m: () => ({
      start: moment().subtract(30, "minute").format(DATE_FORMAT),
      end: moment().format(DATE_FORMAT),
    }),
    l15m: () => ({
      start: moment().subtract(15, "minute").format(DATE_FORMAT),
      end: moment().format(DATE_FORMAT),
    }),
    l5m: () => ({
      start: moment().subtract(5, "minute").format(DATE_FORMAT),
      end: moment().format(DATE_FORMAT),
    }),
    l1m: () => ({
      start: moment().subtract(1, "minute").format(DATE_FORMAT),
      end: moment().format(DATE_FORMAT),
    }),
  },
  Minutely: {
    l360m: () => ({
      start: moment().subtract(360, "minute").format(DATE_FORMAT),
      end: moment().format(DATE_FORMAT),
    }),
    l120m: () => ({
      start: moment().subtract(120, "minute").format(DATE_FORMAT),
      end: moment().format(DATE_FORMAT),
    }),
    l60m: () => ({
      start: moment().subtract(60, "minute").format(DATE_FORMAT),
      end: moment().format(DATE_FORMAT),
    }),
    l30m: () => ({
      start: moment().subtract(30, "minute").format(DATE_FORMAT),
      end: moment().format(DATE_FORMAT),
    }),
    l15m: () => ({
      start: moment().subtract(15, "minute").format(DATE_FORMAT),
      end: moment().format(DATE_FORMAT),
    }),
    l5m: () => ({
      start: moment().subtract(5, "minute").format(DATE_FORMAT),
      end: moment().format(DATE_FORMAT),
    }),
  },
  Hourly: {
    l48h: () => ({
      start: moment().subtract(48, "hour").format(DATE_FORMAT),
      end: moment().format(DATE_FORMAT),
    }),
    l24h: () => ({
      start: moment().subtract(24, "hour").format(DATE_FORMAT),
      end: moment().format(DATE_FORMAT),
    }),
  },
  Daily: {
    l3d: () => ({
      start: moment().subtract(3, "day").startOf("day").format(DATE_FORMAT),
      end: moment().endOf("day").format(DATE_FORMAT),
    }),
    l10d: () => ({
      start: moment().subtract(10, "day").startOf("day").format(DATE_FORMAT),
      end: moment().endOf("day").format(DATE_FORMAT),
    }),
    l30d: () => ({
      start: moment().subtract(30, "day").startOf("day").format(DATE_FORMAT),
      end: moment().endOf("day").format(DATE_FORMAT),
    }),
  },
};

export const canSearch = (
  translate,
  start,
  end,
  sampling,
  selection = null
) => {
  if (selection !== null) {
    const { sensors, devices, locations } = selection;
    if (sensors.length === 0 && devices.length === 0) {
      return {
        message: "pages.measurements.errors.no_filters",
        args: {},
      };
    }
    if (locations.length > 0) {
      return {
        message: "pages.measurements.errors.locations_not_allowed",
        args: {},
      };
    }
    if (devices.length > 1) {
      return {
        message: "pages.measurements.errors.too_many_devices",
        args: {},
      };
    }
  }

  let s = moment(start, "YYYY-MM-DDTHH:mm:ss");
  let e = moment(end, "YYYY-MM-DDTHH:mm:ss");
  let ms = e.diff(s);
  let d = moment.duration(ms);
  let can = false;
  switch (sampling) {
    case "Daily":
      can = d.asDays() > 1;
      if (!can) {
        return {
          message: "pages.measurements.errors.range_daily",
          args: { min: 1 },
        };
      }
      return true;
    case "Hourly":
      const hours = d.asHours();
      can = hours >= 1 && hours <= 48;
      if (!can) {
        return {
          message: "pages.measurements.errors.range_hourly",
          args: { min: 1, max: 48 },
        };
      }
      return true;
    case "Minutely":
      const minutes = d.asMinutes();
      can = minutes >= 1 && minutes <= 3600;
      if (!can) {
        return {
          message: "pages.measurements.errors.range_minutely",
          args: { min: 1, max: 3600 },
        };
      }
      return true;
    case "RealTime":
    default:
      var after3DaysAgo = moment().subtract(3, "day");
      can = moment(start).isAfter(after3DaysAgo);
      if (!can) {
        return {
          message: "pages.measurements.errors.range_realtime_out",
          args: {
            min: after3DaysAgo.format(translate("app.date_format.long")),
            max: moment().format(translate("app.date_format.long")),
          },
        };
      }
      can = d.asMinutes() >= 1 && d.asMinutes() <= 60;
      if (!can) {
        return {
          message: "pages.measurements.errors.range_realtime",
          args: { min: 1, max: 60 },
        };
      }
      return true;
  }
};
