import { connect } from "react-redux";
import useWebsocket from "./useWebsocket";

/**
 * Fake component required to handle all socket connections received inside the application.
 */
const Connector = ({ dispatch, ...props }) => {
  useWebsocket({ dispatch });
  return null;
};

export default connect()(Connector);
