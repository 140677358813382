import {
  PUSH_EVENT,
  PUSH_MEASUREMENT,
  RECEIVE_REFRESH_DATA,
  REQUEST_REFRESH_DATA,
} from "./actions";

import { get } from "lodash";
import makeid from "../utils/makeid";
import moment from "moment";

const parseEvent = (payload) => ({
  ...payload.event,
  id: parseInt(payload.event.id, 10),
  device_id: parseInt(payload.device.id, 10),
  sensor_id: parseInt(payload.event.sensor_id, 10),
  monitor_id: parseInt(payload.event.monitor_id, 10),
});

const reducer = (
  state = {
    ids: [],
    reload: makeid(10),
    loading: false,
    currentTarget: "none",
    measurements: [],
    events: [],
    cakeLogs: [],
  },
  { type, payload }
) => {
  switch (type) {
    case PUSH_EVENT: {
      const { events } = state;
      return {
        ...state,
        events: events
          .map((event) => ({
            ...event,
            ...(event.id === parseInt(payload.event.id, 10)
              ? parseEvent(payload)
              : null),
          }))
          .concat(
            events.some((e) => e.id === parseInt(payload.event.id, 10))
              ? []
              : [parseEvent(payload)]
          ),
        reload: makeid(10),
      };
    }
    case PUSH_MEASUREMENT: {
      const { measurements } = state;
      const value = parseFloat(get(payload, "data.value"));
      const sensorId = parseInt(get(payload, "sensor.id"), 10);
      const deviceId = parseInt(get(payload, "device.id"), 10);
      const formatter = get(payload, "sensor.formatter");
      const unit = get(payload, "sensor.unit");
      let measurement = measurements.find(
        (m) => m.sensor_id === sensorId && m.device_id === deviceId
      );
      if (measurement === undefined) {
        measurements.push({
          value,
          sensor_id: sensorId,
          device_id: deviceId,
          formatter,
          unit,
          created: moment(),
        });
      } else {
        measurement.created = moment();
        measurement.value = value;
        measurement.formatter = formatter;
        measurement.unit = unit;
      }
      return {
        ...state,
        measurements: [].concat(measurements),
        reload: makeid(10),
      };
    }
    case REQUEST_REFRESH_DATA:
      const { target, ids } = payload;
      return {
        ...state,
        reload: makeid(10),
        loading: true,
        target,
        ids,
      };
    case RECEIVE_REFRESH_DATA:
      const { measurements } = payload;
      return {
        ...state,
        reload: makeid(10),
        loading: false,
        measurements,
      };
    default:
      return state;
  }
};

export default reducer;
