import {
  Divider,
  Typography,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { MenuItemLink, getResources, translate } from "react-admin";
import React, { createElement, useCallback, useState } from "react";

import List from "@material-ui/core/List";
import SubMenu from "./SubMenu";
import SubMenuIcon from "@material-ui/icons/ChevronRight";
import { VERSION } from "../config";
import { WithPermissions } from "ra-core";
import { compose } from "recompose";
import { connect } from "react-redux";
import useMenu from "./useMenu";
import { withRouter } from "react-router-dom";

const styles = (theme) =>
  createStyles({
    version: {
      padding: theme.spacing.unit * 2,
      textAlign: "right",
    },
  });

const MenuContent = ({
  onMenuClick,
  open,
  classes,
  resources,
  permissions,
  hasDashboard,
  translate,
  location,
}) => {
  const [visibility, setVisibility] = useState({
    dashboard: true,
    config: false,
    admin: false,
  });
  const toggleVisibility = useCallback(
    (name) => setVisibility((prev) => ({ ...prev, [name]: !prev[name] })),
    []
  );
  const menu = useMenu({
    translate,
    resources,
    user: permissions,
    hasDashboard,
    location,
    visibility,
  });
  return (
    <List component="nav">
      {menu.map((group) => (
        <SubMenu
          key={group.name}
          handleToggle={toggleVisibility.bind(this, group.name)}
          isOpen={group.isOpen}
          sidebarIsOpen={open}
          name={group.label}
          icon={<SubMenuIcon />}
        >
          {group.resources.map((resource) => (
            <MenuItemLink
              key={resource.to}
              to={resource.to}
              isActive={resource.isActiveFn}
              primaryText={resource.label}
              leftIcon={createElement(resource.icon)}
              onClick={onMenuClick}
            />
          ))}
        </SubMenu>
      ))}
      <Divider />
      <Typography variant="caption" className={classes.version}>
        {VERSION}
      </Typography>
    </List>
  );
};
const Menu = ({ ...props }) => {
  return (
    <WithPermissions
      render={({ permissions }) =>
        !permissions || permissions === null ? null : (
          <MenuContent {...props} permissions={permissions} />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
  resources: getResources(state),
});
export default compose(
  translate,
  withRouter,
  connect(mapStateToProps),
  withStyles(styles)
)(Menu);
