import { Grid, withStyles } from "@material-ui/core";

import CardItem from "./CardItem";
import React from "react";
import { grey } from "@material-ui/core/colors";

const CardList = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  card: {
    "& > div": {
      height: "100%",
      backgroundColor: grey[100],
    },
  },
}))(
  ({
    cardElevation = 1,
    websocket,
    classes,
    ids,
    data,
    xs,
    sm,
    md,
    lg,
    ...props
  }) => {
    return (
      <Grid container spacing={16} className={classes.root}>
        {(ids || []).map((id) => (
          <Grid
            className={classes.card}
            key={id}
            item
            lg={lg}
            md={md}
            sm={sm}
            xs={xs}
          >
            <CardItem
              {...props}
              key={id}
              record={data[id]}
              id={id}
              websocket={websocket}
              elevation={cardElevation}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
);
export default CardList;
