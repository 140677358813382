import * as Colors from "@material-ui/core/colors";

import React, { Fragment } from "react";
import { SelectInput, required } from "react-admin";

import { withStyles } from "@material-ui/core";

const ColorField = withStyles((theme) => ({
  color: {
    width: 30,
    display: "inline-block",
    marginRight: theme.spacing.unit * 2,
  },
}))(({ classes, record }) => (
  <Fragment>
    <span
      className={classes.color}
      style={{
        backgroundColor: Colors[record.id][500],
      }}
    >
      &nbsp;
    </span>
    {record.name}
  </Fragment>
));

const ChoropleticFieldsStyle = {
  verticalAlign: "middle",
  marginRight: 10,
};
const ColorInput = (props) => (
  <SelectInput
    {...props}
    style={ChoropleticFieldsStyle}
    translateChoice={false}
    optionText={<ColorField />}
    validate={required()}
    choices={Object.keys(Colors).map((key) => ({
      id: key,
      name: key,
    }))}
  />
);
export default ColorInput;
