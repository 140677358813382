import { useEffect, useState } from "react";

const useSearchParams = ({ location }) => {
  const { search } = location;
  const [query, setQuery] = useState(new URLSearchParams(search));
  useEffect(() => {
    const params = new URLSearchParams(search);
    setQuery(params);
  }, [search]);
  return query;
};

export default useSearchParams;
