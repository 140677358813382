import Form from "./Form";
import { Edit as RaEdit } from "react-admin";
import React from "react";

const Edit = ({ ...props }) => (
  <RaEdit {...props} undoable={false}>
    <Form />
  </RaEdit>
);
export default Edit;
