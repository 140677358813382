import { CheckboxGroupInput, ReferenceArrayInput } from "react-admin";

import React from "react";
import { grey } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core";

// record -> input
const format = (groups) => {
  let formattedData = groups ? groups.map((g) => g.group_id.toString()) : [];
  return formattedData;
};

// input -> record
const parse = (groups) => {
  let parsedData = groups
    ? groups.map((id) => ({ group_id: id.toString() }))
    : [];
  return parsedData;
};

const Checkboxes = ({ ...props }) => <CheckboxGroupInput {...props} />;

const GroupsInput = withStyles((theme) => ({
  root: {
    width: "100%",
    "& legend": {
      fontSize: 20,
      marginBottom: theme.spacing.unit * 0.5,
    },
    "& div": {
      borderTopColor: grey[300],
      borderTopStyle: "solid",
      borderTopWidth: 1,
      paddingTop: theme.spacing.unit,
    },
    "& label[for]": {
      width: 250,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& label[for] > span:first-child": {
      display: "flex",
      alignSelf: "flex-start",
    },
    "& label[for] > span:nth-child(2)": {
      marginTop: theme.spacing.unit * 0.5,
      alignSelf: "normal",
    },
  },
}))(({ disableClasses, classes, source, permissions, ...props }) => (
  <ReferenceArrayInput
    {...props}
    format={format}
    parse={parse}
    source={source}
    reference="group"
    sort={{ field: "name", order: "ASC" }}
    className={classes?.root}
  >
    <Checkboxes />
  </ReferenceArrayInput>
));

export default GroupsInput;
