import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { REDUX_FORM_NAME, translate } from "ra-core";
import { change, getFormValues } from "redux-form";

import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import s from "underscore.string";

const PERMISSIONS = ["create", "list", "edit", "delete"];

const styles = (theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
    tableCell: {
      [theme.breakpoints.down("sm")]: {
        padding: 0,
        margin: 0,
      },
    },
  });

const getChecked = ({ permissions, permission }) => {
  return permissions.find((p) => p.action_name === permission) !== undefined;
};
const setChecked = ({ dispatch, source, permissions, permission }) => {
  if (getChecked({ permissions, permission })) {
    let newPermissions = permissions.filter(
      (p) => p.action_name !== permission
    );
    dispatch(change(REDUX_FORM_NAME, source, newPermissions));
  } else {
    let newPermissions = permissions.concat([
      {
        action_name: permission,
      },
    ]);
    dispatch(change(REDUX_FORM_NAME, source, newPermissions));
  }
};
const PermissionsInput = ({
  dispatch,
  translate,
  classes,
  resources,
  record,
  source,
  formValues,
}) => (
  <div className={classes.root}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableCell}>
            {translate("resources.role.fields.permissions.entity_name")}
          </TableCell>
          {PERMISSIONS.map((permissionName) => (
            <TableCell className={classes.tableCell} key={permissionName}>
              {translate(`resources.role.fields.permissions.${permissionName}`)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(resources).map((resource) => (
          <TableRow key={`resource-${resource}`}>
            <TableCell className={classes.tableCell}>{resource}</TableCell>
            {PERMISSIONS.map((permissionName) => (
              <TableCell
                className={classes.tableCell}
                key={`${resource}-${permissionName}`}
              >
                <Checkbox
                  disabled={
                    permissionName !== "delete" &&
                    permissionName !== "list" &&
                    !resources[resource].props[
                      `has${s.capitalize(permissionName)}`
                    ]
                  }
                  checked={getChecked({
                    permissions: formValues ? formValues[source] || [] : [],
                    permission: `${resource}/${permissionName}`,
                  })}
                  onChange={(_) =>
                    setChecked({
                      dispatch,
                      source,
                      permissions: formValues ? formValues[source] || [] : [],
                      permission: `${resource}/${permissionName}`,
                    })
                  }
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
);

export default compose(
  translate,
  connect((state) => ({
    formValues: getFormValues("record-form")(state),
    resources: state.admin.resources,
  })),
  withStyles(styles)
)(PermissionsInput);
