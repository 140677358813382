import { SimpleForm, TextInput } from "react-admin";

import BodyTemplateInput from "./inputs/BodyTemplateInput";
import EmailThemeInput from "./inputs/EmailThemeInput";
import JsonEditorInput from "../components/inputs/JsonEditorInput";
import React from "react";

const Form = (props) => (
  <SimpleForm {...props} redirect="list">
    <TextInput source="code" />
    <TextInput source="name" />
    <EmailThemeInput source="email_theme_id" />
    <TextInput source="subject_template" fullWidth />
    <JsonEditorInput source="subject_template_data" fullWidth />
    <JsonEditorInput source="body_template_data" fullWidth />
    <BodyTemplateInput source="body_template" fullWidth />
  </SimpleForm>
);
export default Form;
