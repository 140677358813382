import { DateField, TextField } from "react-admin";

import Card from "./Card";
import { DATE_RANGE_EXPR } from "../components/inputs/DateRangeInput";
import Filters from "./Filters";
import GroupField from "./fields/GroupField";
import LinkField from "./fields/LinkField";
import React from "react";
import ResponsiveGrid from "../components/list/ResponsiveGrid";
import ResponsiveList from "../components/list/ResponsiveList";
import SizeField from "./fields/SizeField";
import TypeField from "./fields/TypeField";
import useIsAdmin from "../useIsAdmin";

const today = DATE_RANGE_EXPR["today"]();

const List = (props) => {
  const isAdmin = useIsAdmin(props);
  const additionalProps = {};
  if (!isAdmin) {
    additionalProps.bulkActionButtons = false;
  }
  return (
    <ResponsiveList
      {...props}
      {...additionalProps}
      exporter={false}
      sort={{ field: "created", order: "DESC" }}
      filters={<Filters />}
      filterDefaultValues={{ ...today, view: "today" }}
      perPage={10}
      split
      type="table"
    >
      <ResponsiveGrid rowBreakpoint="sm" cardContent={<Card />}>
        <TextField source="id" />
        <GroupField source="group_id" />
        <LinkField source="filename" />
        <SizeField source="filesize" />
        <TypeField source="type" />
        <DateField source="created" showTime />
      </ResponsiveGrid>
    </ResponsiveList>
  );
};

export default List;
