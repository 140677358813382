import { Chip, Typography, withStyles } from "@material-ui/core";
import React, { Fragment } from "react";
import { green, orange, red } from "@material-ui/core/colors";

import classNames from "classnames";
import { compose } from "recompose";
import { get } from "lodash";
import { withTranslate } from "react-admin";

const styles = (theme) => ({
  root: {
    "& aside": {
      color: "white",
    },
  },
  pending: {
    backgroundColor: orange[500],
  },
  success: {
    backgroundColor: green[500],
  },
  error: {
    backgroundColor: red[500],
  },
});

const LastResultField = ({ translate, classes, source, record }) => (
  <Fragment>
    <Chip
      label={
        <Typography variant="body2">
          {translate(`resources.cron-job.status.${get(record, source)}`)}
        </Typography>
      }
      className={classNames(classes.root, classes[get(record, source)])}
    />
  </Fragment>
);

export default compose(withStyles(styles), withTranslate)(LastResultField);
