import Edit from "./Edit";
import List from "./List";
import { SensorIcon } from "../icons";

export default {
  options: {
    group: "config",
    mergeFilters: true,
    accessible: ({ user }) => user.permissions.indexOf("sensor/list") !== -1,
  },
  icon: SensorIcon,
  list: List,
  edit: Edit,
};
