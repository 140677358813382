import React, { useCallback, useEffect, useState } from "react";
import { UPDATE, withDataProvider, withTranslate } from "react-admin";

import { TextField } from "@material-ui/core";
import { compose } from "recompose";
import { get } from "lodash";

const InlineTextInput = ({
  translate,
  dataProvider,
  source,
  record,
  minWidth = 300,
}) => {
  const [value, setValue] = useState(get(record, source, ""));
  const [updating, setUpdating] = useState(false);
  const handleChange = useCallback((evt) => setValue(evt.target.value), []);
  const handleKeyPress = useCallback(
    (evt) => {
      if (evt.key !== "Enter") {
        return;
      }
      setUpdating(true);
      dataProvider(UPDATE, "language-message", {
        data: {
          ...record,
          [source]: value,
        },
      }).then(() => setUpdating(false));
    },
    [value, dataProvider, record, source]
  );
  const handleBlur = useCallback(() => {
    if (get(record, source) === value) {
      return;
    }
    setUpdating(true);
    dataProvider(UPDATE, "language-message", {
      data: {
        ...record,
        [source]: value,
      },
    }).then(() => setUpdating(false));
  }, [value, dataProvider, source, record]);
  useEffect(() => setValue(get(record, source)), [record, source]);

  return (
    <TextField
      value={value}
      disabled={updating}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      onBlur={handleBlur}
      style={{ minWidth }}
      multiline
    />
  );
};

export default compose(withDataProvider, withTranslate)(InlineTextInput);
