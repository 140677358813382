import { DateField, EditButton, TextField, translate } from "react-admin";
import React from "react";

import Actions from "./Actions";
import Card from "./Card";
import Filters from "./Filters";

import ResponsiveGrid from "../components/list/ResponsiveGrid";
import ResponsiveList from "../components/list/ResponsiveList";

import useFieldLabel from "../components/useFieldLabel";
import useFilterValues from "../components/list/useFilterValues";

const List = (props) => {
  const fieldLabel = useFieldLabel({ ...props });
  const filterValues = useFilterValues({ ...props });

  return (
    <ResponsiveList
      {...props}
      filters={<Filters />}
      actions={<Actions filterValues={filterValues} />}
      filterDefaultValues={filterValues}
      split
      type="table"
      perPage={10}
    >
      <ResponsiveGrid rowBreakpoint="sm" cardContent={<Card />}>
        <TextField source="id" />
        <TextField
          source="thing_type.name"
          label={fieldLabel("thing_type_id")}
        />
        <TextField source="code" variant="body2" />
        <TextField source="name" />
        <DateField source="modified" showTime />
        <EditButton />
      </ResponsiveGrid>
    </ResponsiveList>
  );
};
export default translate(List);
