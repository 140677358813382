import { API_URL } from "../../config";
import Link from "../../components/Link";
import React from "react";
import { get } from "lodash";
import { getToken } from "../../authBody";
import { grey } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core";

const LinkField = withStyles((theme) => ({
  root: {
    fontWeight: "bold",
    color: grey[900],
  },
}))(({ classes, source, record, label = undefined }) => (
  <Link
    className={classes.root}
    variant="body1"
    href={`${API_URL}/file-system/${getToken()}/download/${get(
      record,
      "filepath"
    )}`}
  >
    {label || get(record, source, "NA")}
  </Link>
));
export default LinkField;
