import {
  Checkbox,
  FormControlLabel,
  Typography,
  withStyles,
} from "@material-ui/core";

import Link from "../Link";
import PropTypes from "prop-types";
import React from "react";
import compose from "recompose/compose";
import mergeFilter from "./mergeFilter";
import setLocation from "./setLocation";
import { styles } from "./styles";
import useCheckbox from "./useCheckbox";

const NodeLabel = compose(withStyles(styles))(
  ({
    title,
    label,
    checked,
    location,
    filter,
    disabled = false,
    classes,
    onCheckChange,
  }) =>
    useCheckbox({ location }, (useCheckboxes) => {
      return (
        <FormControlLabel
          title={title}
          className={classes.nodeLabel}
          control={
            useCheckboxes ? (
              <Checkbox
                className={classes.nodeCheckbox}
                checked={checked}
                disabled={!useCheckboxes}
                onClick={(e) => e.stopPropagation()}
                onChange={(evt) => onCheckChange(evt.target.checked)}
              />
            ) : (
              <span />
            )
          }
          label={
            useCheckboxes ? (
              <Typography
                variant="subheading"
                component="div"
                color={disabled && !useCheckboxes ? "textSecondary" : "inherit"}
                style={{
                  cursor:
                    disabled && !useCheckboxes ? "not-allowed" : "pointer",
                  textDecoration: "none",
                  opacity: disabled && !useCheckboxes ? 0.5 : 1,
                }}
                onClick={(e) => e.stopPropagation()}
              >
                {label}
              </Typography>
            ) : (
              <Link
                href={`#${setLocation({ location })}?filter=${JSON.stringify(
                  mergeFilter(filter)
                )}`}
                style={{
                  cursor:
                    disabled && !useCheckboxes ? "not-allowed" : "pointer",
                  textDecoration: "none",
                  opacity: disabled && !useCheckboxes ? 0.5 : 1,
                }}
                color={
                  disabled && !useCheckboxes ? "textSecondary" : "textPrimary"
                }
                onClick={(e) =>
                  !e.stopPropagation() &&
                  !disabled &&
                  !useCheckboxes &&
                  (document.location.href = `#${setLocation({
                    location,
                  })}?filter=${JSON.stringify(mergeFilter(filter))}`)
                }
                variant="subheading"
                component="div"
              >
                {label}
              </Link>
            )
          }
        />
      );
    })
);

NodeLabel.propTypes = {
  label: PropTypes.any.isRequired,
  icon: PropTypes.any,
  filter: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  checked: PropTypes.bool.isRequired,
  onCheckChange: PropTypes.func.isRequired,
};

export default NodeLabel;
