import React, { useState } from "react";
import { withDataProvider, withTranslate } from "react-admin";

import { Button } from "@material-ui/core";
import { IMPERSONATE } from "../../addUserFeature";
import { compose } from "recompose";

const LoginButton = ({ translate, dataProvider, record }) => {
  const [loggingIn, setLoggingIn] = useState(false);
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoggingIn(true);
    dataProvider(IMPERSONATE, "user", { access_token: record.token }).then(
      ({ data }) => {
        const originalToken = localStorage.getItem("token");
        const originalUser = localStorage.getItem("user");

        localStorage.setItem("token", data.token);
        localStorage.setItem("user", btoa(JSON.stringify(data)));

        localStorage.setItem("originalToken", originalToken);
        localStorage.setItem("originalUser", originalUser);
        localStorage.setItem("impersonating", "1");
        document.location.href = "#/";
        document.location.reload();
      }
    );
  };

  return (
    <Button disabled={loggingIn} onClick={handleClick} color="primary">
      {translate("ra.action.login")}
    </Button>
  );
};
export default compose(withTranslate, withDataProvider)(LoginButton);
