import { LongTextInput, SimpleForm, TextInput } from "react-admin";

import CodeEditorInput from "../components/inputs/CodeEditorInput";
import CodeParamsInput from "../components/inputs/CodeParamsInput";
import DeviceTypesInput from "../components/inputs/DeviceTypesInput";
import React from "react";
import SensorTypesInput from "../components/inputs/SensorTypesInput";

const Form = (props) => (
  <SimpleForm {...props} redirect="list" undoable>
    <TextInput source="name" />
    <DeviceTypesInput source="device_types" />
    <SensorTypesInput source="sensor_types" />
    <LongTextInput source="description" />
    <CodeEditorInput source="php_code" />
    <CodeParamsInput source="params" />
  </SimpleForm>
);
export default Form;
