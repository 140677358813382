import { FormDataConsumer } from "react-admin";
import HtmlEditorInput from "../../components/inputs/HtmlEditorInput";
import React from "react";

const BodyTemplateInput = (props) => (
  <FormDataConsumer {...props}>
    {({ formData }) => (
      <HtmlEditorInput {...props} template={formData} hasPreview />
    )}
  </FormDataConsumer>
);
export default BodyTemplateInput;
