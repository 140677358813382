import Card from "./Card";
import ChipField from "../components/fields/ChipField";
import EditButton from "../components/buttons/EditButton";
import Filters from "./Filters";
import React from "react";
import ResponsiveGridWithWebsocket from "../components/list/ResponsiveGridWithWebsocket";
import ResponsiveList from "../components/list/ResponsiveList";
import SensorValueField from "../components/fields/SensorValueField";
import StatusField from "./fields/StatusField";
import { TextField } from "react-admin";

const List = (props) => {
  return (
    <ResponsiveList
      {...props}
      exporter={false}
      filters={<Filters />}
      filterDefaultValues={{
        equipment_enum: "all",
        is_assigned: true,
      }}
      type="table"
      split
    >
      <ResponsiveGridWithWebsocket
        target="sensor"
        rowBreakpoint="sm"
        cardContent={<Card />}
      >
        <TextField source="id" />
        <TextField source="device.code" />
        <ChipField source="code" color="primary" />
        <TextField source="name" />
        <StatusField sortable={false} />
        <SensorValueField />
        <EditButton />
      </ResponsiveGridWithWebsocket>
    </ResponsiveList>
  );
};

export default List;
