import { API_URL } from "./config";
import { fetchUtils } from "react-admin";
import { getBody } from "./authBody";

export const GENERATE_LABELS = "GENERATE_LABELS";
export const GET_INFO = "GET_INFO";

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export default (requestHandler) => (type, resource, payload) => {
  const { fetchJson } = fetchUtils;
  if (resource === "device" && type === GET_INFO) {
    let url = `${API_URL}/${resource}/info/${payload.id}`;
    return fetchJson(url, {
      method: "POST",
      body: getBody(payload),
    }).then((data) => ({
      data: {
        device: data.json.device,
        last: data.json.last,
      },
    }));
  }

  if (resource === "device-label" && type === GENERATE_LABELS) {
    let url = `${API_URL}/${resource}/generate`;
    return fetchJson(url, {
      method: "POST",
      body: getBody(payload),
    }).then(({ message, error }) => ({
      data: {
        message,
        error,
      },
    }));
  }
  if ((type === "CREATE" || type === "UPDATE") && resource === "device") {
    let picture = payload.data.image;
    if (picture && picture.rawFile instanceof File) {
      let pictures = [picture]; // Lasciamolo così, magari un giorno vorremo caricare più foto
      return Promise.all(pictures.map(convertFileToBase64))
        .then((base64Pictures) =>
          base64Pictures.map((picture64, index) => ({
            data: picture64,
            name: pictures[index].rawFile.name,
            size: pictures[index].rawFile.size,
            type: pictures[index].rawFile.type,
          }))
        )
        .then((transformedPictures) =>
          requestHandler(type, resource, {
            ...payload,
            data: {
              ...payload.data,
              image: transformedPictures[0],
            },
          })
        );
    }
  }

  return requestHandler(type, resource, payload);
};
