let remapRouteParams = (paramName, array) =>
  array.reduce(
    (finalObj, p) => ({
      ...finalObj,
      [`${paramName}-${p}`]: true,
    }),
    {}
  );

export default () => {
  let route = document.location.toString().split("#")[1];
  let filter = {
    expanded: [],
    checked: [],
    keyword: null,
  };
  if (route.indexOf("?") !== -1) {
    let queryString = new URLSearchParams(route.split("?")[1]);
    filter = {
      ...filter,
      ...JSON.parse(decodeURIComponent(queryString.get("filter")) || "{}"),
    };
    filter = {
      ...filter,
      checked: {
        ...remapRouteParams("locations", filter.locations || []),
        ...remapRouteParams("devices", filter.devices || []),
        ...remapRouteParams("sensors", filter.sensors || []),
      },
    };
  } else {
    filter = {
      ...filter,
      ...(JSON.parse(localStorage.getItem("tvf") || "{}") || {}),
    };
    filter = {
      ...filter,
      checked: {
        ...remapRouteParams("locations", filter.locations || []),
        ...remapRouteParams("devices", filter.devices || []),
        ...remapRouteParams("sensors", filter.sensors || []),
      },
    };
  }

  let routeData = {
    filter: {
      locations: [],
      devices: [],
      sensors: [],
      checked: [],
      ...filter,
    },
  };
  return routeData;
};
