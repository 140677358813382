import { AppSettingIcon } from "../icons";
import List from "./List";
import Edit from "./Edit";
import Create from "./Create";

export default {
  options: {
    group: "admin"
  },
  icon: AppSettingIcon,
  list: List,
  edit: Edit,
  create: Create
};
