import { createStyles, withStyles } from "@material-ui/core/styles";

import { Button } from "@material-ui/core";
import ContentAdd from "@material-ui/icons/Add";
import PropTypes from "prop-types";
import React from "react";
import compose from "recompose/compose";
import onlyUpdateForKeys from "recompose/onlyUpdateForKeys";
import { translate } from "ra-core";

const styles = (theme) =>
  createStyles({
    floating: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      margin: 0,
      top: "auto",
      right: 24,
      bottom: 20,
      left: "auto",
      position: "fixed",
      zIndex: 1000,
    },
    floatingLink: {
      color: "inherit",
    },
    floatingIcon: {
      marginRight: theme.spacing.unit,
    },
  });

const FloatingButton = ({
  classes = {},
  translate,
  label = "ra.action.floating_button",
  icon = <ContentAdd />,
  ...rest
}) => (
  <Button
    variant={label ? "extendedFab" : "fab"}
    color="primary"
    className={classes.floating}
    {...rest}
  >
    {React.cloneElement(icon, {
      className: label ? classes.floatingIcon : null,
    })}
    {label && translate(label)}
  </Button>
);

FloatingButton.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  size: PropTypes.string,
  translate: PropTypes.func.isRequired,
  icon: PropTypes.element,
};

const enhance = compose(
  translate,
  onlyUpdateForKeys(["label", "translate", "onClick", "icon"]),
  withStyles(styles)
);

export default enhance(FloatingButton);
