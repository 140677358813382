import React, { useMemo } from "react";

import { SelectInput } from "react-admin";
import useIsAdmin from "../../useIsAdmin";
import usePermissions from "../../usePermissions";

const UnitInput = ({ source, ...props }) => {
  const { resource } = props;
  const permissions = usePermissions();
  const isAdmin = useIsAdmin({ permissions });
  const choices = useMemo(
    () =>
      [
        {
          id: "minute",
          name: `resources.${resource}.measurement_time_units.minute`,
        },
        {
          id: "hour",
          name: `resources.${resource}.measurement_time_units.hour`,
        },
        {
          id: "day",
          name: `resources.${resource}.measurement_time_units.day`,
        },
        {
          id: "week",
          name: `resources.${resource}.measurement_time_units.week`,
        },
        {
          id: "month",
          name: `resources.${resource}.measurement_time_units.month`,
        },
      ].concat(
        isAdmin
          ? [
              {
                id: "always",
                name: `resources.${resource}.measurement_time_units.always`,
              },
            ]
          : []
      ),
    [resource, isAdmin]
  );
  return <SelectInput {...props} source={source} choices={choices} />;
};

export default UnitInput;
