import { latLng, latLngBounds } from "leaflet";

export const getCenterAndZoom = (location) => {
  if (!location) {
    return null;
  }
  const map_min_x = location.map_min_x
    ? location.map_min_x
    : location.locations && location.locations.length > 0
    ? location.locations.find((l) => l.map_max_x)
      ? location.locations.find((l) => l.map_max_x).map_min_x
      : 0
    : 0;
  const map_max_x = location.map_max_x
    ? location.map_max_x
    : location.locations && location.locations.length > 0
    ? location.locations.find((l) => l.map_max_x)
      ? location.locations.find((l) => l.map_max_x).map_max_x
      : 0
    : 0;
  const map_min_y = location.map_min_y
    ? location.map_min_y
    : location.locations && location.locations.length > 0
    ? location.locations.find((l) => l.map_min_y)
      ? location.locations.find((l) => l.map_min_y).map_min_y
      : 0
    : 0;
  const map_max_y = location.map_max_y
    ? location.map_max_y
    : location.locations && location.locations.length > 0
    ? location.locations.find((l) => l.map_max_y)
      ? location.locations.find((l) => l.map_max_y).map_max_y
      : 0
    : 0;

  const min = latLng(map_min_y, map_min_x);
  const max = latLng(map_max_y, map_max_x);
  if (min === 0 || max === 0) {
    return null;
  }
  const bounds = latLngBounds([min, max]);
  const center = bounds.getCenter();
  if (center.lat === 0 || center.lng === 0) {
    return null;
  }

  return {
    lat: center.lat,
    lng: center.lng,
    zoom: location.zoom || 16,
  };
};

export const getRoomCenter = (room) => {
  const latLngs = room.coords.map((coord) => latLng(coord.lat, coord.lng));
  if (!latLngs || latLngs.length === 0) {
    return null;
  }

  const bounds = latLngBounds(latLngs);
  const center = bounds.getCenter();
  return center;
};
