import * as Icons from "@material-ui/icons";

import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  withStyles,
} from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import { grey, red } from "@material-ui/core/colors";

import { API_URL } from "../../../config";
import EventList from "./EventList";
import SidebarLayout from "../SidebarLayout";
import { get } from "lodash";
import { getToken } from "../../../authBody";
import moment from "moment";

const Device = withStyles((theme) => ({
  item: {
    cursor: "pointer",
  },
}))(({ classes, device, events, onClose, onSensorClick }) => {
  const handleClose = useCallback(() => {
    onClose({ deviceId: null, sensorId: null });
  }, [onClose]);
  const handleSensorClick = useCallback(
    (sensor) => onSensorClick(sensor),
    [onSensorClick]
  );
  const media = useMemo(() => {
    const filepath = get(device, "filepath", null);
    if (filepath === null) {
      return filepath;
    }
    return `${API_URL}/file-system/${getToken()}/download/${filepath}`;
  }, [device]);
  return (
    <SidebarLayout
      media={media}
      title={device.name}
      subTitle={device.code}
      onClose={handleClose}
    >
      <List>
        {device.sensors.map((sensor) => {
          const Icon = Icons[sensor.sensor_type.icon] || Icons.Whatshot;
          const latest = moment
            .duration(moment().diff(moment(sensor.latest)))
            .seconds();
          const isFresh = latest <= 5;
          const unit = get(sensor, "unit", null);
          const formatter = get(sensor, "sensor_type.formatter", null);
          const inAlarm = sensor.event && !sensor.event.closed;
          let value = sensor.value;
          if (formatter) {
            /*eslint-disable no-eval */
            value = eval(formatter);
          }

          if (unit) {
            value = `${value} (${unit})`;
          }
          return (
            <ListItem
              button
              key={sensor.id}
              className={classes.item}
              onClick={handleSensorClick.bind(this, sensor)}
            >
              <ListItemAvatar>
                <Avatar
                  style={{ backgroundColor: inAlarm ? red[500] : grey[500] }}
                  className={isFresh ? (inAlarm ? "pulse-alarm" : "pulse") : ""}
                >
                  <Icon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${sensor.name}`} secondary={value} />
            </ListItem>
          );
        })}
      </List>
      <EventList events={events} />
    </SidebarLayout>
  );
});

export default Device;
