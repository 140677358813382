import {
  ArrayInput,
  CheckboxGroupInput,
  FormDataConsumer,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  required,
} from "react-admin";
import {
  InputAdornment,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { REDUX_FORM_NAME, translate } from "ra-core";

import React from "react";
import SimpleFormIterator from "../../components/forms/SimpleFormIterator";
import { change } from "redux-form";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { get } from "lodash";
import s from "underscore.string";

// record -> input
const format = (monitors) => {
  let formattedData = monitors
    ? monitors.map((g) => g.monitor_id.toString())
    : [];
  return formattedData;
};

// input -> record
const parse = (formData, monitors) => {
  let parsedData = monitors
    ? monitors.map((id) => ({
        monitor_id: id.toString(),
        ...(get(formData, "monitors", []).find((m) => m.monitor_id === id) ||
          {}),
      }))
    : [];
  return parsedData;
};
const styles = (theme) => ({
  root: {
    width: "100%",
    "& legend": {
      fontSize: 20,
      marginBottom: theme.spacing.unit * 0.5,
    },
    "& label[for]": {
      width: "90%",
      clear: "right",
      marginBottom: theme.spacing.unit * 2,
      [theme.breakpoints.down("sm")]: {
        widht: "100%",
      },
    },
    "& label[for] > span:first-child": {
      display: "flex",
      alignSelf: "flex-start",
    },
    "& label[for] > span:nth-child(2)": {
      marginTop: theme.spacing.unit * 0.5,
      alignSelf: "normal",
    },
  },
  input: {
    width: 200,
    marginRight: theme.spacing.unit * 1,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  formInput: {
    float: "left",
    width: 200,
    marginRight: theme.spacing.unit * 1,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  monitorConfig: {
    marginTop: theme.spacing.unit,
  },
});

const setMonitorInterval = ({ dispatch, config, formData, value }) => {
  const updatedMonitors = get(formData, "monitors", []).map((m) =>
    m === config
      ? {
          ...m,
          interval: value,
        }
      : m
  );
  dispatch(change(REDUX_FORM_NAME, "monitors", updatedMonitors));
};
const setMonitorParam = ({
  dispatch,
  choice,
  formData,
  paramName,
  paramValue,
}) => {
  const groupName = s(choice.code).underscored().value();
  let meta = formData.meta;
  let metaParam = meta.find(
    (m) => m.group === groupName && m.key === paramName
  );
  if (!metaParam) {
    metaParam = {
      group: groupName,
      key: paramName,
      value: paramValue,
    };
    meta.push(metaParam);
  } else {
    metaParam.value = paramValue;
  }
  dispatch(change(REDUX_FORM_NAME, "meta", meta));
};
const getMonitorParam = ({ choice, paramName, formData }) => {
  const groupName = s(choice.code).underscored().value();
  const param = formData.meta.find(
    (m) => m.group === groupName && m.key === paramName
  );
  return param ? param.value : "";
};

const MonitorConfig = compose(
  connect(),
  translate,
  withStyles(styles)
)(({ dispatch, classes, translate, choice, config, formData, ...props }) => (
  <div
    key={choice.id}
    className={classes.monitorConfig}
    onClick={(e) => e.preventDefault()}
  >
    <TextField
      required
      className={classes.input}
      label={translate("resources.monitor.fields.interval")}
      onChange={(e) =>
        setMonitorInterval({
          dispatch,
          config,
          formData,
          value: e.target.value,
        })
      }
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            {translate("app.seconds")}
          </InputAdornment>
        ),
      }}
      type="number"
      helperText={translate("helps.monitor_interval")}
      value={config.interval || 1}
    />
    {choice.params.map((param) => (
      <TextField
        required
        className={classes.input}
        key={param.id}
        label={param.label}
        type={param.type}
        value={getMonitorParam({ choice, paramName: param.name, formData })}
        helperText={param.help}
        onChange={(e) =>
          setMonitorParam({
            dispatch,
            choice,
            formData,
            paramName: param.name,
            paramValue: e.target.value,
          })
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">{param.unit}</InputAdornment>
          ),
        }}
      />
    ))}
    <br />
    <ArrayInput
      key={`${choice.id}`}
      label={translate("app.event_notifiers")}
      source={`event_notifiers_${choice.id}`}
      defaultValue={[]}
      resource={`monitor-${choice.id}`}
      style={{ width: "100%" }}
    >
      <SimpleFormIterator showIndex={false}>
        <ReferenceInput
          resource="sensor"
          className={classes.formInput}
          label={translate("app.event_notifier")}
          source="event_notifier_id"
          reference="event-notifier"
          validate={required()}
        >
          <SelectInput resource="sensor" optionText="name" />
        </ReferenceInput>

        <ReferenceInput
          className={classes.formInput}
          resource="sensor"
          label={translate("app.group")}
          source="group_id"
          reference="group"
          validate={required()}
        >
          <SelectInput resource="sensor" optionText="name" />
        </ReferenceInput>

        <NumberInput
          label={translate("app.interval")}
          className={classes.formInput}
          source="interval"
          validate={required()}
        />
      </SimpleFormIterator>
    </ArrayInput>
  </div>
));

const optionRender = ({ choice, formData }) => {
  const config = get(formData, "monitors", []).find(
    (m) => m.monitor_id === choice.id
  );
  const isChecked = config && config !== null;
  const stopPropagation = (e) => !e.stopPropagation() && !e.preventDefault();
  return (
    <div key={`option-${choice.id}`}>
      <Typography variant="body2">{choice.name}</Typography>
      <Typography variant="caption" align="justify" onClick={stopPropagation}>
        {choice.description}
      </Typography>
      {isChecked && (
        <MonitorConfig config={config} choice={choice} formData={formData} />
      )}
    </div>
  );
};

const Checkboxes = ({ classes, formData, ...props }) => (
  <CheckboxGroupInput
    {...props}
    optionText={(choice) => optionRender({ choice, formData })}
  />
);

const MonitorsInput = ({
  classes,
  hasList,
  hasEdit,
  hasCreate,
  source,
  resource,
  ...props
}) => (
  <FormDataConsumer>
    {({ formData }) => (
      <ReferenceArrayInput
        {...props}
        className={classes.root}
        format={format}
        parse={parse.bind(this, formData)}
        source={source}
        resource={resource}
        reference="monitor"
      >
        <Checkboxes label={""} formData={formData} />
      </ReferenceArrayInput>
    )}
  </FormDataConsumer>
);

export default compose(withStyles(styles))(MonitorsInput);
