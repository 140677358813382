import { DateField, translate } from "react-admin";

import React from "react";
import { Typography } from "@material-ui/core";
import { get } from "lodash";

const EventLogCheckedField = ({ translate, ...props }) => {
  const checked = get(props, "record.checked");
  if (checked === null) {
    const { basePath, resource, ...rest } = props;
    return (
      <Typography {...rest}>
        {translate("resources.sentinel-event-log.messages.not_checked")}
      </Typography>
    );
  }
  return <DateField {...props} variant="body2" showTime />;
};

export default translate(EventLogCheckedField);
