import { EditButton, TextField } from "react-admin";

import Card from "./Card";
import React from "react";
import ResponsiveGrid from "../components/list/ResponsiveGrid";
import ResponsiveList from "../components/list/ResponsiveList";

const List = (props) => (
  <ResponsiveList split type="table" {...props} perPage={10}>
    <ResponsiveGrid rowBreakpoint="sm" cardContent={<Card />}>
      <TextField source="id" />
      <TextField source="code" variant="body2" />
      <TextField source="name" />
      <EditButton />
    </ResponsiveGrid>
  </ResponsiveList>
);
export default List;
