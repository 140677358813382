import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import HeroPage from "../components/HeroPage";
import Link from "../../components/Link";
import React from "react";
import classnames from "classnames";
import { compose } from "recompose";
import { isMobile } from "../mobileDetector";
import { translate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    marginTop: -250,
    [theme.breakpoints.down("sm")]: {
      marginTop: -300,
    },
  },
  card: {
    margin: theme.spacing.unit * 2,
  },
  cardContent: {
    minHeight: 300,
  },
  icon: {
    fontSize: 100,
  },
  error: {
    color: red[500],
  },
  success: {
    color: green[500],
  },
});
const Display = ({
  classes,
  translate,
  match: {
    params: { action },
  },
  ...props
}) => (
  <HeroPage>
    <Grid container justify="center" className={classes.root}>
      <Grid item lg={6} md={6} sm={8} xs={12}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Grid container justify="center">
              {translate(`pages.actions.${action}.error`) === "0" && (
                <CheckCircleIcon
                  className={classnames(classes.icon, classes.success)}
                />
              )}
              {translate(`pages.actions.${action}.error`) === "1" && (
                <ErrorIcon
                  className={classnames(classes.icon, classes.error)}
                />
              )}
            </Grid>
            <Typography variant="display1" align="center" color="textPrimary">
              {translate(`pages.actions.${action}.title`)}
            </Typography>
            <Typography variant="body1" align="center">
              {translate(`pages.actions.${action}.message`)}
            </Typography>
          </CardContent>
          <CardActions>
            {!isMobile() && (
              <Button
                size="small"
                color="primary"
                component={Link}
                href="/home"
              >
                <ArrowBackIcon />
                {translate("pages.back")}
              </Button>
            )}
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  </HeroPage>
);
export default compose(withStyles(styles), translate)(Display);
