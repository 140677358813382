import { Filter, SearchInput } from "react-admin";

import React from "react";
import TreeFilter from "../components/tree/TreeFilter";

const Filters = (props) => (
  <Filter {...props}>
    <SearchInput source="subkeyword" alwaysOn />
    <TreeFilter source="tree" alwaysOn />
  </Filter>
);
export default Filters;
