import { ReferenceInput, SelectInput } from "react-admin";

import React from "react";

const DeviceTypeInput = (props) => (
  <ReferenceInput {...props} reference="device-type">
    <SelectInput optionText="name" />
  </ReferenceInput>
);
export default DeviceTypeInput;
