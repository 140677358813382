import { Button } from "react-admin";
import ContentCreate from "@material-ui/icons/Create";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import { linkToRecord } from "ra-core";
import shouldUpdate from "recompose/shouldUpdate";

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e) => e.stopPropagation();

const EditButton = ({
  basePath = "",
  label = "ra.action.edit",
  record = {},
  icon = <ContentCreate />,
  location,
  ...rest
}) => (
  <Button
    component={Link}
    to={{
      pathname: linkToRecord(basePath, record.id),
      search: `parent_id=${record.parent_id}`,
    }}
    label={label}
    onClick={stopPropagation}
    {...rest}
  >
    {icon}
  </Button>
);

EditButton.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
  icon: PropTypes.element,
};

const enhance = shouldUpdate(
  (props, nextProps) =>
    props.translate !== nextProps.translate ||
    (props.record &&
      nextProps.record &&
      props.record.id !== nextProps.record.id) ||
    props.basePath !== nextProps.basePath ||
    (props.record == null && nextProps.record != null)
);

export default enhance(EditButton);
