import * as Colors from "@material-ui/core/colors";

import { get } from "lodash";

export const DEFAULT_COLOR = Colors.grey[900];
export const ALARM_COLOR = Colors.red[500];

export const CHOROPLETIC_SHADES = [
  50, 100, 200, 300, 400, 500, 600, 700, 800, 900,
];
export const SHOW_MARKERS_MIN_ZOOM = 22;
export const SHOW_MARKERS_MAX_ZOOM = 24;
export const ICON_CONTAINER_STYLE = {
  width: 48,
  height: 48,
};

export const ICON_CONTAINER_WARN_STYLE = {
  ...ICON_CONTAINER_STYLE,
  color: ALARM_COLOR,
};
export const ICON_STYLE = {
  color: DEFAULT_COLOR,
};
export const ICON_WARN_STYLE = {
  color: ALARM_COLOR,
};
export const POLYLINE_STYLE = {
  color: DEFAULT_COLOR,
};
export default {
  CHOROPLETIC_SHADES,
  SHOW_MARKERS_MAX_ZOOM,
  SHOW_MARKERS_MIN_ZOOM,
  ICON_CONTAINER_STYLE,
  ICON_CONTAINER_WARN_STYLE,
  ICON_STYLE,
  ICON_WARN_STYLE,
  POLYLINE_STYLE,
};
const ZOOM_CONFIG = {
  21: [28, 28],
  22: [34, 34],
  23: [48, 48],
  24: [64, 64],
  25: [96, 96],
};
export const dim = (zoom) => ({
  width: get(ZOOM_CONFIG, `${zoom}.0`, ZOOM_CONFIG[21][0]),
  height: get(ZOOM_CONFIG, `${zoom}.1`, ZOOM_CONFIG[21][0]),
});
