import { CircularProgress, Paper, withStyles } from "@material-ui/core";
import { GET_BUILDINGS, GET_FLOOR } from "../addLocationFeature";
import React, { useEffect, useMemo, useRef, useState } from "react";
import merge, { shouldMerge } from "./data/merge";

import FilterBox from "./components/FilterBox";
import { GET_LAST_MEASUREMENTS } from "../addMeasurementFeature";
import Legend from "./components/Legend";
import Map from "./components/Map";
import MapLayer from "./components/MapLayer";
import Sidebar from "./sidebar/Sidebar";
import SnackEvent from "./components/SnackEvent";
import { TileLayer } from "react-leaflet";
import TreeFilter from "../components/tree/TreeFilter";
import classnames from "classnames";
import { compose } from "recompose";
import { connect } from "react-redux";
import deduce from "./data/deduce";
import { get } from "lodash";
import prepare from "./data/prepare";
import { toggleMainPadding } from "../ui/actions";
import useDebounce from "../utils/useDebounce";
import { withDataProvider } from "ra-core";
import withWebsocket from "../websocket/withWebsocket";

const Show = withStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  map: {
    height: "100%",
    widht: "100%",
  },
  loader: { position: "absolute", left: "50%", top: "50%", zIndex: 1000 },
  drawer: { zIndex: 1000 },
}))(({ classes, websocket, dataProvider, dispatch, location: { search } }) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      dispatch(toggleMainPadding({ visible: false }));
    }
    return () => {
      dispatch(toggleMainPadding({ visible: true }));
    };
  }, [dispatch]);

  const filter = useMemo(() => {
    const searchQuery = new URLSearchParams(search);
    const filter = searchQuery.get("filter");
    return JSON.parse(filter || "{}");
  }, [search]);
  const [
    {
      center,
      zoom,
      buildings,
      floor,
      devices,
      events,
      loading,
      sidebar,
      sensorTypes,
      selectedSensorTypes,
      selectedChoropletSensorType,

      deviceId,
      sensorId,
    },
    setState,
  ] = useState({
    center: [filter.lat || 40, filter.lng || 16],
    zoom: filter.zoom || 5,
    buildings: [],
    floor: null,
    devices: [],
    events: [],
    loading: 0,
    sidebar: 0,

    // Filtri

    sensorTypes: [],
    selectedSensorTypes: [],
    selectedChoropletSensorType: null,
    deviceId: filter.device_id !== null ? parseInt(filter.device_id, 10) : null,
    sensorId: filter.sensor_id !== null ? parseInt(filter.sensor_id, 10) : null,
  });

  // Carico tutti gli edifici.
  useEffect(() => {
    async function fetchData() {
      const {
        data: { rows: buildings },
      } = await dataProvider(GET_BUILDINGS, "location");
      setState((s) => ({
        ...s,
        buildings,
        loading: false,
      }));
    }
    fetchData();
  }, [dataProvider]);

  // Carico il piano in base ad eventuali variazione dell'URL.
  useEffect(() => {
    async function fetchData() {
      setState((s) => ({ ...s, loading: s.loading + 1 }));
      const {
        data: { floor },
      } = await dataProvider(GET_FLOOR, "location", {
        floor_id: filter.floor_id,
        device_id: filter.device_id,
        sensor_id: filter.sensor_id,
      });
      setState((s) => ({
        ...s,
        loading: s.loading - 1,
        floor: floor
          ? {
              ...floor,
              id: parseInt(floor.id, 10),
              locations: floor.locations.map((location) => ({
                ...location,
                id: parseInt(location.id, 10),
              })),
            }
          : null,
      }));
    }
    if (
      floor === null ||
      (filter.floor_id && floor.id !== parseInt(filter.floor_id, 10))
    ) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider, filter.floor_id, filter.device_id, filter.sensor_id]);

  // Carico i dati riferiti al piano/dispositivi/sensori coinvolti.
  // Ricostruisco la mappa delle informazioni partendo dalle sole rilevazioni.
  const treePath = floor?.tree_path;
  useEffect(() => {
    async function fetchData(treePath) {
      setState((s) => ({ ...s, loading: s.loading + 1 }));
      const {
        data: { measurements },
      } = await dataProvider(GET_LAST_MEASUREMENTS, "measurement", {
        tree_path: treePath,
      });
      const devices = deduce(measurements);
      setState((s) => ({
        ...s,
        devices,
        loading: s.loading - 1,

        sensorTypes: [],
        selectedSensorTypes: [],
        selectedChoropletSensorType: null,
      }));
    }
    if (treePath) {
      fetchData(treePath);
    }
  }, [dataProvider, treePath]);

  // If data has no changes for more then 500ms we update the map.
  const debouncedWebsocket = useDebounce(websocket?.reload, 500);
  useEffect(
    () => {
      if (shouldMerge(devices, websocket)) {
        setState((s) => ({
          ...s,
          devices: merge(devices, websocket),
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedWebsocket]
  );

  const isRefreshingFromURLFilter = useRef(false);
  // Se cambiano i filtri nell'URL del browser devo aggiornare la mappatura.
  useEffect(() => {
    if (isRefreshingFromURLFilter.current) {
      isRefreshingFromURLFilter.current = false;
      return;
    }
    isRefreshingFromURLFilter.current = true;
    setState((s) => ({
      ...s,
      zoom: filter.zoom || s.zoom,
      center: [filter.lat || s.center[0], filter.lng || s.center[1]],
      deviceId: filter?.device_id,
      sensorId: filter?.sensor_id,
    }));
  }, [filter.zoom, filter.lat, filter.lng, filter.device_id, filter.sensor_id]);

  // Generlo la struttura dati necessari a consultare la mappa.
  useEffect(() => {
    const { events, sensorTypes } = prepare(devices);
    setState((s) => ({
      ...s,
      events,
      sensorTypes,
    }));
  }, [devices]);

  // Aggiorno l'URL del browser con i dati gestiti in mappa.
  const handleMove = (event) => {
    if (isRefreshingFromURLFilter.current) {
      isRefreshingFromURLFilter.current = false;
      return;
    }
    const newZoom = event.target.getZoom();
    const newCenter = event.target.getCenter();

    document.location.href = `#/map-view?filter=${JSON.stringify({
      ...filter,
      zoom: newZoom || zoom,
      lat: newCenter.lat || center[0],
      lng: newCenter.lng || center[1],
    })}`;
  };

  const handleDeviceClick = (device) => {
    setState((s) => ({ ...s, deviceId: device.id }));
    document.location.href = `#/map-view?filter=${JSON.stringify({
      ...filter,
      device_id: device.id,
      sensor_id: null,
      sidebar: 1,
    })}`;
  };
  const handleSensorClick = (sensor) => {
    setState((s) => ({
      ...s,
      sensorId: sensor.id,
      deviceId: sensor.device_id,
    }));
    isRefreshingFromURLFilter.current = true;
    document.location.href = `#/map-view?filter=${JSON.stringify({
      ...filter,
      device_id: sensor.device_id,
      sensor_id: sensor.id,
      sidebar: 1,
    })}`;
  };

  const handleSensorTypeChange = (selectedSensorTypes) => {
    setState((s) => ({
      ...s,
      selectedSensorTypes,
    }));
  };
  const handleSensorTypeRadioChange = (selectedChoropletSensorType) => {
    setState((s) => ({
      ...s,
      selectedChoropletSensorType,
    }));
  };

  const handleSidebarClose = (deviceAndSensorFilter) => {
    setState((s) => ({ ...s, ...deviceAndSensorFilter, sidebar: 0 }));

    isRefreshingFromURLFilter.current = true;
    document.location.href = `#/map-view?filter=${JSON.stringify({
      ...filter,
      device_id: get(deviceAndSensorFilter, "deviceId", null),
      sensor_id: get(deviceAndSensorFilter, "sensorId", null),
      sidebar: 0,
    })}`;
  };

  return (
    <Paper elevation={0} className={classnames(classes.root)}>
      <Map
        center={center}
        zoom={zoom}
        className={classes.map}
        onMoveEnd={handleMove}
      >
        <TileLayer
          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxZoom={19}
        />
        {loading > 0 && <CircularProgress className={classes.loader} />}
        <MapLayer
          deviceId={deviceId}
          sensorId={sensorId}
          floor={floor}
          devices={devices}
          buildings={buildings}
          zoom={zoom}
          choropletSensorType={selectedChoropletSensorType}
          selectedSensorTypes={selectedSensorTypes}
          onSensorClick={handleSensorClick}
          onDeviceClick={handleDeviceClick}
        />

        <FilterBox
          items={sensorTypes}
          radio={selectedChoropletSensorType}
          selection={selectedSensorTypes}
          onChange={handleSensorTypeChange}
          onRadioChange={handleSensorTypeRadioChange}
        />
        {selectedChoropletSensorType !== null && (
          <Legend sensorType={selectedChoropletSensorType} />
        )}
      </Map>

      <SnackEvent
        open={deviceId === null && sensorId === null}
        events={events}
        selectedSensorTypes={selectedSensorTypes}
      />
      <Sidebar
        open={sidebar === 1}
        devices={devices}
        events={events}
        deviceId={deviceId}
        sensorId={sensorId}
        onClose={handleSidebarClose}
        onSensorClick={handleSensorClick}
      />
      <TreeFilter visible />
    </Paper>
  );
});

export default compose(
  withWebsocket,
  connect((state) => ({})),
  withDataProvider
)(Show);
