import React, { Component } from "react";
import { SaveButton, crudCreate, crudUpdate, withTranslate } from "react-admin";

import { compose } from "recompose";
import { connect } from "react-redux";
import { get } from "lodash";

const saveAndSendCredentials = (values, basePath, redirectTo) => {
  const update = parseInt(get(values, "id", 0), 10) > 0;
  if (update) {
    return crudUpdate(
      "user",
      values.id,
      { ...values, send_credentials: true },
      null,
      basePath,
      redirectTo,
      true
    );
  } else {
    return crudCreate(
      "user",
      { ...values, send_credentials: true },
      basePath,
      redirectTo
    );
  }
};
class SaveAndSendCredentialsButtonView extends Component {
  handleClick = () => {
    const {
      basePath,
      handleSubmit,
      redirect,
      saveAndSendCredentials,
    } = this.props;

    return handleSubmit((values) => {
      saveAndSendCredentials(values, basePath, redirect);
    });
  };
  render() {
    const {
      handleSubmitWithRedirect,
      saveAndSendCredentials,
      className,
      translate,
      ...props
    } = this.props;

    return (
      <SaveButton
        className={className}
        label={translate("resources.user.buttons.save_and_send_credentials")}
        handleSubmitWithRedirect={this.handleClick}
        {...props}
      />
    );
  }
}
export default compose(
  withTranslate,
  connect(undefined, {
    saveAndSendCredentials,
  })
)(SaveAndSendCredentialsButtonView);
