import Copyright from "./Copyright";
import React from "react";
import { compose } from "recompose";
import { translate } from "react-admin";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  footer: {
    backgroundColor: theme.palette.primary.main,
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: -theme.spacing.unit * 5,
    paddingTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 6,
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing.unit * 6,
      paddingBottom: theme.spacing.unit * 6,
      marginTop: -theme.spacing.unit * 3,
    },
    [theme.breakpoints.up("xs")]: {
      marginTop: -theme.spacing.unit * 2.5,
    },
    flex: 1,
  },
});

const Footer = ({ classes, translate }) => {
  return (
    <footer className={classes.footer}>
      <Copyright />
    </footer>
  );
};
export default compose(withStyles(styles), translate)(Footer);
