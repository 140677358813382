import { LoadingIndicator, UserMenu } from "react-admin";
import React, { Children, cloneElement } from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import { LEFT_SIDEBAR_WIDTH } from "../config";
import MenuIcon from "@material-ui/icons/Menu";
import MuiAppBar from "@material-ui/core/AppBar";
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { toggleSidebar as toggleSidebarAction } from "ra-core";
import withWidth from "@material-ui/core/withWidth";

const styles = (theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: LEFT_SIDEBAR_WIDTH,
      width: `calc(100% - ${LEFT_SIDEBAR_WIDTH - 1}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginLeft: 6,
      marginRight: 12,
    },
    hide: {
      display: "none",
    },
  });

const AppBarLayout = ({
  children,
  classes,
  className,
  logo,
  logout,
  open,
  title,
  toggleSidebar,
  userMenu,
  width,
  ...rest
}) => (
  <MuiAppBar
    position="absolute"
    className={classNames(classes.appBar, open && classes.appBarShift)}
    color="secondary"
    {...rest}
  >
    <Toolbar disableGutters={!open}>
      <IconButton
        color="inherit"
        onClick={toggleSidebar}
        className={classNames(classes.menuButton, open && classes.hide)}
      >
        <MenuIcon />
      </IconButton>
      {Children.count(children) === 0 ? (
        <Typography
          variant="title"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
      ) : (
        children
      )}
      <LoadingIndicator />
      {cloneElement(userMenu, { logout })}
    </Toolbar>
  </MuiAppBar>
);

AppBarLayout.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  logout: PropTypes.element,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  userMenu: PropTypes.node,
  width: PropTypes.string,
};

AppBarLayout.defaultProps = {
  userMenu: <UserMenu />,
};

const enhance = compose(
  connect(
    (state) => ({
      locale: state.i18n.locale, // force redraw on locale change
    }),
    {
      toggleSidebar: toggleSidebarAction,
    }
  ),
  withStyles(styles),
  withWidth()
);

export default enhance(AppBarLayout);
