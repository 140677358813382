import {
  ArrayInput,
  BooleanInput,
  FormDataConsumer,
  SimpleFormIterator,
  TextInput,
  translate,
} from "react-admin";

import AttrConfigNameInput from "./AttrConfigNameInput";
import AttrTypeInput from "./AttrTypeInput";
import React from "react";
import classnames from "classnames";
import compose from "recompose/compose";
import { get } from "lodash";
import useFieldLabel from "../../components/useFieldLabel";
import { withStyles } from "@material-ui/core";

const AttrsInput = withStyles((theme) => ({
  root: {
    width: "100%",
    "& .ra-input": {
      width: "auto",
      display: "inline",
      marginRight: theme.spacing.unit * 2,
    },
    "& >label": {
      fontSize: 20,
      marginBottom: theme.spacing.unit * 0.5,
    },
    "& [class*=SimpleFormIterator-index]": {
      paddingTop: "2.5rem",
    },
    "& [class*=MuiFormGroup-root]": {
      width: "auto",
      display: "inline-block",
    },
  },
  config: {
    display: "block",
    width: "100%",
    "& >label": {
      fontSize: 20,
      marginTop: -theme.spacing.unit * 2,
    },
  },
  inlineInput: {
    display: "inline",
    float: "left",
    marginRight: theme.spacing.unit * 2,
  },
  hidden: {
    display: "none",
    visibility: "hidden",
  },
}))(({ classes, ...props }) => {
  const fieldLabel = useFieldLabel({ ...props, resource: "thing-type-attr" });
  const fieldLabelProp = useFieldLabel({
    ...props,
    resource: "thing-type-attr-prop",
  });
  return (
    <ArrayInput {...props} className={classes.root}>
      <SimpleFormIterator>
        <TextInput
          source="name"
          label={fieldLabel("name")}
          className={classes.input}
        />
        <FormDataConsumer>
          {({ ...rest }) => (
            <AttrTypeInput
              {...rest}
              source={rest.getSource("type")}
              label={fieldLabel("type")}
              className={classes.input}
            />
          )}
        </FormDataConsumer>

        <ArrayInput
          className={classes.config}
          source="config"
          label={fieldLabel("config")}
        >
          <SimpleFormIterator>
            <FormDataConsumer key="data">
              {({ formData, scopedFormData, getSource, ...rest }) => {
                const parent = get(getSource("").split("."), "0");
                const data = get(formData, parent);
                const type = get(data, "type", "none");
                return (
                  <AttrConfigNameInput
                    {...rest}
                    key={`${parent}-${type}`}
                    type={type}
                    label={fieldLabelProp("name")}
                    className={classnames(classes.input)}
                    source={getSource("name")}
                  />
                );
              }}
            </FormDataConsumer>

            <TextInput
              label={fieldLabelProp("value")}
              className={classes.input}
              source="value"
            />
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput
          source="is_required"
          label={fieldLabel("is_required")}
          className={classes.input}
        />
        <BooleanInput
          source="is_favorite"
          label={fieldLabel("is_favorite")}
          className={classes.input}
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
});
export default compose(translate)(AttrsInput);
