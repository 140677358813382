import { SimpleForm, TextInput } from "react-admin";

import AuthTypeInput from "./inputs/AuthTypeInput";
import GroupsInput from "../components/inputs/GroupsInput";
import React from "react";
import RoleInput from "./inputs/RoleInput";
import RolesInput from "./inputs/RolesInput";
import StatusInput from "./inputs/StatusInput";
import Toolbar from "./Toolbar";

const Form = (props) => (
  <SimpleForm {...props} toolbar={<Toolbar />} redirect="list">
    <TextInput source="username" />
    <TextInput source="password" type="password" />
    <TextInput source="email" type="email" />
    <AuthTypeInput source="auth_type" />
    <RoleInput source="role" />
    <StatusInput source="status" />
    <TextInput source="profile.name" />
    <TextInput source="profile.surname" />
    <GroupsInput source="groups" />
    <RolesInput source="roles" />
  </SimpleForm>
);
export default Form;
