import React from "react";
import {
  ArrayInput,
  TextInput,
  SelectInput,
  ReferenceInput,
  SimpleFormIterator
} from "react-admin";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2
  },
  input: {
    marginRight: theme.spacing.unit * 1,
    float: "left",
    width: "45%"
  }
});

const SensorsInput = withStyles(styles)(({ classes, ...props }) => (
  <ArrayInput {...props} className={classes.root}>
    <SimpleFormIterator>
      <ReferenceInput
        reference="sensor-type"
        label={"resources.device-type.fields.sensor_type"}
        source={"sensor_type_id"}
        className={classes.input}
      >
        <SelectInput />
      </ReferenceInput>

      <TextInput
        className={classes.input}
        label={"resources.device-type.fields.sensor_code"}
        source={"sensor_code"}
        defaultValue=""
      />
    </SimpleFormIterator>
  </ArrayInput>
));

export default SensorsInput;
