import { API_URL } from "../config";
import { get } from "lodash";
import { getToken } from "../authBody";
import moment from "moment";

export const PUSH_MEASUREMENT = "WS_PUSH_MEASUREMENT";
export const PUSH_EVENT = "WS_PUSH_EVENT";
export const PUSH_CAKE_LOG = "WS_PUSH_CAKE_LOG";
export const REQUEST_REFRESH_DATA = "WS_REQUEST_REFRESH_DATA";
export const RECEIVE_REFRESH_DATA = "WS_RECEIVE_REFRESH_DATA";

export const pushMeasurement = (payload) => ({
  type: PUSH_MEASUREMENT,
  payload,
});
export const pushEvent = (payload) => ({
  type: PUSH_EVENT,
  payload,
});

export const refreshData = ({ dispatch, target, ids }) => {
  if (!ids || ids.length === 0) {
    return;
  }
  dispatch({ type: REQUEST_REFRESH_DATA, payload: { target, ids } });
  const url = `${API_URL}/measurement/last`;
  fetch(url, {
    method: "POST",
    body: JSON.stringify({ token: getToken(), [`${target}_ids`]: ids }),
  })
    .then((response) => response.json())
    .then((response) => {
      const measurements = response.measurements
        .filter((m) => get(m, "data.value", null) !== null)
        .map((m) => ({
          sensor_id: parseInt(get(m, "sensor.id"), 10),
          device_id: parseInt(get(m, "device.id"), 10),
          formatter: get(m, "sensor.sensor_type.formatter"),
          unit: get(m, "sensor.sensor_type.unit"),
          value: parseFloat(get(m, "data.value")),
          created: moment(get(m, "data.created"), "YYYY-MM-DD HH:mm:ss"),
        }));
      dispatch({ type: RECEIVE_REFRESH_DATA, payload: { measurements } });
    });
};
