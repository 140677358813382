import { CardActions, RefreshButton } from "react-admin";

import CreateButton from "./buttons/CreateButton";
import React from "react";

const Actions = ({
  bulkActions,
  basePath,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    <RefreshButton />

    <CreateButton
      basePath={basePath}
      label="ra.action.create"
      filterValues={filterValues}
    />
  </CardActions>
);

export default Actions;
