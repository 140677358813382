import {
  Button,
  Divider,
  FormHelperText,
  Tooltip,
  createStyles,
  withStyles,
} from "@material-ui/core";
import {
  EXEC_CODING_SAVATAGE,
  EXEC_CODING_SIMULATION,
  EXEC_CODING_TEMPLATE_LOAD,
} from "../../addCodingFeature";
import { Labeled, REDUX_FORM_NAME, addField, translate } from "react-admin";
import React, { useState } from "react";
import { highlight, languages } from "prismjs/components/prism-core";
import { showNotification, withDataProvider } from "ra-core";

import BackupIcon from "@material-ui/icons/Backup";
import Editor from "react-simple-code-editor";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SaveIcon from "@material-ui/icons/Save";
import { change } from "redux-form";
import compose from "recompose/compose";
import { connect } from "react-redux";

require("prismjs/components/prism-markup-templating.js");
require("prismjs/components/prism-clike.js");
require("prismjs/components/prism-php.js");
require("prismjs/themes/prism.css");

const styles = (theme) =>
  createStyles({
    root: {
      "& > div:first-child": {
        width: "100%",
      },
    },
    editorRoot: {
      maxHeight: 55 * 10,
      overflow: "auto",
      width: "100%",
      display: "block",
      "& textarea": {
        outline: 0,
      },
    },
    errorDivider: {
      backgroundColor: "red",
      height: 2,
    },
    divider: {
      height: 1,
      opacity: 0.42,
      backgroundColor: "black",
    },
    toolbar: {
      paddingTop: theme.spacing.unit * 1,
    },
    toolbarButton: {
      marginTop: theme.spacing.unit * 1,
      marginRight: theme.spacing.unit * 1,
    },
    console: {
      clear: "both",
      paddingTop: theme.spacing.unit * 1,
    },
    consoleTimestamp: {
      marginRight: theme.spacing.unit * 1,
    },
    errorLine: {
      color: "red",
      fontFamily: "Fira Code, Consolas, Courier New",
    },
    infoLine: {
      fontFamily: "Fira Code, Consolas, Courier New",
    },
  });
const handleToolbarItemClick = (
  dataProvider,
  { resource, action, callback, code = undefined, record = undefined }
) =>
  dataProvider(action, resource, { code, record }).then((response) =>
    callback(response)
  );

const ButtonGroup = ({ children, ...props }) => <div>{children}</div>;

const CodeEditorToolbar = ({
  classes,
  translate,
  resource,
  dataProvider,
  code,
  record,
  onLoad,
  onSimulate,
  onSave,
}) => (
  <ButtonGroup className={classes.toolbar}>
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={translate(`components.code_editor.actions.run`)}
    >
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.toolbarButton}
        onClick={handleToolbarItemClick.bind(this, dataProvider, {
          resource,
          action: EXEC_CODING_SIMULATION,
          callback: onSimulate,
          code: code,
        })}
      >
        <PlayArrowIcon />
      </Button>
    </Tooltip>
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={translate(`components.code_editor.actions.save`)}
    >
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.toolbarButton}
        onClick={handleToolbarItemClick.bind(this, dataProvider, {
          resource,
          action: EXEC_CODING_SAVATAGE,
          callback: onSave,
          code: code,
          record,
        })}
      >
        <SaveIcon />
      </Button>
    </Tooltip>
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={translate(`components.code_editor.actions.load`)}
    >
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.toolbarButton}
        onClick={handleToolbarItemClick.bind(this, dataProvider, {
          resource,
          action: EXEC_CODING_TEMPLATE_LOAD,
          callback: onLoad,
        })}
      >
        <BackupIcon />
      </Button>
    </Tooltip>
  </ButtonGroup>
);
const CodeEditorInput = ({
  record,
  source,
  translate,
  resource,
  classes,
  dataProvider,
  meta: { touched, error },
  dispatch,
  toolbar = true,
}) => {
  const [code, setCode] = useState(record[source] || "");
  const handleChange = (code) => {
    setCode(code);
    dispatch(change(REDUX_FORM_NAME, source, code));
  };
  return (
    <div className={classes.root}>
      <Labeled
        meta={{ touched, error }}
        label={translate(`resources.${resource}.fields.${source}`)}
      >
        <div className={classes.editorRoot}>
          <Editor
            value={code}
            className={"ide"}
            onValueChange={handleChange}
            highlight={(code) => highlight(code, languages.php)}
            padding={0}
            style={{
              fontFamily: "Fira Code, Consolas, Courier New",
              fontSize: 15,
              padding: 0,
              margin: 0,
              minHeight: 55,
              border: "white",
              outline: "none",
              width: "100%",
            }}
          />
        </div>
      </Labeled>

      <Divider
        className={
          (!!touched && error && classes.errorDivider) || classes.divider
        }
      />
      {!!touched && error && <FormHelperText error>{error}</FormHelperText>}
      {toolbar === true && (
        <CodeEditorToolbar
          dataProvider={dataProvider}
          resource={resource}
          translate={translate}
          classes={classes}
          code={code}
          record={record}
          onLoad={(response) => setCode(response.data)}
          onSave={({ data }) =>
            dispatch(
              showNotification(
                data.message,
                data.error !== false ? "warning" : "info"
              )
            )
          }
          onSimulate={({ data }) =>
            dispatch(
              showNotification(
                JSON.stringify(data.conversion, 2) ||
                  data.notes ||
                  data.output ||
                  data.message,
                data.error !== false ? "warning" : "success"
              )
            )
          }
        />
      )}
    </div>
  );
};
export default compose(
  addField,
  translate,
  withStyles(styles),
  withDataProvider,
  connect()
)(CodeEditorInput);
