import React from "react";
import compose from "recompose/compose";
import { styles } from "./styles";
import { LinearProgress, withStyles } from "@material-ui/core";
import TreeItem from "./TreeItem";

const LoaderNode = compose(withStyles(styles))(({ classes }) => (
  <TreeItem
    nodeId="loader"
    label={
      <span className={classes.loader}>
        <LinearProgress
          color="secondary"
          size={16}
          className={classes.loaderIcon}
        />
      </span>
    }
  />
));

export default LoaderNode;
