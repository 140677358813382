import React, { Fragment } from "react";

import Building from "./Building";
import Floor from "./Floor";

const MapLayer = ({
  buildings,
  devices,
  floor,
  zoom,
  deviceId,
  sensorId,
  choropletSensorType,
  selectedSensorTypes,
  onDeviceClick,
  onSensorClick,
}) => {
  return (
    <Fragment>
      {floor === null &&
        buildings &&
        buildings.map((building) => (
          <Building key={building.id} building={building} />
        ))}
      {floor !== null && zoom > 18 && (
        <Floor
          zoom={zoom}
          floor={floor}
          deviceId={deviceId}
          sensorId={sensorId}
          devices={devices}
          choropletSensorType={choropletSensorType}
          selectedSensorTypes={selectedSensorTypes}
          onDeviceClick={onDeviceClick}
          onSensorClick={onSensorClick}
        />
      )}
    </Fragment>
  );
};

export default MapLayer;
