import { CheckboxGroupInput, ReferenceArrayInput } from "react-admin";

import React from "react";
import { grey } from "@material-ui/core/colors";
import useIsAdmin from "../../useIsAdmin";
import usePermissions from "../../usePermissions";
import { withStyles } from "@material-ui/core";

// record -> input
const format = (roles) => {
  let formattedData = roles ? roles.map((g) => g.role_id.toString()) : [];
  return formattedData;
};

// input -> record
const parse = (roles) => {
  let parsedData = roles ? roles.map((id) => ({ role_id: id.toString() })) : [];
  return parsedData;
};

const Checkboxes = ({ ...props }) => (
  <CheckboxGroupInput style={{ width: "100%" }} {...props} />
);

const RolesInput = withStyles((theme) => ({
  root: {
    width: "100%",
    "& legend": {
      fontSize: 20,
      marginBottom: theme.spacing.unit * 0.5,
    },
    "& div": {
      borderTopColor: grey[300],
      borderTopStyle: "solid",
      borderTopWidth: 1,
      paddingTop: theme.spacing.unit,
    },
    "& label[for]": {
      width: 250,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& label[for] > span:first-child": {
      display: "flex",
      alignSelf: "flex-start",
    },
    "& label[for] > span:nth-child(2)": {
      marginTop: theme.spacing.unit * 0.5,
      alignSelf: "normal",
    },
  },
}))(({ classes, ...props }) => {
  const permissions = usePermissions();
  const isAdmin = useIsAdmin({ permissions });
  if (!isAdmin) {
    return null;
  }
  return (
    <ReferenceArrayInput
      {...props}
      format={format}
      parse={parse}
      reference="role"
      className={classes.root}
    >
      <Checkboxes />
    </ReferenceArrayInput>
  );
});

export default RolesInput;
