import AppSettingIcon from "@material-ui/icons/Settings";
import BloodBlastIcon from "@material-ui/icons/AcUnit";
import BloodIcon from "@material-ui/icons/InvertColors";
import CheckIcon from "@material-ui/icons/Check";
import CronJobIcon from "@material-ui/icons/AlarmOn";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DeviceIcon from "@material-ui/icons/Router";
import DeviceLabelIcon from "@material-ui/icons/Label";
import DeviceTypeIcon from "@material-ui/icons/Dns";
import EmailTemplateIcon from "@material-ui/icons/Mail";
import EmailThemeIcon from "@material-ui/icons/Layers";
import EventIcon from "@material-ui/icons/NotificationsActive";
import EventNotifierIcon from "@material-ui/icons/Send";
import GroupDocIcon from "@material-ui/icons/Description";
import GroupIcon from "@material-ui/icons/Group";
import LocationIcon from "@material-ui/icons/Business";
import MeasurementHourlyIcon from "@material-ui/icons/QueryBuilder";
import MeasurementsIcon from "@material-ui/icons/RssFeed";
import MonitorIcon from "@material-ui/icons/VerifiedUser";
import PictureIcon from "@material-ui/icons/CameraAlt";
import RoleIcon from "@material-ui/icons/Lock";
import SensorEventUnarmedIcon from "@material-ui/icons/PortableWifiOff";
import SensorIcon from "@material-ui/icons/CastConnected";
import SensorTypeIcon from "@material-ui/icons/SettingsInputComponent";
import SentinelIcon from "@material-ui/icons/AddAlert";
import SocketMonitorIcon from "@material-ui/icons/Laptop";
import TechnicalDocIcon from "@material-ui/icons/Pageview";
import ThingIcon from "@material-ui/icons/CenterFocusStrong";
import ThingTypeIcon from "@material-ui/icons/Layers";
import UserFieldIcon from "@material-ui/icons/TextFields";
import UserIcon from "@material-ui/icons/Face";
import ValueConverterIcon from "@material-ui/icons/CallSplit";
import ValueFilterIcon from "@material-ui/icons/FindReplace";
import LanguageMessageIcon from "@material-ui/icons/Flag";

export {
  AppSettingIcon,
  BloodBlastIcon,
  BloodIcon,
  CheckIcon,
  CronJobIcon,
  DashboardIcon,
  DeviceIcon,
  DeviceLabelIcon,
  DeviceTypeIcon,
  EmailTemplateIcon,
  EmailThemeIcon,
  EventIcon,
  EventNotifierIcon,
  GroupDocIcon,
  GroupIcon,
  LocationIcon,
  MeasurementHourlyIcon,
  MeasurementsIcon,
  MonitorIcon,
  PictureIcon,
  RoleIcon,
  SensorEventUnarmedIcon,
  SensorIcon,
  SensorTypeIcon,
  SentinelIcon,
  SocketMonitorIcon,
  TechnicalDocIcon,
  ThingIcon,
  ThingTypeIcon,
  UserFieldIcon,
  UserIcon,
  ValueConverterIcon,
  ValueFilterIcon,
  LanguageMessageIcon,
};
export default {
  AppSettingIcon,
  BloodBlastIcon,
  BloodIcon,
  CheckIcon,
  CronJobIcon,
  DashboardIcon,
  DeviceIcon,
  DeviceLabelIcon,
  DeviceTypeIcon,
  EmailTemplateIcon,
  EmailThemeIcon,
  EventIcon,
  EventNotifierIcon,
  GroupDocIcon,
  GroupIcon,
  LocationIcon,
  MeasurementHourlyIcon,
  MeasurementsIcon,
  MonitorIcon,
  PictureIcon,
  RoleIcon,
  SensorEventUnarmedIcon,
  SensorIcon,
  SensorTypeIcon,
  SentinelIcon,
  SocketMonitorIcon,
  TechnicalDocIcon,
  ThingIcon,
  ThingTypeIcon,
  UserFieldIcon,
  UserIcon,
  ValueConverterIcon,
  ValueFilterIcon,
  LanguageMessageIcon,
};
