import {
  ArrayField,
  DateField,
  EditButton,
  FormInput,
  SingleFieldList,
  TextField,
} from "react-admin";
import {
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Fragment, useMemo } from "react";

import ChipField from "../components/fields/ChipField";
import SensorValueField from "../components/fields/SensorValueField";
import { compose } from "recompose";
import { get } from "lodash";
import { grey } from "@material-ui/core/colors";
import withCardStyles from "../components/list/withCardStyles";

const Sensors = withStyles((theme) => ({
  cardContainer: {
    marginBottom: theme.spacing.unit * 2,
    "& > div": {
      height: "100%",
    },
  },
  card: {
    backgroundColor: grey[300],
    padding: theme.spacing.unit,
  },
}))(({ sensors, websocket, classes }) => (
  <Fragment>
    {sensors.length > 0 && <Divider />}
    {sensors.length > 0 && <br />}
    <Grid container spacing={16}>
      {sensors.map((sensor) => (
        <Grid
          item
          className={classes.cardContainer}
          key={sensor.id}
          xs={6}
          sm={4}
        >
          <Paper className={classes.card} elevation={0}>
            <Typography variant="body2">{sensor.name}</Typography>
            <SensorValueField record={sensor} websocket={websocket} />
          </Paper>
        </Grid>
      ))}
    </Grid>
  </Fragment>
));

const Card = ({ classes, websocket, ...props }) => {
  const sensors = useMemo(
    () => get(props, "record.sensors", []).filter((s) => s.name !== s.code),
    [props]
  );
  return (
    <Fragment>
      <CardHeader
        className={classes.header}
        title={<TextField source="name" {...props} variant="title" />}
      />
      <CardContent className={classes.content}>
        <FormInput {...props} source="code" input={<TextField addLabel />} />
        <FormInput
          {...props}
          source="mac_address"
          input={<TextField addLabel />}
        />
        <FormInput
          {...props}
          source="modified"
          input={<DateField showTime addLabel />}
        />
        <FormInput
          {...props}
          source="groups"
          input={
            <ArrayField addLabel>
              <SingleFieldList linkType={false}>
                <ChipField source="group_code" />
              </SingleFieldList>
            </ArrayField>
          }
        />
        <Sensors sensors={sensors} websocket={websocket} />
      </CardContent>
      <CardActions className={classes.actions}>
        <EditButton {...props} />
      </CardActions>
    </Fragment>
  );
};
export default compose(withCardStyles)(Card);
