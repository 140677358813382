import ArrowIcon from "@material-ui/icons/ArrowForward";
import { Grid } from "@material-ui/core";
import Link from "../../components/Link";
import React from "react";
import compose from "recompose/compose";
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslate } from "react-admin";

const styles = (theme) => ({
  eventsFullList: {
    display: "flex",
    alignItems: "center",
    fontSize: 16,
  },
});

const GoToEventPageLink = ({ translate, classes, fullWidth = false }) => (
  <Grid
    item
    lg={fullWidth ? 12 : 3}
    md={fullWidth ? 12 : 3}
    xs={12}
    style={{
      display: "flex",
      justifyContent: "flex-end",
      marginTop: fullWidth ? 30 : 0,
      marginBottom: fullWidth ? 30 : 0,
    }}
  >
    <Link
      variant="body1"
      href={`#/event`}
      onClick={(e) => e.stopPropagation()}
      className={classes.eventsFullList}
    >
      {translate("dashboard.full_list").toUpperCase()}{" "}
      <ArrowIcon fontSize="inherit" />
    </Link>
  </Grid>
);
export default compose(withStyles(styles), withTranslate)(GoToEventPageLink);
