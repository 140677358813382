import { Typography, withWidth } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MuiAppBar from "@material-ui/core/AppBar";
import MuiLink from "../../components/Link";
import PropTypes from "prop-types";
import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import { compose } from "recompose";
import { connect } from "react-redux";
import logo from "../../assets/logo-white.png";
import { setSidebarVisibility } from "../actions/app";
import { translate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },

  logo: {
    margin: 0,
  },
});

const AppBar = ({ dispatch, classes, open, width, translate, ...props }) => {
  const isDesktop = width === "lg" || width === "md" || width === "xl";
  const toggleSidebar = () => dispatch(setSidebarVisibility(!open));
  return (
    <div className={classes.root}>
      <MuiAppBar position="fixed">
        <Toolbar>
          {!isDesktop && (
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={() => toggleSidebar()}
            >
              <MenuIcon />
            </IconButton>
          )}

          <MuiLink
            href="/home/"
            underline="none"
            variant="button"
            className={classes.title}
            color="inherit"
          >
            <img
              className={classes.logo}
              src={logo}
              alt={translate("app.name")}
            />
          </MuiLink>
          <Typography variant="body1" className={classes.flex}>
            &nbsp;
          </Typography>
          {isDesktop && (
            <Button href="/dashboard/" color="inherit">
              {translate("ra.auth.login")}
            </Button>
          )}
        </Toolbar>
      </MuiAppBar>
    </div>
  );
};

AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withWidth(),
  translate,
  connect((state) => ({
    open: state.app.sidebarOpen,
  }))
)(AppBar);
