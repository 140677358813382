import "./index.css";
import "typeface-roboto";

import * as serviceWorker from "./serviceWorker";

import { BrowserRouter, Route } from "react-router-dom";

import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import Website from "./website/App";

const Proxy = () =>
  /**
   * 2020-02-25 18:08 di un giorno da "Coronavirus"
   *
   * Perché questo approccio?
   *
   * React-Admin soffre di un bug su cui non mi sono soffermato molto, poiché
   * è davvero difficile poterne venire a capo (pur avendo dato un'occhiata
   * ai sorgenti ra-core) per cui, se l'app è istanziata posizionandola all'interno
   * di un altro router (di react-router-dom), tutte le chiamate ajax, ma più in generale
   * anche alcune funzioni dell'app come la posizione dei filtri e alcune specifiche grafiche,
   * vanno letteralmente in tilt, le chiamate si duplicano ed il posizionamento degli elementi
   * smette di funzionare.
   *
   * La soluzione che devi di seguito, una IF, è stata pensata in 3 ore.
   *
   * Vaffanculo
   * Roberto
   */
  document.location.href.split("#")[0].endsWith("/dashboard/") ? (
    <App />
  ) : (
    <BrowserRouter>
      <Route path="/home" component={Website} />
    </BrowserRouter>
  );
ReactDOM.render(<Proxy />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
