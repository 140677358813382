import moment from "moment";

export default measurements => {
  const ticks = measurements
    .reduce(
      (ticks, measurement) =>
        ticks.indexOf(measurement.created) !== -1
          ? ticks
          : ticks.concat([measurement.created]),
      []
    )
    .sort(
      (a, b) =>
        moment(a, "YYYY-MM-DD HH:mm:ss").toDate() -
        moment(b, "YYYY-MM-DD HH:mm:ss").toDate()
    );
  return ticks;
};
