import { Button, Drawer, createStyles, withStyles } from "@material-ui/core";
import { Delete, Save } from "@material-ui/icons";
import { LongTextInput, TextInput, withTranslate } from "react-admin";
import React, { useCallback, useMemo } from "react";

import GroupsInput from "../../../components/inputs/GroupsInput";
import TypeInput from "../TypeInput";
import { compose } from "recompose";
import makeid from "../../../utils/makeid";
import useFieldLabel from "../../../components/useFieldLabel";

const styles = createStyles((theme) => ({
  paper: {
    zIndex: 1,
    padding: 0,
    marginTop: 20,
    minWidth: 300,
    "& fieldset": {
      maxWidth: 300,
    },
  },
  heading: {
    padding: theme.spacing.unit * 2,
    textAlign: "right",
  },
  content: {
    padding: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 5,
    maxWidth: 300 - theme.spacing.unit * 2,
  },
  toolbar: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    paddingLeft: theme.spacing.unit * 2,
    paddingBottom: 12,
    paddingTop: 12,
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[100]
        : theme.palette.grey[900],
    marginLeft: 0,
  },
  button: {
    marginRight: theme.spacing.unit * 2,
  },
}));
const SubLocationEditor = ({
  classes,
  index,
  onClose,
  parentType,
  onDelete,
  translate,
  ...props
}) => {
  const fieldLabel = useFieldLabel({ translate, ...props });
  const fieldSource = useCallback(
    (source) => `locations[${index}].${source}`,
    [index]
  );
  const key = useMemo(
    () => `${index >= 0 ? index : "~"}.${makeid(10)}`,
    [index]
  );
  return (
    <Drawer
      variant="persistent"
      open={index >= 0}
      anchor="right"
      className={classes.drawer}
      classes={{ paper: classes.paper }}
    >
      <div className={classes.content} key={key}>
        <TextInput
          {...props}
          label={fieldLabel("name")}
          source={fieldSource("name")}
        />
        <TypeInput
          {...props}
          parentType={parentType}
          label={fieldLabel("type")}
          source={fieldSource("type")}
        />
        {index >= 0 && (
          <GroupsInput
            {...props}
            label={fieldLabel("groups")}
            source={fieldSource("groups")}
          />
        )}
        <LongTextInput
          {...props}
          label={fieldLabel("notes")}
          source={fieldSource("notes")}
        />
      </div>
      <div className={classes.toolbar}>
        <Button
          className={classes.button}
          variant="contained"
          onClick={onClose}
        >
          <Save />
          {translate("ra.action.close")}
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          onClick={onDelete.bind(this, index)}
        >
          <Delete />
          {translate("ra.action.remove")}
        </Button>
      </div>
    </Drawer>
  );
};

export default compose(withTranslate, withStyles(styles))(SubLocationEditor);
