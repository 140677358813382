import { BulkDeleteButton, translate } from "react-admin";
import React, { Fragment } from "react";

import RestartButton from "./buttons/RestartButton";
import { compose } from "recompose";

const BulkActions = ({ translate, ...props }) => (
  <Fragment>
    <RestartButton {...props} />
    <BulkDeleteButton {...props} />
  </Fragment>
);

export default compose(translate)(BulkActions);
