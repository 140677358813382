import Create from "./Create";
import { CronJobIcon } from "../icons";
import Edit from "./Edit";
import List from "./List";

export default {
  options: {
    group: "admin",
    accessible: ({ user }) =>
      user.role === "admin" && user.permissions.indexOf("cron-job/list") !== -1,
  },
  icon: CronJobIcon,
  list: List,
  edit: Edit,
  create: Create,
};
