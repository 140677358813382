import React, { useMemo } from "react";

import { SelectInput } from "react-admin";

const TYPES = [
  { id: "location", name: "resources.location.types.location" },
  { id: "building", name: "resources.location.types.building" },
  { id: "floor", name: "resources.location.types.floor" },
  { id: "room", name: "resources.location.types.room" },
];

const TypeInput = ({ parentType, ...props }) => {
  const choices = useMemo(() => {
    if (parentType) {
      let index = TYPES.findIndex((type) => type.id === parentType);
      return TYPES.filter((_, i) => i > index);
    }
    return [...TYPES];
  }, [parentType]);
  return <SelectInput {...props} choices={choices} />;
};

export default TypeInput;
