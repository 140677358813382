import { Button, Confirm } from "react-admin";
import React, { Component, Fragment } from "react";

import { API_URL } from "../../config";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { getToken } from "../../authBody";
import makeid from "../../utils/makeid";

class ExportToPdfButton extends Component {
  state = {
    isOpen: false,
    session: makeid(10),
  };

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleConfirm = () => {
    this.setState({ isOpen: false, session: makeid(10) }, () => {
      const { filter } = this.props;
      const token = getToken();
      window.open(
        `${API_URL}/measurement/hourly/print/${token}/doc.pdf?filter=${JSON.stringify(
          filter
        )}`,
        "_blank"
      );
    });
  };

  render() {
    return (
      <Fragment>
        <Button
          label={"resources.measurement-hourly.buttons.export_to_pdf"}
          onClick={this.handleClick}
        >
          <PictureAsPdfIcon />
        </Button>
        <Confirm
          key={this.state.session}
          isOpen={this.state.isOpen}
          title={"resources.measurement-hourly.titles.export_to_pdf"}
          content={"resources.measurement-hourly.messages.export_to_pdf"}
          onConfirm={this.handleConfirm}
          onClose={this.handleDialogClose}
        />
      </Fragment>
    );
  }
}
export default ExportToPdfButton;
