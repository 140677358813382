import { Drawer, Paper, withStyles, withWidth } from "@material-ui/core";
import { INNER_SIDEBAR_WIDTH, LEFT_SIDEBAR_WIDTH } from "../config";
import React, { Fragment, useCallback, useEffect, useRef } from "react";

import InnerSidebarToggleButton from "./InnerSidebarToggleButton";
import PropTypes from "prop-types";
import classnames from "classnames";
import { compose } from "recompose";
import { connect } from "react-redux";
import { isWidthDown } from "@material-ui/core/withWidth";
import { toggleInnerSidebar } from "../ui/actions";

const Sidebar = withStyles((theme) => ({
  root: {
    top: 64,
    width: 0,
    zIndex: 10,
    borderRadius: 0,
    height: "calc(100vh - 64px)",
    position: "fixed",

    left: LEFT_SIDEBAR_WIDTH,
    transition: theme.transitions.create(["width", "padding", "left"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: "hidden",
  },
  visible: {
    height: "calc(100vh - 64px)",
    width: INNER_SIDEBAR_WIDTH,
    overflow: "auto",
  },
  mobilePaper: {
    width: "calc(100vw - 64px)",
  },
  sidebarOpen: {
    left: LEFT_SIDEBAR_WIDTH,
  },
  sidebarClosed: {
    left: 58,
  },
}))(
  ({
    classes,
    sidebarOpen,
    innerSidebarOpen,
    children,
    width,
    dispatch,
    mobileBreakpoint = "xs",
    visible = true,
    toggler = null,
  }) => {
    const toggleSidebar = useCallback(
      () => dispatch(toggleInnerSidebar({ mobileBreakpoint })),
      [dispatch, mobileBreakpoint]
    );
    const isMobile = isWidthDown(mobileBreakpoint, width);
    const Wrapper = !isMobile ? Paper : Drawer;
    const Props = !isMobile
      ? {
          className: classnames(classes.root, {
            [classes.visible]: innerSidebarOpen && !isMobile,
            [classes.sidebarOpen]: sidebarOpen,
            [classes.sidebarClosed]: !sidebarOpen,
          }),
        }
      : {
          anchor: "right",
          variant: "temporary",
          onClose: toggleSidebar,
          open: innerSidebarOpen,
          classes: {
            paper: classes.mobilePaper,
          },
        };

    const didMount = useRef(false);
    useEffect(() => {
      if (!didMount.current && visible && !isMobile) {
        didMount.current = true;
        setTimeout(
          () =>
            dispatch(toggleInnerSidebar({ mobileBreakpoint, visible: true })),
          500
        );
      }
      return () => {
        if (innerSidebarOpen) {
          dispatch(toggleInnerSidebar({ mobileBreakpoint, visible: false }));
        }
      };
    }, [visible, dispatch, mobileBreakpoint, innerSidebarOpen, isMobile]);

    return (
      <Fragment>
        <Wrapper {...Props}>
          {React.Children.map(children, (child) =>
            React.isValidElement(child)
              ? React.cloneElement(child, {
                  innerSidebarOpen,
                  sidebarOpen,
                  width,
                  isMobile,
                })
              : child
          )}
        </Wrapper>
        {toggler &&
          React.cloneElement(toggler, {
            innerSidebarOpen,
            sidebarOpen,
            width,
            isMobile,
          })}
      </Fragment>
    );
  }
);

const InnerSidebar = compose(
  connect((state) => ({
    sidebarOpen: state.admin.ui.sidebarOpen,
    innerSidebarOpen: state.ui.innerSidebarOpen,
  })),
  withWidth()
)(Sidebar);

InnerSidebar.propTypes = {
  visible: PropTypes.bool,
  toggler: PropTypes.element.isRequired,
};
InnerSidebar.defaultProps = {
  visible: true,
  toggler: <InnerSidebarToggleButton />,
};

export default InnerSidebar;
