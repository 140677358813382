import { SimpleForm, TextInput } from "react-admin";

import BodyTemplateInput from "./inputs/BodyTemplateInput";
import JsonEditorInput from "../components/inputs/JsonEditorInput";
import React from "react";

const Form = (props) => (
  <SimpleForm {...props} redirect="list">
    <TextInput source="code" />
    <TextInput source="name" />
    <JsonEditorInput source="body_template_data" />
    <BodyTemplateInput source="body_template" />
  </SimpleForm>
);

export default Form;
