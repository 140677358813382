import { TabbedForm, TextInput, required, translate } from "react-admin";

import AttrsInput from "./inputs/AttrsInput";
import ClassIcon from "@material-ui/icons/Class";
import FormTab from "../components/forms/FormTab";
import GroupsInput from "../components/inputs/GroupsInput";
import PicInput from "./inputs/PicInput";
import React from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import ThingTypeInput from "./inputs/ThingTypeInput";
import { compose } from "recompose";
import useFieldLabel from "../components/useFieldLabel";
import withFormStyles from "../components/forms/withFormStyles";
import { SensorIcon } from "../icons";
import SensorsInput from "./inputs/SensorsInput";

const Form = ({ classes, ...props }) => {
  const fieldLabel = useFieldLabel({ ...props });
  return (
    <TabbedForm {...props} className={classes.root}>
      <FormTab
        label="resources.thing.tabs.config"
        icon={<SettingsIcon />}
        search={props.location?.search}
      >
        <ThingTypeInput source="thing_type_id" validate={required()} />
        <TextInput source="name" />
        <TextInput source="code" helperText={fieldLabel("code.help")} />
        <TextInput source="notes" fullWidth multiline />
        <GroupsInput source="groups" fullWidth />
        <PicInput source="pic" helperText={fieldLabel("pic_file.help")} />
      </FormTab>
      <FormTab
        label="resources.thing.tabs.attrs"
        icon={<ClassIcon />}
        search={props.location?.search}
      >
        <AttrsInput source="attrs" fullWidth />
      </FormTab>
      <FormTab
        label="resources.thing.tab.sensors"
        icon={<SensorIcon />}
        search={props.location?.search}
      >
        <SensorsInput source="sensors" fullWidth />
      </FormTab>
    </TabbedForm>
  );
};
export default compose(translate, withFormStyles)(Form);
