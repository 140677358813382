import React from "react";
import { SelectInput } from "react-admin";

const ICON_TYPES = ["mui", "picture"].map((id) => ({
  id,
  name: id,
}));

const IconTypeInput = (props) => (
  <SelectInput {...props} choices={ICON_TYPES} optionText="name" />
);
export default IconTypeInput;
