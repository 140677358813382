import * as Icons from "@material-ui/icons";

import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Radio,
  withStyles,
} from "@material-ui/core";
import { MapControl, withLeaflet } from "react-leaflet";
import React, { useCallback, useMemo } from "react";

import L from "leaflet";
import ReactDOM from "react-dom";
import WarningIcon from "@material-ui/icons/Warning";
import { compose } from "recompose";
import { orange } from "@material-ui/core/colors";

const Choice = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      "& *": {
        padding: theme.spacing.unit * 0.5,
      },
    },
  },
  warn: {
    color: orange[500],
  },
}))(({ classes, item, radio, checked, onChange, onRadioChange }) => {
  const handleChange = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (onChange) onChange(item, e.target.checked);
      return false;
    },
    [onChange, item]
  );
  const handleRadioChange = useCallback(
    (e) => {
      if (!e.isTrusted) {
        return;
      }
      e.stopPropagation();
      if (onRadioChange) {
        onRadioChange(
          radio !== null && radio.id === item.id ? null : item,
          e.target.checked
        );
      }
      return false;
    },
    [onRadioChange, radio, item]
  );
  const Icon = useMemo(() => Icons[item.icon] || Icons.Storage, [item]);

  return (
    <MenuItem
      className={classes.root}
      key={item.code}
      id={item.code}
      value={item.code}
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText inset primary={item.name} />
      {item.warn > 0 && (
        <ListItemIcon>
          <WarningIcon className={classes.warn} />
        </ListItemIcon>
      )}
      {item.radio > 0 && (
        <Radio
          color="primary"
          checked={radio !== null && radio.id === item.id}
          value={item.id}
          onClick={handleRadioChange}
        />
      )}
      <Checkbox checked={checked} value="secondary" onChange={handleChange} />
    </MenuItem>
  );
});

class FilterBox extends MapControl {
  componentDidMount() {
    super.componentDidMount();
    ReactDOM.render(this.renderComponent(), this.container);
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    if (
      prevProps.items !== this.props.items ||
      prevProps.selection !== this.props.selection ||
      prevProps.radio !== this.props.radio
    ) {
      ReactDOM.render(this.renderComponent(), this.container);
    }
  }

  handleChange(sensorType, checked) {
    const { selection, onChange } = this.props;
    if (!checked) {
      onChange(selection.filter((s) => s.id !== sensorType.id));
    } else {
      onChange(selection.concat([sensorType]));
    }
  }

  handleRadioChange(id) {
    const { onRadioChange } = this.props;
    if (onRadioChange) {
      onRadioChange(id);
    }
  }

  renderComponent() {
    const { classes, items, selection, radio } = this.props;
    if (items.length === 0) {
      return null;
    }
    return (
      <Paper className={classes.root}>
        <MenuList>
          {items.map((choice) => (
            <Choice
              key={choice.id}
              item={choice}
              checked={selection.find((s) => s.id === choice.id) !== undefined}
              radio={radio}
              onChange={this.handleChange.bind(this)}
              onRadioChange={this.handleRadioChange.bind(this)}
            />
          ))}
        </MenuList>
      </Paper>
    );
  }
  createLeafletElement() {
    const { position } = this.props;
    const FilterBox = L.Control.extend({
      onAdd: () => {
        this.container = L.DomUtil.create("div", "");
        return this.container;
      },
    });
    return new FilterBox({ position });
  }
}

FilterBox.defaultProps = {
  position: "bottomleft",
};

export default compose(
  withLeaflet,
  withStyles((theme) => ({
    root: {
      padding: 0,
      margin: 0,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "calc(100vw - 130px)",
        overflow: "hidden",
        "& *": {
          margin: 0,
          padding: theme.spacing.unit * 0.5,
        },
        "& div>span": {
          maxWidth: "calc(100vw - 150px)",
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
      },
    },
  }))
)(FilterBox);
