import { AutocompleteInput, ReferenceInput } from "react-admin";

import React from "react";
import { withStyles } from "@material-ui/core";

const LocationInput = withStyles((theme) => ({
  root: {
    width: "100%",
  },
}))(({ classes, ...props }) => (
  <ReferenceInput
    {...props}
    perPage={10}
    reference="location"
    sort={{ field: "tree_name", order: "ASC" }}
    filter={{ autocomplete: true }}
    resource="device"
    fullWidth
    className={classes.root}
  >
    <AutocompleteInput optionText="tree_name" fullWidth />
  </ReferenceInput>
));
export default LocationInput;
