import { BooleanInput, DateInput, Filter, SearchInput } from "react-admin";

import DeviceTypesSelectInput from "./input/DeviceTypesSelectInput";
import GroupsSelectInput from "./input/GroupsSelectInput";
import React from "react";
import TreeFilter from "../components/tree/TreeFilter";

const Filters = (props) => (
  <Filter {...props}>
    <SearchInput source="subkeyword" alwaysOn />
    <BooleanInput source="is_assigned" />
    <GroupsSelectInput source="groups" />
    <DeviceTypesSelectInput source="device_types" />
    <DateInput source="created" />
    <TreeFilter source="tree" alwaysOn />
  </Filter>
);
export default Filters;
