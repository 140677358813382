import * as Colors from "@material-ui/core/colors";

import { CHOROPLETIC_SHADES } from "../config";

export const calculateSteps = (choropletSensorType) => {
  const max = parseFloat(choropletSensorType.max_value);
  const min = parseFloat(choropletSensorType.min_value);

  const interval = max - min;
  const step = interval / 10;
  let steps = [];
  let index = 0;
  for (let i = min; i < max; i += step) {
    const from = i;
    const to = i + step;
    steps.push({
      label: index < 9 ? `${from} - ${to - 1}` : `${from}+`,
      shade: CHOROPLETIC_SHADES[index],
    });
    index++;
  }

  return steps;
};

export const calculateRange = (choropletSensorType, sensors) => {
  const max = parseFloat(choropletSensorType.max_value);
  const min = parseFloat(choropletSensorType.min_value);
  const unit = choropletSensorType.unit;
  const choropletRelatedSensors = sensors.filter(
    (sensor) => sensor.value && sensor.sensor_type.id === choropletSensorType.id
  );

  // Calcolo la media, potrebbero esserci più sensori dello stesso tipo nella stanza
  const value =
    choropletRelatedSensors
      .map((s) => parseFloat(s.value))
      .reduce((a, b) => a + b, 0) / choropletRelatedSensors.length;

  // Massimo 10 sfumature di colore, quindi massimo 10 step
  const interval = max - min;
  const step = interval / 10;
  let index = 0;
  for (let i = min; i < max; i += step) {
    const from = i;
    const to = i + step;

    if (index < 9 && value >= from && value < to) {
      return `${from} ${unit} - ${to - 1} ${unit}`;
    }

    if (index === 9 && value >= from) {
      return `+ ${from} ${unit}`;
    }

    index++;
  }

  return "";
};

export const calculateColor = (choropletSensorType, sensors) => {
  const choropletRelatedSensors = sensors.filter(
    (sensor) =>
      sensor.sensor_type && sensor.sensor_type.id === choropletSensorType.id
  );

  if (choropletRelatedSensors.length > 0) {
    // Calcolo la media, potrebbero esserci più sensori dello stesso tipo nella stanza
    const value =
      choropletRelatedSensors
        .map((s) => parseFloat(s.value))
        .reduce((a, b) => a + b, 0) / choropletRelatedSensors.length;

    const min = parseFloat(choropletSensorType.min_value);
    const max = parseFloat(choropletSensorType.max_value);

    // Utilizzando i valori minimo e massimo del sensore, mi creo i 10 step
    const interval = max - min;
    const step = interval / 10;

    // Ciclando tutti gli step (che sono sempre 10) controllo che il valore medio sia
    // compreso nell'intervallo. In caso positivo, con l'indice ottengo la sfumatura corretta

    // Esempio
    // 1) Valore minimo sensore = -80, valore massimo sensore = 20
    // 2) Media registrata = 10
    // 3) Intervallo totale = 100 (20 - -80), Step = 10 (100 / 10)
    // 4) -80 = 0, -70 = 1, -60 = 2, -50 = 3, -40 = 4, -30 = 5
    //    -20 = 6, -10 = 7, 0 = 8, 10 = 9
    // 5) Il valore 10 è compreso nell'ultimo intervallo (da 10 a 20, indice 9)
    // 6) shades[9] = 900 (sfumatura massima)
    let index = 0;
    for (let i = min; i < max; i += step) {
      const from = i;
      const to = i + step;
      if (value >= from && value < to) {
        const shade = CHOROPLETIC_SHADES[index];
        return Colors[choropletSensorType.color][shade];
      }

      // Metto l'ultima sfumatura se il valore è uguale o superiore al valore massimo del sensore
      if (index === 9 && value >= from) {
        return Colors[choropletSensorType.color][900];
      }

      index++;
    }
  }

  // Non ritorno nessun colore se è impostata la mappa ma nella stanza
  // non c'è niente da visualizzare
  if (choropletSensorType !== null) {
    return Colors.grey[500];
  }

  return Colors.blue[500];
};
export default {
  calculateSteps,
  calculateColor,
  calculateRange,
};
