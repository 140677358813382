import Create from "./Create";
import Edit from "./Edit";
import List from "./List";
import { UserIcon } from "../icons";

export default {
  options: {
    group: "admin",
    accessible: ({ user }) =>
      user.role === "admin" && user.permissions.indexOf("user/list") !== -1,
  },
  icon: UserIcon,
  list: List,
  edit: Edit,
  create: Create,
};
