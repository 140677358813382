import * as Choroplet from "../data/choroplet";
import * as Colors from "@material-ui/core/colors";

import { Marker, Polygon, Rectangle, Tooltip } from "react-leaflet";
import React, { useMemo } from "react";

import Device from "./Device";
import { SHOW_MARKERS_MIN_ZOOM } from "../config";
import { divIcon } from "leaflet";
import { getRoomCenter } from "../../utils/locations";
import { renderToString } from "react-dom/server";
import { withStyles } from "@material-ui/core";

const Room = withStyles((theme) => ({
  transparentLabel: {
    width: 100,
    marginLeft: -50,
    fontFamily: "Consolas, Courier New",
    textAlign: "center",
  },
}))(
  ({
    classes,
    room,
    devices,
    zoom,
    deviceId,
    sensorId,
    choropletSensorType,
    selectedSensorTypes,
    onDeviceClick,
    onSensorClick,
  }) => {
    const ShapeComponent = useMemo(
      () => (room.shape_type === "polygon" ? Polygon : Rectangle),
      [room]
    );
    const roomDevices = useMemo(
      () =>
        room && devices
          ? devices.filter(
              (device) =>
                device.lat &&
                device.lng &&
                (parseInt(device.location_id, 10) === room.id ||
                  device.sensors.some(
                    (sensor) => parseInt(sensor.location_id, 10) === room.id
                  ))
            )
          : [],
      [room, devices]
    );
    const alarm = useMemo(
      () =>
        roomDevices.some((device) =>
          device.sensors.some((sensor) => sensor.event && !sensor.event.closed)
        ),
      [roomDevices]
    );
    const color = useMemo(() => {
      if (choropletSensorType === null) {
        return null;
      }
      if (alarm) {
        return Colors.red[500];
      }
      const sensors = roomDevices.reduce(
        (sensors, device) => sensors.concat(device.sensors),
        []
      );
      return Choroplet.calculateColor(choropletSensorType, sensors);
    }, [roomDevices, choropletSensorType, alarm]);
    const range = useMemo(() => {
      if (choropletSensorType === null) {
        return null;
      }
      const sensors = roomDevices.reduce(
        (sensors, device) => sensors.concat(device.sensors),
        []
      );
      const range = Choroplet.calculateRange(choropletSensorType, sensors);
      if (range !== null) {
        const position = getRoomCenter(room);
        const icon = divIcon({
          html: renderToString(
            <p className={classes.transparentLabel}>{range}</p>
          ),
        });
        return { position: [position.lat, position.lng], icon };
      }
    }, [room, roomDevices, choropletSensorType, classes]);

    return (
      <ShapeComponent
        positions={room.coords}
        id={room.id}
        color={color}
        code={room.code}
        className={alarm ? "pulse" : ""}
      >
        <Tooltip key={room.id}>{room.name}</Tooltip>
        {range !== null && (
          <Marker position={range.position} icon={range.icon} />
        )}
        {zoom >= SHOW_MARKERS_MIN_ZOOM &&
          roomDevices.map((device) => (
            <Device
              key={device.id}
              device={device}
              zoom={zoom}
              deviceId={deviceId}
              sensorId={sensorId}
              onClick={onDeviceClick}
              onSensorClick={onSensorClick}
              selectedSensorTypes={selectedSensorTypes}
            />
          ))}
      </ShapeComponent>
    );
  }
);

export default Room;
