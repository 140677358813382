import { Button, Snackbar } from "@material-ui/core";
import React, { Fragment, useMemo, useState } from "react";

import SnackAlert from "../../components/SnackAlert";
import WarningIcon from "@material-ui/icons/Warning";
import compose from "recompose/compose";
import moment from "moment";
import orange from "@material-ui/core/colors/orange";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

const EventMessage = ({ event }) => (
  <Fragment>
    #{event.id} - {moment(event.start_at).format("DD/MM/YYYY HH:mm")}
    {event.closed && " - "}
    {event.closed && moment(event.closed).format("HH:mm")}
    <br />
    {event.notes}
    <br />
    {event.device.name} - {event.sensor.name}
  </Fragment>
);

const SnackEvent = withStyles((theme) => ({
  event: {
    margin: theme.spacing.unit * 1,
  },
  button: {
    position: "absolute",
    top: 10,
    right: 32,
    cursor: "pointer",
    zIndex: 1000,
    backgroundColor: orange[500],
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down("sm")]: {
      right: 8,
    },
  },
  icon: {
    width: 22,
    height: 22,
    padding: 3,
    color: "#fff",
  },
}))(({ classes, events, selectedSensorTypes, open = true }) => {
  const [closed, setClosed] = useState([]);
  const { openEvents, closedEvents } = useMemo(
    () => ({
      openEvents: events.filter(
        (e) =>
          !e.closed &&
          closed.indexOf(e.id) === -1 &&
          (selectedSensorTypes.length === 0 ||
            selectedSensorTypes.some(
              (sensorType) => sensorType.id === e.sensor.sensor_type_id
            ))
      ),
      closedEvents: events.filter(
        (e) =>
          e.closed &&
          closed.indexOf(e.id) === -1 &&
          // Voglio mostrare gli eventi chiusi non più vecchi di 3 minuti,
          // dopo non ha più senso mostrarli.
          Math.abs(
            moment.duration(moment(e.closed).diff(moment())).seconds()
          ) <= 180 &&
          (selectedSensorTypes.length === 0 ||
            selectedSensorTypes.some(
              (sensorType) => sensorType.id === e.sensor.sensor_type_id
            ))
      ),
    }),
    [events, closed, selectedSensorTypes]
  );

  const handleClose = (event) =>
    setClosed((closed) => closed.concat([event.id]));
  const handleToggle = () =>
    setClosed((closed) =>
      closed.length === events.length ? [] : events.map((event) => event.id)
    );
  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open && (openEvents.length > 0 || closedEvents.length > 0)}
        style={{
          marginTop: 106,
        }}
      >
        <div>
          {openEvents.map((event) => (
            <SnackAlert
              key={event.id}
              variant="warning"
              className={classes.event}
              message={<EventMessage event={event} />}
              onClose={handleClose.bind(this, event)}
            />
          ))}

          {closedEvents.map((event) => (
            <SnackAlert
              key={event.id}
              variant="success"
              className={classes.event}
              message={<EventMessage event={event} />}
              onClose={handleClose.bind(this, event)}
            />
          ))}
        </div>
      </Snackbar>

      {events.length > 0 && (
        <Button
          variant="contained"
          onClick={handleToggle}
          className={classes.button}
        >
          <WarningIcon />
        </Button>
      )}
    </Fragment>
  );
});
export default compose(withWidth())(SnackEvent);
