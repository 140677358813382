import * as Icons from "@material-ui/icons";

import {
  CardMedia,
  Divider,
  IconButton,
  Typography,
  withStyles,
} from "@material-ui/core";

import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";

const SidebarLayout = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      width: 300,
    },
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      overflow: "hidden",
    },
  },

  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: theme.spacing.unit * 3,
  },
  content: {
    overflowY: "auto",
    overflowX: "auto",
  },
  contentWithMedia: {},
  media: {
    height: 200,
    marginTop: -theme.spacing.unit,
  },
}))(
  ({
    classes,
    media = null,
    title = null,
    subTitle = null,
    onClose,
    children,
    CloseIcon = Icons.Close,
  }) => (
    <div className={classes.root}>
      {media !== null && <CardMedia image={media} className={classes.media} />}
      <div className={classes.header}>
        <div>
          <Typography variant="title">{title}</Typography>
          <Typography variant="subheading">{subTitle}</Typography>
        </div>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <div className={classnames(classes.content)}>{children}</div>
    </div>
  )
);

SidebarLayout.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SidebarLayout;
