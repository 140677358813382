import {
  ArrayField,
  ChipField,
  DateField,
  SingleFieldList,
  TextField,
} from "react-admin";
import React, { Fragment } from "react";

import Actions from "./Actions";
import Card from "./Card";
import EditButton from "./buttons/EditButton";
import Location from "./Location";
import ResponsiveGrid from "../components/list/ResponsiveGrid";
import ResponsiveList from "../components/list/ResponsiveList";
import TreeLinkField from "./fields/TreeLinkField";
import TypeField from "./fields/TypeField";
import { get } from "lodash";

const Grid = (props) => (
  <Fragment>
    <Location
      loading={get(props, "loading")}
      id={get(props, "filterValues.location_id")}
    />
    <ResponsiveGrid {...props} rowBreakpoint="sm" cardContent={<Card />}>
      <TextField source="id" />
      <TypeField source="type" />
      <TreeLinkField source="name">
        <TextField />
      </TreeLinkField>
      <ArrayField source="groups" sortable={false}>
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <DateField source="modified" showTime />
      <EditButton />
    </ResponsiveGrid>
  </Fragment>
);
const List = (props) => (
  <ResponsiveList {...props} actions={<Actions />} type="table" split>
    <Grid />
  </ResponsiveList>
);

export default List;
