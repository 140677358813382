import { withStyles } from "@material-ui/core";

const withToolbarStyles = withStyles((theme) => ({
  toolbar: {
    "& button": {
      marginRight: theme.spacing.unit * 2,
    },
  },
}));
export default withToolbarStyles;
