import { ReferenceInput, SelectInput } from "react-admin";

import React from "react";

const EmailThemeInput = (props) => (
  <ReferenceInput {...props} reference="email-theme">
    <SelectInput optionText="name" />
  </ReferenceInput>
);
export default EmailThemeInput;
