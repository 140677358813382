import { Button, withWidth } from "@material-ui/core";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PropTypes from "prop-types";
import React from "react";
import compose from "recompose/compose";
import { isWidthUp } from "@material-ui/core/withWidth";
import { withTranslate } from "react-admin";

const LinkButton = ({
  Icon = ArrowBackIcon,
  width,
  translate,
  href,
  label,
}) => {
  const isSmall = isWidthUp(width, "sm");
  return (
    <Button href={href}>
      <Icon /> {!isSmall && translate(label)}
    </Button>
  );
};

LinkButton.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  Icon: PropTypes.elementType,
};
export default compose(withTranslate, withWidth())(LinkButton);
