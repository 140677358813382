import React from "react";
import { ReactLeafletSearch } from "react-leaflet-search";
import { withLeaflet } from "react-leaflet";

class Search extends React.Component {
  render() {
    const { props } = this;
    const Search = withLeaflet(ReactLeafletSearch);
    return <Search {...props} />;
  }
}

export default Search;
