import {
  CardActions,
  CardContent,
  CardHeader,
  withStyles,
} from "@material-ui/core";
import { EditButton, TextField } from "react-admin";
import React, { Fragment } from "react";

import SensorValueField from "../components/fields/SensorValueField";
import { grey } from "@material-ui/core/colors";

const Card = withStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.secondary.main,
    "& *": {
      color: "white",
    },
  },
  content: {
    backgroundColor: grey[100],
  },
  actions: {
    backgroundColor: grey[100],
  },
}))(({ classes, websocket, ...props }) => (
  <Fragment>
    <CardHeader
      className={classes.header}
      title={<TextField {...props} source="name" variant="subheading" />}
    />
    <CardContent className={classes.content}>
      <SensorValueField {...props} websocket={websocket} />
    </CardContent>
    <CardActions className={classes.actions}>
      <EditButton {...props} />
    </CardActions>
  </Fragment>
));
export default Card;
