import { Chip, Typography, withStyles } from "@material-ui/core";
import { grey, orange, red } from "@material-ui/core/colors";

import React from "react";
import classNames from "classnames";
import { compose } from "recompose";
import { get } from "lodash";
import { withTranslate } from "react-admin";

const styles = (theme) => ({
  root: {
    "& aside": {
      color: "white",
    },
  },
  running: {
    backgroundColor: orange[500],
  },
  scheduled: {
    backgroundColor: grey[500],
  },
  aborted: {
    backgroundColor: red[500],
  },
});

const StatusField = ({ translate, classes, source, record }) => (
  <Chip
    label={
      <Typography variant="body2">
        {translate(`resources.cron-job.status.${get(record, source)}`)}
      </Typography>
    }
    className={classNames(classes.root, classes[get(record, source)])}
  />
);

export default compose(withStyles(styles), withTranslate)(StatusField);
