import { Grid, Typography, withStyles } from "@material-ui/core";

import CalendarIcon from "@material-ui/icons/Event";
import EventHelper from "../../helpers/EventHelper";
import React from "react";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import TimerIcon from "@material-ui/icons/Timer";
import classnames from "classnames";
import { get } from "lodash";
import moment from "moment";

const HeaderTime = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing.unit,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing.unit * 1,
      marginBottom: theme.spacing.unit * 2,
    },
  },
  content: {
    display: "flex",
    flexDirection: "row",
    "& svg": {
      marginRight: theme.spacing.unit,
    },
  },
  left: {
    justifyContent: "flex-start",
  },
  center: {
    justifyContent: "center",
  },
  right: {
    justifyContent: "flex-end",
  },
}))(({ record, status, classes }) => (
  <Grid container className={classes.root}>
    <Grid
      item
      lg={4}
      sm={4}
      xs={4}
      className={classnames(classes.content, classes.left)}
    >
      <CalendarIcon />
      <Typography>
        {moment(get(record, "start_at")).format("DD/MM/YYYY")}
        {status === "closed" && <br />}
        {status === "closed" &&
          moment(get(record, "end_at")).format("DD/MM/YYYY")}
      </Typography>
    </Grid>
    <Grid
      item
      lg={4}
      sm={4}
      xs={4}
      className={classnames(classes.content, classes.center)}
    >
      <TimerIcon />
      <Typography>
        {moment(get(record, "start_at")).format("HH:mm")}
        {status === "closed" && <br />}
        {status === "closed" && moment(get(record, "end_at")).format("HH:mm")}
      </Typography>
    </Grid>
    <Grid
      item
      lg={4}
      sm={4}
      xs={4}
      className={classnames(classes.content, classes.right)}
    >
      <TimelapseIcon />
      <Typography>{EventHelper.getDuration(record)}</Typography>
    </Grid>
  </Grid>
));
export default HeaderTime;
