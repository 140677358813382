import { ReferenceInput, SelectInput, withTranslate } from "react-admin";

import React from "react";
import { compose } from "recompose";

const LanguageModelInput = ({ translate, ...props }) => (
  <ReferenceInput {...props} reference="language-model">
    <SelectInput optionText="name" allowEmpty resettable />
  </ReferenceInput>
);
export default compose(withTranslate)(LanguageModelInput);
