import { REDUX_FORM_NAME, SelectInput, translate } from "react-admin";
import React, { useEffect, useMemo, useState } from "react";

import Types from "./Types";
import { change } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import { get } from "lodash";

const AttrTypeInput = ({
  dispatch,
  formData,
  scopedFormData,
  getSource,
  ...props
}) => {
  const source = getSource("type");
  const config = getSource("config");
  const type = get(formData, source);
  const [init, setInit] = useState(false);
  const inputTypes = useMemo(
    () =>
      Types.map((type) => ({
        id: type,
        name: props.translate(`resources.thing-type-attr.types.${type}`),
      })),
    [props]
  );
  useEffect(
    () => {
      if (!init) {
        setInit(true);
      }
      switch (type) {
        case "text":
          dispatch(
            change(REDUX_FORM_NAME, config, [
              {
                name: "min_length",
                value: "0",
              },
              {
                name: "max_length",
                value: "100",
              },
            ])
          );
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type]
  );
  return (
    <SelectInput
      {...props}
      choices={inputTypes}
      optionText="name"
      optionValue="id"
    />
  );
};

export default compose(
  translate,
  connect(() => ({}))
)(AttrTypeInput);
