import { ArrayField, DateField, SingleFieldList, TextField } from "react-admin";

import Actions from "./Actions";
import BulkActions from "./BulkActions";
import Card from "./Card";
import ChipField from "../components/fields/ChipField";
import EditButton from "../components/buttons/EditButton";
import ExpandRow from "./ExpandRow";
import Filters from "./Filters";
import React from "react";
import ResponsiveGridWithWebsocket from "../components/list/ResponsiveGridWithWebsocket";
import ResponsiveList from "../components/list/ResponsiveList";

const List = (props) => (
  <ResponsiveList
    {...props}
    perPage={25}
    filters={<Filters />}
    bulkActionButtons={<BulkActions />}
    actions={<Actions />}
    type="table"
    split
  >
    <ResponsiveGridWithWebsocket
      {...props}
      target="device"
      rowBreakpoint="sm"
      cardContent={<Card />}
      expand={<ExpandRow />}
      expandAsRow
    >
      <TextField source="id" />
      <ChipField source="code" color="primary" />
      <TextField source="name" />
      <ArrayField source="groups" sortable={false}>
        <SingleFieldList linkType={false}>
          <ChipField source="group_code" />
        </SingleFieldList>
      </ArrayField>
      <DateField source="modified" showTime />
      <EditButton />
    </ResponsiveGridWithWebsocket>
  </ResponsiveList>
);
export default List;
