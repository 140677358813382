import {
  DateField,
  Pagination,
  ReferenceManyField,
  TextField,
} from "react-admin";

import ChipField from "../../components/fields/ChipField";
import LogCard from "./LogCard";
import React from "react";
import ResponsiveGrid from "../../components/list/ResponsiveGrid";

const LogsField = (props) => (
  <ReferenceManyField
    {...props}
    addLabel={false}
    pagination={<Pagination />}
    reference="sensor-log"
    sort={{ field: "created", order: "DESC" }}
    target="sensor_id"
    perPage={10}
  >
    <ResponsiveGrid rowBreakpoint="md" type="table" cardContent={<LogCard />}>
      <DateField source="created" showTime />
      <ChipField source="type" />
      <TextField source="message" />
    </ResponsiveGrid>
  </ReferenceManyField>
);

export default LogsField;
