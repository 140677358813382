import { REDUX_FORM_NAME, TextInput } from "react-admin";

import React from "react";
import { change } from "redux-form";
import { connect } from "react-redux";
import { useEffect } from "react";

const AttrConfigNameInput = connect(() => ({}))(
  ({ dispatch, type, ...props }) => {
    useEffect(() => {
      if (["select", "select-multi"].indexOf(type) !== -1) {
        dispatch(change(REDUX_FORM_NAME, props.source, "option"));
      }
    }, [dispatch, type, props.source]);
    return <TextInput {...props} />;
  }
);

export default AttrConfigNameInput;
