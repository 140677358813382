import Avatar from "@material-ui/core/Avatar";
import BorderOuterIcon from "@material-ui/icons/BorderOuter";
import BorderStyleIcon from "@material-ui/icons/BorderStyle";
import BusinessIcon from "@material-ui/icons/Business";
import { Chip } from "@material-ui/core";
import React from "react";
import compose from "recompose/compose";
import { translate } from "react-admin";

export const TYPES_ICONS = {
  building: BusinessIcon,
  floor: BorderOuterIcon,
  room: BorderStyleIcon,
  none: BorderStyleIcon,
};

const TypeField = ({ translate, record }) => {
  const Icon = TYPES_ICONS[record.type] || TYPES_ICONS.none;
  return (
    <Chip
      avatar={
        <Avatar>
          <Icon />
        </Avatar>
      }
      label={translate(`resources.location.types.${record.type}`)}
      color="secondary"
    />
  );
};

export default compose(translate)(TypeField);
