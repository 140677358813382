import Link from "../../components/Link";
import React from "react";
import { get } from "lodash";

const EventField = ({ source, record }) => (
  <Link variant="body2" href={`#/event/${get(record, "event_id")}/show`}>
    {get(record, source)}
  </Link>
);
export default EventField;
