import { LongTextInput, SimpleForm, TextInput } from "react-admin";

import React from "react";

const Form = (props) => (
  <SimpleForm {...props} redirect="list">
    <TextInput source="key" fullWidth />
    <TextInput source="value" fullWidth />
    <LongTextInput source="description" />
  </SimpleForm>
);
export default Form;
