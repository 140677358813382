import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  HttpError,
  UPDATE,
  UPDATE_MANY,
  fetchUtils,
} from "react-admin";
import { get, set } from "lodash";
import { getBody, getToken } from "./authBody";

import { API_URL } from "./config";
import { DELETE_MANY } from "ra-core";

const FILE_FIELDS = ["image", "icon", "pic"];

let loadingResources = [];

export const validateResponse = (response) => {
  if (
    response.json.error === true &&
    response.json.message === "Invalid token provided."
  ) {
    localStorage.clear();
    throw new HttpError(response.message, 401);
  }
  if (response.json && response.json.valid === false) {
    throw new HttpError("Unprocessable entity", 422, response);
  }

  if (response.error === true) {
    throw new HttpError(response.message);
  }
  return response;
};

export const getLoadingResources = () => loadingResources;
export const isLoadingResource = (resource) =>
  loadingResources.indexOf(resource) !== -1;
export const addLoadingResource = (resource) => loadingResources.push(resource);
export const removeLoadingResource = (resource) =>
  (loadingResources = loadingResources.filter((lr) => lr !== resource));
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
const convertFile = async (file) =>
  file.rawFile
    ? convertFileToBase64(file).then((convertedFile) => ({
        data: convertedFile,
        name: file.rawFile.name,
        size: file.rawFile.size,
        type: file.rawFile.type,
      }))
    : Promise.resolve(file);

async function prepareUpload(data) {
  for (var i = 0; i < FILE_FIELDS.length; i++) {
    const field = FILE_FIELDS[i];
    const value = get(data, field);
    if (value && Array.isArray(value)) {
      const files = await value.map((file) => convertFile(file));
      data = set(data, field, files);
    } else if (value) {
      const file = await convertFile(value);
      data = set(data, field, file);
    }
  }
  return Promise.resolve(data);
}
/**
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
  switch (type) {
    case GET_LIST: {
      addLoadingResource(resource);
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      let request = {
        sort: {
          column: field,
          descending: order === "DESC",
        },
        page: page,
        limit: perPage,
        resource,
        filters: Object.keys(params.filter || {}).map((key) => ({
          name: key,
          clause: "eq",
          value: params.filter[key],
        })),
      };
      const body = getBody(request);
      return Promise.resolve({
        url: `${API_URL}/${resource}/list`,
        options: {
          method: "POST",
          body,
        },
      });
    }
    case GET_ONE:
      let { id } = params;
      return Promise.resolve({
        url: `${API_URL}/${resource}/get/${id}/${getToken()}`,
        options: { method: "POST" },
      });
    case GET_MANY: {
      const request = {
        filters: [
          {
            name: "id",
            clause: "in",
            value: params.ids,
          },
        ],
        page: 1,
        limit: 1000,
      };
      const body = getBody(request);
      return Promise.resolve({
        url: `${API_URL}/${resource}/list`,
        options: { method: "POST", body },
      });
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const request = {
        sort: {
          column: field,
          descending: order === "DESC",
        },
        page: page,
        limit: perPage,
        filters: [
          {
            name: params.target,
            clause: "eq",
            value: params.id,
          },
        ].concat(
          params.filter
            ? Object.keys(params.filter).map((f) => ({
                name: f,
                clause: "eq",
                value: params.filter[f],
              }))
            : []
        ),
      };
      const body = getBody(request);
      return Promise.resolve({
        url: `${API_URL}/${resource}/list`,
        options: { method: "POST", body },
      });
    }
    case UPDATE:
    case CREATE:
      let item =
        resource !== "device"
          ? params.data
          : // TODO: questa cosa è TROPPO SBAGLIATA, va riscritta.
            {
              ...params.data,
              sensors: params.data.sensors.map((sensor) => ({
                ...(params.previousData || { sensors: [] }).sensors.find(
                  (s) => s.id === sensor.id
                ),
                ...sensor,
              })),
            };
      return prepareUpload(item).then((data) => {
        let request = { item: JSON.stringify(data) };
        let body = getBody(request);
        return Promise.resolve({
          url: `${API_URL}/${resource}/save`,
          options: {
            method: "POST",
            body,
          },
        });
      });
    case DELETE:
      return Promise.resolve({
        url: `${API_URL}/${resource}/delete/${params.id}/${getToken()}`,
        options: { method: "POST" },
      });
    case DELETE_MANY:
      return Promise.resolve({
        url: `${API_URL}/${resource}/delete-multi/${getToken()}`,
        options: { method: "POST", body: JSON.stringify({ ids: params.ids }) },
      });
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};

const convertHTTPResponseToDataProvider = (response, type, params) => {
  const { json } = response;
  switch (type) {
    case GET_MANY:
    case GET_MANY_REFERENCE:
    case GET_LIST:
      const resource = json.resource;
      removeLoadingResource(resource);
      localStorage.setItem(`last_${resource}_count`, json.all);
      return {
        data: json.rows.map((x) => x),
        total: parseInt(json.count, 10),
      };
    case GET_ONE:
      return {
        data: json.item,
      };
    case CREATE:
      return { data: json.data, id: json.data.id };
    case UPDATE:
      return { data: { ...params.data } };
    case DELETE_MANY:
      return { data: params.ids };
    default:
      return { data: json };
  }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  if (type === UPDATE_MANY) {
    return Promise.all(
      params.ids.map((id) =>
        fetchJson(`${API_URL}/${resource}/${id}`, {
          method: "PUT",
          body: JSON.stringify(params.data),
        })
      )
    ).then((responses) => ({
      data: responses.map((response) => response.json),
    }));
  }

  return convertDataProviderRequestToHTTP(type, resource, params).then(
    ({ url, options }) =>
      fetchJson(url, options)
        .then(validateResponse)
        .then((response) =>
          convertHTTPResponseToDataProvider(response, type, params)
        )
        .catch((error) => console.error(error))
  );
};
