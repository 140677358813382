import { FormDataConsumer, ReferenceInput, SelectInput } from "react-admin";

import React from "react";
import { get } from "lodash";

const ThingTypeInput = ({ ...props }) => {
  return (
    <FormDataConsumer>
      {({ formData }) => (
        <ReferenceInput
          {...props}
          reference="thing-type"
          filter={{
            thing_id: get(formData, "parent_id"),
            use_is_parent_required: true,
          }}
          sort={{ field: "name", order: "ASC" }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
    </FormDataConsumer>
  );
};
export default ThingTypeInput;
