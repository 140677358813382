import React, { Fragment } from "react";

import Form from "./Form";
import Location from "./Location";
import { Create as RaCreate } from "react-admin";
import useSearchParams from "./useSearchParams";

const Create = (props) => {
  const searchParams = useSearchParams({ ...props });
  return (
    <Fragment>
      <Location divider={false} id={searchParams.get("parent_id")} />
      <RaCreate {...props}>
        <Form location={props.location} />
      </RaCreate>
    </Fragment>
  );
};
export default Create;
