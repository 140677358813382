import {
  CardActions,
  CardContent,
  CardHeader,
  withStyles,
} from "@material-ui/core";
import { FormInput, TextField, translate } from "react-admin";
import React, { Fragment } from "react";

import GroupField from "./fields/GroupField";
import LinkField from "./fields/LinkField";
import TypeField from "./fields/TypeField";
import { grey } from "@material-ui/core/colors";

const Card = withStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.secondary.main,
    "& *": {
      color: "white",
    },
  },
  content: {
    backgroundColor: grey[100],
  },
  actions: {
    backgroundColor: grey[100],
  },
}))(({ classes, translate, ...props }) => (
  <Fragment>
    <CardHeader
      className={classes.header}
      title={<TextField source="filename" {...props} variant="body2" />}
    />
    <CardContent className={classes.content}>
      <FormInput {...props} source="type" input={<TypeField addLabel />} />
      <FormInput {...props} source="group_id" input={<GroupField addLabel />} />
    </CardContent>
    <CardActions className={classes.actions}>
      <LinkField {...props} label={translate("ra.action.download")} />
    </CardActions>
  </Fragment>
));
export default translate(Card);
