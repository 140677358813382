import { Toolbar as RaToolbar, SaveButton } from "react-admin";

import BackButton from "../components/buttons/BackButton";
import React from "react";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { useResourcePermissions } from "../usePermissions";
import { withStyles } from "@material-ui/core";

const Toolbar = withStyles((theme) => ({
  toolbar: {
    "& button": {
      marginRight: theme.spacing.unit * 2,
    },
  },
}))(({ classes, ...props }) => {
  const { edit, create } = useResourcePermissions(props);
  return (
    <RaToolbar {...props} classes={classes}>
      <SaveButton {...props} disabled={!edit || !create} />
      <SaveButton
        {...props}
        icon={<ScheduleIcon />}
        disabled={!edit || !create}
        label="resources.cron-job.buttons.restart"
      />
      <BackButton href="#/cron-job" />
    </RaToolbar>
  );
});
export default Toolbar;
