import { FileInput, ImageField } from "react-admin";
import { FormHelperText, withStyles } from "@material-ui/core";
import React, { Fragment } from "react";

import { API_URL } from "../../config";
import { REDUX_FORM_NAME } from "ra-core";
import { change } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getToken } from "../../authBody";
import { grey } from "@material-ui/core/colors";
import { withTranslate } from "react-admin";

const formatUrl = (file) => {
  if (!file) {
    return null;
  }
  if (file.rawFile) {
    return file;
  }

  return {
    title: file.title,
    src: `${API_URL}/file-system/${getToken()}/download/${file.src}`,
  };
};
const PicInput = withStyles((theme) => ({
  root: {
    "& button": {
      marginTop: theme.spacing.unit,
      backgroundColor: grey[300],
    },
    "& button svg": {
      fontSize: 30,
      color: grey[900],
    },
    "& button:hover": {
      backgroundColor: grey[300],
    },
    "& button:hover svg": {
      color: theme.palette.secondary.main,
    },
    "& img": {
      marginTop: -theme.spacing.unit * 5,
      borderRadius: theme.spacing.unit,
      borderWidth: theme.spacing.unit,
      borderColor: grey[300],
      borderStyle: "solid",
    },
  },
}))(({ classes, dispatch, translate, helperText, ...props }) => (
  <Fragment>
    <FileInput
      {...props}
      format={formatUrl}
      multiple={false}
      className={classes.root}
      label={translate(`resources.thing.fields.${props.source}`)}
      onRemove={() => {
        dispatch(change(REDUX_FORM_NAME, props.source, null));
        dispatch(change(REDUX_FORM_NAME, "filepath", null));
      }}
    >
      <ImageField source="src" title="title" />
    </FileInput>
    <FormHelperText>{helperText}</FormHelperText>
  </Fragment>
));
export default compose(
  withTranslate,
  connect(() => ({}))
)(PicInput);
