import React, { useEffect } from "react";

import FileInput from "./FileInput";
import PropTypes from "prop-types";
import { REDUX_FORM_NAME } from "react-admin";
import { change } from "redux-form";
import { fromBlob } from "geotiff";

const TifFileInput = ({ formData, dispatch, ...props }) => {
  const tif = formData.tif_file;
  const [src, setSrc] = React.useState(tif?.src);
  useEffect(() => {
    if (!tif || tif === null || src === tif?.src) {
      return;
    }

    fromBlob(tif.rawFile).then((tiff) => {
      tiff.getImage().then((image) => {
        let meta = image.getFileDirectory();
        let x_min = meta.ModelTiepoint[3];
        let x_max = x_min + meta.ModelPixelScale[0] * meta.ImageWidth;
        let y_min = meta.ModelTiepoint[4];
        let y_max = y_min - meta.ModelPixelScale[1] * meta.ImageLength;

        dispatch(change(REDUX_FORM_NAME, "map_min_y", y_min));
        dispatch(change(REDUX_FORM_NAME, "map_min_x", x_min));
        dispatch(change(REDUX_FORM_NAME, "map_max_y", y_max));
        dispatch(change(REDUX_FORM_NAME, "map_max_x", x_max));
      });
    });
    setSrc(tif.src);
  }, [tif, src, dispatch]);
  return <FileInput {...props} />;
};

TifFileInput.propTypes = {
  formData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default TifFileInput;
