import React, { useMemo } from "react";
import { Typography, withStyles } from "@material-ui/core";

import TimerIcon from "@material-ui/icons/Timer";
import classnames from "classnames";
import { get } from "lodash";
import moment from "moment";
import { translate } from "react-admin";

const DurationField = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    minWidth: 150,
  },
  duration: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: theme.spacing.unit * 0.25,
    "& svg": {
      fontSize: 16,
      marginLeft: theme.spacing.unit * 0.25,
    },
    "& span": {
      alignSelf: "center",
    },
  },
}))(({ classes, translate, source, record, addLabel }) => {
  const { lastRun, duration, date } = useMemo(() => {
    const theDate = get(record, source);
    const date = moment(theDate);
    const lastRun = moment.duration(-moment().diff(date)).humanize(true);
    const dateFormat = translate("app.date_format.long");
    const lastRunEndAt = moment(get(record, "last_run_end_at"));
    const duration = moment.duration(lastRunEndAt.diff(date)).humanize();
    return {
      duration,
      lastRun,
      date: theDate !== null ? date.format(dateFormat) : null,
    };
  }, [record, source, translate]);
  return (
    <div className={classnames(!addLabel && classes.root)}>
      <Typography variant="body2">{lastRun}</Typography>
      <Typography variant="caption">{date}</Typography>
      <Typography variant="caption" className={classes.duration}>
        <span>{duration}</span>
        <TimerIcon />
      </Typography>
    </div>
  );
});

export default translate(DurationField);
