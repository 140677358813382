import NodeLabel from "./NodeLabel";
import PropTypes from "prop-types";
import React from "react";
import SensorNode from "./SensorNode";
import TreeItem from "./TreeItem";
import compose from "recompose/compose";
import getNodeKey from "./getNodeKey";
import makeid from "../../utils/makeid";
import showSensors from "./showSensors";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core";

const DeviceNode = compose(withStyles(styles))(
  ({ device, checked, classes, location, onNodeClick, onNodeCheckChange }) => (
    <TreeItem
      nodeId={getNodeKey({ resource: "device", record: device })}
      marginLeft={0}
      classes={{
        content: classes.content,
        expanded: classes.expanded,
      }}
      label={
        <NodeLabel
          label={device.name}
          filter={{
            floor_id: device.floor_id,
            device_id: device.id,
            sensor_id: undefined,
            location: undefined,
            zoom: 22,
            lat: parseFloat(device.lat),
            lng: parseFloat(device.lng),
            rc: makeid(10),
          }}
          disabled={device.lat === null || device.lng === null}
          checked={
            checked[getNodeKey({ resource: "device", record: device })] === true
          }
          location={location}
          onClick={(_) =>
            onNodeClick(getNodeKey({ resource: "device", record: device }))
          }
          onCheckChange={(checked) =>
            onNodeCheckChange(
              getNodeKey({ resource: "device", record: device }),
              checked
            )
          }
        />
      }
      onClick={(_) =>
        onNodeClick(getNodeKey({ resource: "device", record: device }))
      }
    >
      {(showSensors({ location }) &&
        device.sensors &&
        device.sensors.length > 0 &&
        device.sensors.map((sensor) => (
          <SensorNode
            key={getNodeKey({ resource: "sensor", record: sensor })}
            sensor={sensor}
            checked={
              checked[getNodeKey({ resource: "sensor", record: sensor })] ===
              true
            }
            location={location}
            onNodeClick={(_) =>
              onNodeClick(getNodeKey({ resource: "sensor", record: sensor }))
            }
            onNodeCheckChange={(id, checked) => onNodeCheckChange(id, checked)}
          />
        ))) ||
        []}
    </TreeItem>
  )
);
DeviceNode.propTypes = {
  device: PropTypes.object.isRequired,
  checked: PropTypes.any.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};
export default DeviceNode;
