import Indicator from "./Indicator";
import { ONGOING } from "../../events/colors";
import React from "react";
import { SensorIcon } from "../../icons";

const SensorsIndicator = ({ getValue, ...props }) => (
  <Indicator
    color={ONGOING}
    label="dashboard.sensors"
    value={getValue("Sensors")}
    icon={SensorIcon}
  />
);

export default SensorsIndicator;
