import { CLOSED, ONGOING } from "../../events/colors";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import Avatar from "@material-ui/core/Avatar";
import CalendarIcon from "@material-ui/icons/Event";
import Card from "@material-ui/core/Card";
import CardIcon from "../CardIcon";
import ClockIcon from "@material-ui/icons/AccessTime";
import LocationIcon from "@material-ui/icons/Domain";
import React from "react";
import TimerIcon from "@material-ui/icons/Timer";
import { Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import compose from "recompose/compose";
import icons from "../../icons";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  main: {
    flex: "1",
    marginTop: 20,
    height: "85%",
  },
  card: {
    padding: 16,
    overflow: "inherit",
    height: "100%",
    minHeight: 250,
    "&:hover": {
      cursor: "pointer",
    },
  },
  date: {
    display: "flex",
    justifyContent: "flex-end",
    overflow: "hidden",
  },
  dateText: {
    margin: "0 8px",
    fontSize: 15,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  sensor: {
    fontSize: 18,
    fontWeight: 500,
    marginTop: 25,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  detail: {
    display: "flex",
    alignItems: "center",
    marginTop: 20,
  },
  detailText: {
    marginLeft: 16,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  detailIcon: {
    fontSize: 20,
    color: "white",
  },
});

const getDuration = (event) => {
  const from = moment(event.start_at, "YYYY-MM-DD HH:mm:ss");
  const to = event.closed
    ? moment(event.end_at, "YYYY-MM-DD HH:mm:ss")
    : moment();
  const duration = moment.duration(from.diff(to));
  return duration.humanize();
};

const getLocation = (event) => {
  const location = event.location.replace(event.sensor, "");
  return location.substring(0, location.length - 1);
};

const ListItem = ({ event, classes, width }) => {
  const handleClick = () =>
    (document.location.href = `#/event/${event.event_id}/show`);
  return (
    <div className={classes.main}>
      <CardIcon
        Icon={event.closed ? icons["CheckIcon"] : icons["EventIcon"]}
        bgColor={event.closed ? CLOSED : ONGOING}
      />
      <Card className={classes.card} onClick={handleClick}>
        <div className={classes.date}>
          <CalendarIcon />
          <Typography className={classes.dateText}>
            {moment(event.start_at, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}
          </Typography>

          <div style={{ flexDirection: "column", display: "flex" }}>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ClockIcon />
              <Typography className={classes.dateText}>
                {moment(event.start_at, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}
              </Typography>
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                marginTop: 10,
                alignItems: "center",
              }}
            >
              <TimerIcon />
              <Typography className={classes.dateText}>
                {getDuration(event)}
              </Typography>
            </div>
          </div>
        </div>
        <Typography
          className={classes.sensor}
          title={event.sensor}
          style={{ whiteSpace: isWidthUp("sm", width) ? "nowrap" : "normal" }}
        >
          {event.sensor}
        </Typography>
        <div className={classes.detail}>
          <Avatar
            style={{
              height: 40,
              width: 40,
              backgroundColor: event.closed ? CLOSED : ONGOING,
            }}
          >
            <WarningIcon
              classes={{
                root: classes.detailIcon,
              }}
            />
          </Avatar>
          <Typography
            className={classes.detailText}
            style={{ fontWeight: 500 }}
          >
            {event.details}
          </Typography>
        </div>
        <div className={classes.detail}>
          <Avatar
            style={{
              height: 40,
              width: 40,
              backgroundColor: event.closed ? CLOSED : ONGOING,
            }}
          >
            <LocationIcon
              classes={{
                root: classes.detailIcon,
              }}
            />
          </Avatar>
          <Typography
            className={classes.detailText}
            style={{ fontWeight: 400, maxHeight: 60 }}
            title={getLocation(event)}
          >
            {getLocation(event)}
          </Typography>
        </div>
      </Card>
    </div>
  );
};

export default compose(withWidth(), withStyles(styles))(ListItem);
