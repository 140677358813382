import { LongTextInput, SimpleForm, TextInput } from "react-admin";

import CodeEditorInput from "../components/inputs/CodeEditorInput";
import React from "react";

const Form = (props) => (
  <SimpleForm {...props} redirect="edit">
    <TextInput source="name" />
    <LongTextInput source="description" />
    <CodeEditorInput source="php_code" />
  </SimpleForm>
);
export default Form;
