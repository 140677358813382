import { Card, withStyles } from "@material-ui/core";

import React from "react";
import classnames from "classnames";
import { compose } from "recompose";
import { connect } from "react-redux";
import { linkToRecord } from "ra-core";
import { push } from "react-router-redux";

const styles = (theme) => ({
  root: {
    "& label": {
      textTransform: "uppercase",
    },
    "& .ra-input > div": {
      marginBottom: 0,
    },
  },
});

const CardItem = ({ classes, cardClasses, cardContent, ...props }) => {
  const handleRedirection = (path, event) => {
    const { basePath, id, push } = props;

    if (path === "edit") {
      push(linkToRecord(basePath, id));
      return;
    }
    if (path === "show") {
      push(linkToRecord(basePath, id, "show"));
      return;
    }
    if (path === "expand") {
      this.handleToggleExpanded(event);
      return;
    }
    if (!path) return;

    push(path);
  };

  const handleClick = async (event) => {
    const { basePath, rowClick, id, record } = props;

    if (!rowClick) return;

    if (typeof rowClick === "function") {
      const path = await rowClick(id, basePath, record);
      handleRedirection(path, event);
      return;
    }

    handleRedirection(rowClick, event);
  };
  const { websocket, elevation = 1, record, id, resource, basePath } = props;
  return (
    <Card
      elevation={elevation}
      className={classnames(classes.root, cardClasses && cardClasses(record))}
      onClick={handleClick}
    >
      {React.isValidElement(cardContent)
        ? React.cloneElement(cardContent, {
            websocket,
            basePath,
            resource,
            record,
            id,
          })
        : null}
    </Card>
  );
};
export default compose(withStyles(styles), connect(null, { push }))(CardItem);
