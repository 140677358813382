import { grey } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core";

const withCardStyles = withStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.secondary.main,
    "& *": {
      color: "white",
    },
  },
  content: {
    backgroundColor: grey[100],
  },
  actions: {
    backgroundColor: grey[100],
  },
}));
export default withCardStyles;
