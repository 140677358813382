import { ReferenceArrayInput, SelectArrayInput } from "react-admin";

import React from "react";

const DeviceTypesSelectInput = (props) => (
  <ReferenceArrayInput {...props} source="device_types" reference="device-type">
    <SelectArrayInput optionText="name" />
  </ReferenceArrayInput>
);
export default DeviceTypesSelectInput;
