import {
  Create as RaCreate,
  SimpleForm,
  TextInput,
  translate,
} from "react-admin";

import React from "react";
import Toolbar from "./Toolbar";

const PasswordChangeCreate = ({ translate, ...props }) => (
  <RaCreate {...props}>
    <SimpleForm redirect={false} toolbar={<Toolbar />}>
      <TextInput source="old_password" type="password" />
      <TextInput source="new_password" type="password" />
      <TextInput source="confirm_password" type="password" />
    </SimpleForm>
  </RaCreate>
);

export default translate(PasswordChangeCreate);
