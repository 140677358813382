import { Avatar, Grid, Typography, withStyles } from "@material-ui/core";
import { CLOSED, ONGOING } from "../../events/colors";
import React, { Fragment } from "react";

import classnames from "classnames";
import compose from "recompose/compose";
import { withTranslate } from "react-admin";

const Legend = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing.unit * 6,
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing.unit,
    },
  },
  legendContainer: {
    display: "flex",
    alignItems: "center",
  },
  legendLabel: {
    display: "inline",
    marginLeft: theme.spacing.unit,
  },
  avatar: {
    height: 16,
    width: 16,
  },
  avatarOngoing: {
    backgroundColor: ONGOING,
  },
  avatarClosed: {
    backgroundColor: CLOSED,
  },
}))(({ classes, translate }) => (
  <Fragment>
    <Grid container item className={classes.root}>
      <Grid item lg={2} md={3} xs={5} className={classes.legendContainer}>
        <Avatar className={classnames(classes.avatar, classes.avatarOngoing)} />
        <Typography className={classes.legendLabel}>
          {translate("website.event.ongoing")}
        </Typography>
      </Grid>
      <Grid item lg={7} md={6} xs={4} className={classes.legendContainer}>
        <Avatar className={classnames(classes.avatar, classes.avatarClosed)} />
        <Typography className={classes.legendLabel}>
          {translate("website.event.closed")}
        </Typography>
      </Grid>
    </Grid>
  </Fragment>
));

export default compose(withTranslate)(Legend);
