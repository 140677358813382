import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  createStyles,
  withStyles,
} from "@material-ui/core";

import PropTypes from "prop-types";
import { RANGES } from "./config";
import React from "react";
import TreeFilter from "../components/tree/TreeFilter";
import compose from "recompose/compose";
import { translate } from "ra-core";

const styles = (theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      padding: theme.spacing.unit * 2,
      borderRadius: theme.spacing.unit * 0.5,
    },
    textField: {
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      width: 230,
    },
    formControl: {
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      width: 150,
    },
    selectEmpty: {
      marginTop: theme.spacing.unit * 2,
    },
  });
class Toolbar extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    search: PropTypes.bool.isRequired,
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    view: PropTypes.string.isRequired,
    sampling: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);

    this.state = {
      sampling: this.props.sampling || "",
      view: this.props.view || "",
      start_at: this.props.start || "",
      end_at: this.props.end || "",
      helpers: RANGES[this.props.sampling || "RealTime"] || [],
    };
  }

  handleChange(filterName, evt) {
    let type = this.state.type;
    let step = this.state.step;
    let start = this.state.start_at;
    let end = this.state.end_at;
    let helpers = this.state.helpers;
    let value = evt.target.value;
    if (filterName === "view" && this.state.helpers[value]) {
      let range = this.state.helpers[value]();
      start = range.start;
      end = range.end;
    } else if (filterName === "sampling") {
      helpers = RANGES[value || ""];
    }

    this.setState(
      {
        type,
        step,
        start_at: start,
        end_at: end,
        helpers,
        [filterName]: value,
      },
      () =>
        this.props.onChange({
          ...this.state,
          helpers: undefined,
        })
    );
  }

  render() {
    const { classes, search, translate } = this.props;
    return (
      <Paper elevation={1} className={classes.container}>
        <TextField
          type="datetime-local"
          disabled={!search}
          className={classes.textField}
          label={translate("resources.event.fields.start_at")}
          value={this.state.start_at}
          onChange={this.handleChange.bind(this, "start_at")}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          type="datetime-local"
          disabled={!search}
          className={classes.textField}
          label={translate("resources.event.fields.end_at")}
          value={this.state.end_at}
          onChange={this.handleChange.bind(this, "end_at")}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="sampling">
            {translate("pages.measurements.sampling.name")}
          </InputLabel>
          <Select
            value={this.state.sampling}
            disabled={!search}
            onChange={this.handleChange.bind(this, "sampling")}
            inputProps={{
              name: "sampling",
              id: "sampling",
            }}
          >
            <MenuItem value="RealTime">
              {translate("pages.measurements.sampling.items.real_time")}
            </MenuItem>
            <MenuItem value="Minutely">
              {translate("pages.measurements.sampling.items.minutely")}
            </MenuItem>
            <MenuItem value="Hourly">
              {translate("pages.measurements.sampling.items.hourly")}
            </MenuItem>
            <MenuItem value="Daily">
              {translate("pages.measurements.sampling.items.daily")}
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="view">
            {translate("pages.measurements.view.name")}
          </InputLabel>
          <Select
            value={this.state.view}
            disabled={!search}
            onChange={this.handleChange.bind(this, "view")}
            inputProps={{
              name: "view",
              id: "view",
            }}
          >
            {Object.keys(this.state.helpers).map((helper) => (
              <MenuItem key={helper} value={helper}>
                {translate(`pages.measurements.view.items.${helper}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TreeFilter visible />
      </Paper>
    );
  }
}

export default compose(translate, withStyles(styles))(Toolbar);
