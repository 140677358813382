import { Toolbar as RaToolbar, SaveButton } from "react-admin";

import LinkButton from "../components/buttons/LinkButton";
import React from "react";
import { get } from "lodash";
import { useResourcePermissions } from "../usePermissions";
import { withStyles } from "@material-ui/core";

const Toolbar = withStyles((theme) => ({
  toolbar: {
    "& button": {
      marginRight: theme.spacing.unit * 2,
    },
  },
}))(({ classes, ...props }) => {
  const { edit, create } = useResourcePermissions(props);
  return (
    <RaToolbar {...props} classes={classes}>
      <SaveButton {...props} disabled={!edit || !create} />
      <LinkButton
        label="resources.sensor.buttons.back_to_device"
        href={`#/device/${get(props, "record.device_id")}`}
      />
    </RaToolbar>
  );
});

export default Toolbar;
