import { withStyles } from "@material-ui/core";

const withFormStyles = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      "& a[role=tab] > span > span > span": {
        fontSize: 10,
      },
    },
  },
}));
export default withFormStyles;
