import React, { Component } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import { FieldTitle } from "ra-core";

import Specs from "./BaseInput.specs";
import makeid from "../../utils/makeid";

export class BooleanInput extends Component {
  state = {
    id: makeid(10)
  };
  handleChange = evt => {
    this.props.onChange(evt.target.checked);
  };

  render() {
    const { error, prop, className } = this.props;
    const { name, value, label } = prop;

    return (
      <FormGroup className={className}>
        <FormControlLabel
          htmlFor={this.state.id}
          control={
            <Switch
              id={this.state.id}
              color="primary"
              checked={!!value}
              onChange={this.handleChange}
            />
          }
          label={<FieldTitle label={label || name} source={name} />}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormGroup>
    );
  }
}

BooleanInput.propTypes = { ...Specs };

export default BooleanInput;
