import {
  Divider,
  FormHelperText,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Labeled, REDUX_FORM_NAME, addField, translate } from "react-admin";
import React, { useState } from "react";
import { highlight, languages } from "prismjs/components/prism-core";

import Editor from "react-simple-code-editor";
import { change } from "redux-form";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withDataProvider } from "ra-core";

require("prismjs/components/prism-markup-templating.js");
require("prismjs/components/prism-clike.js");
require("prismjs/components/prism-json.js");
require("prismjs/themes/prism.css");

const styles = (theme) =>
  createStyles({
    root: {
      "& > div:first-child": {
        width: "100%",
      },
    },
    editorRoot: {
      maxHeight: 55 * 10,
      overflow: "auto",
      width: "100%",
      display: "block",
      "& textarea": {
        outline: 0,
      },
    },
    errorDivider: {
      backgroundColor: "red",
      height: 2,
    },
    divider: {
      height: 1,
      opacity: 0.42,
      backgroundColor: "black",
    },
    console: {
      clear: "both",
      paddingTop: theme.spacing.unit * 1,
    },
    consoleTimestamp: {
      marginRight: theme.spacing.unit * 1,
    },
    errorLine: {
      color: "red",
      fontFamily: "Fira Code, Consolas, Courier New",
    },
    infoLine: {
      fontFamily: "Fira Code, Consolas, Courier New",
    },
    toolbar: {
      paddingTop: theme.spacing.unit * 1,
    },
    toolbarButton: {
      marginTop: theme.spacing.unit * 1,
      marginRight: theme.spacing.unit * 1,
    },
  });

const HtmlEditorInput = ({
  record,
  source,
  translate,
  resource,
  classes,
  meta: { touched, error },
  dispatch,
}) => {
  const [code, setCode] = useState(record[source] || "");
  const handleChange = (code) => {
    setCode(code);
    dispatch(change(REDUX_FORM_NAME, source, code));
  };

  return (
    <div className={classes.root}>
      <Labeled
        meta={{ touched, error }}
        label={translate(`resources.${resource}.fields.${source}`)}
        className="code-editor"
      >
        <div className={classes.editorRoot}>
          <Editor
            value={code}
            onValueChange={handleChange}
            highlight={(code) => highlight(code, languages.json)}
            padding={0}
            style={{
              fontFamily: "Fira Code, Consolas, Courier New",
              fontSize: 15,
              padding: 0,
              margin: 0,
              minHeight: 55,
              border: "white",
              outline: "none",
              width: "100%",
            }}
          />
        </div>
      </Labeled>
      <Divider
        className={
          (!!touched && error && classes.errorDivider) || classes.divider
        }
      />
      {!!touched && error && <FormHelperText error>{error}</FormHelperText>}
    </div>
  );
};

export default compose(
  addField,
  translate,
  withStyles(styles),
  withDataProvider,
  connect()
)(HtmlEditorInput);
