import { BulkDeleteButton, translate } from "react-admin";
import React, { Fragment } from "react";

import PrintVerbalButton from "./buttons/PrintVerbalButton";
import { compose } from "recompose";

const BulkActions = ({ translate, ...props }) => (
  <Fragment>
    {translate("app.name") === "SensIC" && (
      <PrintVerbalButton
        label="resources.device.buttons.print_verbal"
        {...props}
      />
    )}

    <BulkDeleteButton {...props} />
  </Fragment>
);

export default compose(translate)(BulkActions);
