import { Card, withStyles, withWidth } from "@material-ui/core";

import { APP_URL } from "../../config";
import React from "react";
import classnames from "classnames";
import { get } from "lodash";
import { getToken } from "../../authBody";
import { isWidthUp } from "@material-ui/core/withWidth";
import nopicture from "../../assets/nopicture.png";

const HeaderPic = withStyles((theme) => ({
  root: {
    width: 128,
    height: 128,
    backgroundColor: "#d3d3d3",
    borderRadius: theme.spacing.unit,
    backgroundSize: "cover",
    marginBottom: 38,
    [theme.breakpoints.down("xs")]: {
      width: 120,
      height: 120,
    },
  },
  fixed: {
    position: "sticky",
    marginTop: -40,
    marginBottom: 0,
  },
}))(({ width, classes, record }) => {
  const isSmall = isWidthUp(width, "sm");
  let image = get(record, "sensor.filepath");
  if (image === null) {
    image = nopicture;
  } else {
    image = `${APP_URL}rest/file-system/${getToken()}/download/${image}`;
  }
  return (
    <Card
      elevation={isSmall ? 0 : 1}
      className={classnames(classes.root, !isSmall && classes.fixed)}
      style={{ backgroundImage: `url('${image}')` }}
    />
  );
});

export default withWidth()(HeaderPic);
