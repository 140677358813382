// import {
//   SkillsIcon,
//   CertificationsIcon,
//   ResearchIcon,
//   CookiesPolicyIcon,
//   PrivacyPolicyIcon,
//   TrainingIcon,
//   ContactsIcon,
//   AboutIcon,
// } from "../icons";
// import Skills from "./pages/Skills";
// import Certifications from "./pages/Certifications";
// import Research from "./pages/Research";
// import Training from "./pages/Training";
// import CookiesPolicy from "./pages/CookiesPolicy";
// import PrivacyPolicy from "./pages/PrivacyPolicy";
// import Contacts from "./pages/contacts";
// import About from "./pages/About";
export default [
  // {
  //   icon: AboutIcon,
  //   name: "website.menu.about",
  //   path: "/home/about",
  //   component: About,
  // },
  // {
  //   icon: SkillsIcon,
  //   name: "website.menu.skills",
  //   path: "/home/skills",
  //   component: Skills,
  // },
  // {
  //   icon: CertificationsIcon,
  //   name: "website.menu.certifications",
  //   path: "/home/certifications",
  //   component: Certifications,
  // },
  // {
  //   icon: ResearchIcon,
  //   name: "website.menu.research",
  //   path: "/home/research",
  //   component: Research,
  // },
  // {
  //   icon: TrainingIcon,
  //   name: "website.menu.traning",
  //   path: "/home/traning",
  //   component: Training,
  // },
  // {
  //   icon: CookiesPolicyIcon,
  //   name: "website.menu.cookies_policy",
  //   path: "/home/cookies-policy",
  //   component: CookiesPolicy,
  //   footer: false,
  //   appbar: false,
  // },
  // {
  //   icon: PrivacyPolicyIcon,
  //   name: "website.menu.privacy_policy",
  //   path: "/home/privacy-policy",
  //   component: PrivacyPolicy,
  //   footer: false,
  //   appbar: false,
  // },
  // {
  //   icon: ContactsIcon,
  //   name: "website.menu.contacts",
  //   path: "/home/contacts",
  //   component: Contacts,
  //   footer: true,
  //   appbar: true,
  // },
];
