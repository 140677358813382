import Form from "./Form";
import { Edit as RaEdit } from "react-admin";
import React from "react";

const Edit = (props) => (
  <RaEdit {...props} undoable={false}>
    <Form location={props.location} />
  </RaEdit>
);
export default Edit;
