import { Filter, SearchInput } from "react-admin";

import React from "react";

const Filters = ({ ...props }) => (
  <Filter {...props}>
    <SearchInput source="keyword" alwaysOn />
  </Filter>
);
export default Filters;
