import { BooleanField, DateField, TextField } from "react-admin";

import Actions from "./Actions";
import { DATE_RANGE_EXPR } from "../components/inputs/DateRangeInput";
import Filters from "./Filters";
import React from "react";
import ResponsiveGrid from "../components/list/ResponsiveGrid";
import ResponsiveList from "../components/list/ResponsiveList";

const today = DATE_RANGE_EXPR["today"]();
const List = (props) => (
  <ResponsiveList
    {...props}
    perPage={25}
    type="table"
    split
    actions={<Actions />}
    filters={<Filters />}
    filterDefaultValues={{ ...today, view: "today" }}
    sort={{ field: "created", order: "DESC" }}
  >
    <ResponsiveGrid rowBreakpoint="sm">
      <DateField source="created" showTime />
      <BooleanField source="result" />
      <TextField source="transaction.operator_code" />
    </ResponsiveGrid>
  </ResponsiveList>
);

export default List;
