import React, { Fragment } from "react";
import { Typography, createStyles, withStyles } from "@material-ui/core";

import { compose } from "recompose";
import { get } from "lodash";

const styles = (theme) =>
  createStyles({
    params: {
      minWidth: 100,
    },
    param: {
      marginRight: theme.spacing.unit,
      fontWeight: "bold",
      wordBreak: "keep-all",
      wordWrap: "normal",
      display: "inline-block",
    },
  });

const MonitorField = ({ addLabel = false, classes, record: { monitor } }) =>
  monitor && monitor.params ? (
    <Fragment>
      <Typography variant="body2" color="secondary">
        {get(monitor, "name")}
      </Typography>
      <Typography variant="caption" className={classes.params}>
        {get(monitor, "params", []).map((param) => (
          <span key={param.id} className={classes.param}>
            {param.label}: {param.value} {param.unit}
          </span>
        ))}
      </Typography>
    </Fragment>
  ) : addLabel ? (
    "N.D."
  ) : (
    ""
  );

export default compose(withStyles(styles))(MonitorField);
