import Card from "@material-ui/core/Card";
import CardIcon from "../CardIcon";
import React from "react";
import Typography from "@material-ui/core/Typography";
import compose from "recompose/compose";
import { translate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  main: {
    flex: "1",
    marginTop: 0,
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
    height: 80,
  },
  textContainer: {
    overflow: "hidden",
    maxHeight: 80,
  },
};

const Indicator = ({ label, value, color, icon, translate, classes }) => (
  <div className={classes.main}>
    <CardIcon Icon={icon} bgColor={color} />
    <Card className={classes.card}>
      <div className={classes.textContainer}>
        <Typography
          className={classes.title}
          variant="title"
          title={translate(label)}
        >
          {translate(label)}
        </Typography>
        <Typography variant="subheading" component="h2">
          {value}
        </Typography>
      </div>
    </Card>
  </div>
);

const enhance = compose(withStyles(styles), translate);

export default enhance(Indicator);
