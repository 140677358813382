import {
  DateTimeInput,
  FormDataConsumer,
  Labeled,
  ReferenceInput,
  required,
  translate,
} from "react-admin";
import React, { Fragment } from "react";

import AutocompleteInput from "../../components/inputs/AutocompleteInput";
import SensorValueField from "../../components/fields/SensorValueField";
import compose from "recompose/compose";
import useFieldLabel from "../../components/useFieldLabel";
import { withStyles } from "@material-ui/core";
import withWebsocket from "../../websocket/withWebsocket";

const SensorRowInput = withStyles((theme) => ({
  input: {
    display: "inline-block",
    float: "left",
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.down("sm")]: {
      "& div>div": {
        backgroundColor: "yellow",
        width: "100%",
        display: "block",
      },
    },
  },
}))(({ classes, translate, websocket, ...props }) => {
  const fieldLabel = useFieldLabel({ translate, resource: "thing-sensor" });
  return (
    <FormDataConsumer {...props}>
      {({ formData, scopedFormData, getSource, ...rest }) => {
        return (
          <Fragment>
            <ReferenceInput
              {...rest}
              source={getSource("device_id")}
              reference="device"
              label={fieldLabel("device_id")}
              className={classes.input}
              validate={required()}
            >
              <AutocompleteInput optionText="code" fullWidth />
            </ReferenceInput>
            {scopedFormData?.device_id && (
              <ReferenceInput
                {...rest}
                source={getSource("sensor_id")}
                reference="sensor"
                label={fieldLabel("sensor_id")}
                filter={{ device_id: scopedFormData?.device_id }}
                className={classes.input}
                validate={required()}
              >
                <AutocompleteInput optionText="code" fullWidth />
              </ReferenceInput>
            )}

            {scopedFormData?.sensor_id && (
              <DateTimeInput
                {...rest}
                label={fieldLabel("disabled")}
                fullWidth={false}
                className={classes.input}
                source={getSource("disabled")}
              />
            )}

            {scopedFormData?.sensor_id && (
              <Labeled label={fieldLabel("last_measurement")}>
                <SensorValueField
                  websocket={websocket}
                  record={{ id: scopedFormData?.sensor_id }}
                />
              </Labeled>
            )}
          </Fragment>
        );
      }}
    </FormDataConsumer>
  );
});

export default compose(translate, withWebsocket)(SensorRowInput);
