import { BooleanField, EditButton, TextField } from "react-admin";

import Card from "./Card";
import DurationField from "./fields/DurationField";
import Filters from "./Filters";
import React from "react";
import ResponsiveGrid from "../components/list/ResponsiveGrid";
import ResponsiveList from "../components/list/ResponsiveList";
import ThingField from "./fields/ThingField";
import useIsAdmin from "../useIsAdmin";

const List = (props) => {
  const isAdmin = useIsAdmin(props);
  return (
    <ResponsiveList
      {...props}
      exporter={false}
      filters={<Filters isAdmin={isAdmin} />}
      type="table"
      split
    >
      <ResponsiveGrid rowBreakpoint="md" cardContent={<Card />}>
        <TextField source="id" />
        {isAdmin && <TextField source="user.username" sortBy="user.username" />}
        <ThingField source="location.tree_name" sortBy="location.tree_name" />
        <ThingField source="device.name" sortBy="device.name" />
        <ThingField source="sensor.name" sortBy="sensor.name" />
        <BooleanField source="is_unarmed" />
        <DurationField source="duration" sortBy="Event.start_at" />
        <EditButton />
      </ResponsiveGrid>
    </ResponsiveList>
  );
};
export default List;
