import * as icons from "@material-ui/icons";

import React, { Fragment } from "react";

import { SelectInput } from "react-admin";

const RenderOptionText = ({ record }) => {
  const Icon = icons[record.name];
  return (
    <Fragment>
      <span>
        {record.name}
        <Icon />
      </span>
    </Fragment>
  );
};
const IconInput = (props) => (
  <SelectInput
    {...props}
    choices={Object.keys(icons).map((i) => ({
      id: i,
      name: i,
      icon: i,
    }))}
    optionText={<RenderOptionText />}
  />
);
export default IconInput;
