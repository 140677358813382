import { Avatar, Chip, withStyles } from "@material-ui/core";

import BusinessIcon from "@material-ui/icons/Business";
import PowerIcon from "@material-ui/icons/Power";
import PropTypes from "prop-types";
import React from "react";
import RouterIcon from "@material-ui/icons/Router";
import compose from "recompose/compose";
import { styles } from "./styles";
import { translate } from "ra-core";

const ResettableChip = ({ classes, count, prop, onResetClick, ...props }) => (
  <Chip
    {...props}
    className={classes.resetChip}
    label={count.toString()}
    clickable
    color="primary"
    onDelete={(_) => onResetClick(prop)}
  />
);
ResettableChip.propTypes = {
  count: PropTypes.number.isRequired,
  prop: PropTypes.string.isRequired,
  onResetClick: PropTypes.func.isRequired,
};

const iconMap = {
  locations: <BusinessIcon />,
  devices: <RouterIcon />,
  sensors: <PowerIcon />,
};

const Bottom = ({ classes, onResetClick, filter }) => (
  <div className={classes.filters}>
    {Object.keys(filter)
      .filter((k) => ["locations", "devices", "sensors"].indexOf(k) !== -1)
      .map(
        (name) =>
          filter[name].length > 0 && (
            <ResettableChip
              key={name}
              classes={classes}
              avatar={<Avatar>{iconMap[name]}</Avatar>}
              prop={name}
              count={filter[name].length}
              onResetClick={onResetClick}
            />
          )
      )}
  </div>
);

Bottom.propTypes = {
  onResetClick: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    locations: PropTypes.array.isRequired,
    devices: PropTypes.array.isRequired,
    sensors: PropTypes.array.isRequired,
  }),
};

export default compose(translate, withStyles(styles))(Bottom);
