import { CardActions, CardContent, CardHeader } from "@material-ui/core";
import { EditButton, FormInput, TextField } from "react-admin";
import React, { Fragment } from "react";

import LoginButton from "./buttons/LoginButton";
import { compose } from "recompose";
import withCardStyles from "../components/list/withCardStyles";

const Card = ({ classes, ...props }) => (
  <Fragment>
    <CardHeader
      className={classes.header}
      title={<TextField source="username" {...props} variant="subheading" />}
    />
    <CardContent className={classes.content}>
      <FormInput {...props} source="auth_type" input={<TextField addLabel />} />
      <FormInput
        {...props}
        source="profile.name"
        input={<TextField addLabel />}
      />
      <FormInput
        {...props}
        source="profile.surname"
        input={<TextField addLabel />}
      />
    </CardContent>
    <CardActions className={classes.actions}>
      <EditButton {...props} />
      <LoginButton {...props} />
    </CardActions>
  </Fragment>
);
export default compose(withCardStyles)(Card);
