import { Avatar, Typography, withStyles } from "@material-ui/core";
import { CLOSED, ONGOING } from "../colors";
import React, { useMemo } from "react";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import { LocationIcon } from "../../icons";
import classnames from "classnames";
import { get } from "lodash";

const HeaderTitle = withStyles((theme) => ({
  details: {
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",

    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing.unit * 2,
      paddingBottom: 50,
    },
  },
  section: {
    display: "flex",
    flexDirection: "row",
    justifyItems: "flex-start",
  },
  sectionAvatar: {
    marginRight: theme.spacing.unit * 2,
    width: 50,
    height: 50,
    color: "white",
    alignSelf: "center",
  },
  sectionContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  sectionLocation: {
    marginTop: theme.spacing.unit,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  ongoing: {
    backgroundColor: ONGOING,
  },
  closed: {
    backgroundColor: CLOSED,
  },
}))(({ width, record, status, classes }) => {
  const room = useMemo(() => {
    const location = get(record, "location.tree_name");
    const parts = location.split("/");
    return parts[parts.length - 1];
  }, [record]);
  const isSmall = isWidthUp(width, "sm");
  return (
    <div className={classnames(classes.details)}>
      <div className={classes.section}>
        {!isSmall && (
          <Avatar
            className={classnames(classes.sectionAvatar, classes[status])}
          >
            <LocationIcon />
          </Avatar>
        )}
        <div className={classes.sectionContent}>
          <Typography variant={isSmall ? "body2" : "title"}>
            {get(record, "sensor.name")}
          </Typography>
          <Typography variant="body2">{room}</Typography>
        </div>
      </div>
      <Typography
        className={classes.sectionLocation}
        variant={isSmall ? "caption" : "body1"}
      >
        {get(record, "location.tree_name")}
      </Typography>
    </div>
  );
});
export default withWidth()(HeaderTitle);
