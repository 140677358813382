import React, { Fragment, useMemo } from "react";

import { API_URL } from "../../config";
import Device from "./Device";
import { ImageOverlay } from "react-leaflet";
import Room from "./Room";
import { SHOW_MARKERS_MIN_ZOOM } from "../config";
import { getToken } from "../../authBody";

const Floor = ({
  floor,
  devices,
  zoom,
  deviceId,
  sensorId,
  choropletSensorType,
  selectedSensorTypes,
  onDeviceClick,
  onSensorClick,
}) => {
  // Recupero la mappa del piano, formato immagine, se presente.
  const overlay = useMemo(() => {
    if (floor === null) {
      return null;
    }
    const url = `${API_URL}/file-system/${getToken()}/download/${
      floor.map_filepath
    }`;
    const bounds = [
      [parseFloat(floor.map_min_y), parseFloat(floor.map_min_x)],
      [parseFloat(floor.map_max_y), parseFloat(floor.map_max_x)],
    ];
    return { url, bounds };
  }, [floor]);

  // Recupero tutti i dispositivi/sensori mappati a livello di piano.
  const floorDevices = useMemo(
    () =>
      floor && devices
        ? devices.filter(
            (device) =>
              parseInt(device.location_id, 10) === floor.id ||
              // Attualmente il campo 'location_id' non è mai utilizzato,
              // noi pensiamo comunque al futuro.
              device.sensors.some((sensor) => sensor.location_id === floor.id)
          )
        : [],
    [floor, devices]
  );
  return (
    <Fragment>
      {overlay && (
        <ImageOverlay url={overlay.url} bounds={overlay.bounds} opacity={0.5} />
      )}
      {floor.locations
        .filter((l) => l.coords.length > 0)
        .map((location) => (
          <Room
            key={location.id}
            room={location}
            devices={devices}
            zoom={zoom}
            deviceId={deviceId}
            sensorId={sensorId}
            choropletSensorType={choropletSensorType}
            selectedSensorTypes={selectedSensorTypes}
            onDeviceClick={onDeviceClick}
            onSensorClick={onSensorClick}
          />
        ))}

      {zoom >= SHOW_MARKERS_MIN_ZOOM &&
        floorDevices.map((device) => (
          <Device
            key={device.id}
            device={device}
            deviceId={deviceId}
            sensorId={sensorId}
            zoom={zoom}
            onClick={onDeviceClick}
            onSensorClick={onSensorClick}
          />
        ))}
    </Fragment>
  );
};

export default Floor;
