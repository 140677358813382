import { ReferenceArrayInput, SelectArrayInput } from "react-admin";

import React from "react";

const GroupsArrayInput = (props) => (
  <ReferenceArrayInput {...props} reference="group">
    <SelectArrayInput optionText="name" />
  </ReferenceArrayInput>
);
export default GroupsArrayInput;
