import { Typography, withStyles } from "@material-ui/core";

import Link from "../../components/Link";
import React from "react";
import { compose } from "recompose";
import { translate } from "react-admin";

const styles = (theme) => ({
  root: {
    paddingTop: -theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 3,
    textAlign: "center",
    bottom: 0,
  },
  whiteColor: {
    color: "white",
    display: "inline",
  },
  address: {
    color: "white",
    fontStyle: "normal",
  },
});
const Copyright = ({ classes, translate }) => {
  return (
    <div className={classes.root}>
      <Typography
        className={classes.whiteColor}
        color="textSecondary"
        align="center"
      >
        {"Copyright © "}
        <Link variant="body2" className={classes.whiteColor} href="/home/">
          {translate("app.name")}
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
      {translate("footer.address")
        .split("\n")
        .map((phrase, index) => (
          <Typography
            key={index}
            variant="caption"
            component="address"
            align="center"
            className={classes.address}
          >
            {phrase}
          </Typography>
        ))}
    </div>
  );
};
export default compose(translate, withStyles(styles))(Copyright);
