import mergeFilter from "./mergeFilter";
import setLocation from "./setLocation";

const resettableFilter = (filter) => ({
  ...filter,
  locations: filter.locations || [],
  devices: filter.devices || [],
  sensors: filter.sensors || [],
});

export const clearData = () => localStorage.removeItem("tvf");

export default ({ history, location, state }) => {
  const prevFilters = mergeFilter();

  const finalFilter = mergeFilter(
    resettableFilter({
      searchKeyword:
        state.searchKeyword &&
        state.searchKeyword !== null &&
        state.searchKeyword.length > 0
          ? state.searchKeyword
          : undefined,
      expanded: state.expanded,
      ...Object.keys(state.checked)
        .map((key) => ({
          key,
          checked: state.checked[key],
          val: key.split("-")[1],
          group: key.split("-")[0],
        }))
        .filter((p) => p.checked === true)
        .reduce(
          (arrays, current) => ({
            ...arrays,
            [current.group]: (arrays[current.group] || []).concat(current.val),
          }),
          {}
        ),
    })
  );

  localStorage.setItem(
    "tvf",
    JSON.stringify(
      /*finalFilter */ {
        searchKeyword: finalFilter.searchKeyword,
        expanded: finalFilter.expanded,
        checked: finalFilter.checked,
        devices: finalFilter.devices,
        sensors: finalFilter.sensors,
        locations: finalFilter.locations,
      }
    )
  );

  const prevSelectedLocations = JSON.stringify(prevFilters.locations);
  const prevSelectedDevices = JSON.stringify(prevFilters.devices);
  const prevSelectedSensors = JSON.stringify(prevFilters.sensors);

  const selectedLocations = JSON.stringify(finalFilter.locations);
  const selectedDevices = JSON.stringify(finalFilter.devices);
  const selectedSensors = JSON.stringify(finalFilter.sensors);

  if (
    prevSelectedLocations !== selectedLocations ||
    prevSelectedDevices !== selectedDevices ||
    prevSelectedSensors !== selectedSensors
  ) {
    history.push(
      `${setLocation({ location })}?filter=${JSON.stringify(finalFilter)}`
    );
  }
};
