import React, { Fragment } from "react";

import Layout from "./Layout";
import Legend from "./Legend";
import Loader from "../../components/Loader";
import { Show as RaShow } from "react-admin";
import { connect } from "react-redux";
import { get } from "lodash";
import { withStyles } from "@material-ui/core";

const Show = withStyles((theme) => ({
  card: {
    overflow: "visible",
    [theme.breakpoints.down("sm")]: {
      marginTop: 260,
      "& > div:first-child": {
        marginTop: -245,
      },
    },
  },
}))(({ dispatch, loading, ...props }) => {
  return (
    <Fragment>
      <Legend />
      <Loader loading={loading} />
      <RaShow {...props}>{!loading ? <Layout /> : null}</RaShow>
    </Fragment>
  );
});

export default connect((state) => ({
  loading: get(state, "admin.loading", 0) > 0,
}))(Show);
