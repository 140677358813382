import { createStyles, withStyles } from "@material-ui/core";

import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import { TextField } from "@material-ui/core";
import compose from "recompose/compose";
import { translate } from "ra-core";

const styles = (theme) =>
  createStyles({
    root: {
      margin: theme.spacing.unit * 2,
      marginBottom: 0,
      display: "flex",
      alignItems: "center",
      width: "auto",
      [theme.breakpoints.down("xs", "sm")]: {
        marginTop: theme.spacing.unit * 2,
      },
    },
    input: {
      marginLeft: theme.spacing.unit * 1,
      flex: 1,
      borderBottom: 0,
    },
    inputField: {},
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  });

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: props.keyword || "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.keyword !== this.props.keyword) {
      this.setState({ keyword: nextProps.keyword });
    }
  }

  handleChange(evt) {
    let keyword = evt.target.value;

    this.setState({ keyword });
  }

  handleSearch() {
    this.props.onChange(this.state.keyword);
  }

  handleClear() {
    this.setState({ keyword: "" });
    this.props.onChange("");
  }

  handleKeyUp(e) {
    if (e.keyCode === 13) {
      this.handleSearch();
    }
  }

  render() {
    const { classes, translate } = this.props;
    return (
      <Paper className={classes.root} elevation={0}>
        <TextField
          value={this.state.keyword}
          error={false}
          className={classes.input}
          onChange={this.handleChange}
          onKeyUp={this.handleKeyUp}
          placeholder={translate("components.tree.fields.search")}
          inputProps={{
            "aria-label": translate("components.tree.fields.search"),
            className: classes.inputField,
          }}
        />
        <IconButton
          className={classes.iconButton}
          aria-label="Reset"
          onClick={this.handleClear}
          disabled={!this.state.keyword}
        >
          <ClearIcon />
        </IconButton>
        <IconButton
          className={classes.iconButton}
          aria-label="search"
          onClick={this.handleSearch}
        >
          <SearchIcon />
        </IconButton>
      </Paper>
    );
  }
}

Search.propTypes = {
  keyword: PropTypes.string.isRequired,
};

export default compose(translate, withStyles(styles))(Search);
