import {
  AutocompleteArrayInput,
  ReferenceArrayInput,
  translate,
} from "react-admin";
import { FormHelperText, withStyles } from "@material-ui/core";
import React, { Fragment } from "react";

// record -> input
const format = (locations) => {
  let formattedData = locations
    ? locations.map((g) => g.location_id.toString())
    : [];
  return formattedData;
};

// input -> record
const parse = (locations) => {
  let parsedData = locations
    ? locations.map((id) => ({ location_id: id.toString() }))
    : [];
  return parsedData;
};
const LocationsInput = withStyles((theme) => ({
  helperText: {
    lineHeight: 1.35,
  },
}))(({ translate, classes, ...props }) => (
  <Fragment>
    <ReferenceArrayInput
      {...props}
      perPage={100}
      reference="location"
      sort={{ field: "tree_name", order: "ASC" }}
      filter={{ autocomplete: true }}
      format={format}
      parse={parse}
    >
      <AutocompleteArrayInput optionText="tree_name" fullWidth />
    </ReferenceArrayInput>
    <FormHelperText className={classes.helperText}>
      {translate("resources.sentinel.fields.locations.help")}
    </FormHelperText>
  </Fragment>
));
export default translate(LocationsInput);
