import { Toolbar as RaToolbar, SaveButton } from "react-admin";

import React from "react";
import { useResourcePermissions } from "../usePermissions";
import { withStyles } from "@material-ui/core";

const Toolbar = withStyles((theme) => ({
  toolbar: {
    "& button": {
      marginRight: theme.spacing.unit * 2,
    },
  },
}))(({ classes, ...props }) => {
  const { edit, create } = useResourcePermissions(props);

  return (
    <RaToolbar {...props} classes={classes}>
      <SaveButton {...props} disabled={!edit || !create} />
    </RaToolbar>
  );
});
export default Toolbar;
