import { NumberInput, SimpleForm, TextInput, translate } from "react-admin";

import BooleanSelectInput from "../components/inputs/BooleanSelectInput";
import ChoropletInput from "./inputs/ChoropletInput";
import CodeEditorInput from "../components/inputs/CodeEditorInput";
import ColorInput from "./inputs/ColorInput";
import IconInput from "./inputs/IconInput";
import MapViewModeInput from "./inputs/MapViewModeInput";
import React from "react";
import useFieldLabel from "../components/useFieldLabel";

const Form = ({ translate, ...props }) => {
  const fieldLabel = useFieldLabel({ translate, ...props });
  return (
    <SimpleForm redirect="list" {...props}>
      <TextInput source="code" />
      <TextInput source="name" />
      <MapViewModeInput source="map_view_mode" />
      <IconInput source="icon" />
      <BooleanSelectInput source="is_external" />
      <BooleanSelectInput source="enable_choroplet_map" />
      <ChoropletInput source="min_value" input={<NumberInput />} />
      <ChoropletInput source="max_value" input={<NumberInput />} />
      <ChoropletInput source="color" input={<ColorInput />} />
      <TextInput source="unit" />
      <TextInput
        source="unit_chart"
        helperText={fieldLabel("unit_chart.help")}
      />
      <CodeEditorInput source="formatter" toolbar={false} />
    </SimpleForm>
  );
};
export default translate(Form);
