import { Button, Mutation } from "react-admin";

import { RESTART_CRONJOBS } from "../../addCronJobFeature";
import React from "react";
import ScheduleIcon from "@material-ui/icons/Schedule";

const options = {
  onSuccess: {
    notification: {
      body: "resources.cron-job.messages.restart_success",
      level: "info",
    },
    refresh: true,
    unselectAll: true,
  },
  onFailure: {
    notification: {
      body: "resources.cron-job.messages.restart_failure",
      level: "warning",
    },
    unselectAll: true,
  },
};

const RestartButton = ({ selectedIds }) => {
  const payload = { ids: selectedIds };
  return (
    <Mutation type={RESTART_CRONJOBS} options={options} payload={payload}>
      {(restart) => (
        <Button
          alignIcon="left"
          label="resources.cron-job.buttons.restart"
          onClick={restart}
        >
          <ScheduleIcon />
        </Button>
      )}
    </Mutation>
  );
};
export default RestartButton;
