import { DeviceLabelIcon } from "../icons";
import List from "./List";

export default {
  options: {
    group: "types",
    accessible: ({ user }) =>
      user.role === "admin" &&
      user.permissions.indexOf("device-label/list") !== -1,
  },
  icon: DeviceLabelIcon,
  list: List,
};
