import { DateField, EditButton, TextField } from "react-admin";

import Card from "./Card";
import Filters from "./Filters";
import GroupsField from "./fields/GroupsField";
import LoginButton from "./buttons/LoginButton";
import React from "react";
import ResponsiveGrid from "../components/list/ResponsiveGrid";
import ResponsiveList from "../components/list/ResponsiveList";

const List = (props) => (
  <ResponsiveList {...props} type="table" split filters={<Filters />}>
    <ResponsiveGrid rowBreakpoint="md" cardContent={<Card />}>
      <TextField source="id" />
      <GroupsField source="groups" />
      <TextField source="auth_type" variant="body2" />
      <TextField source="username" />
      <DateField source="created" showTime />
      <DateField source="modified" showTime />
      <LoginButton />
      <EditButton />
    </ResponsiveGrid>
  </ResponsiveList>
);
export default List;
