import { Children, cloneElement, useCallback, useEffect } from "react";
import {
  Divider,
  Drawer,
  IconButton,
  createStyles,
  withStyles,
} from "@material-ui/core";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { LEFT_SIDEBAR_WIDTH } from "../config";
import React from "react";
import classNames from "classnames";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { isWidthUp } from "@material-ui/core/withWidth";
import logo from "../assets/logo.png";
import { setSidebarVisibility } from "ra-core";
import withWidth from "@material-ui/core/withWidth";

const styles = (theme) =>
  createStyles({
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      height: "100vh",
      width: LEFT_SIDEBAR_WIDTH - 1,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 58,
      [theme.breakpoints.up("sm")]: {
        // width: theme.spacing.unit * 9,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      paddingRight: theme.spacing.unit * 1.8,
      ...theme.mixins.toolbar,
      [theme.breakpoints.down("sm")]: {
        paddingRight: theme.spacing.unit * 0.9,
      },
    },
    version: {
      padding: theme.spacing.unit * 2,
    },
  });
const Sidebar = ({ open, width, classes, children, setSidebarVisibility }) => {
  const handleClose = useCallback(
    (force) => {
      if (!isWidthUp("sm", width) || force === true) {
        setSidebarVisibility(false);
      }
    },
    [setSidebarVisibility, width]
  );
  const toggleSidebar = useCallback(
    () => setSidebarVisibility(!open),
    [open, setSidebarVisibility]
  );

  useEffect(() => {
    if (isWidthUp("sm", width)) {
      setSidebarVisibility(true);
    }
  }, [width, setSidebarVisibility]);

  return (
    <Drawer
      classes={{
        paper: classNames(
          classes.drawerPaper,
          !open && classes.drawerPaperClose
        ),
      }}
      variant={isWidthUp("sm", width) ? "permanent" : "temporary"}
      open={open}
      onClose={toggleSidebar}
    >
      <div className={classes.toolbar}>
        <img src={logo} alt="SensIC" width="150" />
        <IconButton onClick={handleClose.bind(this, true)}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Divider />
      {cloneElement(Children.only(children), {
        onMenuClick: handleClose,
      })}
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
  locale: state.locale,
});

export default compose(
  connect(mapStateToProps, { setSidebarVisibility }),
  withStyles(styles),
  withWidth()
)(Sidebar);
