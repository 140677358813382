import { DeviceIcon } from "../../../icons";
import React from "react";
import { divIcon } from "leaflet";
import { grey } from "@material-ui/core/colors";
import { renderToString } from "react-dom/server";

const LocationDeviceIcon = divIcon({
  className: `transparent`,
  html: renderToString(
    <div
      style={{
        width: 30,
        height: 30,
        backgroundColor: "white",
        paddingTop: 2,
        overflow: "hidden",
        borderRadius: 16,
        border: `4px solid ${grey[900]}`,
        textAlign: "center",
        marginTop: -9, // Il valore di default di leaflet è -6 a cui vado ad aggiungere questa ulteriore misura.
        marginLeft: -9,
      }}
    >
      <DeviceIcon style={{ color: grey[900] }} />
    </div>
  ),
});
export default LocationDeviceIcon;
