import { get } from "lodash";

export const useResourcePermissions = ({ resource }) => {
  const user = getPermissions();
  return {
    create: get(user, "permissions", []).indexOf(`${resource}/create`) !== -1,
    delete: get(user, "permissions", []).indexOf(`${resource}/delete`) !== -1,
    remove: get(user, "permissions", []).indexOf(`${resource}/delete`) !== -1,
    list: get(user, "permissions", []).indexOf(`${resource}/list`) !== -1,
    edit: get(user, "permissions", []).indexOf(`${resource}/edit`) !== -1,
  };
};
export const getPermissions = () => {
  let userData = localStorage.getItem("user");
  let user = userData ? JSON.parse(atob(userData)) : null;
  const isLoggedIn = user !== undefined && user !== null;

  return isLoggedIn ? user : null;
};
const usePermissions = getPermissions;
export default usePermissions;
