import CardContent from "@material-ui/core/CardContent";
import HelpBlock from "../HelpBlock";
import React from "react";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import compose from "recompose/compose";
import pure from "recompose/pure";

const PaginationLimit = () => (
  <CardContent>
    <HelpBlock
      title="Oops!"
      message="ra.navigation.no_results"
      Icon={SentimentVeryDissatisfiedIcon}
    />
  </CardContent>
);

const enhance = compose(pure);

export default enhance(PaginationLimit);
