import {
  MuiThemeProvider,
  createMuiTheme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import React, { Component } from "react";

import Card from "@material-ui/core/Card";
import Notification from "../components/Notification";
import PropTypes from "prop-types";
import classnames from "classnames";
import logo from "../assets/logo.png";
import theme from "../theme";

const styles = (theme) =>
  createStyles({
    main: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      height: "1px",
      alignItems: "center",
      justifyContent: "flex-start",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    card: {
      minWidth: 300,
      marginTop: "6em",
    },
    logo: {
      margin: "2em",
      display: "flex",
      justifyContent: "center",
    },
    icon: {
      backgroundColor: theme.palette.secondary[500],
    },
  });

/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider` using the AUTH_LOGIN verb. Redirects to the root page
 * (/) upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */
class Login extends Component {
  theme = createMuiTheme(this.props.theme);
  containerRef = React.createRef();
  backgroundImageLoaded = false;

  updateBackgroundImage = () => {
    if (!this.backgroundImageLoaded && this.containerRef.current) {
      const { backgroundImage } = this.props;
      this.containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
      this.backgroundImageLoaded = true;
    }
  };

  // Load background image asynchronously to speed up time to interactive
  lazyLoadBackgroundImage() {
    const { backgroundImage } = this.props;

    if (backgroundImage) {
      const img = new Image();
      img.onload = this.updateBackgroundImage;
      img.src = backgroundImage;
    }
  }

  componentDidMount() {
    this.lazyLoadBackgroundImage();
  }

  componentDidUpdate() {
    if (!this.backgroundImageLoaded) {
      this.lazyLoadBackgroundImage();
    }
  }

  render() {
    const {
      backgroundImage,
      classes,
      className,
      loginForm,
      staticContext,
      ...rest
    } = this.props;

    return (
      <MuiThemeProvider theme={this.theme}>
        <div
          className={classnames(classes.main, className)}
          {...rest}
          ref={this.containerRef}
        >
          <Card className={classes.card}>
            <div className={classes.logo}>
              <img src={logo} alt="SensIC" />
            </div>
            {loginForm}
          </Card>
          <Notification />
        </div>
      </MuiThemeProvider>
    );
  }
}

const EnhancedLogin = withStyles(styles)(Login);

EnhancedLogin.propTypes = {
  backgroundImage: PropTypes.string,
  loginForm: PropTypes.element,
  theme: PropTypes.object,
  staticContext: PropTypes.object,
};
EnhancedLogin.defaultProps = {
  theme: theme,
};

export default EnhancedLogin;
