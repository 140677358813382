import { Button, translate } from "react-admin";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  withStyles,
} from "@material-ui/core";
import React, { Component, Fragment } from "react";

import { API_URL } from "../../config";
import DoneIcon from "@material-ui/icons/Done";
import MuiButton from "@material-ui/core/Button";
import PrintIcon from "@material-ui/icons/Print";
import { compose } from "recompose";
import { getToken } from "../../authBody";
import makeid from "../../utils/makeid";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    cursor: "pointer",
    border: "3px dotted #f9f9f9",
  },
  paperSelected: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
});

class PrintButton extends Component {
  state = {
    isOpen: false,
    session: makeid(10),
    position: 1,
    borders: false,
    sensors: false,
  };

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  handleConfirm = () => {
    this.setState({ isOpen: false, session: makeid(10) }, () => {
      const { selectedIds } = this.props;
      const { borders, sensors, position } = this.state;
      const token = getToken();
      window.open(
        `${API_URL}/device-label/print/${token}/doc.pdf?ids=${selectedIds.join(
          ","
        )}${selectedIds.length === 1 ? `&position=${position}` : ""}${
          borders ? "&borders=1" : ""
        }${sensors ? "&sensors=1" : ""}`,
        "_blank"
      );
    });
  };

  render() {
    const { translate, classes, selectedIds } = this.props;
    return (
      <Fragment>
        <Button
          label={"resources.device-label.buttons.print"}
          onClick={this.handleClick}
        >
          <PrintIcon />
        </Button>
        <Dialog open={this.state.isOpen} onClose={this.handleDialogClose}>
          <DialogTitle>
            {translate("resources.device-label.titles.print")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {translate("resources.device-label.messages.print")}
            </DialogContentText>
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.borders}
                  onChange={(e) =>
                    this.setState({
                      borders: e.target.checked,
                    })
                  }
                />
              }
              label={translate("resources.device-label.messages.print_borders")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.sensors}
                  onChange={(e) =>
                    this.setState({
                      sensors: e.target.checked,
                    })
                  }
                />
              }
              label={translate("resources.device-label.messages.print_sensors")}
            />
            {selectedIds.length === 1 && !this.state.sensors && (
              <Fragment>
                <br />
                <DialogContentText>
                  {translate("resources.device-label.messages.print_single")}
                </DialogContentText>
                <br />

                <div className={classes.root}>
                  {[1, 3, 5, 7].map((row) => (
                    <Grid container spacing={24} key={row}>
                      {[row, row + 1].map((position) => (
                        <Grid item xs key={position}>
                          <Paper
                            elevation={this.state.position === position ? 1 : 0}
                            className={
                              this.state.position === position
                                ? classes.paperSelected
                                : classes.paper
                            }
                            onClick={() => this.setState({ position })}
                          >
                            {this.state.position === position && <DoneIcon />}
                            {this.state.position !== position && <br />}
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  ))}
                </div>
              </Fragment>
            )}
          </DialogContent>
          <DialogActions>
            <MuiButton color="secondary" onClick={this.handleDialogClose}>
              {translate("ra.action.cancel")}
            </MuiButton>
            <MuiButton color="primary" onClick={this.handleConfirm}>
              {translate("ra.action.confirm")}
            </MuiButton>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}
export default compose(translate, withStyles(styles))(PrintButton);
