import React, { Fragment } from "react";
import { Typography, withStyles } from "@material-ui/core";

import LastPageIcon from "@material-ui/icons/LastPage";
import NodeLabel from "./NodeLabel";
import TreeItem from "./TreeItem";
import compose from "recompose/compose";
import makeid from "../../utils/makeid";
import { styles } from "./styles";

const SensorNode = compose(withStyles(styles))(
  ({ sensor, classes, checked, location, onNodeCheckChange }) => (
    <TreeItem
      nodeId={`sensor-${sensor.id}`}
      icon={<LastPageIcon />}
      classes={{
        content: classes.content,
        expanded: classes.expanded,
      }}
      disabled={sensor.lat === null || sensor.lng === null}
      label={
        <NodeLabel
          title={sensor.name}
          label={
            <Fragment>
              <Typography variant="body2" className={classes.sensorName}>
                {sensor.name}
              </Typography>
              <Typography variant="caption">{sensor.code}</Typography>
            </Fragment>
          }
          disabled={sensor.lat === null || sensor.lng === null}
          filter={{
            floor_id: sensor.floor_id,
            device_id: sensor.device_id,
            sensor_id: sensor.id,
            location: undefined,
            zoom: 23,
            lat: parseFloat(sensor.lat),
            lng: parseFloat(sensor.lng),
            rc: makeid(10),
          }}
          location={location}
          checked={checked}
          onCheckChange={(checked) =>
            onNodeCheckChange(`sensors-${sensor.id}`, checked)
          }
        />
      }
    />
  )
);

export default SensorNode;
