import React, { Fragment } from "react";

import { Typography } from "@material-ui/core";
import { get } from "lodash";

const NameField = ({ source, record, basePath, resource, ...props }) => (
  <Fragment>
    <Typography variant="body2" {...props}>
      {get(record, source)}
    </Typography>
    <Typography variant="caption">{get(record, "task_name")}</Typography>
    <Typography>{get(record, "description")}</Typography>
  </Fragment>
);
export default NameField;
