import { ReferenceArrayInput, SelectArrayInput } from "react-admin";

import React from "react";

const GroupsSelectInput = (props) => (
  <ReferenceArrayInput {...props} source="groups" reference="group">
    <SelectArrayInput optionText="name" />
  </ReferenceArrayInput>
);
export default GroupsSelectInput;
