import { Button, TextField } from "@material-ui/core";
import {
  Notification,
  showNotification,
  translate,
  withDataProvider,
} from "react-admin";
import React, { Component } from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";

import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import LockIcon from "@material-ui/icons/Lock";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Title } from "react-admin";
import { compose } from "recompose";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import theme from "../theme";

const styles = (theme) =>
  createStyles({
    root: {
      display: "flex",
      minHeight: "100vh",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "flex-start",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundColor: "GhostWhite",
    },
    form: {
      minWidth: 300,
      marginTop: "6em",
    },
    header: {
      margin: "1em",
      display: "flex",
      justifyContent: "center",
    },
    buttonsContainer: {
      flex: 1,
      flexDirection: "column",
      margin: theme.spacing.unit,
    },
    button: {
      display: "block",
      clear: "both",
      width: "100%",
      marginBottom: theme.spacing.unit,
    },
    link: {
      width: "auto",
      float: "left",
      alignSelf: "flex-start",
      marginLeft: 0,
    },
    icon: {
      marginRight: theme.spacing.unit,
    },
  });

class ResetPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: "",
      error: null,
      loading: false,
    };
  }

  handleSubmit(evt) {
    evt.preventDefault();
    const { dataProvider, showNotification, push } = this.props;
    this.setState({ loading: true }, () => {
      dataProvider("CREATE", "reset-password", {
        account: this.state.account,
      }).then((response) => {
        if (!response.data.valid) {
          if (response.data.errors.some((e) => e.field === "account")) {
            const error = response.data.errors[0];
            this.setState({ error: error.message, loading: false });
          }
        } else {
          this.setState({ loading: false, error: null }, () => {
            showNotification(response.data.message, "info");
            push("/");
          });
        }
      });
    });
  }

  render() {
    const { classes, translate, theme } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <form
            className={classes.form}
            onSubmit={this.handleSubmit.bind(this)}
          >
            <Card>
              <Title title="Reset password" />
              <div className={classes.header}>
                <Avatar>
                  <LockIcon />
                </Avatar>
              </div>
              <CardContent>
                <TextField
                  autoFocus
                  label="Account"
                  fullWidth
                  error={this.state.error !== null}
                  helperText={this.state.error}
                  onChange={(evt) =>
                    this.setState({ account: evt.target.value })
                  }
                />
              </CardContent>
              <CardActions className={classes.buttonsContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  type="submit"
                  disabled={this.state.loading}
                >
                  {this.state.loading && (
                    <CircularProgress
                      className={classes.icon}
                      size={18}
                      thickness={2}
                    />
                  )}
                  {translate("ra.auth.reset_password")}
                </Button>

                <Button
                  className={classes.link}
                  href="/dashboard/#/login"
                  size="small"
                  color="primary"
                >
                  &larr;
                  {translate("ra.auth.back_to_login")}
                </Button>
              </CardActions>
            </Card>
          </form>
          <Notification />
        </div>
      </MuiThemeProvider>
    );
  }
}

const EnhancedResetPage = compose(
  withStyles(styles),
  withDataProvider,
  connect(null, { showNotification, push }),
  translate
)(ResetPage);

EnhancedResetPage.defautProps = {
  theme: theme,
};

export default EnhancedResetPage;
