import { translate, withDataProvider } from "ra-core";

import DeviceNode from "./DeviceNode";
import { GET_CHILDREN } from "../../addExplorerFeature";
import Link from "../Link";
import LoaderNode from "./LoaderNode";
import NodeLabel from "./NodeLabel";
import PropTypes from "prop-types";
import React from "react";
import SensorNode from "./SensorNode";
import TreeItem from "./TreeItem";
import compose from "recompose/compose";
import { getCenterAndZoom } from "../../utils/locations";
import getNodeKey from "./getNodeKey";
import makeid from "../../utils/makeid";
import setLocation from "./setLocation";
import showDevices from "./showDevices";
import showSensors from "./showSensors";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core";

export const withChildren = ({
  node,
  loaded,
  checked,
  expanded,
  onNodeClick,
  onNodeCheckChange,
  location,
}) => {
  const children = [
    !loaded[node.id] && <LoaderNode key="loader" />,
    loaded[node.id] &&
      loaded[node.id] &&
      loaded[node.id].map((child) => (
        <LocationNode
          {...{
            key: child.id,
            node: child,
            loaded,
            checked,
            expanded,
            location,
            onNodeClick,
            onNodeCheckChange,
          }}
        />
      )),
    showDevices({ location }) &&
      node.devices &&
      node.devices.map((device) => (
        <DeviceNode
          key={getNodeKey({ resource: "device", record: device })}
          device={device}
          checked={checked}
          location={location}
          onNodeClick={(id) => onNodeClick(id)}
          onNodeCheckChange={(id, checked) => onNodeCheckChange(id, checked)}
        />
      )),
    showSensors({ location }) &&
      node.sensors &&
      node.sensors.map((sensor) => (
        <SensorNode
          key={getNodeKey({ resource: "sensor", record: sensor })}
          sensor={sensor}
          checked={
            checked[getNodeKey({ resource: "sensor", record: sensor }) === true]
          }
          location={location}
          onNodeClick={(id) => onNodeClick(id)}
          onNodeCheckChange={(id, checked) => onNodeCheckChange(id, checked)}
        />
      )),
  ].filter((c) => c && c !== null);
  return (fn) => fn(children);
};

const createFilter = (node, expanded) => {
  const cz = getCenterAndZoom(node);
  if (cz === null) {
    return { empty: true };
  }
  return {
    location_id: node.id,
    location: node.type === "floor" ? node.parent_id : node.id,
    floor_id: node.type === "floor" ? node.id : null,
    expanded,
    ...cz,
    device_id: null,
    sensor_id: null,
    zoom: node.type === "floor" ? 21 : cz.zoom,
    rc: makeid(10),
  };
};

const LocationNode = compose(
  withStyles(styles),
  withDataProvider,
  translate
)(
  ({
    node,
    loaded,
    checked,
    expanded,
    dataProvider,
    classes,
    location,
    onNodeClick,
    onNodeCheckChange,
  }) =>
    withChildren({
      node,
      loaded,
      checked,
      expanded,
      location,
      onNodeClick,
      onNodeCheckChange,
    })((children) =>
      children.length > 0 ? (
        <TreeItem
          nodeId={node.id}
          className={classes.locationNode}
          classes={{
            content: classes.content,
            expanded: classes.expanded,
          }}
          label={
            <NodeLabel
              label={node.name}
              filter={createFilter(node, expanded)}
              checked={
                checked[getNodeKey({ resource: "location", record: node })] ===
                true
              }
              disabled={
                getCenterAndZoom(node) === null && node.type !== "location"
              }
              location={location}
              onCheckChange={(checked) =>
                onNodeCheckChange(
                  getNodeKey({ resource: "location", record: node }),
                  checked
                )
              }
            />
          }
          onClick={() =>
            (!loaded[node.id] &&
              dataProvider(GET_CHILDREN, "explorer", node).then((response) =>
                onNodeClick(node.id, response.data.nodes)
              )) ||
            onNodeClick(node.id)
          }
        >
          {children}
        </TreeItem>
      ) : (
        <TreeItem
          nodeId={node.id}
          classes={{
            content: classes.content,
          }}
          label={
            <Link
              variant="subheading"
              href={`#/${setLocation({ location })}?filter=${JSON.stringify({
                id: node.id,
              })}`}
            >
              {node.name}
            </Link>
          }
        ></TreeItem>
      )
    )
);

LocationNode.propTypes = {
  node: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  onNodeClick: PropTypes.func.isRequired,
  onNodeCheckChange: PropTypes.func.isRequired,
};

export default LocationNode;
