import React, { useEffect } from "react";
import { SelectInput, translate } from "react-admin";
import { change, formValueSelector } from "redux-form";

import { addField } from "ra-core";
import { connect } from "react-redux";
import moment from "moment";

const DATE_FORMAT_START = "YYYY-MM-DDT00:00";
const DATE_FORMAT_END = "YYYY-MM-DDT23:59";
export const DATE_RANGE_EXPR = {
  all: () => ({
    start_at: null,
    end_at: null,
  }),
  today: () => ({
    start_at: moment().format(DATE_FORMAT_START),
    end_at: moment().format(DATE_FORMAT_END),
  }),
  yesterday: () => ({
    start_at: moment().subtract(1, "day").format(DATE_FORMAT_START),
    end_at: moment().subtract(1, "day").format(DATE_FORMAT_END),
  }),
  last_week: () => ({
    start_at: moment().subtract(7, "day").format(DATE_FORMAT_START),
    end_at: moment().format(DATE_FORMAT_END),
  }),
  last_month: () => ({
    start_at: moment()
      .subtract(1, "month")
      .startOf("month")
      .format(DATE_FORMAT_START),
    end_at: moment()
      .subtract(1, "month")
      .endOf("month")
      .format(DATE_FORMAT_END),
  }),
  current_month: () => ({
    start_at: moment().startOf("month").format(DATE_FORMAT_START),
    end_at: moment().format(DATE_FORMAT_END),
  }),
};
const DATE_RANGES = Object.keys(DATE_RANGE_EXPR).map((rangeName) => ({
  id: rangeName,
  name: `app.${rangeName}`,
}));

const DateRangeInput = ({
  dispatch,
  resource,
  source,
  translate,
  meta,
  addLabel,
  ...state
}) => {
  const selector = formValueSelector(meta.form);
  const value = selector(state, source);
  useEffect(() => {
    if (DATE_RANGE_EXPR[value]) {
      const range = (DATE_RANGE_EXPR[value]
        ? DATE_RANGE_EXPR[value]
        : DATE_RANGE_EXPR["all"])();
      dispatch(change(meta.form, `start_at`, range.start_at));
      dispatch(change(meta.form, `end_at`, range.end_at));
    }
  }, [dispatch, value, meta.form, source]);
  return (
    <SelectInput
      source={source}
      label={translate(`app.view`)}
      choices={DATE_RANGES}
    />
  );
};

export default connect((state) => state)(addField(translate(DateRangeInput)));
