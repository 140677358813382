import { FormDataConsumer, NumberInput } from "react-admin";

import React from "react";
import { get } from "lodash";

const ValueInput = (props) => (
  <FormDataConsumer>
    {({ formData }) => (
      <NumberInput
        {...props}
        defaultValue={1}
        options={{ disabled: get(formData, "unit") === "always" }}
      />
    )}
  </FormDataConsumer>
);
export default ValueInput;
