import React from "react";
import { TextField } from "react-admin";
import { withStyles } from "@material-ui/core";

const CodeField = withStyles((theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 250,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}))(({ classes, fullWidth, ...props }) => (
  <TextField {...props} className={classes.root} variant="body2" />
));
export default CodeField;
