import { Map as LfMap } from "react-leaflet";
import React from "react";

class Map extends React.Component {
  render() {
    const { children, ...props } = this.props;
    return (
      <LfMap attributionControl={false} ref={"map"} maxZoom={25} {...props}>
        {children}
      </LfMap>
    );
  }
}

export default Map;
