import Form from "./Form";
import { Create as RaCreate } from "react-admin";
import React from "react";
import { compose } from "recompose";
import withFormStyles from "../components/forms/withFormStyles";

const Create = ({ classes, ...props }) => (
  <RaCreate {...props} className={classes.root}>
    <Form />
  </RaCreate>
);

export default compose(withFormStyles)(Create);
