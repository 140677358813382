import React from "react";
import { SelectInput } from "react-admin";

const choices = [
  {
    id: 1,
    name: "ra.boolean.true",
  },
  {
    id: 0,
    name: "ra.boolean.false",
  },
];

const BooleanSelectInput = (props) => (
  <SelectInput choices={choices} {...props} />
);
export default BooleanSelectInput;
