import { Filter, TextInput } from "react-admin";

import DateRangeInput from "../components/inputs/DateRangeInput";
import { DateTimeInput } from "../components/inputs/DateTimeInput";
import React from "react";

const Filters = ({ ...props }) => (
  <Filter {...props}>
    <DateRangeInput source="view" alwaysOn />
    <DateTimeInput source={`start_at`} alwaysOn />
    <DateTimeInput source={`end_at`} alwaysOn />
    <TextInput source={`keyword`} alwaysOn />
  </Filter>
);
export default Filters;
