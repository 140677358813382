import { API_URL } from "./config";
import { fetchUtils } from "react-admin";
import { getBody } from "./authBody";
import { validateResponse } from "./dataProvider";

export const EXEC_CODING_SAVATAGE = "coding_php_save";
export const EXEC_CODING_TEMPLATE_LOAD = "coding_php_template";
export const EXEC_CODING_SIMULATION = "coding_php_simulate";
export const EXEC_CODING_TEMPLATE_PREVIEW = "coding_php_preview";
export const EXEC_CODING_SEND_EMAIL_TEMPLATE_PREVIEW =
  "coding_php_send-preview";

const addCodingFeature = (requestHandler) => (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  if (
    [
      EXEC_CODING_SAVATAGE,
      EXEC_CODING_TEMPLATE_LOAD,
      EXEC_CODING_SIMULATION,
      EXEC_CODING_TEMPLATE_PREVIEW,
      EXEC_CODING_SEND_EMAIL_TEMPLATE_PREVIEW,
    ].indexOf(type) !== -1
  ) {
    let action = type.replace("coding_php_", "");
    const url = `${API_URL}/${resource}/coding/${action}/run`;

    return fetchJson(url, {
      method: "POST",
      body: getBody({
        ...(params.record || {}),
        php_code: params.code,
      }),
    })
      .then((response) => validateResponse(response, true))
      .then((response) => ({
        data:
          type === EXEC_CODING_TEMPLATE_LOAD
            ? response.json.template
            : response.json,
      }))
      .catch((error) => ({
        data: error,
      }));
  }

  return requestHandler(type, resource, params);
};

export default addCodingFeature;
