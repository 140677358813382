import {
  DateField,
  Pagination,
  ReferenceManyField,
  TextField,
} from "react-admin";

import EventField from "./EventField";
import EventLogCheckedField from "./EventLogCheckedField";
import React from "react";
import ResponsiveGrid from "../../components/list/ResponsiveGrid";

const EventLogsField = (props) => (
  <ReferenceManyField
    {...props}
    addLabel={false}
    pagination={<Pagination />}
    reference="sentinel-event-log"
    sort={{ field: "id", order: "DESC" }}
    target="sentinel_id"
    perPage={10}
  >
    <ResponsiveGrid rowBreakpoint="md" type="table">
      <TextField source="id" />
      <EventField source="event_id" />
      <DateField source="created" showTime />
      <EventLogCheckedField source="checked" />
    </ResponsiveGrid>
  </ReferenceManyField>
);

export default EventLogsField;
