import Actions from "./Actions";
import Card from "./Card";
import { DATE_RANGE_EXPR } from "../components/inputs/DateRangeInput";
import Filters from "./Filters";
import React from "react";
import ResponsiveGrid from "../components/list/ResponsiveGrid";
import ResponsiveList from "../components/list/ResponsiveList";
import { TextField } from "react-admin";

const today = DATE_RANGE_EXPR["today"]();
const List = (props) => (
  <ResponsiveList
    {...props}
    perPage={25}
    type="table"
    split
    actions={<Actions />}
    filters={<Filters />}
    filterDefaultValues={{ ...today, view: "today" }}
    sort={{ field: "created", order: "DESC" }}
  >
    <ResponsiveGrid rowBreakpoint="sm" cardContent={<Card />}>
      <TextField source="created" />
      <TextField source="bag_type" />
      <TextField source="code" />
      <TextField source="validation_code" />
      <TextField source="package_code" />
      <TextField source="operation" />
      <TextField source="start" />
      <TextField source="end" />
      <TextField source="work_time" />
      <TextField source="operator_code" />
    </ResponsiveGrid>
  </ResponsiveList>
);

export default List;
