import React, { useCallback } from "react";

import ArrowLeft from "@material-ui/icons/ChevronLeft";
import ArrowRight from "@material-ui/icons/ChevronRight";
import FloatingButton from "./FloatingButton";
import { connect } from "react-redux";
import { toggleInnerSidebar } from "../ui/actions";

const InnerSidebarToggleButton = ({
  dispatch,
  mobileBreakpoint,
  innerSidebarOpen,
  sidebarOpen,
  isMobile,
  label,
  icon,
  ...props
}) => {
  const toggleSidebar = useCallback(
    () => dispatch(toggleInnerSidebar({ mobileBreakpoint })),
    [dispatch, mobileBreakpoint]
  );
  return (
    <FloatingButton
      {...props}
      onClick={toggleSidebar}
      icon={
        (icon && typeof icon === "function"
          ? icon({ isMobile, sidebarOpen: innerSidebarOpen })
          : icon) || (innerSidebarOpen ? <ArrowLeft /> : <ArrowRight />)
      }
      label={
        typeof label === "function"
          ? label({ isMobile, sidebarOpen: innerSidebarOpen })
          : label
      }
    />
  );
};

export default connect((state) => ({}))(InnerSidebarToggleButton);
