import { ReferenceArrayField, SingleFieldList } from "react-admin";

import ChipField from "../../components/fields/ChipField";
import React from "react";
import { get } from "lodash";

const GroupsField = (props) => (
  <ReferenceArrayField
    {...props}
    reference="group"
    source="groups"
    record={{
      ...get(props, "record"),
      groups: get(props, "record.groups", []).map((g) => get(g, "group_id")),
    }}
  >
    <SingleFieldList>
      <ChipField source="code" />
    </SingleFieldList>
  </ReferenceArrayField>
);
export default GroupsField;
