import * as Icons from "@material-ui/icons";

import React, { useCallback, useEffect, useMemo, useState } from "react";

import { API_URL } from "../../../config";
import ChartList from "../../../measurements/ChartList";
import EventList from "./EventList";
import { GET_LAST_VALUES } from "../../../addSensorFeature";
import Loader from "../../../components/Loader";
import SidebarLayout from "../SidebarLayout";
import { compose } from "recompose";
import { get } from "lodash";
import { getToken } from "../../../authBody";
import { withDataProvider } from "react-admin";

const Sensor = ({ dataProvider, device, sensor, events, onClose }) => {
  const [data, setData] = useState({
    loading: false,
    events,
    measurements: [],
  });

  useEffect(() => {
    async function fetchData() {
      const {
        data: { data },
      } = await dataProvider(GET_LAST_VALUES, "sensor", {
        sensor_id: sensor.id,
        limit: 20,
      });
      setData({ ...data, loading: false });
    }
    setData((s) => ({ ...s, loading: true }));
    fetchData();
  }, [sensor.id, dataProvider]);
  useEffect(() => {
    if (!data.measurements.find((m) => m.created === sensor.latest)) {
      setData((s) => ({
        ...s,
        measurements: data.measurements.concat([
          {
            sensor,
            device,
            sensor_type: sensor.sensor_type,
            created: sensor.latest,
            value: sensor.value,
          },
        ]),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device, sensor]);

  const handleClose = useCallback(() => onClose({ sensorId: null }), [onClose]);
  const media = useMemo(() => {
    const filepath = get(sensor, "filepath", null);
    if (filepath === null) {
      return filepath;
    }
    return `${API_URL}/file-system/${getToken()}/download/${filepath}`;
  }, [sensor]);

  const indicators = useMemo(() => {
    const meta = sensor.meta;
    if (!meta) {
      return null;
    }
    const monitors = meta.filter((m) => m.group.indexOf("_monitor") > 0);
    return monitors.map((m) => ({
      label: null,
      value: m.value,
    }));
  }, [sensor]);
  const domain = useMemo(() => {
    if (indicators.length > 0) {
      const values = indicators.map((i) => parseFloat(i.value));
      const min = Math.min(...values);
      const max = Math.max(...values);
      return [min - 10, max + 10];
    } else if (data.measurements.length > 0) {
      const values = data.measurements.map((i) => parseFloat(i.value));
      const min = Math.floor(Math.min(...values));
      const max = Math.floor(Math.max(...values));
      return [min - 10, max + 10];
    }
  }, [indicators, data.measurements]);

  return (
    <SidebarLayout
      media={media}
      title={sensor.name}
      subTitle={sensor.code}
      onClose={handleClose}
      CloseIcon={device !== null ? Icons.ArrowBack : Icons.Close}
    >
      {data.loading && <Loader loading />}
      {data.loading === false && (
        <ChartList
          aspect={2}
          indicators={indicators}
          sampling={"Minutely"}
          measurements={data.measurements}
          cardStyle={{ boxShadow: "none" }}
          showLegend={false}
          showTitle={false}
          domain={domain}
        />
      )}
      {data.loading === false && <EventList events={data.events} sensor />}
    </SidebarLayout>
  );
};

export default compose(withDataProvider)(Sensor);
