import { CheckboxGroupInput, ReferenceArrayInput } from "react-admin";
import React, { Fragment } from "react";
import { Typography, withStyles } from "@material-ui/core";

import { grey } from "@material-ui/core/colors";

// record -> input
const format = (valueConverters) => {
  let formattedData = valueConverters
    ? valueConverters.map((g) => g.value_converter_id.toString())
    : [];
  return formattedData;
};

// input -> record
const parse = (valueConverters) => {
  let parsedData = valueConverters
    ? valueConverters.map((id) => ({ value_converter_id: id.toString() }))
    : [];
  return parsedData;
};

const optionRender = (choice) => (
  <Fragment>
    <Typography variant="body2">{choice.name}</Typography>
    <Typography variant="caption" component="p" align="justify">
      {choice.description}
    </Typography>
  </Fragment>
);

const Checkboxes = ({ ...props }) => (
  <CheckboxGroupInput {...props} optionText={optionRender} />
);

const ValueConvertersInput = withStyles((theme) => ({
  root: {
    width: "100%",
    "& legend": {
      fontSize: 20,
      marginBottom: theme.spacing.unit * 0.5,
    },
    "& div": {
      borderTopColor: grey[300],
      borderTopStyle: "solid",
      borderTopWidth: 1,
      paddingTop: theme.spacing.unit,
    },
    "& label[for]": {
      width: 250,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& label[for] > span:first-child": {
      display: "flex",
      alignSelf: "flex-start",
    },
    "& label[for] > span:nth-child(2)": {
      marginTop: theme.spacing.unit * 0.5,
      alignSelf: "normal",
    },
  },
}))(({ classes, source, ...props }) => (
  <ReferenceArrayInput
    {...props}
    format={format}
    parse={parse}
    source={source}
    reference="value-converter"
    className={classes.root}
    sort={{ field: "name", order: "ASC" }}
  >
    <Checkboxes />
  </ReferenceArrayInput>
));

export default ValueConvertersInput;
