import React, { Fragment } from "react";

import { Typography } from "@material-ui/core";
import { get } from "lodash";
import moment from "moment";

const DateRangeField = ({
  addLabel,
  source,
  record,
  basePath,
  resource,
  ...props
}) => {
  const value = get(record, source);
  const date = moment(value);
  return (
    <Fragment>
      <Typography variant="body1" {...props}>
        {date.format("DD/MM/YYYY")}
        <br />
        <Typography variant="caption">
          {date.format("HH:mm")} -{date.clone().add(1, "hour").format("HH")}:00
        </Typography>
      </Typography>
    </Fragment>
  );
};

export default DateRangeField;
