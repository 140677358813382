import { Grid, withWidth } from "@material-ui/core";

import HeaderConfig from "./HeaderConfig";
import HeaderPic from "./HeaderPic";
import HeaderTitle from "./HeaderTitle";
import PropTypes from "prop-types";
import React from "react";
import { isWidthUp } from "@material-ui/core/withWidth";

const Header = ({ width, status, record }) => {
  const isSmall = isWidthUp(width, "sm");
  return (
    <Grid container justify="space-between" direction="row">
      {isSmall && (
        <Grid item xs={5} sm={5}>
          <HeaderPic record={record} />
        </Grid>
      )}
      {!isSmall && <HeaderPic record={record} />}
      <Grid item lg={6} sm={7} xs={7}>
        <HeaderTitle record={record} status={status} />
      </Grid>
      <Grid item lg={4} md={5}>
        <HeaderConfig record={record} status={status} />
      </Grid>
    </Grid>
  );
};
Header.propTypes = {
  record: PropTypes.any,
  status: PropTypes.oneOf(["ongoing", "closed"]),
};
export default withWidth()(Header);
