import { API_URL } from "./config";
import { fetchUtils } from "react-admin";
import { getBody } from "./authBody";

export const GET_PATH = "GET_LOCATION_PATH";
export const GET_BUILDINGS = "GET_BUILDINGS";
export const GET_FLOOR = "GET_FLOOR";

/**
 * Implements Location API.
 * @param {*} requestHandler
 */
const addLocationFeature = (requestHandler) => (type, resource, params) => {
  const { fetchJson } = fetchUtils;

  if (resource === "location" && type === GET_FLOOR) {
    const url = `${API_URL}/location/floor`;
    return fetchJson(url, {
      method: "POST",
      body: getBody(params),
    }).then((response) => ({
      data: response.json,
    }));
  }
  if (resource === "location" && type === GET_PATH) {
    const url = `${API_URL}/location/${params.id}/path`;
    return fetchJson(url, {
      method: "POST",
      body: getBody(),
    }).then((response) => ({
      data: response.json,
    }));
  }
  if (resource === "location" && type === GET_BUILDINGS) {
    const url = `${API_URL}/location/buildings`;
    return fetchJson(url, {
      method: "POST",
      body: getBody(params),
    }).then((response) => ({
      data: response.json,
    }));
  }

  return requestHandler(type, resource, params);
};

export default addLocationFeature;
