import { Button, Drawer, withWidth } from "@material-ui/core";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PropTypes from "prop-types";
import React from "react";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import classNames from "classnames";
import { compose } from "recompose";
import { connect } from "react-redux";
import { setSidebarVisibility } from "../actions/app";
import { translate } from "ra-core";
import { withStyles } from "@material-ui/core/styles";

export const LEFT_SIDEBAR_WIDTH = 240;
export const CLOSED_LEFT_SIDEBAR_WIDTH = 55;

const styles = (theme) => ({
  root: {
    "& div:nth-child(2)": {
      minWidth: LEFT_SIDEBAR_WIDTH,
    },
  },
  linkRoot: {
    padding: theme.spacing.unit * 2,
    justifyContent: "flex-start",
  },

  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
});

const Sidebar = ({
  dispatch,
  width,
  open,
  classes,
  children,
  closedSize,
  size,
  translate,
  classes: classesOverride,
  routes,
  ...props
}) => {
  const isDesktop = width === "lg" || width === "md";
  const toggleSidebar = () => dispatch(setSidebarVisibility(!open));

  return !isDesktop ? (
    <Drawer
      {...props}
      variant="temporary"
      open={open}
      anchor="left"
      onClose={toggleSidebar}
      className={classes.root}
    >
      {routes
        .filter((route) => route.sidebar !== false)
        .map((route) => (
          <Button key={route.path} href={route.path} color="inherit">
            <ChevronRightIcon
              className={classNames(classes.leftIcon, classes.iconSmall)}
            />
            {translate(route.name)}
          </Button>
        ))}

      <Button href="/dashboard/" color="inherit" className={classes.linkRoot}>
        <VpnKeyIcon
          className={classNames(classes.leftIcon, classes.iconSmall)}
        />
        {translate("ra.auth.login")}
      </Button>
    </Drawer>
  ) : null;
};

Sidebar.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default compose(
  translate,
  withWidth(),
  withStyles(styles),
  connect(
    (state) => ({
      open: state.app.sidebarOpen,
    }),
    null
  )
)(Sidebar);
