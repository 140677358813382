import { Filter, SearchInput } from "react-admin";

import DateRangeInput from "../components/inputs/DateRangeInput";
import { DateTimeInput } from "../components/inputs/DateTimeInput";
import GroupsInput from "./inputs/GroupsInput";
import React from "react";
import TreeFilter from "../components/tree/TreeFilter";
import { compose } from "recompose";
import withFiltersStyles from "../components/list/withFiltersStyles";

const Filters = ({ classes, ...props }) => (
  <div className={classes.root}>
    <Filter {...props}>
      <SearchInput source="subkeyword" alwaysOn />
      <DateRangeInput source="view" alwaysOn />
      <DateTimeInput source={`start_at`} alwaysOn />
      <DateTimeInput source={`end_at`} alwaysOn />
      <GroupsInput source="groups" />
    </Filter>
    <TreeFilter visible />
  </div>
);
export default compose(withFiltersStyles)(Filters);
