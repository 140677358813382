export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR";
export const TOGGLE_INNER_SIDEBAR = "TOGGLE_INNER_SIDEBAR";
export const TOGGLE_MAIN_PADDING = "TOGGLE_MAIN_PADDING";

export const toggleRightSidebar = (payload) => ({
  type: TOGGLE_RIGHT_SIDEBAR,
  payload,
});
export const toggleInnerSidebar = ({ mobileBreakpoint = "xs", visible }) => ({
  type: TOGGLE_INNER_SIDEBAR,
  payload: { mobileBreakpoint, visible },
});

export const toggleMainPadding = ({ visible }) => ({
  type: TOGGLE_MAIN_PADDING,
  payload: { visible },
});
