import React, { Fragment } from "react";

import Form from "./Form";
import Location from "./Location";
import { Edit as RaEdit } from "react-admin";
import useSearchParams from "./useSearchParams";

const Edit = (props) => {
  const searchParams = useSearchParams({ ...props });
  return (
    <Fragment>
      <Location divider={false} id={searchParams.get("parent_id")} />
      <RaEdit {...props} undoable={false}>
        <Form location={props.location} edit />
      </RaEdit>
    </Fragment>
  );
};
export default Edit;
