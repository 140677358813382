import { Typography, withStyles } from "@material-ui/core";

import React from "react";
import compose from "recompose/compose";
import moment from "moment";

const styles = (theme) => ({
  root: {
    minWidth: 70,
  },
});

const DurationField = ({ classes, record, basePath, resource, ...props }) => {
  const to = record.closed
    ? moment(record.closed, "YYYY-MM-DD HH:mm:ss")
    : moment();
  const from = moment(record.start_at, "YYYY-MM-DD HH:mm:ss");
  const duration = moment.duration(from.diff(to));
  return (
    <Typography {...props} className={classes.root}>
      {duration.humanize()}
    </Typography>
  );
};
export default compose(withStyles(styles))(DurationField);
