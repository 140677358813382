import { ArrayField, TextField } from "react-admin";

import MetaCard from "./MetaCard";
import React from "react";
import ResponsiveGrid from "../../components/list/ResponsiveGrid";

const MetaListField = ({ fullWidth, ...props }) => (
  <ArrayField {...props} addLabel={false} source="meta">
    <ResponsiveGrid type="table" rowBreakpoint="sm" cardContent={<MetaCard />}>
      <TextField source="group" />
      <TextField source="key" />
      <TextField source="value" />
    </ResponsiveGrid>
  </ArrayField>
);

export default MetaListField;
