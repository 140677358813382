import { generateFlat } from "./color";
import moment from "moment";
import tickFormat from "./tickFormat";

const colors = {};

export default ({ type, ticks, start, end, sampling, measurements }) => {
  const series = measurements
    .filter((m) => m.sensor_type.name === type.name)
    .reduce((series, measurement) => {
      let serie = series.find((s) => s.sensor_id === measurement.sensor_id);
      if (!serie) {
        if (!colors[measurement.sensor_id]) {
          colors[measurement.sensor_id] = generateFlat();
        }
        serie = {
          sensor_id: measurement.sensor_id,
          name: `${measurement.device.name} - ${measurement.sensor.name}`,
          data: [],
          color: colors[measurement.sensor_id],
        };

        series.push(serie);
      }
      serie.data.push({
        ...measurement,
        created: measurement.created,
        value: !isNaN(measurement.value) ? parseFloat(measurement.value) : null,
      });
      return series;
    }, [])
    .map((serie) => ({
      ...serie,
      data: serie.data
        .concat(
          ticks
            .filter((t) => !serie.data.find((sd) => sd.created === t))
            .map((t) => ({ created: t, value: null }))
        )
        .sort(
          (a, b) =>
            moment(a.created, "YYYY-MM-DD HH:mm:ss").toDate() -
            moment(b.created, "YYYY-MM-DD HH:mm:ss").toDate()
        )
        .map((m) => ({
          ...m,
          name: m.device ? `${m.device.name} - ${m.sensor.name}` : null,
          time: moment(m.created, "YYYY-MM-DD HH:mm:ss").format(
            tickFormat(start, end, sampling)
          ),
        })),
    }));
  return series;
};
