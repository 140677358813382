import InnerSidebar from "../InnerSidebar";
import InnerSidebarToggleButton from "../InnerSidebarToggleButton";
import { LocationIcon } from "../../icons";
import React from "react";
import Tree from "./";

const TreeFilter = ({ visible = false }) => (
  <InnerSidebar
    visible={visible}
    toggler={
      <InnerSidebarToggleButton
        icon={({ isMobile }) => (isMobile ? <LocationIcon /> : undefined)}
        label={({ sidebarOpen, isMobile }) =>
          !sidebarOpen && !isMobile && "ra.action.location_filter"
        }
      />
    }
  >
    <Tree open />
  </InnerSidebar>
);
export default TreeFilter;
