import {
  FormTab,
  Create as RaCreate,
  TabbedForm,
  TextInput,
  maxLength,
  required,
} from "react-admin";
import { LocationIcon, SensorIcon } from "../icons";

import DeviceTypeInput from "./input/DeviceTypeInput";
import GroupsInput from "../components/inputs/GroupsInput";
import LocationEditorInput from "./input/LocationEditorInput";
import LocationInput from "./input/LocationInput";
import PictureInput from "./input/PictureInput";
import React from "react";
import SensorsInput from "./input/SensorsInput";
import SettingsIcon from "@material-ui/icons/Settings";
import Toolbar from "./Toolbar";
import ValueConvertersInput from "../components/inputs/ValueConvertersInput";
import { compose } from "recompose";
import withFormStyles from "../components/forms/withFormStyles";

const Create = ({ classes, ...props }) => (
  <RaCreate {...props} className={classes.root}>
    <TabbedForm redirect="list" toolbar={<Toolbar />}>
      <FormTab icon={<SettingsIcon />} label="resources.device.tabs.config">
        <TextInput
          source="access_token"
          fullWidth
          validate={[required(), maxLength(32)]}
        />

        <TextInput source="code" validate={[required(), maxLength(32)]} />
        <TextInput source="name" validate={[required(), maxLength(32)]} />
        <DeviceTypeInput source="device_type_id" validate={required()} />
        <TextInput
          source="mac_address"
          validate={[required(), maxLength(32)]}
        />
        <GroupsInput source="groups" resource="device" fullWidth />
        <ValueConvertersInput
          source="value_converters"
          resource="device"
          fullWidth
        />

        <PictureInput source="image" fullWidth />
      </FormTab>
      <FormTab icon={<SensorIcon />} label="resources.device.tabs.sensors">
        <SensorsInput source="sensors" validate={required()} fullWidth />
      </FormTab>
      <FormTab icon={<LocationIcon />} label="resources.device.tabs.location">
        <LocationInput source="location_id" validate={required()} />
        <LocationEditorInput source="position" />
      </FormTab>
    </TabbedForm>
  </RaCreate>
);
export default compose(withFormStyles)(Create);
