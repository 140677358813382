import { API_URL } from "./config";
import { fetchUtils } from "react-admin";
import { getBody } from "./authBody";

export const GET_PATH = "@@things/GET_PATH";
export const GET_LAYERS = "@@things/GET_LAYERS";

const addThingFeature = (requestHandler) => (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  if (resource === "thing" && type === GET_PATH) {
    const url = `${API_URL}/thing/path?id=${params.id}`;
    return fetchJson(url, {
      method: "POST",
      body: getBody(),
    }).then((response) => ({
      data: response.json,
    }));
  } else if (resource === "thing" && type === GET_LAYERS) {
    const url = `${API_URL}/thing/layers?id=${params.id}`;
    return fetchJson(url, {
      method: "POST",
      body: getBody(),
    }).then((response) => ({
      data: response.json,
    }));
  }

  return requestHandler(type, resource, params);
};

export default addThingFeature;
