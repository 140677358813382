import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";

import CookiePolicy from "../components/CookiePolicy";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import HeroPage from "../components/HeroPage";
import React from "react";
import RouterIcon from "@material-ui/icons/Router";
import VideogameAssetIcon from "@material-ui/icons/VideogameAsset";
import background from "../../assets/background.jpg";
import classNames from "classnames";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  heroUnit: {
    marginBottom: -(theme.spacing.unit * 10),
    backgroundColor: theme.palette.background.paper,
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: 450,
    [theme.breakpoints.down("sm")]: {
      height: 500,
    },
  },
  heroContent: {
    maxWidth: 800,
    margin: "0 auto",
    padding: `${theme.spacing.unit * 14}px 0 ${theme.spacing.unit * 6}px`,
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4,
    [theme.breakpoints.down("sm")]: {
      marginTop: -10,
    },
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`,
    marginTop: -150,
    [theme.breakpoints.down("sm")]: {
      marginTop: -100,
    },
  },
  cardIcon: {
    fontSize: 50,
    alignSelf: "center",
    [theme.breakpoints.up("md")]: {
      fontSize: 100,
    },
  },
  cardTitle: {
    marginBottom: theme.spacing.unit * 2,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 6,
  },
});
const cards = [
  {
    icon: RouterIcon,
    title: "Cloud",
    content: (
      <Typography variant="body1" justify="center" align="center">
        Nel panorama IoT la piattaforma SensIC si propone come valido strumento
        per la raccolta e l'elaborazione dei dati rilevati da differenti
        tipologie di dispositivi.
      </Typography>
    ),
  },
  {
    icon: DataUsageIcon,
    title: "Big Data",
    content: (
      <Typography variant="body1" justify="center" align="center">
        Utilizzando strumenti avanzati di filtering, conversion e monitoring è
        possibile sfruttare le potenzialità della piattaforma per analizzare e
        ottenere informazioni rilevanti rispetto ai dati raccolti e
        visualizzarli su mappe digitali (2d georeferenziate).
      </Typography>
    ),
  },
  {
    icon: VideogameAssetIcon,
    title: "Sensbox",
    content: (
      <Typography variant="body1" justify="center" align="center">
        &Egrave; stato progettato e realizzato un dispositivo intelligente,
        denominato Sensbox, in grado di monitorare diversi parametri ambientali
        e offrire funzionalità avanzate di monitoraggio indoor e allarmi.
      </Typography>
    ),
  },
];
const Home = ({ classes }) => {
  return (
    <HeroPage
      message={
        <>
          Monitoraggio Real-Time, rilevamento e analisi di parametri ambientali
          come temperatura, monossido di carbonio o movimento PIR, prossimità e
          notifiche intelligenti, tutto all'interno di un unica piattaforma!
        </>
      }
      buttons={[
        {
          label: "Contattaci",
          href: "https://www.its.engineering",
        },
        {
          variant: "outlined",
          label: "Scopri di più",
          href: "#details",
        },
      ]}
    >
      <>
        <div
          className={classNames(classes.layout, classes.cardGrid)}
          id="details"
        >
          <Grid container justify="center" spacing={40}>
            {cards.map((card, i) => (
              <Grid item key={i} xs={12} sm={12} md={12} lg={4}>
                <Card>
                  <CardContent>
                    <Typography
                      variant="headline"
                      align="center"
                      component="div"
                      className={classes.cardTitle}
                    >
                      {card.icon && <card.icon className={classes.cardIcon} />}
                      {card.icon && <br />}
                      <Typography variant="subheading">{card.title}</Typography>
                    </Typography>

                    {card.content}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
        <footer className={classes.footer}>
          <Typography variant="title" align="center" gutterBottom>
            Vuoi ricevere maggiori informazioni su questo prodotto?
          </Typography>
          <Typography
            variant="subheading"
            align="center"
            color="textSecondary"
            component="p"
          >
            <Button href="https://www.its.engineering/" color="primary">
              Contattaci
            </Button>
          </Typography>
        </footer>
        <CookiePolicy />
      </>
    </HeroPage>
  );
};
export default compose(withStyles(styles))(Home);
