import Form from "../value-filters/Form";
import { Create as RaCreate } from "react-admin";
import React from "react";

const Create = ({ ...props }) => (
  <RaCreate {...props}>
    <Form />
  </RaCreate>
);

export default Create;
