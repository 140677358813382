import { createStyles, withStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";
import React from "react";
import Toolbar from "@material-ui/core/Toolbar";

const styles = createStyles({
  toolbar: {
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
});

const ListToolbar = ({
  classes,
  filters,
  filterValues, // dynamically set via the UI by the user
  permanentFilter, // set in the List component by the developer
  actions,
  bulkActions,
  exporter,
  // hasList,
  // hasEdit,
  // hasCreate,
  ...rest
}) => (
  <Toolbar className={classes.toolbar}>
    {filters &&
      React.cloneElement(filters, {
        ...rest,
        filterValues,
        context: "form",
      })}
    <span />
    <div style={{ marginTop: 20 }}>
      {actions &&
        React.cloneElement(actions, {
          ...rest,
          className: classes.actions,
          bulkActions,
          exporter,
          filters,
          filterValues,
          permanentFilter,
          ...actions.props,
        })}
    </div>
  </Toolbar>
);

ListToolbar.propTypes = {
  classes: PropTypes.object,
  filters: PropTypes.element,
  permanentFilter: PropTypes.object,
  actions: PropTypes.element,
  bulkActions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

ListToolbar.defaultProps = {
  classes: {},
};

export default withStyles(styles)(ListToolbar);
