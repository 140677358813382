// in addUploadFeature.js
/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const addUploadFeature = (requestHandler) => (type, resource, params) => {
  if ((type === "CREATE" || type === "UPDATE") && resource === "location") {
    let pictures = [params.data.png_file, params.data.tif_file];
    pictures = pictures.filter((p) => p && p.rawFile instanceof File);
    if (pictures && pictures.length > 0) {
      return Promise.all(pictures.map(convertFileToBase64))
        .then((base64Pictures) =>
          base64Pictures.map((picture64, index) => ({
            data: picture64,
            name: pictures[index].rawFile.name,
            size: pictures[index].rawFile.size,
            type: pictures[index].rawFile.type,
          }))
        )
        .then((transformedPictures) =>
          requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              png_file: transformedPictures.find(
                (t) => t.name.indexOf("png") !== -1
              ),
            },
          })
        );
    }
  }

  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};

export default addUploadFeature;
