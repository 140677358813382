import {
  AutocompleteArrayInput,
  ReferenceArrayInput,
  translate,
} from "react-admin";
import { FormHelperText, withStyles } from "@material-ui/core";
import React, { Fragment } from "react";

const format = (devices) =>
  devices ? devices.map((d) => d.device_id.toString()) : [];
const parse = (devices) =>
  devices ? devices.map((id) => ({ device_id: id.toString() })) : [];

const DevicesInput = withStyles((theme) => ({
  helperText: {
    lineHeight: 1.35,
  },
}))(({ translate, classes, ...props }) => (
  <Fragment>
    <ReferenceArrayInput
      {...props}
      perPage={10}
      reference="device"
      sort={{ field: "name", order: "ASC" }}
      format={format}
      parse={parse}
    >
      <AutocompleteArrayInput optionText="name" fullWidth />
    </ReferenceArrayInput>
    <FormHelperText className={classes.helperText}>
      {translate("resources.sentinel.fields.devices.help")}
    </FormHelperText>
  </Fragment>
));
export default translate(DevicesInput);
