import {
  AppBar,
  Error,
  Menu,
  Notification,
  Sidebar,
  defaultTheme,
} from "react-admin";
import { INNER_SIDEBAR_WIDTH, RIGHT_SIDEBAR_WIDTH } from "../config";
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import React, { Component, createElement } from "react";

import Connector from "../websocket/Connector";
import PropTypes from "prop-types";
import classnames from "classnames";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { isWidthDown } from "@material-ui/core/withWidth";
import { withRouter } from "react-router";
import { withWidth } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
  },
  content: {
    marginTop: 64,
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,

    overflowY: "auto",

    [theme.breakpoints.down("sm")]: {
      marginTop: 56,
      padding: theme.spacing.unit,
    },
    transition: theme.transitions.create(["width", "margin", "padding"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  mainPadding: {
    padding: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 4,
  },
  contentWithInnerSidebar: {
    marginLeft: INNER_SIDEBAR_WIDTH,
    borderLeft: 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  contentWithRightSidebar: {
    marginLeft: INNER_SIDEBAR_WIDTH + RIGHT_SIDEBAR_WIDTH,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
});

const sanitizeRestProps = ({
  staticContext,
  history,
  location,
  match,
  ...props
}) => props;

class Layout extends Component {
  state = { hasError: false, errorMessage: null, errorInfo: null };

  constructor(props) {
    super(props);
    /**
     * Reset the error state upon navigation
     *
     * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
     */
    props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentDidCatch(errorMessage, errorInfo) {
    this.setState({ hasError: true, errorMessage, errorInfo });
  }

  render() {
    const {
      appBar,
      children,
      classes,
      className,
      customRoutes,
      error,
      dashboard,
      logout,
      menu,
      notification,
      open,
      sidebar,
      title,
      rightSidebarOpen,
      innerSidebarOpen,
      innerSidebarBreakpoint,
      width,
      state,
      mainPadding,
      ...props
    } = this.props;
    const { hasError, errorMessage, errorInfo } = this.state;
    const isMobile = isWidthDown(innerSidebarBreakpoint, width);

    return (
      <div className={classes.root} {...sanitizeRestProps(props)}>
        {createElement(sidebar, {
          isMobile,
          children: createElement(menu, {
            logout,
            hasDashboard: !!dashboard,
          }),
        })}
        {createElement(appBar, { title, open, logout })}
        <main
          className={classnames(classes.content, {
            [classes.contentWithInnerSidebar]: innerSidebarOpen && !isMobile,
            [classes.contentWithRightSidebar]: rightSidebarOpen,
            [classes.mainPadding]: mainPadding,
          })}
        >
          <div className={classes.toolbar} />
          {hasError
            ? createElement(error, {
                error: errorMessage,
                errorInfo,
                title,
              })
            : children}
        </main>
        {createElement(notification)}
        <Connector />
      </div>
    );
  }
}

const componentPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.string,
]);

Layout.propTypes = {
  appBar: componentPropType,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  classes: PropTypes.object,
  className: PropTypes.string,
  customRoutes: PropTypes.array,
  dashboard: componentPropType,
  error: componentPropType,
  history: PropTypes.object.isRequired,
  logout: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.string,
  ]),
  menu: componentPropType,
  notification: componentPropType,
  open: PropTypes.bool,
  sidebar: componentPropType,
  title: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  appBar: AppBar,
  error: Error,
  menu: Menu,
  notification: Notification,
  sidebar: Sidebar,
};

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
  mainPadding: state.ui.mainPadding,
  rightSidebarOpen: state.ui.rightSidebarOpen,
  innerSidebarOpen: state.ui.innerSidebarOpen,
  innerSidebarBreakpoint: state.ui.innerSidebarBreakpoint,
  state,
});

const EnhancedLayout = compose(
  connect(
    mapStateToProps,
    {} // Avoid connect passing dispatch in props
  ),
  withRouter,
  withWidth({ resizeInterval: Infinity }),
  withStyles(styles)
)(Layout);

class LayoutContent extends Component {
  constructor(props) {
    super(props);
    this.theme = createMuiTheme(props.theme);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.theme !== this.props.theme) {
      this.theme = createMuiTheme(nextProps.theme);
    }
  }
  render() {
    const { theme, ...rest } = this.props;
    return (
      <MuiThemeProvider theme={this.theme}>
        <EnhancedLayout {...rest} />
      </MuiThemeProvider>
    );
  }
}

LayoutContent.propTypes = {
  theme: PropTypes.object,
};

LayoutContent.defaultProps = {
  theme: defaultTheme,
};

export default LayoutContent;
