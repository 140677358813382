import { FormDataConsumer, ReferenceInput } from "react-admin";

import AutocompleteInput from "../../components/inputs/AutocompleteInput";
import React from "react";
import { get } from "lodash";

const DeviceInput = (props) => (
  <FormDataConsumer {...props}>
    {({ formData }) => (
      <ReferenceInput
        {...props}
        reference="device"
        perPage={20}
        filter={{ locations: get(formData, "location_id", null) }}
        options={{ disabled: get(formData, "location_id", null) === null }}
        allowEmpty
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    )}
  </FormDataConsumer>
);

export default DeviceInput;
