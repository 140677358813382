import { Typography, createStyles, withStyles } from "@material-ui/core";

import React from "react";
import { compose } from "recompose";
import { resolveBrowserLocale } from "react-admin";
import { translate } from "ra-core";

const styles = (theme) =>
  createStyles({
    root: {
      padding: theme.spacing.unit * 3,

      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing.unit * 2,
      },
    },
  });

const resolveDefaultLocale = () => {
  const choosenLocal = localStorage.getItem("locale");
  if (choosenLocal) {
    return choosenLocal;
  }
  return resolveBrowserLocale();
};

const RecordsCount = ({
  translate,
  classes,
  style,
  total,
  resource,
  boldWords = [],
}) => {
  const locale = resolveDefaultLocale();
  const words = {
    total: (() => {
      let v = parseFloat(
        localStorage.getItem(`last_${resource}_count`)
      ).toLocaleString(locale);
      return isNaN(v) ? 0 : v;
    })(),
    results: total.toLocaleString(locale),
    resource: translate(`resources.${resource}.name`, total).toLowerCase(),
  };
  const text = translate("app.messages.records_found", words);
  const bold = [words.total, words.results].concat(boldWords);

  return (
    <Typography className={classes.root} style={style} variant="body1">
      {text
        .split(" ")
        .map((word) =>
          bold.indexOf(word) !== -1 ? (
            <b key={Math.random()}>{word} </b>
          ) : (
            `${word} `
          )
        )}
    </Typography>
  );
};

export default compose(withStyles(styles), translate)(RecordsCount);
