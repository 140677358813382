import React, { useMemo } from "react";
import { Typography, withStyles } from "@material-ui/core";

import { get } from "lodash";
import moment from "moment";
import { translate } from "react-admin";

const DurationField = withStyles((theme) => ({
  root: {
    minWidth: 120,
  },
  caption: {
    fontSize: 12,
  },
}))(
  ({
    classes,
    translate,
    record,
    source,
    basePath,
    resource,
    sortBy,
    ...props
  }) => {
    const { label, duration } = useMemo(() => {
      const dateFormatMedium = translate("app.date_format.medium");
      const dateFormatShort = translate("app.date_format.short");
      const startAt = get(record, "start_at");
      const startAtDate = moment(startAt);
      const endAt = get(record, "end_at");
      const endAtDate = endAt !== null ? moment(endAt) : null;

      let duration = "";
      let label = "";
      if (endAtDate !== null) {
        duration = moment.duration(endAtDate.diff(startAtDate));
        if (duration.asDays() > 1) {
          label = `${startAtDate.format(dateFormatShort)} - ${endAtDate.format(
            dateFormatShort
          )}`;
        } else {
          label = `${startAtDate.format(dateFormatMedium)} - ${endAtDate.format(
            "HH:mm"
          )}`;
        }
        duration = duration.humanize();
      } else {
        duration = translate(`resources.${resource}.duration.infinite`);
        label = translate(`resources.${resource}.duration.since`, {
          date: startAtDate.format(dateFormatMedium),
        });
      }
      return {
        label,
        duration,
      };
    }, [resource, record, translate]);

    return (
      <div className={classes.root}>
        <Typography variant="body2">{duration}</Typography>
        <Typography variant="caption" className={classes.caption}>
          {label}
        </Typography>
      </div>
    );
  }
);

export default translate(DurationField);
