import React, { Fragment } from "react";

import Breadcrumbs from "./Breadcrumbs";
import { Divider } from "@material-ui/core";
import { GET_PATH } from "../addLocationFeature";
import { Query } from "ra-core";

const Location = ({ id, divider = true }) => (
  <Fragment>
    {id === null && <Breadcrumbs loading={false} breadcrumbs={[]} />}
    {id !== null && (
      <Query resource="location" type={GET_PATH} payload={{ id }}>
        {({ data, loading }) => (
          <Breadcrumbs
            loading={loading}
            items={loading ? [] : data !== null ? data.segments : []}
          />
        )}
      </Query>
    )}
    {divider && <Divider />}
  </Fragment>
);

export default Location;
