import List from "./List";
import { MeasurementHourlyIcon } from "../icons";

export default {
  icon: MeasurementHourlyIcon,
  options: {
    group: "dashboard",
    mergeFilters: true,
    accessible: ({ user }) =>
user && 
      user.permissions.indexOf("measurement-hourly/list") !== -1,
  },
  list: List,
};
