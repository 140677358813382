import { Filter, SearchInput } from "react-admin";

import BooleanSelectInput from "../components/inputs/BooleanSelectInput";
import React from "react";

const Filters = (props) => (
  <Filter {...props}>
    <SearchInput source="keyword" alwaysOn />
    <BooleanSelectInput source="is_translated" defaultValue={0} />
  </Filter>
);
export default Filters;
