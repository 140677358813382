import AppBar from "./AppBar";
import LayoutContent from "./LayoutContent";
import Notification from "./Notification";
import React from "react";
import Sidebar from "./Sidebar";

const Layout = (props) => (
  <LayoutContent
    {...props}
    appBar={AppBar}
    sidebar={Sidebar}
    notification={Notification}
  />
);

export default Layout;
