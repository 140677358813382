import React from "react";
import { SelectInput } from "react-admin";

const MAP_VIEW_MODES = [
  { id: "none", name: "resources.sensor-type.map_view_mode.none" },
  { id: "marker", name: "resources.sensor-type.map_view_mode.marker" },
  { id: "tooltip", name: "resources.sensor-type.map_view_mode.tooltip" },
];
const MapViewModeInput = (props) => (
  <SelectInput {...props} choices={MAP_VIEW_MODES} />
);
export default MapViewModeInput;
