import { BooleanInput, NumberInput, withTranslate } from "react-admin";
import { Divider, withStyles } from "@material-ui/core";
import React, { Fragment } from "react";

import FileInput from "./FileInput";
import TifFileInput from "./TifFileInput";
import { compose } from "recompose";
import { connect } from "react-redux";
import useFieldLabel from "../../components/useFieldLabel";

const styles = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing.unit * 4,
  },
  input: {
    float: "left",
    width: 200,
    marginRight: theme.spacing.unit * 1,
  },
  divider: {
    marginBottom: theme.spacing.unit * 2,
  },
}));
const MapCoordsInput = ({
  classes,
  formData,
  dispatch,
  translate,
  state,
  ...props
}) => {
  const fieldLabel = useFieldLabel({ translate, ...props });
  const { record } = props;

  return (
    <Fragment>
      <div className={classes.root}>
        <BooleanInput
          source="configure"
          disabled={!record.id}
          label={translate(fieldLabel(props.source))}
        />
        <Divider className={classes.divider} />
        {formData.configure && (
          <Fragment>
            <NumberInput
              {...props}
              className={classes.input}
              source="map_min_y"
              label={translate("resources.location.fields.miny")}
            />
            <NumberInput
              {...props}
              className={classes.input}
              source="map_min_x"
              label={translate("resources.location.fields.minx")}
            />
            <NumberInput
              {...props}
              className={classes.input}
              source="map_max_y"
              label={translate("resources.location.fields.maxy")}
            />
            <NumberInput
              {...props}
              className={classes.input}
              source="map_max_x"
              label={translate("resources.location.fields.maxx")}
            />
            <TifFileInput
              {...props}
              source="tif_file"
              accept=".tif"
              formData={formData}
              dispatch={dispatch}
              fullWidth
            />
            <FileInput {...props} source="png_file" accept=".png" fullWidth />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};
export default compose(
  withTranslate,
  styles,
  connect((state) => ({ state }))
)(MapCoordsInput);
