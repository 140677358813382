import { CALLBACK_TYPE, Connection } from "../../websocket/Connection";
import { CLOSED, ONGOING } from "../colors";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React, { Component } from "react";
import { Typography, withStyles } from "@material-ui/core";
import { WithPermissions, withTranslate } from "ra-core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import ChartPointTooltip from "./ChartPointTooltip";
import EventHelper from "../../helpers/EventHelper";
import PortableWifiOffIcon from "@material-ui/icons/PortableWifiOff";
import TypographyField from "./TypographyField";
import classnames from "classnames";
import { compose } from "recompose";
import { get } from "lodash";
import moment from "moment";
import { red } from "@material-ui/core/colors";

const styles = (theme) => ({
  legend: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  line: {
    display: "inline-block",
    width: 50,
    height: 4,
    borderBottomStyle: "solid",
    borderBottomWidth: 4,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  lineInterval: {
    borderBottomColor: red[500],
  },
  lineMeasurement: {
    borderBottomColor: "black",
  },
  noData: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing.unit * 2,
  },
  noDataIcon: {
    fontSize: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: 300,
    },
  },
  ongoing: {
    color: ONGOING,
  },
  closed: {
    color: CLOSED,
  },
});

class Chart extends Component {
  constructor(props) {
    super(props);
    const { record, source } = props;
    this.state = {
      params: EventHelper.getChartParams(record),
      measurements: get(record, source),
    };
  }

  componentDidMount() {
    this.createChartData(this.props);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.record !== this.props.record) {
      this.createChartData(nextProps);
    }

    if (
      nextProps.permissions !== null &&
      this.props.permissions === null &&
      nextProps.record.closed === null
    ) {
      this.connection = new Connection(nextProps.permissions);
      this.connection.setCallback(
        CALLBACK_TYPE.MEASUREMENTS,
        this.handleMeasurement.bind(this)
      );
      this.connection.open();
    } else if (this.connection) {
      this.connection.close();
    }
  }

  componentWillUnmount() {
    if (this.connection && this.connection !== null) {
      this.connection.close();
    }
  }

  createChartData(props) {
    const { record, source } = props;
    const sampling = get(record, "sampling");
    const measurements = get(record, source, []);
    const daily = sampling === "Daily";

    this.setState({
      params: EventHelper.getChartParams(props.record),
      measurements: measurements.map((m) => ({
        ...m,
        value: parseFloat(m.value).toFixed(2),
        created: moment(get(m, "created"), "YYYY-MM-DD HH:mm:ss").format(
          daily ? "DD/MM/YYYY" : "HH:mm"
        ),
      })),
    });
  }

  handleMeasurement(payload) {
    const { record } = this.props;
    const { sensor, data } = payload;
    const { measurements } = this.state;
    if (sensor.id === record.sensor.id) {
      this.setState({
        measurements: measurements.concat([
          {
            created: moment().format("HH:mm:ss"),
            value: parseFloat(data.value).toFixed(2),
          },
        ]),
      });
    }
  }

  getChartDomain(indicators) {
    const values = indicators.map((i) => parseFloat(i.value));
    const min = Math.min(...values);
    const max = Math.max(...values);
    return [min - 10, max + 10];
  }

  render() {
    const { translate, width, status, record, classes } = this.props;
    const { measurements } = this.state;
    if (!measurements || measurements.length === 0) {
      return (
        <div className={classes.noData}>
          <PortableWifiOffIcon
            className={classnames(classes.noDataIcon, classes[status])}
          />

          <TypographyField source="notes" variant="body2" record={record} />
        </div>
      );
    }
    const isSmall = isWidthUp(width, "sm");
    const indicators = this.state.params;
    const domain =
      indicators.length > 1
        ? this.getChartDomain(this.state.params)
        : this.getChartDomain(measurements);

    return (
      <div>
        <TypographyField source="notes" variant="body1" record={record} />
        <br />
        <ResponsiveContainer width="100%" aspect={isSmall ? 1 : 3}>
          <LineChart margin={{ top: 32, left: 0, right: 32, bottom: 32 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="created"
              type="category"
              allowDuplicatedCategory={false}
            />
            <YAxis dataKey="value" domain={domain} />

            <Tooltip
              content={(payload) => (
                <ChartPointTooltip payload={payload} record={record} />
              )}
            />
            {indicators.map((p) => (
              <ReferenceLine
                key={p.id}
                y={p.value}
                stroke={"red"}
                label={p.label}
              />
            ))}
            <Line
              key={this.props.source}
              data={measurements}
              dataKey="value"
              connectNulls={true}
              type="monotone"
              stroke="black"
              activeDot={{ r: 8 }}
              fill="black"
            />
          </LineChart>
        </ResponsiveContainer>
        <Typography variant="body1" align="center" className={classes.legend}>
          <span className={classnames(classes.line, classes.lineInterval)}>
            &nbsp;
          </span>
          {translate("resources.event.fields.thresholds")}{" "}
          <span className={classnames(classes.line, classes.lineMeasurement)}>
            &nbsp;
          </span>
          {translate("resources.event.fields.measurements")}
        </Typography>
      </div>
    );
  }
}

const ChartField = (props) => (
  <WithPermissions
    render={({ permissions }) => <Chart permissions={permissions} {...props} />}
  />
);

export default compose(
  withStyles(styles),
  withWidth(),
  withTranslate
)(ChartField);
