import { WEBSOCKET_URL } from "../config";

export const CONNECTION_STATUS = {
  PENDING: "pending",
  OPENED: "opened",
  CLOSED: "closed",
};
export const CALLBACK_TYPE = {
  MEASUREMENTS: "PushMeasurement",
  EVENTS: "PushEvent",
  LOGS: "Logs",
  CAKE_LOG: "CakeLog",
};
export class Connection {
  constructor(permissions) {
    if (permissions === null) {
      throw new Error(
        "Unable to create socket connection withtout permissions object."
      );
    }
    this.permissions = permissions;
    this.status = CONNECTION_STATUS.PENDING;
    this.instance = null;
    this.callback = {};
    this.queue = [];
  }

  open() {
    this.log("Opening connection...");
    if (this.status === CONNECTION_STATUS.OPENED) {
      return;
    }
    this.instance = new WebSocket(WEBSOCKET_URL);
    this.instance.onopen = this.handleOpen.bind(this);
    this.instance.onmessage = this.handleMessage.bind(this);
  }

  enqueue(command) {
    if (this.status === CONNECTION_STATUS.OPENED) {
      command(this);
    } else {
      this.queue.push(command);
    }
  }
  dequeue() {
    if (this.status !== CONNECTION_STATUS.OPENED) {
      return;
    }
    this.queue.forEach((command) => command(this));
  }

  watch() {
    this.enqueue((webSocket) => {
      const message = webSocket.createMessage({
        event: "StartStreamingCakeLogs",
      });
      webSocket.instance.send(message);
    });
  }

  unwatch() {
    this.enqueue((webSocket) => {
      const message = webSocket.createMessage({
        event: "StopStreamingCakeLogs",
      });
      webSocket.instance.send(message);
    });
  }

  close() {
    if (this.instance !== null) {
      this.instance.close();
      this.instance = null;
      this.status = CONNECTION_STATUS.CLOSED;
    }
  }

  reconnect() {
    this.close();
    this.open();
  }
  isOpened() {
    return this.status === CONNECTION_STATUS.OPENED;
  }
  isClosed() {
    return this.status === CONNECTION_STATUS.CLOSED;
  }
  isPending() {
    return this.status === CONNECTION_STATUS.PENDING;
  }

  setCallback(callbackType, fn) {
    this.callback[callbackType] = fn;
  }

  handleOpen() {
    this.log("Connection opened, configuring client...");
    const groups =
      this.permissions && this.permissions.groups
        ? this.permissions.groups.map((g) => g.group_id)
        : [];
    const configurationMessage = this.createMessage({
      event: "ConfigureClient",
      payload: { groups },
    });
    this.log("Requesting authorizations...");
    this.status = CONNECTION_STATUS.OPENED;
    this.instance.send(configurationMessage);
    this.dequeue();
  }
  handleMessage(evt) {
    if (this.status !== CONNECTION_STATUS.OPENED) {
      return;
    }

    const data = JSON.parse(evt.data);
    const eventName = data.event || CALLBACK_TYPE.LOGS;
    if (this.callback[eventName]) {
      try {
        this.callback[eventName](data.payload || data.message || data);
      } catch (e) {
        console.info(e);
      }
    }
  }

  log(message) {
    if (this.callback[CALLBACK_TYPE.LOGS]) {
      this.callback[CALLBACK_TYPE.LOGS](message);
    }
  }

  createMessage(messageObject) {
    return JSON.stringify(messageObject);
  }
}
