import {
  Avatar,
  Divider,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import { CLOSED, ONGOING } from "../../events/colors";
import React, { Fragment } from "react";

import GoToEventPageLink from "./GoToEventPageLink";
import classnames from "classnames";
import compose from "recompose/compose";
import { withTranslate } from "react-admin";

const Title = withStyles((theme) => ({
  legendContainer: {
    display: "flex",
    alignItems: "center",
  },
  legendLabel: {
    display: "inline",
    marginLeft: theme.spacing.unit,
  },
  avatar: {
    height: 16,
    width: 16,
  },
  avatarOngoing: {
    backgroundColor: ONGOING,
  },
  avatarClosed: {
    backgroundColor: CLOSED,
  },
}))(({ classes, translate }) => (
  <Fragment>
    <Grid item lg={12} md={12} xs={12}>
      <Typography variant="title">
        {translate("dashboard.last_events")}
      </Typography>
      <Divider style={{ marginTop: 10 }} />
    </Grid>
    <Grid container item style={{ marginTop: 10 }}>
      <Grid item lg={2} md={3} xs={12} className={classes.legendContainer}>
        <Avatar className={classnames(classes.avatar, classes.avatarOngoing)} />
        <Typography className={classes.legendLabel}>
          {translate("website.event.ongoing")}
        </Typography>
      </Grid>
      <Grid item lg={7} md={6} xs={12} className={classes.legendContainer}>
        <Avatar className={classnames(classes.avatar, classes.avatarClosed)} />
        <Typography className={classes.legendLabel}>
          {translate("website.event.closed")}
        </Typography>
      </Grid>
      <GoToEventPageLink />
    </Grid>
  </Fragment>
));

export default compose(withTranslate)(Title);
