import { FormDataConsumer, REDUX_FORM_NAME, translate } from "react-admin";
import React, { useCallback, useMemo } from "react";
import { Typography, withStyles } from "@material-ui/core";

import Field from "../../thing-types/inputs";
import { change } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";
import { get } from "lodash";

const AttrInput = connect(null)(
  ({ translate, dispatch, currentData, attr, errors, source, ...props }) => {
    const error = useMemo(() => {
      const item = currentData.find(
        (thingAttr) => thingAttr.thing_type_attr_id === attr.id
      );
      const propIndex = currentData.indexOf(item);
      const propErrors = get(
        errors,
        `${propIndex}.value`,
        get(errors, `0.${propIndex}.0.value`)
      );
      if (propErrors !== null) {
        if (typeof propErrors === "object") {
          let keys = Object.keys(propErrors);
          let message = keys.map((k) => propErrors[k]).join("\n");
          return message;
        } else {
          return propErrors;
        }
      }
      return null;
    }, [attr, currentData, errors]);
    const value = useMemo(() => {
      const data = currentData.find(
        (thingAttr) => thingAttr.thing_type_attr_id === attr.id
      );
      return data ? data.value || "" : "";
    }, [currentData, attr]);

    const handleChange = useCallback(
      (value) => {
        dispatch(
          change(
            REDUX_FORM_NAME,
            source,
            currentData
              .map((thingAttr) => ({
                ...thingAttr,
                value:
                  thingAttr.thing_type_attr_id === attr.id
                    ? value
                    : thingAttr.value,
              }))
              .concat([
                !currentData.find(
                  (thingAttr) => thingAttr.thing_type_attr_id === attr.id
                )
                  ? {
                      thing_type_attr_id: attr.id,
                      value,
                    }
                  : null,
              ])
              .filter((prop) => prop !== null)
          )
        );
      },
      [dispatch, source, currentData, attr]
    );
    return (
      <Field
        {...props}
        prop={{ ...attr, value }}
        error={error}
        onChange={handleChange}
      />
    );
  }
);

const AttrsComponent = compose(
  withStyles((theme) => ({
    emptyMessage: {
      textAlign: "center",
      padding: theme.spacing.unit * 6,
    },
    input: {
      marginRight: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2,
      width: "45%",
      minWidth: 250,
      display: "inline-flex",
    },
  })),
  translate
)(({ translate, classes, formData, errors, thingTypes, source }) => {
  const attrs = useMemo(() => {
    return get(thingTypes, `${get(formData, "thing_type_id")}.attrs`, []);
  }, [formData, thingTypes]);
  const currentData = useMemo(
    () => get(formData, source, []),
    [formData, source]
  );
  return attrs.length > 0 ? (
    attrs.map((attr) => (
      <AttrInput
        key={attr.id}
        attr={attr}
        errors={errors}
        source={source}
        currentData={currentData}
        className={classes.input}
      />
    ))
  ) : (
    <div className={classes.emptyMessage}>
      <Typography variant="title">
        {translate("resources.thing.fields.attrs.empty_title")}
      </Typography>
      <Typography variant="subheading">
        {translate("resources.thing.fields.attrs.empty_message")}
      </Typography>
    </div>
  );
});

const AttrsInput = ({ thingTypes, fullWidth, ...props }) => {
  return (
    <FormDataConsumer>
      {({ formData }) => (
        <AttrsComponent
          {...props}
          thingTypes={thingTypes}
          formData={formData}
        />
      )}
    </FormDataConsumer>
  );
};
export default connect((state) => ({
  errors: get(state, "form.record-form.submitErrors.attrs", {}),
  thingTypes: get(state, "admin.resources.thing-type.data"),
}))(AttrsInput);
