import { Toolbar as RaToolbar } from "react-admin";
import React from "react";
import SaveButton from "./buttons/SaveButton";

const Toolbar = (props) => (
  <RaToolbar {...props}>
    <SaveButton />
  </RaToolbar>
);
export default Toolbar;
