import Form from "./Form";
import { Edit as RaEdit } from "react-admin";
import React from "react";
import { compose } from "recompose";

const Edit = (props) => (
  <RaEdit {...props} undoable={false}>
    <Form />
  </RaEdit>
);

export default compose()(Edit);
