import DateRangeInput from "../components/inputs/DateRangeInput";
import { DateTimeInput } from "../components/inputs/DateTimeInput";
import { Filter } from "react-admin";
import GroupsInput from "./inputs/GroupsInput";
import React from "react";
import TypeInput from "./inputs/TypeInput";
import { compose } from "recompose";
import withFiltersStyles from "../components/list/withFiltersStyles";

const Filters = ({ classes, ...props }) => (
  <div className={classes.root}>
    <Filter {...props}>
      <DateRangeInput source="view" alwaysOn />
      <DateTimeInput source={`start_at`} alwaysOn />
      <DateTimeInput source={`end_at`} alwaysOn />
      <GroupsInput source="groups" />
      <TypeInput source="type" />
    </Filter>
  </div>
);

export default compose(withFiltersStyles)(Filters);
