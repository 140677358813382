import { CardContent, CardHeader, withStyles } from "@material-ui/core";
import { FormInput, TextField } from "react-admin";
import React, { Fragment } from "react";

const MetaCard = withStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.secondary.main,
    "& *": {
      color: "white",
      fontFamily: "Consolas, Courier New",
    },
  },
  content: {
    "& *": {
      fontFamily: "Consolas, Courier New",
    },
  },
}))(({ classes, ...props }) => (
  <Fragment>
    <CardHeader
      className={classes.header}
      title={<TextField {...props} source="key" variant="subheading" noWrap />}
    />
    <CardContent className={classes.content}>
      <FormInput
        {...props}
        source="value"
        input={<TextField noWrap />}
        addLabel
      />
    </CardContent>
  </Fragment>
));
export default MetaCard;
