import { Paper, Typography } from "@material-ui/core";

import React from "react";
import moment from "moment";

const ChartTooltip = ({ cardStyle, classes, ...props }) => {
  const { active, payload } = props;
  if (active && payload !== null && payload.length > 0) {
    let measurements = payload.map((p) => ({
      payload: p.payload,
      color: p.color,
    }));
    const created = moment(
      measurements[0].payload.created,
      "YYYY-MM-DD HH:mm:ss"
    ).format("DD/MM/YYYY HH:mm:ss");
    return (
      <Paper elevation={1} className={classes.tooltip} style={cardStyle}>
        <Typography variant="title">{created}</Typography>
        {measurements.map((m) => {
          let value = m.payload.value;
          if (m.payload.sensor_type.formatter) {
            /*eslint-disable no-eval */
            value = eval(m.payload.sensor_type.formatter);
          }
          if (m.payload.sensor_type.unit) {
            value = `${value} ${m.payload.sensor_type.unit}`;
          }
          return (
            <div key={Math.random()}>
              <Typography variant="subheading" style={{ color: m.color }}>
                {m.payload.sensor.name}
              </Typography>
              <Typography variant="body1">{value}</Typography>
            </div>
          );
        })}
      </Paper>
    );
  }
  return null;
};
export default ChartTooltip;
