import { DateField, EditButton } from "react-admin";
import React, { useMemo } from "react";
import { TableCell, TableRow, Typography } from "@material-ui/core";

import ChipField from "../components/fields/ChipField";
import SensorValueField from "../components/fields/SensorValueField";
import { get } from "lodash";
import { translate } from "react-admin";

const ExpandedRow = ({ websocket, translate, classes, record }) => {
  const sensors = useMemo(
    () => get(record, "sensors", []).filter((s) => s.name !== s.code),
    [record]
  );
  if (sensors.length === 0) {
    return (
      <TableRow>
        <TableCell padding="none" className={classes.rowCell} colSpan={2} />
        <TableCell padding="none" className={classes.rowCell} colSpan={6}>
          <Typography variant="body2">
            {translate("resources.device.no_sensors")}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }
  return sensors.map((sensor) => (
    <TableRow key={get(sensor, "id")}>
      <TableCell padding="none" className={classes.rowCell} colSpan={2}>
        &nbsp;
      </TableCell>
      <TableCell padding="none" className={classes.rowCell}>
        {get(sensor, "id")}
      </TableCell>
      <TableCell padding="none" className={classes.rowCell}>
        <ChipField record={sensor} source="code" />
      </TableCell>
      <TableCell padding="none" className={classes.rowCell}>
        {get(sensor, "name")}
      </TableCell>
      <TableCell padding="none" className={classes.rowCell}>
        <SensorValueField websocket={websocket} record={sensor} />
      </TableCell>
      <TableCell padding="none" className={classes.rowCell}>
        <DateField record={sensor} source="modified" showTime />
      </TableCell>
      <TableCell padding="none" className={classes.rowCell}>
        <EditButton record={sensor} basePath="sensor" />
      </TableCell>
    </TableRow>
  ));
};

export default translate(ExpandedRow);
