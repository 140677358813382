import { DeviceIcon } from "../../icons";
import Indicator from "./Indicator";
import React from "react";

const DevicesIndicator = ({ getValue, ...props }) => (
  <Indicator
    color="#f44336"
    label="dashboard.devices"
    value={getValue("Devices")}
    icon={DeviceIcon}
  />
);

export default DevicesIndicator;
