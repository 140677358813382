import { CardActions, CardContent, CardHeader } from "@material-ui/core";
import { DateField, FormInput, TextField } from "react-admin";
import React, { Fragment } from "react";

import ChipField from "../../components/fields/ChipField";
import { compose } from "recompose";
import withCardStyles from "../../components/list/withCardStyles";

const LogCard = ({ classes, ...props }) => (
  <Fragment>
    <CardHeader
      className={classes.header}
      title={<DateField {...props} source="created" showTime />}
    />
    <CardContent className={classes.content}>
      <FormInput {...props} source="message" input={<TextField />} />
    </CardContent>
    <CardActions className={classes.actions}>
      <ChipField {...props} source="type" />
    </CardActions>
  </Fragment>
);
export default compose(withCardStyles)(LogCard);
