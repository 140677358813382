import AlarmOnIcon from "@material-ui/icons/AlarmOn";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import React from "react";
import { compose } from "recompose";
import { translate } from "react-admin";

const icons = {
  ts: <AlarmOnIcon />,
  default: <InsertDriveFileIcon />,
  transport: <LocalShippingIcon />,
};
const TypeField = ({ record, source, translate }) => (
  <Chip
    component="span"
    avatar={
      <Avatar component="span">{icons[record[source] || icons.default]}</Avatar>
    }
    label={translate(`resources.group-doc.fields.types.${record[source]}`)}
  />
);
export default compose(translate)(TypeField);
