let appUrl = `//${document.location.host}/`;
let websocketUrl = `wss://${document.location.host}/socket`;
let useProductionEndpoints = true;
if (appUrl.endsWith(":3000/")) {
  appUrl = useProductionEndpoints ? "//sensic.it/" : "//dev.local:8880/";
  websocketUrl = useProductionEndpoints
    ? "wss://sensic.it/socket"
    : "ws://dev.local:8881";
}
export const WEBSOCKET_URL = websocketUrl;
export const APP_URL = appUrl;
export const API_URL = `${APP_URL}rest`;
export const LEFT_SIDEBAR_WIDTH = 260;
export const RIGHT_SIDEBAR_WIDTH = 300;
export const INNER_SIDEBAR_WIDTH = 320;
export const VERSION = `3.0.5`;
