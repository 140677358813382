import { Typography, withStyles } from "@material-ui/core";

import React from "react";
import { get } from "lodash";

const NotesField = withStyles((theme) => ({
  root: {
    maxWidth: 250,
  },
}))(({ source, record, classes }) => (
  <Typography variant="body2" className={classes.root}>
    {get(record, source)}
  </Typography>
));
export default NotesField;
