import React from "react";
import { SelectInput } from "react-admin";

const STATUSES = [
  {
    id: "in_alarm",
    name: "In Alarm",
  },
  {
    id: "pending",
    name: "Pending",
  },
  {
    id: "paused",
    name: "Paused",
  },
];

const StatusInput = (props) => <SelectInput {...props} choices={STATUSES} />;
export default StatusInput;
