import { BooleanInput, SimpleForm, TextInput } from "react-admin";

import React from "react";
import SensorsInput from "./inputs/SensorsInput";

const Form = (props) => (
  <SimpleForm {...props} redirect="list">
    <TextInput source="code" {...props} />
    <TextInput source="name" {...props} />
    <BooleanInput source="autoassign" {...props} />
    <SensorsInput source="sensors" {...props} />
  </SimpleForm>
);

export default Form;
