import { BooleanField, EditButton, TextField } from "react-admin";

import BulkActions from "./BulkActions";
import Card from "./Card";
import DurationField from "./fields/DurationField";
import Filters from "./Filters";
import LastResultField from "./fields/LastResultField";
import NameField from "./fields/NameField";
import React from "react";
import ResponsiveGrid from "../components/list/ResponsiveGrid";
import ResponsiveList from "../components/list/ResponsiveList";
import StatusField from "./fields/StatusField";

const List = (props) => (
  <ResponsiveList
    {...props}
    type="table"
    sort={{ field: "last_run", order: "DESC" }}
    split
    filters={<Filters />}
    bulkActionButtons={<BulkActions />}
    perPage={10}
  >
    <ResponsiveGrid rowBreakpoint="sm" cardContent={<Card />}>
      <TextField source="id" />
      <BooleanField source="is_enabled" />
      <NameField source="name" />
      <StatusField source="status" />
      <LastResultField source="last_result" />
      <DurationField source="last_run" />
      <EditButton />
    </ResponsiveGrid>
  </ResponsiveList>
);

export default List;
