import "moment/locale/it";

import { Admin, Resource, resolveBrowserLocale } from "react-admin";

import { Dashboard } from "./dashboard";
import Layout from "./components/Layout";
import LoginPage from "./users/LoginPage";
import Menu from "./components/Menu";
import React from "react";
import addAuthHeaderFeature from "./addAuthHeaderFeature";
import addCacheableFeature from "./addCacheableFeature";
import addCodingFeature from "./addCodingFeature";
import addCronJobFeature from "./addCronJobFeature";
import addDashboardFeature from "./addDashboardFeature";
import addDeviceFeature from "./addDeviceFeature";
import addEAuthFeature from "./addEAuthFeature";
import addExplorerFeature from "./addExplorerFeature";
import addLocationFeature from "./addLocationFeature";
import addMeasurementFeature from "./addMeasurementFeature";
import addSensorFeature from "./addSensorFeature";
import addThingFeature from "./addThingFeature";
import addUploadFeature from "./addUploadFeature";
import addUserFeature from "./addUserFeature";
import appSettings from "./app-settings";
import authProvider from "./authProvider";
import bloodBagWorking from "./blood-bag-working";
import bloodBlast from "./blood-blast";
import cronJobs from "./cron-jobs";
import customRoutes from "./customRoutes";
import dataProvider from "./dataProvider";
import deviceLabels from "./device-labels";
import deviceTypes from "./device-types";
import devices from "./devices";
import emailTemplates from "./email-templates";
import emailThemes from "./email-themes";
import errorSagas from "./sagas/errors";
import eventNotifiers from "./event-notifiers";
import events from "./events";
import groupDocs from "./group-docs";
import groups from "./groups";
import languageMessage from "./language-message";
import locations from "./locations";
import measurementHourly from "./measurements-hourly";
import moment from "moment";
import monitors from "./monitors";
import roles from "./roles";
import sensorEventUnarmed from "./sensor-event-unarmed";
import sensorTypes from "./sensor-types";
import sensors from "./sensors";
import sentinels from "./sentinels";
import theme from "./theme";
import thingTypes from "./thing-types";
import things from "./things";
import uiReducer from "./ui/reducer";
import useI18nCatcher from "./useI18nCatcher";
import useLanguages from "./useLanguages";
import userPasswordChange from "./user-password-change";
import userProfile from "./user-profile";
import users from "./users";
import valueConverters from "./value-converters";
import valueFilters from "./value-filters";
import webSocketReducer from "./websocket/reducer";

const resolveDefaultLocale = () => {
  const choosenLocal = localStorage.getItem("locale");
  if (choosenLocal) {
    return choosenLocal;
  }
  return resolveBrowserLocale();
};

let dp = addAuthHeaderFeature(dataProvider);
dp = addCacheableFeature(dp);
dp = addCodingFeature(dp);
dp = addCronJobFeature(dp);
dp = addDashboardFeature(dp);
dp = addDeviceFeature(dp);
dp = addEAuthFeature(dp);
dp = addExplorerFeature(dp);
dp = addLocationFeature(dp);
dp = addLocationFeature(dp);
dp = addMeasurementFeature(dp);
dp = addSensorFeature(dp);
dp = addUploadFeature(dp);
dp = addUserFeature(dp);
dp = addThingFeature(dp);

const App = () => {
  useI18nCatcher();
  const { loading, languages } = useLanguages();
  if (loading) {
    return null;
  }

  const i18nProvider = (locale) => {
    moment.locale(locale);
    return languages[locale] || languages.it;
  };

  return (
    <Admin
      customSagas={[errorSagas]}
      customRoutes={customRoutes}
      customReducers={{
        websocket: webSocketReducer,
        ui: uiReducer,
      }}
      menu={Menu}
      locale={resolveDefaultLocale()}
      theme={theme}
      appLayout={Layout}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      dataProvider={dp}
      loginPage={LoginPage}
      dashboard={Dashboard}
    >
      <Resource name="location" {...locations} />
      <Resource name="device" {...devices} />
      <Resource name="sensor" {...sensors} />
      <Resource name="thing" {...things} />
      <Resource name="event" {...events} />
      <Resource name="group-doc" {...groupDocs} />
      <Resource name="sensor-log" />
      <Resource name="sentinel" {...sentinels} />
      <Resource name="sentinel-event-log" />
      <Resource name="value-filter" {...valueFilters} />
      <Resource name="monitor" {...monitors} />
      <Resource name="value-converter" {...valueConverters} />
      <Resource name="event-notifier" {...eventNotifiers} />

      <Resource name="device-label" {...deviceLabels} />
      <Resource name="device-type" {...deviceTypes} />
      <Resource name="sensor-type" {...sensorTypes} />
      <Resource name="thing-type" {...thingTypes} />

      <Resource name="user" {...users} />
      <Resource name="group" {...groups} />
      <Resource name="role" {...roles} />
      <Resource name="language" />
      <Resource name="language-model" />
      <Resource name="language-message" {...languageMessage} />
      <Resource name="cron-job" {...cronJobs} />
      <Resource name="cron-job-log" />
      <Resource name="app-setting" {...appSettings} />
      <Resource name="email-template" {...emailTemplates} />
      <Resource name="email-theme" {...emailThemes} />

      <Resource name="measurement-hourly" {...measurementHourly} />

      <Resource name="sensor-event-unarmed" {...sensorEventUnarmed} />

      <Resource name="user-profile" {...userProfile} />
      <Resource name="user-password-change" {...userPasswordChange} />
      <Resource name="municipality" />

      <Resource name="blood-bag-working" {...bloodBagWorking} />
      <Resource name="blood-blast" {...bloodBlast} />
    </Admin>
  );
};
export default App;
