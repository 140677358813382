import {
  DisabledInput,
  FormTab,
  Edit as RaEdit,
  TabbedForm,
  TextInput,
} from "react-admin";
import { LocationIcon, SensorIcon } from "../icons";

import GroupsInput from "../components/inputs/GroupsInput";
import LocationEditorInput from "./input/LocationEditorInput";
import LocationInput from "./input/LocationInput";
import MetaListField from "./fields/MetaListField";
import PictureInput from "./input/PictureInput";
import React from "react";
import SensorListField from "./fields/SensorListField";
import SettingsIcon from "@material-ui/icons/Settings";
import SettingsRemoteIcon from "@material-ui/icons/SettingsRemote";
import Toolbar from "./Toolbar";
import ValueConvertersInput from "../components/inputs/ValueConvertersInput";
import { compose } from "recompose";
import withFormStyles from "../components/forms/withFormStyles";

const Edit = ({ classes, ...props }) => (
  <RaEdit {...props} undoable={false} className={classes.root}>
    <TabbedForm redirect={false} toolbar={<Toolbar />}>
      <FormTab icon={<SettingsIcon />} label="resources.device.tabs.config">
        <TextInput source="access_token" fullWidth />
        <DisabledInput source="code" />
        <DisabledInput source="mac_address" />
        <TextInput source="name" fullWidth />
        <GroupsInput source="groups" resource="device" fullWidth />
        <ValueConvertersInput source="value_converters" resource="device" />
        <PictureInput source="image" />
      </FormTab>
      <FormTab icon={<SensorIcon />} label="resources.device.tabs.sensors">
        <SensorListField source="sensors" />
      </FormTab>
      <FormTab icon={<LocationIcon />} label="resources.device.tabs.location">
        <LocationInput source="location_id" fullWidth />
        <LocationEditorInput source="position" fullWidth />
      </FormTab>
      <FormTab icon={<SettingsRemoteIcon />} label="resources.device.tabs.meta">
        <MetaListField source="meta" fullWidth />
      </FormTab>
    </TabbedForm>
  </RaEdit>
);
export default compose(withFormStyles)(Edit);
