import { WithPermissions, translate } from "react-admin";

import AppBarLayout from "./AppBarLayout";
import { Hidden } from "@material-ui/core";
import LanguageSwitcher from "./LanguageSwitcher";
import MyUserMenu from "../UserMenu";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    verticalAlgin: "bottom",
    fontWeight: "normal",
  },
  spacer: {
    flex: 1,
  },
});

const AppBar = ({ translate, classes, ...props }) => {
  return (
    <AppBarLayout {...props} userMenu={<MyUserMenu />}>
      <Typography
        id="react-admin-title"
        variant="title"
        color="inherit"
        className={classes.title}
      />
      <Hidden smDown>
        <WithPermissions
          render={({ permissions }) => translate("app.welcome", permissions)}
        />
      </Hidden>
      <LanguageSwitcher />
    </AppBarLayout>
  );
};
export default compose(withStyles(styles), translate)(AppBar);
