import { CardContent, Grid, Typography } from "@material-ui/core";

import CalendarIcon from "@material-ui/icons/Event";
import CardHeader from "./CardHeader";
import CardSection from "./CardSection";
import ClockIcon from "@material-ui/icons/AccessTime";
import { LocationIcon } from "../../icons";
import React from "react";
import TimerIcon from "@material-ui/icons/Timer";
import WarningIcon from "@material-ui/icons/Warning";
import { get } from "lodash";
import { getDuration } from "../../helpers/EventHelper";
import moment from "moment";

const Card = ({ record }) => (
  <CardContent>
    <Grid container justify="space-between" spacing={8}>
      <CardHeader justify="left" xs={5}>
        <CalendarIcon />
        <Typography>
          {moment(get(record, "start_at"), "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY"
          )}
        </Typography>
      </CardHeader>
      <CardHeader xs={3}>
        <ClockIcon />
        <Typography>
          {moment(get(record, "start_at"), "YYYY-MM-DD HH:mm:ss").format(
            "HH:mm"
          )}
        </Typography>
      </CardHeader>
      <CardHeader justify="right">
        <TimerIcon />
        <Typography>{getDuration(record)}</Typography>
      </CardHeader>
      <Grid item xs={12}>
        <Typography variant="body2">{get(record, "sensor.name")}</Typography>
      </Grid>
      <CardSection
        icon={<LocationIcon />}
        status={get(record, "closed") !== null ? "closed" : "ongoing"}
        title={get(record, "device.name")}
        sub={get(record, "location.tree_name")}
      />
      <CardSection
        icon={<WarningIcon />}
        status={get(record, "closed") !== null ? "closed" : "ongoing"}
        bold={get(record, "notes")}
      />
      <Grid item xs={12}>
        <Grid container direction="row" justify="flex-end">
          <Typography variant="body2">{get(record, "device.code")}</Typography>
        </Grid>
      </Grid>
    </Grid>
  </CardContent>
);

export default Card;
