import { Grid, Typography } from "@material-ui/core";

import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import compose from "recompose/compose";
import { translate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  main: {
    marginTop: 150,
  },
  loader: {
    height: 150,
    width: 150,
    borderRadius: 75,
    backgroundColor: "#ADADAD",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "border-radius ease 0.5s",
  },
  spinner: {
    color: "white",
  },
  checkIcon: {
    fontSize: 100,
    color: "white",
  },
  text: {
    fontSize: 25,
    color: "#ADADAD",
    fontWeight: "bold",
  },
  textContainer: {
    textAlign: "center",
    marginTop: 20,
    transition: "opacity ease 0.5s",
  },
});

const EventLoader = ({ classes, loading, noEvents, translate }) => {
  const text = translate("dashboard.no_events_today").split("\\n");
  if (!loading && !noEvents) {
    return null;
  }
  return (
    <Grid
      container
      item
      lg={12}
      direction="column"
      alignContent="center"
      alignItems="center"
      className={classes.main}
    >
      <div
        className={classes.loader}
        style={loading === false ? { borderRadius: 20 } : { borderRadius: 75 }}
      >
        {loading && (
          <CircularProgress
            size={100}
            classes={{
              root: classes.spinner,
            }}
          />
        )}

        {!loading && (
          <CheckIcon
            classes={{
              root: classes.checkIcon,
            }}
          />
        )}
      </div>

      <div
        className={classes.textContainer}
        style={
          loading === false && noEvents === true
            ? { opacity: 1 }
            : { opacity: 0 }
        }
      >
        <Typography className={classes.text}>{text[0]}</Typography>
        <Typography className={classes.text}>{text[1]}</Typography>
      </div>
    </Grid>
  );
};

const enhance = compose(withStyles(styles), translate);
export default enhance(EventLoader);
