import { Typography, withStyles } from "@material-ui/core";

import React from "react";
import { get } from "lodash";

const ValueField = withStyles((theme) => ({
  root: {
    minWidth: 80,
  },
}))(({ addLabel, classes, source, record, basePath, resource, ...props }) => {
  let value = get(record, source);
  const formatter = get(record, "sensor_type.formatter");
  const unit = get(record, "sensor_type.unit");
  if (formatter) {
    /*eslint-disable no-eval */
    let result = eval(formatter);
    let parser = get(result, "parser", null);
    if (parser === null) {
      value = result;
    } else {
      value = parser.parse(value);
    }
  }
  if (unit) {
    value = `${value} ${unit}`;
  }
  return (
    <Typography variant="body2" {...props} className={classes.root}>
      {value}
    </Typography>
  );
});
export default ValueField;
