import { BooleanInput, Filter, withTranslate } from "react-admin";
import { CLOSED, CLOSED_BG, ONGOING, ONGOING_BG } from "../colors";

import React from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core";

const StatusInput = withStyles((theme) => ({
  colorSwitchBaseClosed: {
    color: CLOSED,
    "& + $colorBar": {
      backgroundColor: CLOSED_BG,
    },

    "&$colorChecked": {
      color: CLOSED,
      "& + $colorBar": {
        backgroundColor: CLOSED_BG,
      },
    },
  },
  colorSwitchBaseOngoing: {
    color: ONGOING,
    "& + $colorBar": {
      backgroundColor: ONGOING_BG,
    },

    "&$colorChecked": {
      color: ONGOING,
      "& + $colorBar": {
        backgroundColor: ONGOING_BG,
      },
    },
  },
  colorBar: {},
  colorChecked: {},
}))(({ classes, source, alwaysOn, translate, allowEmpty, ...props }) => (
  <Filter {...props} style={{ marginTop: 10 }}>
    <BooleanInput
      source="is_ongoing"
      label={translate("website.event.ongoing")}
      alwaysOn
      options={{
        classes: {
          switchBase: classes.colorSwitchBaseOngoing,
          checked: classes.colorChecked,
          bar: classes.colorBar,
        },
      }}
    />
    <BooleanInput
      source={"is_closed"}
      label={translate("website.event.closed")}
      alwaysOn
      options={{
        classes: {
          switchBase: classes.colorSwitchBaseClosed,
          checked: classes.colorChecked,
          bar: classes.colorBar,
        },
      }}
    />
  </Filter>
));

export default compose(withTranslate)(StatusInput);
