import { BooleanInput, Filter, SearchInput } from "react-admin";

import { DateTimeInput } from "../components/inputs/DateTimeInput";
import React from "react";

const Filters = ({ isAdmin, ...props }) => (
  <Filter {...props}>
    <SearchInput source="subkeyword" alwaysOn />
    <BooleanInput source="is_unarmed" alwaysOn />
    <DateTimeInput source={`start_at`} />
    <DateTimeInput source={`end_at`} />
  </Filter>
);

export default Filters;
