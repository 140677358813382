import { FormDataConsumer } from "react-admin";
import React from "react";
import TheInput from "../../components/inputs/GroupsInput";
import { get } from "lodash";

const GroupsInput = (props) => (
  <FormDataConsumer {...props}>
    {({ formData }) =>
      get(formData, "has_notification_groups", false) && <TheInput {...props} />
    }
  </FormDataConsumer>
);
export default GroupsInput;
