import Form from "./Form";
import { Create as RaCreate } from "react-admin";
import React from "react";

const Create = (props) => (
  <RaCreate {...props}>
    <Form
      defaultValue={{
        role: "user",
        status: "active",
        auth_type: "local",
      }}
    />
  </RaCreate>
);
export default Create;
