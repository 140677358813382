import { API_URL } from "./config";

export const EAUTH_REQUEST = "EAUTH_REQUEST";
const addEAuthFeature = (requestHandler) => (type, resource, params) => {
  if (type === EAUTH_REQUEST) {
    const { r } = params;
    const requestURL = `${API_URL}/eauth`;
    const request = new Request(requestURL, {
      method: "POST",
      body: JSON.stringify({ r }),
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "applicatio/json",
      }),
    });
    return fetch(request)
      .then((response) => response.json())
      .then((data) => ({ data }));
  }
  return requestHandler(type, resource, params);
};

export default addEAuthFeature;
