import * as React from "react";

import { API_URL } from "./config";

const queued = [];

export const flatten = function (data) {
  var result = {};
  function recurse(cur, prop) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      for (var i = 0, l = cur.length; i < l; i++)
        recurse(cur[i], prop + "[" + i + "]");
      if (l === 0) result[prop] = [];
    } else {
      var isEmpty = true;
      for (var p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + "." + p : p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, "");
  return result;
};

export const importData = function (locale, localizedMessages) {
  const messages = flatten(localizedMessages);
  const messageKeys = Object.keys(messages);
  return messageKeys.reduce(
    (p, message) => p.then(putMessage(locale, message, messages[message])),
    Promise.resolve()
  );
};

const putMessage = (locale, message, text = undefined) =>
  queued.indexOf(`${locale}-${message}`) === -1 &&
  queued.push(`${locale}-${message}`)
    ? fetch(`${API_URL}/language/put-message`, {
        method: "PUT",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          code: locale,
          message: {
            code: message,
            text: text || message,
          },
        }),
      })
    : Promise.resolve();

const useI18nCatcher = () => {
  React.useMemo(() => {
    if (process.env.NODE_ENV === "production") {
      return;
    }

    const consoleError = console.error;
    console.error = function (message) {
      if (
        typeof message === "string" &&
        message.indexOf("Missing translation for key: ") >= 0
      ) {
        message = message.replace("Warning: Missing translation for key: ", "");
        message = message.split('"').join("").trim();
        if (message.indexOf(" ") !== -1) {
          return;
        }
        const lc = localStorage.getItem("locale") || "it";
        putMessage(lc, message);
        return;
      }

      consoleError.apply(console, arguments);
    };
  }, []);
  return true;
};

export default useI18nCatcher;
