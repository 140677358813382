import * as Colors from "@material-ui/core/colors";

import { MapControl, withLeaflet } from "react-leaflet";
import { createStyles, withStyles } from "@material-ui/core/styles";

import L from "leaflet";
import { Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import { calculateSteps } from "../data/choroplet";
import compose from "recompose/compose";
import withWidth from "@material-ui/core/withWidth";

const styles = (theme) =>
  createStyles({
    menuContainer: {
      width: 80,
      padding: theme.spacing.unit,
    },
    label: {
      minWidth: 70,
      textAlign: "left",
    },
    color: {
      width: 16,
      height: 16,
      display: "inline-block",
      marginRight: theme.spacing.unit * 1,
    },
  });

class Legend extends MapControl {
  componentDidMount() {
    super.componentDidMount();
    ReactDOM.render(this.renderLegend(), this.container);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sensorType !== this.props.sensorType) {
      ReactDOM.render(this.renderLegend(), this.container);
    }
  }

  renderLegend() {
    const { classes, sensorType } = this.props;
    const steps = calculateSteps(sensorType);
    if (!steps) {
      return null;
    }

    return (
      <Paper className={classes.menuContainer}>
        {steps.map((step) => (
          <div key={step.label} className={classes.label}>
            <span
              className={classes.color}
              style={{ backgroundColor: Colors[sensorType.color][step.shade] }}
            >
              &nbsp;
            </span>
            {step.label}
          </div>
        ))}
      </Paper>
    );
  }

  createLeafletElement() {
    const Legend = L.Control.extend({
      onAdd: () => {
        this.container = L.DomUtil.create("div", "");
        return this.container;
      },
    });
    return new Legend({ position: "bottomright" });
  }
}

Legend.propTypes = {
  sensorType: PropTypes.object,
};

export default compose(withLeaflet, withStyles(styles), withWidth())(Legend);
