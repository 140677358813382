import { CardContent, CardHeader } from "@material-ui/core";
import React, { Fragment } from "react";

import DateRangeField from "./fields/DateRangeField";
import DeviceField from "./fields/DeviceField";
import { FormInput } from "react-admin";
import MonitorField from "./fields/MonitorField";
import SensorField from "./fields/SensorField";
import ValueField from "./fields/ValueField";
import { compose } from "recompose";
import withCardStyles from "../components/list/withCardStyles";

const Card = ({ classes, ...props }) => (
  <Fragment>
    <CardHeader
      className={classes.header}
      title={
        <Fragment>
          <DeviceField {...props} source="device.name" variant="title" />
          <SensorField {...props} source="sensor.name" variant="caption" />
        </Fragment>
      }
    />
    <CardContent className={classes.content}>
      <FormInput
        {...props}
        source="created"
        input={<DateRangeField addLabel />}
      />
      <FormInput {...props} source="value" input={<ValueField addLabel />} />
      <FormInput
        {...props}
        source="min_value"
        input={<ValueField addLabel />}
      />
      <FormInput
        {...props}
        source="max_value"
        input={<ValueField addLabel />}
      />
      <FormInput
        {...props}
        source="monitor"
        input={<MonitorField addLabel />}
      />
    </CardContent>
  </Fragment>
);
export default compose(withCardStyles)(Card);
