import { createStyles, withStyles } from "@material-ui/core/styles";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "../components/Link";
import MuiBreadcrumbs from "../components/breadcrumbs/Breadcrumbs";
import PropTypes from "prop-types";
import React from "react";
import { Typography } from "@material-ui/core";
import compose from "recompose/compose";
import { translate } from "react-admin";

const styles = (theme) =>
  createStyles({
    root: {
      padding: 0,
      margin: theme.spacing.unit * 2,
    },
    icon: {
      padding: theme.spacing.unit * 0,
      margin: theme.spacing.unit * 0,
    },
    loader: {
      marginBottom: theme.spacing.unit,
    },
  });

const Breadcrumbs = withStyles(styles)(
  ({ translate, classes, items, loading }) =>
    loading ? (
      <LinearProgress className={classes.loader} color="secondary" />
    ) : (
      <MuiBreadcrumbs className={classes.root} separator={<ChevronRightIcon />}>
        <Typography
          variation="body1"
          component="span"
          className={classes.label}
        >
          {translate("resources.location.breadcrumbs.position")}
        </Typography>

        <Link
          variant="body1"
          href={`#/location?filter=${JSON.stringify({
            location_id: null,
          })}`}
        >
          {translate("resources.location.breadcrumbs.root")}
        </Link>
        {items?.map((item, idx) =>
          item.name ? (
            <Link
              key={idx}
              variant="body1"
              href={`#/location?filter=${JSON.stringify({
                location_id: item.id,
              })}`}
            >
              {item.name}
            </Link>
          ) : null
        )}
      </MuiBreadcrumbs>
    )
);
Breadcrumbs.propTypes = {
  items: PropTypes.array,
  translate: PropTypes.func,
};
export default compose(translate)(Breadcrumbs);
