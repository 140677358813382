import { Fade, Grid } from "@material-ui/core";

import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";
import compose from "recompose/compose";
import { translate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  main: {},
  loader: {
    borderRadius: 75,
    backgroundColor: "#ADADAD",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "border-radius ease 0.5s",
  },
  visible: {
    marginTop: 150,
    height: 150,
    width: 150,
  },
  center: {
    marginBottom: 150,
  },
  spinner: {
    color: "white",
  },
  checkIcon: {
    fontSize: 100,
    color: "white",
  },
  text: {
    fontSize: 25,
    color: "#ADADAD",
    fontWeight: "bold",
  },
  textContainer: {
    textAlign: "center",
    marginTop: 20,
    transition: "opacity ease 0.5s",
  },
});

const Loader = ({ classes, center = false, loading }) => {
  if (!loading) {
    return null;
  }
  return (
    <Fade in={loading}>
      <Grid
        container
        item
        lg={12}
        style={{
          height: loading ? null : 0,
        }}
        direction="column"
        alignContent="center"
        alignItems="center"
        className={classnames(classes.main)}
      >
        <div
          className={classnames(
            classes.loader,
            loading && classes.visible,
            center && classes.center
          )}
          style={
            loading === false ? { borderRadius: 20 } : { borderRadius: 75 }
          }
        >
          {loading && (
            <CircularProgress
              size={100}
              classes={{
                root: classes.spinner,
              }}
            />
          )}

          {!loading && (
            <CheckIcon
              classes={{
                root: classes.checkIcon,
              }}
            />
          )}
        </div>
      </Grid>
    </Fade>
  );
};

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
};

const enhance = compose(withStyles(styles), translate);
export default enhance(Loader);
