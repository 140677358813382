import Create from "./Create";
import { DeviceIcon } from "../icons";
import Edit from "./Edit";
import List from "./List";

export default {
  options: {
    group: "config",
    mergeFilters: true,
    accessible: ({ user }) => user.permissions.indexOf("device/list") !== -1,
  },
  icon: DeviceIcon,
  list: List,
  edit: Edit,
  create: Create,
};
