import { CardActions, CardContent, CardHeader } from "@material-ui/core";
import { EditButton, FormInput, TextField } from "react-admin";
import React, { Fragment } from "react";

import DurationField from "./fields/DurationField";
import ThingField from "./fields/ThingField";
import { compose } from "recompose";
import withCardStyles from "../components/list/withCardStyles";

const Card = ({ classes, ...props }) => (
  <Fragment>
    <CardHeader
      className={classes.header}
      title={<DurationField {...props} />}
    />
    <CardContent className={classes.content}>
      <FormInput
        {...props}
        source="user.username"
        input={<TextField addLabel />}
      />
      <FormInput
        {...props}
        source="location.tree_name"
        input={<ThingField addLabel />}
      />
      <FormInput
        {...props}
        source="device.name"
        input={<ThingField addLabel />}
      />
      <FormInput
        {...props}
        source="sensor.name"
        input={<ThingField addLabel />}
      />
    </CardContent>
    <CardActions className={classes.actions}>
      <EditButton {...props} />
    </CardActions>
  </Fragment>
);
export default compose(withCardStyles)(Card);
