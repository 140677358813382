import React, { Component } from "react";
import { Title, resolveBrowserLocale, withDataProvider } from "react-admin";
import { WithPermissions, translate } from "ra-core";

import AnonymousWelcome from "./AnonymousWelcome";
import DevicesIndicator from "./indicators/DevicesIndicator";
import EquipmentsIndicator from "./indicators/EquipmentsIndicator";
import Events from "./events";
import { GET_STATS } from "../addDashboardFeature";
import { Grid } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import SensorsIndicator from "./indicators/SensorsIndicator";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  container: {
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing.unit * 1,
      marginTop: theme.spacing.unit * 4,
    },
  },
  indicator: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing.unit * 1,
    },
  },
});

const resolveDefaultLocale = () => {
  const choosenLocal = localStorage.getItem("locale");
  if (choosenLocal) {
    return choosenLocal;
  }
  return resolveBrowserLocale();
};

class Dashboard extends Component {
  state = {
    stats: {
      indicators: [],
      events: [],
    },
    logs: [],
    loading: false,
  };
  connection = null;
  redirect = null;

  constructor(props) {
    super(props);
    this.getValue = this.getValue.bind(this);
  }

  componentDidMount() {
    this.redirect = localStorage.getItem("redirect");
    if (this.redirect) {
      localStorage.removeItem("redirect");
    } else {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.redirect) {
      return;
    }
    if (this.props.version !== prevProps.version) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    if (this.connection !== null) {
      this.connection.close();
    }
  }

  async fetchData() {
    this.setState({ loading: true });
    const { dataProvider } = this.props;
    const response = await dataProvider(GET_STATS, "dashboard", {});
    if (!response || response.error) {
      return;
    }
    this.setState({
      stats: response.data,
    });
    setTimeout(() => this.setState({ loading: false }), 500);
  }

  getValue(name) {
    if (this.state.stats && this.state.stats.indicators) {
      let v = this.state.stats.indicators.find((i) => i.name === name);
      let locale = resolveDefaultLocale();
      return v !== undefined ? v.value.toLocaleString(locale) : 0;
    }
    return 0;
  }

  render() {
    if (this.redirect) {
      return <Redirect to={this.redirect} />;
    }
    const { getValue } = this;
    const { permissions, classes } = this.props;
    const needsHelp =
      permissions &&
      (!permissions.roles || permissions.roles.length === 0) &&
      (!permissions.groups || permissions.groups.length === 0);
    if (needsHelp) {
      return <AnonymousWelcome permissions={permissions} />;
    }

    return (
      <div className={classes.container}>
        <Title title={"Dashboard"} />
        <Grid container spacing={16}>
          <Grid item lg={3} md={6} xs={12} className={classes.indicator}>
            <DevicesIndicator getValue={getValue} />
          </Grid>
          <Grid item lg={3} md={6} xs={12} className={classes.indicator}>
            <EquipmentsIndicator getValue={getValue} />
          </Grid>
          <Grid item lg={3} md={6} xs={12} className={classes.indicator}>
            <SensorsIndicator getValue={getValue} />
          </Grid>
        </Grid>
        <Events
          loading={this.state.loading}
          events={this.state.stats?.events}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  version: state.admin.ui.viewVersion,
});

const ComposedDashboard = compose(
  connect(mapStateToProps),
  withStyles(styles),
  withDataProvider,
  translate
)(Dashboard);

const DashboardWithPermissions = (props) => (
  <WithPermissions
    render={({ permissions }) => (
      <ComposedDashboard permissions={permissions} />
    )}
  />
);
export default DashboardWithPermissions;
