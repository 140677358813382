import { CreateButton as RaCreateButton } from "react-admin";
import React from "react";
import { get } from "lodash";
import { stringify } from "query-string";

const CreateButton = ({ filterValues, ...props }) => (
  <RaCreateButton
    {...props}
    key={JSON.stringify(filterValues)}
    to={{
      pathname: `/${props.resource}/create`,
      search: stringify({
        source: JSON.stringify({
          parent_id: get(filterValues, "parent_id"),
        }),
      }),
    }}
  />
);
export default CreateButton;
