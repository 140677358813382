import { CALLBACK_TYPE, Connection } from "../websocket/Connection";
import React, { Component } from "react";
import { WithPermissions, translate, withDataProvider } from "ra-core";
import { createStyles, withStyles } from "@material-ui/core";

import ChartList from "./ChartList";
import EmptyMessage from "./EmptyMessage";
import { LOAD } from "../addMeasurementFeature";
import Loader from "../components/Loader";
import SearchHelper from "./SearchHelper";
import { Title } from "react-admin";
import Toolbar from "./Toolbar";
import { canSearch } from "./config";
import compose from "recompose/compose";
import { connect } from "react-redux";
import mergeFilter from "../components/tree/mergeFilter";
import moment from "moment";
import parseRoute from "../components/tree/parseRoute";

const styles = (theme) =>
  createStyles({
    chartWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    chartLoader: {
      flex: 1,
    },
    textField: {
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit,
      width: 200,
    },
  });

class Measurements extends Component {
  constructor(props) {
    super(props);
    const { filter } = parseRoute();
    const start = moment(
      filter.start_at || moment().subtract(5, "minutes"),
      "YYYY-MM-DDTHH:mm:ss"
    );
    const end = moment(filter.end_at || moment(), "YYYY-MM-DDTHH:mm:ss");
    this.state = {
      loading: false,
      sampling: filter.sampling || "RealTime",
      view: filter.view || "l5m",
      start_at: start.format("YYYY-MM-DDTHH:mm:ss"),
      end_at: end.format("YYYY-MM-DDTHH:mm:ss"),
      selection: {
        locations: filter.locations,
        expanded: filter.expanded,
        devices: filter.devices,
        sensors: filter.sensors,
      },
      charts: {
        types: [],
        measurements: [],
        events: [],
      },
      filter,
      measurements: [],
    };
  }
  componentDidMount() {
    const { translate } = this.props;
    const { start_at, end_at, sampling, selection } = this.state;
    const search = canSearch(translate, start_at, end_at, sampling, selection);
    if (search === true) {
      this.refreshData();
    } else {
      this.setState({ measurements: [] });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { translate } = this.props;
    const { filter } = parseRoute();
    const hash1 = JSON.stringify(filter);
    const hash2 = JSON.stringify(this.state.filter);
    if (hash1 !== hash2 || this.props.version !== nextProps.version) {
      if (this.connection && this.connection !== null) {
        this.connection.reconnect();
      }
      this.setState(
        {
          hash: hash2,
          filter,
          selection: {
            locations: filter.locations,
            expanded: filter.expanded,
            devices: filter.devices,
            sensors: filter.sensors,
          },
        },
        () => {
          const { start_at, end_at, sampling, selection } = this.state;
          const search = canSearch(
            translate,
            start_at,
            end_at,
            sampling,
            selection
          );

          if (search === true) {
            this.refreshData();
          } else {
            this.setState({ measurements: [] });
          }
        }
      );
    }
    if (
      nextProps.permissions !== null &&
      this.props.permissions === null &&
      !this.connection
    ) {
      this.connection = new Connection(nextProps.permissions);
      this.connection.setCallback(CALLBACK_TYPE.MEASUREMENTS, (payload) => {
        const { start_at, end_at, sampling, selection } = this.state;
        const search = canSearch(
          translate,
          start_at,
          end_at,
          sampling,
          selection
        );
        if (search !== true) {
          return;
        }
        const { sensor, sensor_type, device, data } = payload;
        if (
          !data ||
          data.value === null ||
          data.value === undefined ||
          !sensor_type
        ) {
          return;
        }
        const {
          measurements,
          selection: { devices, sensors },
        } = this.state;
        if (
          sensors.indexOf(sensor.id) === -1 &&
          devices.indexOf(device.id) === -1
        ) {
          return;
        }
        this.setState({
          measurements: measurements.concat([
            {
              sensor,
              device,
              sensor_id: sensor.id,
              sensor_type: sensor_type,
              value: data.value,
              created: moment().format("YYYY-MM-DD HH:mm:ss"),
            },
          ]),
        });
      });
      this.connection.open();
    }
  }

  componentWillUnmount() {
    if (this.connection) {
      this.connection.close();
    }
  }

  handleChange(filters) {
    const { history } = this.props;
    const { push } = history;
    this.setState(
      {
        ...filters,
      },
      () => push(`/measurement?filter=${JSON.stringify(mergeFilter(filters))}`)
    );
  }
  refreshData() {
    const { dataProvider } = this.props;
    this.setState({ loading: true, measurements: [] }, () =>
      dataProvider(LOAD, "measurement", {
        sampling:
          this.state.sampling === "RealTime" ? "Hot" : this.state.sampling,
        start_at: this.state.start_at,
        end_at: this.state.end_at,
        devices: this.state.selection.devices,
        sensors: this.state.selection.sensors,
        locations: this.state.selection.locations,
      }).then(({ data }) =>
        this.setState({ loading: false, measurements: data })
      )
    );
  }

  render() {
    const { translate } = this.props;
    const { selection, start_at, end_at, sampling, hash } = this.state;

    const search = canSearch(translate, start_at, end_at, sampling, selection);
    const warning = search !== true ? search : null;
    return (
      <div>
        <Title title={translate("pages.measurements.name")} />
        <Toolbar
          search={true}
          start={this.state.start_at}
          end={this.state.end_at}
          view={this.state.view}
          sampling={this.state.sampling}
          onChange={this.handleChange.bind(this)}
        />
        <SearchHelper warning={warning} />
        <Loader loading={this.state.loading} />
        <EmptyMessage {...this.state} />
        <ChartList
          search={search}
          hash={hash}
          loading={this.state.loading}
          start={this.state.start_at}
          end={this.state.end_at}
          sampling={this.state.sampling}
          measurements={this.state.measurements}
        />
      </div>
    );
  }
}
const ComposedMeasurements = compose(
  withStyles(styles),
  translate,
  withDataProvider,
  connect((state) => ({
    version: state.admin.ui.viewVersion,
  }))
)(Measurements);

const MeasurementsWithPermissions = (props) => (
  <WithPermissions
    render={({ permissions }) => (
      <ComposedMeasurements permissions={permissions} {...props} />
    )}
  />
);
export default MeasurementsWithPermissions;
