import { Avatar, Typography, withStyles } from "@material-ui/core";
import { CLOSED, ONGOING } from "../colors";

import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";

const CardSection = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: theme.spacing.unint,
  },
  avatar: {
    fontSize: 20,
    margin: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit,
    "& svg": {
      color: "white",
    },
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
  },
  ongoing: {
    backgroundColor: ONGOING,
  },
  closed: {
    backgroundColor: CLOSED,
  },
}))(
  ({
    icon,
    classes,
    status,
    title = undefined,
    bold = undefined,
    sub = undefined,
  }) => (
    <div className={classes.root}>
      <Avatar className={classnames(classes.avatar, classes[status])}>
        {React.cloneElement(icon)}
      </Avatar>
      <div className={classes.textContainer}>
        {title && <Typography>{title}</Typography>}
        {sub && <Typography variant="caption">{sub}</Typography>}
        {bold && <Typography variant="body2">{bold}</Typography>}
      </div>
    </div>
  )
);

CardSection.propTypes = {
  status: PropTypes.oneOf(["ongoing", "closed"]),
  icon: PropTypes.element,
  title: PropTypes.string,
  sub: PropTypes.string,
  bold: PropTypes.string,
};

export default CardSection;
