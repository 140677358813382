import {
  ArrayField,
  DateField,
  TextField,
  withDataProvider,
} from "react-admin";
import React, { useEffect, useMemo } from "react";

import ChipField from "../../components/fields/ChipField";
import EditButton from "../../components/buttons/EditButton";
import ResponsiveGrid from "../../components/list/ResponsiveGrid";
import SensorCard from "./SensorCard";
import SensorValueField from "../../components/fields/SensorValueField";
import { compose } from "recompose";
import { get } from "lodash";
import { refreshData } from "../../websocket/actions";
import withWebsocket from "../../websocket/withWebsocket";

const SensorListField = ({ dispatch, websocket, dataProvider, ...props }) => {
  const { record, source } = props;
  // Ricorda: se non usi memo con withWebsocket l'array degli ID
  // restituito da .map() è un puntatore ad oggetto differente
  // che scatena un loop di refresh di telemetria rompendo il corretto
  // funzionamento dell'applicazione.
  const ids = useMemo(() => get(record, source, []).map(({ id }) => id), [
    record,
    source,
  ]);
  // Devo comunicare al socket che è necessario recuperare dati aggiornati
  // almeno sui sensori definiti tramite gli id elaborati da questa lista.
  useEffect(() => refreshData({ dispatch, target: "sensor", ids }), [
    ids,
    dispatch,
  ]);
  return (
    <ArrayField {...props} id="id" basePath="/sensor" addLabel={false}>
      <ResponsiveGrid
        type="table"
        xs={6}
        sm={6}
        md={4}
        lg={3}
        websocket={websocket}
        cardContent={<SensorCard websocket={websocket} />}
      >
        <ChipField source="code" />
        <TextField source="name" />
        <DateField source="modified" showTime />
        <SensorValueField websocket={websocket} />
        <EditButton />
      </ResponsiveGrid>
    </ArrayField>
  );
};
export default compose(withDataProvider, withWebsocket)(SensorListField);
