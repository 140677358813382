import { FormDataConsumer, Query } from "react-admin";
import {
  ImageOverlay,
  Map,
  Marker,
  Polygon,
  Polyline,
  Rectangle,
} from "react-leaflet";
import React, { useMemo } from "react";

import { GET_PATH } from "../../../addLocationFeature";
import Loader from "../../../components/Loader";
import LocationDeviceIcon from "./LocationDeviceIcon";
import LocationEmptyBox from "./LocationEmptyBox";
import LocationSensorIcon from "./LocationSensorIcon";
import { REDUX_FORM_NAME } from "ra-core";
import { change } from "redux-form";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { get } from "lodash";
import { grey } from "@material-ui/core/colors";
import useLocationEditorInput from "./useLocationEditorInput";
import { withStyles } from "@material-ui/core";

const LocationMap = compose(connect(() => ({})))(
  withStyles((theme) => ({
    map: {
      height: "calc(100vh - 330px)",
      borderRadius: theme.spacing.unit * 0.5,
    },
  }))(({ dispatch, classes, formData, data, loading }) => {
    const { location, center, sensors, overlay, hasData } =
      useLocationEditorInput({
        data,
        device: formData,
      });
    const visibleSensors = useMemo(
      () => sensors.filter((s) => s.visible),
      [sensors]
    );
    if (loading) {
      return <Loader loading center />;
    }
    if (!hasData) {
      return <LocationEmptyBox />;
    }
    const handleDeviceDragEnd = (event) => {
      const latLng = event.target.getLatLng();
      dispatch(change(REDUX_FORM_NAME, "lat", latLng.lat));
      dispatch(change(REDUX_FORM_NAME, "lng", latLng.lng));
    };
    const handleSensorDragEnd = (sensor, event) => {
      const match = sensors.find((s) => s.id === sensor.id);
      const latLng = event.target.getLatLng();
      match.lat = latLng.lat;
      match.lng = latLng.lng;
      console.info("Updating sensors: ", sensors);
      dispatch(change(REDUX_FORM_NAME, "sensors", sensors));
    };

    return (
      <Map center={center} zoom={22} maxZoom={25} className={classes.map}>
        {get(location, "shape_type") === "rectangle" && (
          <Rectangle
            id={get(location, "id")}
            key={get(location, "id")}
            code={get(location, "code")}
            bounds={get(location, "coords")}
          />
        )}
        {get(location, "shape_type") === "polygon" && (
          <Polygon
            id={get(location, "id")}
            key={get(location, "id")}
            code={get(location, "code")}
            positions={get(location, "coords")}
          />
        )}

        <Marker
          position={
            get(formData, "lat", null) !== null &&
            get(formData, "lng", null) !== null
              ? [formData.lat, formData.lng]
              : [center.lat, center.lng]
          }
          icon={LocationDeviceIcon}
          draggable={true}
          onDragEnd={handleDeviceDragEnd}
          title={get(formData, "name")}
        />
        {visibleSensors.map((sensor, index) => (
          <Marker
            key={`${sensor.id}-marker`}
            position={
              sensor.lat && sensor.lng
                ? [sensor.lat, sensor.lng]
                : [center.lat, center.lng + (index + 1) * 0.00001]
            }
            icon={LocationSensorIcon(sensor)}
            onDragEnd={handleSensorDragEnd.bind(this, sensor)}
            draggable={true}
            title={sensor.name}
          />
        ))}
        {visibleSensors.map((sensor) => (
          <Polyline
            key={`${sensor.id}-polyline`}
            positions={sensor.coords}
            color={grey[900]}
          />
        ))}

        {overlay && (
          <ImageOverlay
            url={overlay.url}
            bounds={overlay.bounds}
            zIndex={100}
            opacity={0.5}
          />
        )}
      </Map>
    );
  })
);

const LocationEditorInput = (props) => {
  return (
    <FormDataConsumer {...props}>
      {({ formData }) => (
        <Query
          resource="location"
          type={GET_PATH}
          payload={{ id: get(formData, "location_id") }}
        >
          {({ data, loading }) => (
            <LocationMap
              formData={formData}
              loading={loading}
              data={data}
              {...props}
            />
          )}
        </Query>
      )}
    </FormDataConsumer>
  );
};
export default LocationEditorInput;
