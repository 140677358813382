import * as Icons from "@material-ui/icons";

import React from "react";
import { divIcon } from "leaflet";
import { grey } from "@material-ui/core/colors";
import { renderToString } from "react-dom/server";

const LocationSensorIcon = (sensor) => {
  const Icon = Icons[sensor.icon];
  return divIcon({
    className: `transparent`,
    html: renderToString(
      <div
        style={{
          width: 24,
          height: 24,
          backgroundColor: "white",
          paddingTop: 4,
          overflow: "hidden",
          borderRadius: 12,
          border: `2px solid ${grey[900]}`,
          textAlign: "center",
          marginTop: -9, // Il valore di default di leaflet è -6 a cui vado ad aggiungere questa ulteriore misura.
          marginLeft: -9,
        }}
      >
        <Icon style={{ color: grey[900], fontSize: 20 }} />
      </div>
    ),
  });
};
export default LocationSensorIcon;
