import { FormDataConsumer } from "react-admin";
import React from "react";
import { get } from "lodash";

const ChoropletInput = ({ input, ...props }) => (
  <FormDataConsumer {...props}>
    {({ formData }) =>
      get(formData, "enable_choroplet_map", 0) === 1 &&
      React.cloneElement(input, {
        ...props,
      })
    }
  </FormDataConsumer>
);
export default ChoropletInput;
