export default (filter = {}) => {
  let route = document.location.toString().split("#")[1];
  let queryString = new URLSearchParams(route.split("?")[1]);
  let qsFilter = JSON.parse(
    decodeURIComponent(queryString.get("filter")) || "{}"
  );
  let lsFilter = JSON.parse(localStorage.getItem("tvf") || "{}") || {};

  let out = {
    searchKeyword: "",
    expanded: [],
    checked: {},
    devices: [],
    sensors: [],
    locations: [],

    ...lsFilter,
    ...qsFilter,
    ...filter,
  };
  return out;
};
