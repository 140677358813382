import { DashboardIcon } from "../icons";
import customRoutes from "../customRoutes";
import mergeFilter from "./tree/mergeFilter";
import { useMemo } from "react";

const groupOrders = {
  dashboard: 1,
};
const isActive = (location, resource) => {
  return (
    location.pathname === `/${resource.name}` ||
    location.pathname === resource.path ||
    location.pathname.indexOf(`/${resource.name}?`) === 0 ||
    location.pathname.indexOf(`/${resource.name}/`) === 0
  );
};

const createResource = ({ resource, translate, location }) => {
  const mergeFilters = resource.options.mergeFilters === true;
  let to = `${resource.path || `/${resource.name}`}`;
  let filters = mergeFilter({});

  if (mergeFilters && Object.keys(filters).length > 0) {
    to =
      to.indexOf("?") !== -1
        ? `${to}&filter=${JSON.stringify(filters)}`
        : `${to}?filter=${JSON.stringify(filters)}`;
  }
  const resourceOuptut = {
    ...resource,
    to,
    label: resource.options.label
      ? translate(resource.options.label)
      : translate(`menu.items.${resource.name}`),
    isActive: isActive(location, resource),
    isActiveFn: () => isActive(location, resource),
  };
  return resourceOuptut;
};

const useMenu = ({
  translate,
  resources,
  user,
  hasDashboard,
  location,
  visibility,
}) => {
  const groups = useMemo(() => {
    let groups = (
      hasDashboard
        ? [
            {
              path: "/",
              name: "dashboard",
              icon: DashboardIcon,
              options: {
                group: "dashboard",
                accessible: true,
              },
              label: translate("menu.groups.dashboard"),
            },
          ]
        : []
    )
      .concat(resources.filter((r) => r.hasList))
      .concat(
        customRoutes
          .filter((cr) => cr.props.options.menu !== false)
          .map((cr) => ({
            path: cr.props.path,
            icon: cr.props.options.icon,
            options: cr.props.options,
            label: cr.props.options.label
              ? translate(cr.props.options.label)
              : translate(`menu.items.${cr.props.name}`),
          }))
      )
      .filter(({ options: { accessible }, ...resource }) => {
        if (accessible && user && user.permissions) {
          return typeof accessible === "function"
            ? accessible({ resource, user })
            : accessible === true;
        }
        return user && user.permissions
          ? user.permissions.indexOf(`${resource.name}/list`) !== -1
          : false;
      })
      .reduce((groups, resource) => {
        let groupName = resource.options ? resource.options.group : "";
        let group = groups.find((g) => g.name === groupName);
        if (group) {
          group.resources.push(
            createResource({ resource, translate, location })
          );
        } else {
          group = {
            name: groupName,
            label: `menu.groups.${groupName}`,
            order: groupOrders[groupName] || 1000,
            resources: [createResource({ resource, translate, location })],
            isOpen: visibility[groupName] || false,
          };
          groups.push(group);
        }
        return groups;
      }, []);

    groups.sort((a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0));
    return groups.map((group) => ({
      ...group,
      isOpen: group.isOpen || group.resources.some((r) => r.isActive),
    }));
  }, [translate, resources, user, hasDashboard, location, visibility]);

  return groups;
};

export default useMenu;
