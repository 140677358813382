import { CLOSED, ONGOING } from "../colors";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Fragment, useMemo } from "react";

import EventHelper from "../../helpers/EventHelper";
import HeaderTime from "./HeaderTime";
import PropTypes from "prop-types";
import classnames from "classnames";
import { get } from "lodash";
import { translate } from "react-admin";

const HeaderConfig = withStyles((theme) => ({
  root: {
    color: "white",
    "& *": {
      color: "white",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: -25,
    },
  },
  ongoing: {
    backgroundColor: ONGOING,
  },
  closed: {
    backgroundColor: CLOSED,
  },
}))(({ translate, classes, record, status }) => {
  const { minBound, maxBound, lastValue, avgValue } = useMemo(() => {
    const interval = EventHelper.getInterval(record);
    const stats = EventHelper.getStats(record);
    return {
      minBound: interval.min,
      maxBound: interval.max,
      avgValue: stats.avg,
      lastValue: stats.last,
    };
  }, [record]);

  return (
    <Fragment>
      <Card elevation={0} className={classnames(classes.root, classes[status])}>
        <CardContent>
          <Grid container direction="row">
            <Grid item lg={4} md={4} xs={4}>
              <Typography align="center">
                {translate("resources.event.fields.last_measurement")}
              </Typography>
            </Grid>
            <Grid item lg={4} md={4} xs={4}>
              <Typography align="center">
                {translate("resources.event.fields.avg_measurement")}
              </Typography>
            </Grid>
            <Grid item lg={4} md={4} xs={4}>
              <Typography align="center">
                {translate("resources.event.fields.thresholds")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item lg={4} md={4} xs={4}>
              <Typography align="center" variant="body2">
                {EventHelper.formatValue(record, lastValue)}
              </Typography>
            </Grid>
            <Grid item lg={4} md={4} xs={4}>
              <Typography align="center" variant="body2">
                {EventHelper.formatValue(record, avgValue)}
              </Typography>
            </Grid>
            <Grid item lg={4} md={4} xs={4}>
              <Typography align="center" variant="body2">
                {minBound}
                {" - "}
                {maxBound} {get(record, "sensor.unit")}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <HeaderTime record={record} status={status} />
    </Fragment>
  );
});

HeaderConfig.propTypes = {
  status: PropTypes.oneOf(["closed", "ongoing"]),
};
export default translate(HeaderConfig);
