import { CRUD_CREATE_FAILURE, CRUD_UPDATE_FAILURE } from "ra-core";
import { put, takeEvery } from "redux-saga/effects";

import { stopSubmit } from "redux-form";

const mapFieldErrors = (field, errors) => {
  const keys = Object.keys(errors);
  const messages = keys.filter((k) => typeof errors[k] === "string");
  if (typeof errors === "string") {
    return {
      [field]: errors,
    };
  }
  if (messages.length > 0) {
    return {
      [field]: messages.map((m) => errors[m]).join("\n"),
    };
  } else {
    const out = keys.reduce(
      (errorMap, key, idx) => ({
        ...errorMap,
        ...mapFieldErrors(key, errors[key]),
      }),
      {}
    );
    return {
      [field]: { 0: out },
    };
  }
};

export default function* errorSagas() {
  yield takeEvery(CRUD_CREATE_FAILURE, crudCreateFailure);
  yield takeEvery(CRUD_UPDATE_FAILURE, crudCreateFailure);
  yield takeEvery("PASSWORD_CHANGE_FAILURE", crudCreateFailure);
}

export function* crudCreateFailure(action) {
  if (!action.payload) {
    return;
  }
  if (!action.payload.json) {
    return;
  }
  var json = action.payload.json;
  var validationErrors = json.errors.reduce((errorsMap, error) => {
    return {
      ...errorsMap,
      ...mapFieldErrors(error.field, error.message),
    };
  }, {});
  yield put(stopSubmit("record-form", validationErrors));
}
