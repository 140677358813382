import { Button, Grid, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { showNotification, translate, withDataProvider } from "react-admin";

import { EAUTH_REQUEST } from "../addEAuthFeature";
import Login from "./Login";
import LoginForm from "./LoginForm";
import { VERSION } from "../config";
import { compose } from "recompose";
import { connect } from "react-redux";
import { push } from "react-router-redux";

const styles = (theme) =>
  createStyles({
    background: {
      backgroundColor: "GhostWhite",
    },
    version: {
      position: "fixed",
      bottom: theme.spacing.unit,
      right: theme.spacing.unit,
    },
    reset: {
      marginLeft: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2,
    },
  });

class CustomLoginPage extends React.Component {
  state = {
    processingEAuth: false,
  };

  componentDidMount() {
    const url = document.location.href.split("?");
    if (url.length > 1) {
      let queryString = new URLSearchParams(url[1]);
      let r = queryString.get("r");
      if (r && r !== null) {
        this.setState({ processingEAuth: true }, () => {
          const { dataProvider, showNotification, push } = this.props;
          dataProvider(EAUTH_REQUEST, "users", { r }).then(({ data }) => {
            if (data.error === true) {
              this.setState({ processingEAuth: false }, () => {
                showNotification(data.message, "warning");
              });
            } else {
              localStorage.setItem("token", data.token);
              localStorage.setItem("user", btoa(JSON.stringify(data)));
              push("/");
            }
          });
        });
      }
    }
  }
  render() {
    const { classes, translate } = this.props;
    const { processingEAuth } = this.state;
    return (
      <Fragment>
        {processingEAuth && (
          <Grid container alignItems="center">
            <Grid item lg={12}>
              <Typography variant="body2">
                Autenticazione in corso...
              </Typography>
            </Grid>
          </Grid>
        )}
        {!processingEAuth && (
          <Fragment>
            <Login
              backgroundImage=""
              className={classes.background}
              loginForm={
                <div>
                  <LoginForm />
                  <Button
                    color="primary"
                    size="small"
                    className={classes.reset}
                    href="#/reset-password"
                  >
                    {translate("ra.auth.forgot_password")} &rarr;
                  </Button>
                </div>
              }
            />
            <Typography className={classes.version} variant="caption">
              {VERSION}
            </Typography>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default compose(
  translate,
  withStyles(styles),
  withDataProvider,
  connect(null, { showNotification, push })
)(CustomLoginPage);
