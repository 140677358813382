import { Divider, Slide, Typography, withStyles } from "@material-ui/core";

import Bottom from "./Bottom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Loader from "../Loader";
import LocationNode from "./LocationNode";
import PropTypes from "prop-types";
import React from "react";
import SearchBox from "./SearchBox";
import TreeView from "./TreeView";
import compose from "recompose/compose";
import { styles } from "./styles";
import { translate } from "react-admin";

const Content = ({
  classes,
  hash,
  open,
  filter,
  searchKeyword,
  translate,
  expanded,
  loaded,
  loading,
  checked,
  onSearch,
  onNodeClick,
  onNodeCheckChange,
  onResetClick,
  roots,
  location,
}) => {
  return (
    <Slide direction="right" in={open} mountOnEnter unmountOnExit>
      <div>
        <SearchBox keyword={searchKeyword} onChange={onSearch} />
        {loading && <Loader loading />}
        {!loading && (!roots || roots.length === 0) && (
          <Typography
            className={classes.messageBox}
            variant="caption"
            component="p"
          >
            {translate("components.tree.messages.no_search_results")}
          </Typography>
        )}
        {!loading && roots && roots.length > 0 && (
          <TreeView
            key={hash}
            className={classes.root}
            defaultExpanded={expanded}
            defaultEndIcon={<ExpandMoreIcon />}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {roots.map((root) => (
              <LocationNode
                {...{
                  key: root.id,
                  node: root,
                  expanded,
                  loaded,
                  checked,
                  location,
                  onNodeClick,
                  onNodeCheckChange,
                }}
              />
            ))}
          </TreeView>
        )}
        {!loading && <Bottom filter={filter} onResetClick={onResetClick} />}
        {!loading && <Divider className={classes.divider} />}
      </div>
    </Slide>
  );
};
Content.propTypes = {
  hash: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  filter: PropTypes.any.isRequired,
  searchKeyword: PropTypes.string.isRequired,
};

export default compose(withStyles(styles), translate)(Content);
