import React, { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { FieldTitle } from "ra-core";
import Specs from "./BaseInput.specs";

const styles = theme => ({
  root: {
    minWidth: 250
  },
  rootEmpty: {
    marginTop: -1
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing.unit / 4
  },
  select: {
    height: "auto",
    overflow: "auto"
  }
});

export class SelectArrayInput extends Component {
  state = {
    value: this.props.value ? this.props.value.split(",") : []
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.prop.value !== this.props.value) {
      this.setState({
        value: nextProps.prop.value ? nextProps.prop.value.split(",") : []
      });
    }
  }

  handleChange = event => {
    this.props.onChange(event.target.value.join(","));
    this.setState({ value: event.target.value });
  };

  renderMenuItemOption = choice => get(choice, "value");

  renderMenuItem = (choice, idx) => (
    <MenuItem key={idx} value={get(choice, "value")}>
      {this.renderMenuItemOption(choice)}
    </MenuItem>
  );

  render() {
    const { classes, className, prop, error } = this.props;
    const { config, name, value, label } = prop;

    return (
      <FormControl
        margin="normal"
        className={classnames(classes.root, className)}
        style={value.length > 0 ? { marginTop: -1 } : {}}
      >
        <InputLabel htmlFor={name}>
          <FieldTitle label={label || name} source={name} />
        </InputLabel>
        <Select
          autoWidth
          multiple
          error={!!error}
          input={<Input id={name} />}
          value={this.state.value}
          renderValue={selected => (
            <div className={classes.chips}>
              {selected
                .map(item =>
                  config.find(choice => get(choice, "value") === item)
                )
                .map((item, idx) => (
                  <Chip
                    key={idx}
                    label={this.renderMenuItemOption(item)}
                    className={classes.chip}
                  />
                ))}
            </div>
          )}
          data-testid="selectArray"
          onChange={this.handleChange}
        >
          {config && config.map(this.renderMenuItem)}
        </Select>
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
}

SelectArrayInput.propTypes = { ...Specs, classes: PropTypes.object.isRequired };

const EnhancedSelectArrayInput = withStyles(styles)(SelectArrayInput);

export default EnhancedSelectArrayInput;
