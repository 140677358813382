import React, { useCallback } from "react";

import { SaveButton as RaSaveButton } from "react-admin";
import { change } from "../actions/change";
import { connect } from "react-redux";

const SaveButton = ({ change, ...props }) => {
  const { basePath, handleSubmit } = props;
  const handleSubmitWithRedirect = useCallback(() => {
    return handleSubmit((values) => {
      change(values, basePath);
    });
  }, [handleSubmit, basePath, change]);
  return (
    <RaSaveButton
      {...props}
      handleSubmitWithRedirect={handleSubmitWithRedirect}
    />
  );
};

export default connect(null, { change })(SaveButton);
