import {
  ArrayField,
  DateField,
  EditButton,
  FormInput,
  SingleFieldList,
  TextField,
} from "react-admin";
import { CardActions, CardContent, CardHeader } from "@material-ui/core";
import React, { Fragment } from "react";

import ChipField from "../components/fields/ChipField";
import { compose } from "recompose";
import withCardStyles from "../components/list/withCardStyles";

const Card = ({ classes, ...props }) => (
  <Fragment>
    <CardHeader
      className={classes.header}
      title={<TextField source="name" {...props} variant="title" />}
    />
    <CardContent className={classes.content}>
      <FormInput {...props} source="code" input={<TextField addLabel />} />
      <FormInput
        {...props}
        source="mac_address"
        input={<TextField addLabel />}
      />
      <FormInput
        {...props}
        source="modified"
        input={<DateField showTime addLabel />}
      />
      <FormInput
        {...props}
        source="groups"
        input={
          <ArrayField addLabel>
            <SingleFieldList linkType={false}>
              <ChipField source="group_code" />
            </SingleFieldList>
          </ArrayField>
        }
      />
    </CardContent>
    <CardActions className={classes.actions}>
      <EditButton {...props} />
    </CardActions>
  </Fragment>
);
export default compose(withCardStyles)(Card);
