import * as Icons from "@material-ui/icons";

import React, { useCallback, useMemo } from "react";

import { Marker } from "react-leaflet";
import classnames from "classnames";
import { divIcon } from "leaflet";
import moment from "moment";
import { red } from "@material-ui/core/colors";
import { renderToString } from "react-dom/server";
import { withStyles } from "@material-ui/core";

// import { get } from "lodash";

const Sensor = withStyles((theme) => ({
  icon: {
    overflow: "hidden",
    "& svg": {
      width: "100%",
      height: "100%",
    },
  },
  zoom_21: {
    width: 28,
    height: 28,
  },
  zoom_22: {
    width: 34,
    height: 34,
  },
  zoom_23: {
    width: 48,
    height: 48,
  },
  zoom_24: {
    width: 64,
    height: 64,
  },
  zoom_25: {
    width: 96,
    height: 96,
  },
  selected: {
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: "50%",
    "& svg": {
      color: theme.palette.primary.main,
    },
  },

  inAlarm: {
    backgroundColor: red[500],
  },
}))(({ classes, zoom, device, sensor, sensorId, onClick }) => {
  const handleClick = useCallback(
    () => onClick && onClick(sensor),
    [sensor, onClick]
  );
  const isSelected = parseInt(sensorId, 10) === parseInt(sensor.id, 10);
  const icon = useMemo(() => {
    const inAlarm = sensor.event && !sensor.event.closed;

    const Icon = sensor.sensor_type.type
      ? Icons[sensor.sensor_type.icon]
      : Icons.Whatshot;
    const latest = moment
      .duration(moment().diff(moment(sensor.latest)))
      .seconds();
    const isFresh = latest <= 5;
    // const unit = get(sensor, "unit", null);
    // const formatter = get(sensor, "sensor_type.formatter", null);

    // if (formatter) {
    //   /*eslint-disable no-eval */
    //   value = eval(formatter);
    // }

    // if (unit) {
    //   value = `${value} (${unit})`;
    // }

    const sIcon = divIcon({
      className: `transparent`,
      html: renderToString(
        <div
          className={classnames(classes.icon, classes[`zoom_${zoom}`], {
            [classes.inAlarm]: inAlarm,
            [classes.inAlarmWithPulse]: inAlarm && isFresh,
            [classes.selected]: isSelected,
          })}
        >
          <Icon />
        </div>
      ),
    });

    return sIcon;
  }, [sensor, classes, zoom, isSelected]);
  return (
    <div key={sensor.id}>
      <Marker
        position={[sensor.lat, sensor.lng]}
        icon={icon}
        onClick={handleClick}
        title={sensor.name}
      />
    </div>
  );
});
export default Sensor;
