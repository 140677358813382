import React, { Component } from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

import CardList from "./CardList";
import DatagridBody from "./DatagridBody";
import PropTypes from "prop-types";
import { Datagrid as RaDatagrid } from "react-admin";
import { compose } from "recompose";
import { grey } from "@material-ui/core/colors";

const borderRadius = 8;
const styles = (theme) =>
  createStyles({
    card: {
      borderCollapse: "separate",
      borderSpacing: "0 10px",
    },
    table: {
      borderRadius,
      "& thead tr th": {
        borderBottomColor: "transparent !important",
      },
      "& tbody": {
        borderRadius,
      },
      "& tbody tr:hover td": {
        backgroundColor: grey[100],
      },
      "& tbody tr:not(:first-child) td, & tbody tr:not(:last-child) td": {
        borderTop: 1,
        borderTopStyle: "solid",
        borderTopColor: "#d3d3d3",
        borderBottom: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "#d3d3d3",
      },
      "& tbody tr:last-child td": {
        borderBottomColor: "transparent !important",
      },
      "& tr:first-child td": {
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
      },
      "& tr:last-child td": {
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
      },
      "& tr:first-child td:first-child": {
        borderTopLeftRadius: borderRadius,
      },
      "& tr:first-child td:last-child": {
        borderTopRightRadius: borderRadius,
      },
      "& tr:last-child td:first-child": {
        borderBottomLeftRadius: borderRadius,
      },
      "& tr:last-child td:last-child": {
        borderBottomRightRadius: borderRadius,
      },
      "& td:first-child": {
        borderRadius: 0,
      },
      "& td:last-child": {
        borderRadius: 0,
      },
      "& td": {
        borderBottom: 0,
      },
    },
  });

class ResponsiveGrid extends Component {
  render() {
    const {
      type = "card",
      split = false,
      classes,
      rowClasses,
      rowBreakpoint = "md",
      cardContent = null,
      cardClasses = null,
      cardElevation = 1,
      websocket,
      width,
      hasList,
      hasEdit,
      hasShow,
      hasCreate,
      expandAsRow,
      xs = 12,
      sm = 6,
      md = 6,
      lg = 4,
      ...props
    } = this.props;
    const className = classes[type];
    // Se voglio visualizzare le card ed il breakpoint impostato è stato raggiunto,
    // provvedo a mostrare una interfaccia completamente diversa dalla solita.
    const isDownBreakpoint = isWidthDown(rowBreakpoint, width);
    const showCards =
      isDownBreakpoint && (type === "card" || cardContent !== null);
    if (showCards) {
      return (
        <CardList
          {...props}
          xs={xs}
          sm={sm}
          md={md}
          lg={lg}
          websocket={websocket}
          className={className}
          cardContent={cardContent}
          cardClasses={cardClasses}
          cardElevation={cardElevation}
        />
      );
    }
    return (
      <RaDatagrid
        {...props}
        className={className}
        body={
          <DatagridBody
            type={type}
            split={split}
            rowClasses={rowClasses}
            websocket={websocket}
            expandAsRow={expandAsRow}
          />
        }
      />
    );
  }
}

ResponsiveGrid.propTypes = {
  rowClasses: PropTypes.func,
  rowBreakpoint: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  cardClasses: PropTypes.func,
  cardContent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  cardElevation: PropTypes.number,
  websocket: PropTypes.shape({
    loading: PropTypes.bool,
    measurements: PropTypes.arrayOf(
      PropTypes.shape({
        sensor_id: PropTypes.number,
        device_id: PropTypes.number,
        formatter: PropTypes.string,
        unit: PropTypes.string,
        value: PropTypes.number,
        created: PropTypes.object,
      })
    ),
  }),
};

export default compose(withWidth(), withStyles(styles))(ResponsiveGrid);
