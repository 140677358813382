import { EditButton as RaEditButton } from "react-admin";
import React from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useResourcePermissions } from "../../usePermissions";

const EditButton = ({ label, icon, ...props }) => {
  const { edit } = useResourcePermissions(props);
  return (
    <RaEditButton
      {...props}
      icon={edit ? icon : <VisibilityIcon />}
      label={edit ? label : "ra.action.show"}
    />
  );
};

export default EditButton;
