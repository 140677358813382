import { SelectInput as RaSelectInput, ReferenceInput } from "react-admin";

import { REDUX_FORM_NAME } from "ra-core";
import React from "react";
import { change } from "redux-form";
import { compose } from "recompose";
import { connect } from "react-redux";

const SelectInput = compose(connect((_) => ({})))(
  ({ dispatch, choices, ...props }) => {
    const handleIconChange = (id) => {
      const sensorType = choices.find((c) => c.id === id);
      const sourceArgs = props.source.split(".");
      const source = sourceArgs
        .filter((_, i) => i < sourceArgs.length - 1)
        .concat(["icon"])
        .join(".");
      dispatch(change(REDUX_FORM_NAME, source, sensorType.icon));
    };
    return (
      <RaSelectInput
        {...props}
        choices={choices}
        input={{
          ...props.input,
          onChange: handleIconChange,
        }}
        optionText="name"
      />
    );
  }
);

const SensorTypeInput = (props) => {
  return (
    <ReferenceInput {...props} reference="sensor-type">
      <SelectInput optionText="name" />
    </ReferenceInput>
  );
};
export default SensorTypeInput;
