import React, { Component } from "react";
import Specs from "./BaseInput.specs";
import {
  TYPE_TEXT,
  TYPE_TEXTAREA,
  TYPE_DATE,
  TYPE_DATETIME,
  TYPE_NUMBER,
  TYPE_CHECKBOX,
  TYPE_SELECT,
  TYPE_SELECT_MULTI
} from "./Types";
import BooleanInput from "./BooleanInput";
import BaseInput from "./BaseInput";
import SelectArrayInput from "./SelectArrayInput";

export default class Field extends Component {
  render() {
    const { allowEmpty, ...props } = this.props;
    const { prop } = this.props;
    if (
      [
        TYPE_TEXT,
        TYPE_TEXTAREA,
        TYPE_DATE,
        TYPE_DATETIME,
        TYPE_NUMBER,
        TYPE_SELECT
      ].indexOf(prop.type) !== -1
    ) {
      return <BaseInput {...props} />;
    } else if (prop.type === TYPE_CHECKBOX) {
      return <BooleanInput {...props} />;
    } else if (prop.type === TYPE_SELECT_MULTI) {
      return <SelectArrayInput {...props} />;
    } else {
      return null;
    }
  }
}

Field.propTypes = { ...Specs };
