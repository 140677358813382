import { API_URL } from "./config";
import { fetchUtils } from "react-admin";
import { getBody } from "./authBody";

export const GET_ROOTS = "GET_EXPLORER_ROOTS";
export const GET_CHILDREN = "GET_EXPLORER_CHILDREN";
export const SEARCH = "SEARCH_IN_EXPLORER";

/**
 * Implements Explorer API.
 * @param {*} requestHandler
 */
const addExplorerFeature = (requestHandler) => (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  if (resource === "explorer" && type === GET_ROOTS) {
    const url = `${API_URL}/explorer/list`;
    return fetchJson(url, {
      method: "POST",
      body: getBody({
        devices: [],
        expanded: [],
        locations: [],
        sensors: [],
      }),
    }).then((response) => ({
      data: response.json,
    }));
  }
  if (resource === "explorer" && type === GET_CHILDREN) {
    const url = `${API_URL}/explorer/list`;
    return fetchJson(url, {
      method: "POST",
      body: getBody({
        id: params.id,
      }),
    }).then((response) => ({
      data: response.json,
    }));
  }
  if (resource === "explorer" && type === SEARCH) {
    const url = `${API_URL}/explorer/search`;
    return fetchJson(url, {
      method: "POST",
      body: getBody({
        keyword: params.keyword,
      }),
    }).then((response) => ({
      data: response.json,
    }));
  }
  return requestHandler(type, resource, params);
};

export default addExplorerFeature;
