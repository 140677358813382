import { CREATE, GET_ONE, UPDATE } from "ra-core";
import { getBody, getToken } from "./authBody";

import { API_URL } from "./config";
import { fetchUtils } from "react-admin";

export const GET_LAST_VALUES = "GET_LAST_VALUES";
export const GET_INFO = "GET_INFO";

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

/**
 * Implements Sensor API.
 * @param {*} requestHandler
 */
const addSensorFeature = (requestHandler) => (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  if (resource === "sensor" && [CREATE, UPDATE].indexOf(type) !== -1) {
    let data = {
      ...params.data,
      event_notifiers: Object.keys(params.data)
        .filter((k) => k.indexOf("event_notifiers_") === 0)
        .map((key) => ({ key, id: key.replace("event_notifiers_", "") }))
        .reduce(
          (evtNotifiers, monitor) =>
            evtNotifiers.concat(
              params.data[monitor.key].map((data) => ({
                ...data,
                monitor_id: monitor.id,
              }))
            ),
          []
        ),
    };

    params.data = data;
    let picture = params.data.image;
    if (picture && picture.rawFile instanceof File) {
      let pictures = [picture]; // Lasciamolo così, magari un giorno vorremo caricare più foto
      return Promise.all(pictures.map(convertFileToBase64))
        .then((base64Pictures) =>
          base64Pictures.map((picture64, index) => ({
            data: picture64,
            name: pictures[index].rawFile.name,
            size: pictures[index].rawFile.size,
            type: pictures[index].rawFile.type,
          }))
        )
        .then((transformedPictures) =>
          requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              image: transformedPictures[0],
            },
          })
        );
    }
  }
  if (resource === "sensor" && type === GET_ONE) {
    const url = `${API_URL}/${resource}/get/${params.id}/${getToken()}`;
    return fetchJson(url, {
      method: "POST",
      body: getBody(),
    }).then((response) => {
      const { json } = response;
      const { item } = json;

      item.event_notifiers
        .map((e) => e.monitor_id)
        .forEach((monitorId) => {
          item[`event_notifiers_${monitorId}`] = item.event_notifiers.filter(
            (e) => e.monitor_id === monitorId
          );
        });

      delete item.event_notifiers;
      return {
        data: item,
      };
    });
  }
  if (resource === "sensor" && type === GET_LAST_VALUES) {
    const url = `${API_URL}/sensor/last-values`;
    return fetchJson(url, {
      method: "POST",
      body: getBody(params),
    }).then((response) => ({
      data: response.json,
    }));
  }
  if (resource === "sensor" && type === GET_INFO) {
    let url = `${API_URL}/${resource}/info/${params.id}`;
    return fetchJson(url, {
      method: "POST",
      body: getBody(params),
    }).then((data) => ({
      data: {
        sensor: data.json.sensor,
        device: data.json.device,
        last: data.json.last,
        events: data.json.events,
      },
    }));
  }
  return requestHandler(type, resource, params);
};

export default addSensorFeature;
