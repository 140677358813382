import React from "react";
import { Typography } from "@material-ui/core";
import compose from "recompose/compose";
import { get } from "lodash";
import { translate } from "react-admin";

const ThingField = ({
  record,
  source,
  resource,
  translate,
  basePath,
  sortBy,
  addLabel,
  ...props
}) => {
  return (
    <Typography {...props}>
      {get(record, source) !== null
        ? get(record, source)
        : translate(`resources.${resource}.fields.all_label`)}
    </Typography>
  );
};

export default compose(translate)(ThingField);
