import { Icon } from "@material-ui/core";
import Indicator from "./Indicator";
import React from "react";
import fridge from "../../assets/fridge.svg";

const EquipmentsIcon = () => {
  return (
    <Icon>
      <img src={fridge} alt="fridge-icon" />
    </Icon>
  );
};
const EquipmentsIndicator = ({ getValue, ...props }) => (
  <Indicator
    color="#f44336"
    label="dashboard.equipments"
    value={getValue("Equipments")}
    icon={EquipmentsIcon}
  />
);

export default EquipmentsIndicator;
