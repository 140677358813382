import Link from "../../components/Link";
import React from "react";
import makeid from "../../utils/makeid";
import mergeFilter from "../../components/tree/mergeFilter";
import parseRoute from "../../components/tree/parseRoute";

const TreeLinkField = ({ record, source, children, dispatch, basePath }) => {
  const { filter } = parseRoute();
  if (record?.type === "floor") {
    return React.Children.map(children, (child) =>
      React.cloneElement(child, {
        record,
        source,
        dispatch,
      })
    );
  }
  return (
    <Link
      variant="body1"
      href={`#${basePath}?filter=${JSON.stringify(
        mergeFilter({
          location_id: record.id,
          expanded: (filter.expanded || []).concat(record.id),
          reload: makeid(8),
        })
      )}`}
    >
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          record,
          source,
          dispatch,
        })
      )}
    </Link>
  );
};

export default TreeLinkField;
