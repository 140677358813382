export const SET_SIDEBAR_VISIBILTY = "WEBSITE/SET_SIDEBAR_VISIBLITY";
export const TOGGLE_SIDEBAR = "WEBSITE/TOGGLE_SIDEBAR";
export const SHOW_LOADER = "WEBSITE/SHOW_LOADER";
export const HIDE_LOADER = "WEBSITE/HIDE_LOADER";

export const showLoader = () => ({ type: SHOW_LOADER });
export const hideLoader = () => ({ type: HIDE_LOADER });

export const setSidebarVisibility = visible => ({
  type: SET_SIDEBAR_VISIBILTY,
  visible
});

export const toggleSidebar = () => ({
  type: TOGGLE_SIDEBAR
});
