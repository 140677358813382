import { DateField, EditButton, TextField } from "react-admin";

import Card from "./Card";
import Filters from "./Filters";
import React from "react";
import ResponsiveGrid from "../components/list/ResponsiveGrid";
import ResponsiveList from "../components/list/ResponsiveList";

const List = (props) => (
  <ResponsiveList
    {...props}
    perPage={10}
    split
    type="table"
    filters={<Filters />}
  >
    <ResponsiveGrid rowBreakpoint="sm" cardContent={<Card />}>
      <TextField source="id" />
      <TextField source="code" variant="body2" />
      <TextField source="name" />
      <DateField source="modified" showTime />
      <EditButton />
    </ResponsiveGrid>
  </ResponsiveList>
);
export default List;
