import { Button, Grid, Typography } from "@material-ui/core";

import React from "react";
import background from "../../assets/background.jpg";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  heroUnit: {
    marginBottom: -(theme.spacing.unit * 10),
    backgroundColor: theme.palette.background.paper,
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: 450,
    [theme.breakpoints.down("sm")]: {
      height: 500,
    },
  },
  heroContent: {
    maxWidth: 800,
    margin: "0 auto",
    padding: `${theme.spacing.unit * 14}px 0 ${theme.spacing.unit * 6}px`,
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4,
    [theme.breakpoints.down("sm")]: {
      marginTop: -10,
    },
  },
});

const HeroPage = ({
  classes,
  title = null,
  message = null,
  buttons = [],
  children,
}) => {
  return (
    <main className={classes.root}>
      <div className={classes.heroUnit}>
        <div className={classes.heroContent}>
          {title && (
            <Typography variant="display1" color="textPrimary" align="center">
              {title}
            </Typography>
          )}
          {message && (
            <Typography
              variant="title"
              align="center"
              color="textSecondary"
              paragraph
            >
              {message}
            </Typography>
          )}
          {buttons.length > 0 && (
            <div className={classes.heroButtons}>
              <Grid container spacing={16} justify="center">
                {buttons.map((button, i) => (
                  <Grid key={i} item>
                    <Button
                      href={button.href}
                      variant={button.variant || "contained"}
                      color={button.color || "primary"}
                    >
                      {button.label}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
        </div>
      </div>
      {children}
    </main>
  );
};
export default compose(withStyles(styles))(HeroPage);
