import { CardContent, CardHeader } from "@material-ui/core";
import { FormInput, TextField } from "react-admin";
import React, { Fragment } from "react";

import { compose } from "recompose";
import withCardStyles from "../components/list/withCardStyles";

const Card = ({ classes, ...props }) => (
  <Fragment>
    <CardHeader
      className={classes.header}
      title={<TextField {...props} source="name" />}
    />
    <CardContent>
      <FormInput {...props} source="code" input={<TextField addLabel />} />
    </CardContent>
  </Fragment>
);
export default compose(withCardStyles)(Card);
