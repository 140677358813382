import { SimpleForm, TextInput } from "react-admin";

import PermissionsInput from "./inputs/PermissionsInput";
import React from "react";

const Form = (props) => (
  <SimpleForm {...props} redirect="list">
    <TextInput source="code" />
    <TextInput source="name" />
    <TextInput source="description" fullWidth />
    <PermissionsInput source="permissions" />
  </SimpleForm>
);
export default Form;
