import { SENSITIVE_LOCATION_PATHS, CHECKABLE_LOCATION_PATHS } from "./config";

const setLocation = ({ location }) => {
  const { pathname } = location;
  let url =
    SENSITIVE_LOCATION_PATHS.concat(CHECKABLE_LOCATION_PATHS).find(
      (path) =>
        location.pathname.indexOf(`${path}?`) === 0 ||
        location.pathname.indexOf(`${path}/`) === 0 ||
        location.pathname === path
    ) !== undefined
      ? `/${pathname.split("/")[1]}`
      : "/map-view";

  return url;
};
export default setLocation;
