import { API_URL } from "./config";
import { getBody } from "./authBody";

export const RESTART_CRONJOBS = "RESTART_CRONJOBS";
const addCronJobFeature = (requestHandler) => (type, resource, params) => {
  if (type === RESTART_CRONJOBS) {
    const requestURL = `${API_URL}/cron-job/restart`;
    const request = new Request(requestURL, {
      method: "POST",
      body: getBody(params),
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "applicatio/json",
      }),
    });
    return fetch(request)
      .then((response) => response.json())
      .then((data) => ({ data }));
  }
  return requestHandler(type, resource, params);
};

export default addCronJobFeature;
