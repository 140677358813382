import AutocompleteInput from "../../components/inputs/AutocompleteInput";
import React from "react";
import { ReferenceInput } from "react-admin";

const LocationInput = (props) => (
  <ReferenceInput
    {...props}
    reference="location"
    perPage={20}
    filter={{ autocomplete: true }}
    sort={{ field: "tree_path", order: "ASC" }}
  >
    <AutocompleteInput optionText="tree_name" />
  </ReferenceInput>
);

export default LocationInput;
