import {
  BooleanInput,
  DateField,
  FormTab,
  Edit as RaEdit,
  TabbedForm,
  TextField,
  TextInput,
} from "react-admin";

import CodeField from "./fields/CodeField";
import DevicesInput from "./inputs/DevicesInput";
import EventLogsField from "./fields/EventLogsField";
import LocationsInput from "./inputs/LocationsInput";
import MonitorsInput from "./inputs/MonitorsInput";
import React from "react";
import SensorsInput from "./inputs/SensorsInput";
import SettingsIcon from "@material-ui/icons/Settings";
import StatusInput from "./inputs/StatusInput";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { compose } from "recompose";
import withFormStyles from "../components/forms/withFormStyles";

const Edit = ({ classes, ...props }) => (
  <RaEdit {...props} undoable={false}>
    <TabbedForm redirect="list" className={classes.root}>
      <FormTab label="resources.sentinel.tabs.general" icon={<SettingsIcon />}>
        <CodeField addLabel source="code" variant="body2" />
        <StatusInput source="status" />
        <TextField source="mac_address" />
        <TextField source="local_ip_address" />
        <TextField source="public_ip_address" />
        <TextField source="client_version" />
        <DateField source="modified" />
        <TextInput source="name" fullWidth />
        <BooleanInput source="is_play_sound_enabled" fullWidth />
        <BooleanInput source="is_read_message_enabled" fullWidth />
        <LocationsInput source="locations" fullWidth />
        <DevicesInput source="devices" fullWidth />
        <SensorsInput source="sensors" fullWidth />
        <MonitorsInput source="monitors" fullWidth />
      </FormTab>
      <FormTab label="resources.sentinel.tabs.logs" icon={<VisibilityIcon />}>
        <EventLogsField fullWidth />
      </FormTab>
    </TabbedForm>
  </RaEdit>
);
export default compose(withFormStyles)(Edit);
