export function getBody(request = {}) {
  const token = localStorage.getItem("token");
  const lang = "it";
  let body = { token, lang };
  body = Object.assign(body, request);
  return JSON.stringify(body);
}

export function getToken() {
  return localStorage.getItem("token");
}
