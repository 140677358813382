import { HttpError, fetchUtils } from "react-admin";
import { getBody, getToken } from "./authBody";

import { API_URL } from "./config";
import makeid from "./utils/makeid";

export const IMPERSONATE = "impersonate";

const addUserFeature = (requestHandler) => (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  if (resource === "user-password-change" && type === "CREATE") {
    const url = `${API_URL}/user/change-password`;
    const item = params.data;
    return fetchJson(url, {
      method: "POST",
      body: getBody(item),
    }).then((response) => {
      if (response.json && !response.json.valid) {
        throw new HttpError("Unprocessable entity", 422, response);
      }
      if (response.error) {
        throw new HttpError(response.message);
      }
      return {
        data: { ...params.data, id: makeid(5) },
      };
    });
  }
  if (resource === "reset-password" && type === "CREATE") {
    const url = `${API_URL}/user/reset-password`;
    const item = params;
    return fetchJson(url, {
      method: "POST",
      body: getBody(item),
    }).then((response) => {
      return {
        data: { ...response.json, id: makeid(5) },
      };
    });
  }
  if (resource === "user-profile" && type === "GET_ONE") {
    const url = `${API_URL}/user/${getToken()}/profile`;
    return fetchJson(url, {
      method: "GET",
    }).then(({ json: { data } }) => {
      return {
        data: { ...data, id: params.id },
      };
    });
  }
  if (resource === "user-profile" && type === "UPDATE") {
    const url = `${API_URL}/user/${getToken()}/profile`;
    const item = params.data;
    const request = { item: JSON.stringify(item) };
    return fetchJson(url, {
      method: "POST",
      body: getBody(request),
    }).then((response) => {
      if (response.json && !response.json.valid) {
        throw new HttpError("Unprocessable entity", 422, response);
      }
      if (response.error) {
        throw new HttpError(response.message);
      }
      return { data: { ...params.data } };
    });
  }
  if (resource === "user" && type === IMPERSONATE) {
    const url = `${API_URL}/user/impersonate`;
    return fetchJson(url, {
      method: "POST",
      body: getBody(params),
    }).then(({ json }) => ({ data: json }));
  }

  return requestHandler(type, resource, params);
};

export default addUserFeature;
