import {
  AutocompleteArrayInput,
  ReferenceArrayInput,
  translate,
} from "react-admin";
import { FormHelperText, withStyles } from "@material-ui/core";
import React, { Fragment } from "react";

const format = (monitors) =>
  monitors ? monitors.map((d) => d.monitor_id.toString()) : [];
const parse = (monitors) =>
  monitors ? monitors.map((id) => ({ monitor_id: id.toString() })) : [];

const MonitorsInput = withStyles((theme) => ({
  helperText: {
    lineHeight: 1.35,
  },
}))(({ translate, classes, ...props }) => (
  <Fragment>
    <ReferenceArrayInput
      {...props}
      perPage={10}
      reference="monitor"
      sort={{ field: "name", order: "ASC" }}
      format={format}
      parse={parse}
    >
      <AutocompleteArrayInput optionText="name" fullWidth />
    </ReferenceArrayInput>
    <FormHelperText className={classes.helperText}>
      {translate("resources.sentinel.fields.monitors.help")}
    </FormHelperText>
  </Fragment>
));
export default translate(MonitorsInput);
