import {
  BulkActionsToolbar,
  BulkDeleteButton,
  ListActions as DefaultActions,
  Title,
  defaultTheme,
} from "react-admin";
import { ListController, getListControllerProps } from "ra-core";
import React, { Children, cloneElement } from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";

import { Card } from "@material-ui/core";
import ListToolbar from "./ListToolbar";
import Loader from "../Loader";
import Pagination from "./Pagination";
import PropTypes from "prop-types";
import RecordsCount from "../RecordsCount";
import classnames from "classnames";
import { isLoadingResource } from "../../dataProvider";

const DefaultBulkActionButtons = (props) => <BulkDeleteButton {...props} />;

export const styles = createStyles((theme) => ({
  root: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  card: {
    position: "relative",
    flex: "1 1 auto",
  },
  actions: {
    zIndex: 2,
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignSelf: "flex-start",
  },
  // noResults: { padding: theme.spacing.unit, backgroundColor: "yellow" },
  hiddenContent: {
    display: "none",
    visibility: "hidden",
  },
}));

const sanitizeRestProps = ({
  actions,
  basePath,
  bulkActions,
  changeListParams,
  children,
  classes,
  className,
  crudGetList,
  currentSort,
  data,
  defaultTitle,
  displayedFilters,
  exporter,
  filter,
  filterDefaultValues,
  filters,
  filterValues,
  hasCreate,
  hasEdit,
  hasList,
  hasShow,
  hideFilter,
  history,
  ids,
  isLoading,
  loadedOnce,
  locale,
  location,
  match,
  onSelect,
  onToggleItem,
  onUnselectItems,
  options,
  page,
  pagination,
  params,
  permissions,
  perPage,
  push,
  query,
  refresh,
  resource,
  selectedIds,
  setFilters,
  setPage,
  setPerPage,
  setSelectedIds,
  setSort,
  showFilter,
  sort,
  theme,
  title,
  toggleItem,
  total,
  translate,
  version,
  ...rest
}) => rest;

const ListView = withStyles(styles)(
  ({
    actions,
    aside,
    filter,
    filters,
    bulkActions,
    bulkActionButtons,
    pagination,
    children,
    className,
    classes,
    exporter,
    title,
    type,
    split,
    recordsCountStyle,
    recordsCountBoldWords = [],
    state,
    isLoading,
    ...rest
  }) => {
    const { resource, loadedOnce, defaultTitle, version } = rest;

    const Component = split || type === "card" ? "div" : Card;
    const ToolbarComponent = type === "card" || split ? Card : "div";
    const loading = (!loadedOnce || isLoading) && isLoadingResource(resource);
    const controllerProps = {
      ...getListControllerProps(rest),
      isLoading: loading,
      type,
    };
    return (
      <Component
        className={classnames("list-page", classes.root, className)}
        {...sanitizeRestProps(rest)}
      >
        <Title title={title} defaultTitle={defaultTitle} />
        <div
          className={classes.card}
          style={{
            margin: type === "card" ? 10 : 0,
            marginLeft: 0,
            marginRight: 0,
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          {bulkActions !== false &&
            bulkActionButtons !== false &&
            bulkActionButtons &&
            !bulkActions && (
              <BulkActionsToolbar {...controllerProps}>
                {bulkActionButtons}
              </BulkActionsToolbar>
            )}

          {(filters || actions) && (
            <ToolbarComponent style={{ padding: type === "card" ? 10 : 0 }}>
              <ListToolbar
                filters={filters}
                {...controllerProps}
                actions={actions}
                bulkActions={bulkActions}
                exporter={exporter}
                permanentFilter={filter}
              />
              <RecordsCount
                {...controllerProps}
                style={recordsCountStyle}
                boldWords={recordsCountBoldWords}
              />
            </ToolbarComponent>
          )}
          <div
            className={classnames(loading && classes.hiddenContent)}
            key={version}
          >
            {children &&
              cloneElement(Children.only(children), {
                ...controllerProps,
                split,
                hasBulkActions:
                  bulkActions !== false && bulkActionButtons !== false,
              })}
            {pagination && cloneElement(pagination, controllerProps)}
          </div>
          <Loader center loading={loading} />
        </div>
        {aside && cloneElement(aside, controllerProps)}
      </Component>
    );
  }
);
ListView.propTypes = {
  actions: PropTypes.element,
  aside: PropTypes.node,
  basePath: PropTypes.string,
  bulkActions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  bulkActionButtons: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  children: PropTypes.element,
  className: PropTypes.string,
  classes: PropTypes.object,
  currentSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  data: PropTypes.object,
  defaultTitle: PropTypes.string,
  displayedFilters: PropTypes.object,
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  filterDefaultValues: PropTypes.object,
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  hasCreate: PropTypes.bool,
  hideFilter: PropTypes.func,
  ids: PropTypes.array,
  isLoading: PropTypes.bool,
  onSelect: PropTypes.func,
  onToggleItem: PropTypes.func,
  onUnselectItems: PropTypes.func,
  page: PropTypes.number,
  pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  perPage: PropTypes.number,
  refresh: PropTypes.func,
  resource: PropTypes.string,
  selectedIds: PropTypes.array,
  setFilters: PropTypes.func,
  setPage: PropTypes.func,
  setPerPage: PropTypes.func,
  setSort: PropTypes.func,
  showFilter: PropTypes.func,
  title: PropTypes.any,
  total: PropTypes.number,
  translate: PropTypes.func,
  version: PropTypes.number,
};

ListView.defaultProps = {
  actions: <DefaultActions />,
  classes: {},
  bulkActionButtons: <DefaultBulkActionButtons />,
  pagination: <Pagination />,
};

/**
 * List page component
 *
 * The <List> component renders the list layout (title, buttons, filters, pagination),
 * and fetches the list of records from the REST API.
 * It then delegates the rendering of the list of records to its child component.
 * Usually, it's a <Datagrid>, responsible for displaying a table with one row for each post.
 *
 * In Redux terms, <List> is a connected component, and <Datagrid> is a dumb component.
 *
 * Props:
 *   - title
 *   - perPage
 *   - sort
 *   - filter (the permanent filter to apply to the query)
 *   - actions
 *   - filters (a React Element used to display the filter form)
 *   - pagination
 *
 * @example
 *     const PostFilter = (props) => (
 *         <Filter {...props}>
 *             <TextInput label="Search" source="q" alwaysOn />
 *             <TextInput label="Title" source="title" />
 *         </Filter>
 *     );
 *     export const PostList = (props) => (
 *         <List {...props}
 *             title="List of posts"
 *             sort={{ field: 'published_at' }}
 *             filter={{ is_published: true }}
 *             filters={<PostFilter />}
 *         >
 *             <Datagrid>
 *                 <TextField source="id" />
 *                 <TextField source="title" />
 *                 <EditButton />
 *             </Datagrid>
 *         </List>
 *     );
 */
const ResponsiveList = (props) => (
  <ListController {...props}>
    {(controllerProps) => <ListView {...props} {...controllerProps} />}
  </ListController>
);

ResponsiveList.propTypes = {
  // the props you can change
  actions: PropTypes.element,
  aside: PropTypes.node,
  bulkActions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  bulkActionButtons: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  filter: PropTypes.object,
  filterDefaultValues: PropTypes.object,
  filters: PropTypes.element,
  pagination: PropTypes.element,
  perPage: PropTypes.number.isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  title: PropTypes.any,
  // the props managed by react-admin
  authProvider: PropTypes.func,
  hasCreate: PropTypes.bool.isRequired,
  hasEdit: PropTypes.bool.isRequired,
  hasList: PropTypes.bool.isRequired,
  hasShow: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  path: PropTypes.string,
  resource: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  type: PropTypes.oneOf(["card", "table"]),
};

ResponsiveList.defaultProps = {
  filter: {},
  perPage: 10,
  theme: defaultTheme,
};

export default ResponsiveList;
