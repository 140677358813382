import DurationField from "./DurationField";
import { FormInput } from "react-admin";
import LastResultField from "./LastResultField";
import React from "react";
import { get } from "lodash";
import { withStyles } from "@material-ui/core";

const RunDetailsField = withStyles((theme) => ({
  root: {},
}))(({ classes, ...props }) => {
  const { record } = props;
  const id = get(record, "id", null);
  if (id === null) {
    return null;
  }
  return (
    <div className={classes.root}>
      <FormInput
        {...props}
        source="last_run"
        input={<DurationField showDate addLabel />}
      />
      <FormInput
        {...props}
        source="last_result"
        input={<LastResultField addLabel />}
      />
    </div>
  );
});
export default RunDetailsField;
