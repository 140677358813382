import {
  BooleanInput,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectInput,
} from "react-admin";

import React from "react";
import { compose } from "recompose";

const Filters = (props) => (
  <Filter {...props}>
    <SearchInput source="subkeyword" alwaysOn />
    <ReferenceInput source="device_type_id" reference="device-type">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <BooleanInput source="is_printed" />
    <BooleanInput source="is_assigned" />
  </Filter>
);
export default compose()(Filters);
