import React, { Fragment } from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import classnames from "classnames";
import compose from "recompose/compose";
import { translate } from "ra-core";

const styles = (theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing.unit * 4,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing.unit,
        marginTop: theme.spacing.unit,
      },
      opacity: 0.5,
    },
    title: {
      display: "flex",
      textAlign: "center",
      alignItems: "center",
      alignContent: "center",
    },
    icon: {
      width: "2em",
      height: "2em",
      marginRight: "0.5em",
    },
    panel: {
      marginTop: "1em",
    },
    panelDetails: {
      whiteSpace: "pre-wrap",
    },
    toolbar: {
      marginTop: "2em",
    },
    message: {
      width: "50%",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  });

const HelpBlock = ({
  classes,
  translate,
  Icon,
  title,
  message,
  staticContext,
  ...rest
}) => (
  <Fragment>
    <div className={classnames(classes.container)} {...rest}>
      <h1 className={classes.title} role="alert">
        <Icon className={classes.icon} />
        {translate(title)}
      </h1>
      <Typography component="p" variant="body1" className={classes.message}>
        {translate(message)}
      </Typography>
    </div>
  </Fragment>
);

HelpBlock.propTypes = {
  Icon: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default compose(withStyles(styles), translate)(HelpBlock);
