import { CHECKABLE_LOCATION_PATHS } from "./config";

const useCheckbox = ({ location }, fn = undefined) => {
  let use =
    CHECKABLE_LOCATION_PATHS.find(
      clp =>
        location.pathname === clp || location.pathname.indexOf(`${clp}?`) === 0
    ) !== undefined;
  return fn ? fn(use) : use;
};

export default useCheckbox;
