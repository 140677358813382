import linkURL from "../../../utils/linkURL";
import { useMemo } from "react";

const useData = (formData) =>
  useMemo(() => {
    const bounds = [
      [parseFloat(formData?.map_min_y), parseFloat(formData?.map_min_x)],
      [parseFloat(formData?.map_max_y), parseFloat(formData?.map_max_x)],
    ];
    const lat = (bounds[0][0] + bounds[1][0]) / 2;
    const lng = (bounds[0][1] + bounds[1][1]) / 2;
    const mapCenterLat = parseFloat(formData?.map_center_lat) || lat;
    const mapCenterLng = parseFloat(formData?.map_center_lng) || lng;
    const mapZoom = parseInt(formData?.map_zoom, 10) || 10;
    const png = formData?.png_file;
    const url = png?.rawFile ? png?.src : linkURL(png?.src);
    const locations = formData?.locations || [];
    const zoom = mapZoom || (formData?.png_file ? 18 : lat ? 18 : 10);

    return {
      center: [mapCenterLat || lat || 40, mapCenterLng || lng || 16],
      zoom,
      overlay: png ? { url, bounds } : null,
      locations,
      maxZoom: formData?.png_file ? 22 : 19,
    };
  }, [formData]);

export default useData;
