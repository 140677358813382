import moment from "moment";

export default (start, end, sampling) => {
  let s = moment(start, "YYYY-MM-DDTHH:mm:ss");
  let e = moment(end, "YYYY-MM-DDTHH:mm:ss");
  let ms = e.diff(s);
  let d = moment.duration(ms);
  let days = d.asDays();
  switch (sampling) {
    case "Daily":
      return "DD MMMM";
    case "Hourly":
      if (days > 1) {
        return "DD HH:mm";
      }
      return "HH:mm";
    case "Minutely":
      if (days > 1) {
        return "HH:mm";
      }
      return "HH:mm";
    case "":
    default:
      if (days > 1) {
        return "YYYY-MM-DD HH:mm:ss";
      }
      return "HH:mm:ss";
  }
};
