import { Card } from "@material-ui/core";
import DatagridRow from "./DatagridRow";
import { DatagridBody as RaDatagridBody } from "react-admin";
import React from "react";

const CardBody = ({ ...props }) => <Card {...props} component="tbody" />;

const DatagridBody = ({
  websocket,
  split,
  type = "card",
  rowClasses,
  expandAsRow,
  ...props
}) => {
  const additionalProps = {};
  if (split) {
    additionalProps.component = CardBody;
  }
  return (
    <RaDatagridBody
      {...props}
      {...additionalProps}
      row={
        <DatagridRow
          type={type}
          rowClasses={rowClasses}
          websocket={websocket}
          expandAsRow={expandAsRow}
        />
      }
    />
  );
};
export default DatagridBody;
