import React from "react";
import { SelectInput } from "react-admin";
import useIsAdmin from "../../useIsAdmin";
import usePermissions from "../../usePermissions";

const ROLES = [
  {
    id: "admin",
    name: "Admin",
  },
  {
    id: "user",
    name: "User",
  },
];

const RoleInput = (props) => {
  const permissions = usePermissions();
  const isAdmin = useIsAdmin({ permissions });
  if (!isAdmin) {
    return null;
  }
  return <SelectInput choices={ROLES} {...props} />;
};
export default RoleInput;
