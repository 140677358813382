import React, { Fragment } from "react";
import { createStyles, Typography, withStyles } from "@material-ui/core";
import { translate, Title } from "react-admin";
import { compose } from "recompose";
import classnames from "classnames";

import HelpIcon from "@material-ui/icons/LiveHelp";

const styles = theme =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        padding: "1em"
      },
      opacity: 0.5
    },
    title: {
      display: "flex",
      alignItems: "center"
    },
    icon: {
      width: "2em",
      height: "2em",
      marginRight: "0.5em"
    },
    panel: {
      marginTop: "1em"
    },
    panelDetails: {
      whiteSpace: "pre-wrap"
    },
    toolbar: {
      marginTop: "2em"
    },
    message: {
      width: "50%",
      textAlign: "justify",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    }
  });

const AnonymousWelcome = ({ classes, permissions, translate, ...props }) => (
  <Fragment>
    <Title title={"pages.dashboard.anonymous.name"} />
    <div className={classnames(classes.container)}>
      <h1 className={classes.title} role="alert">
        <HelpIcon className={classes.icon} />
        {translate("pages.dashboard.anonymous.title")}
      </h1>
      <Typography component="p" variant="body1" className={classes.message}>
        {translate("pages.dashboard.anonymous.message_1")}
      </Typography>
      <br />
      <Typography component="p" variant="body1" className={classes.message}>
        {translate("pages.dashboard.anonymous.message_2")}
      </Typography>
      {permissions && <br />}
      {permissions && (
        <Typography variant="display1">{permissions.username}</Typography>
      )}
    </div>
  </Fragment>
);
export default compose(withStyles(styles), translate)(AnonymousWelcome);
