import { Grid, withStyles } from "@material-ui/core";

import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";

const CardHeader = withStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "row",
    "& svg": {
      marginRight: theme.spacing.unit,
    },
  },
  left: {
    justifyContent: "flex-start",
  },
  center: {
    justifyContent: "center",
  },
  right: {
    justifyContent: "flex-end",
  },
}))(({ children, classes, justify = "center", xs = 4, sm = 4 }) => (
  <Grid item xs={xs} sm={sm}>
    <Grid
      container
      direction="row"
      className={classnames(classes.content, classes[justify])}
    >
      {children}
    </Grid>
  </Grid>
));
CardHeader.propTypes = {
  justify: PropTypes.oneOf(["center", "left", "right"]),
};

export default CardHeader;
