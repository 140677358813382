import { CardActions, CardContent, CardHeader } from "@material-ui/core";
import { DateField, EditButton, FormInput, TextField } from "react-admin";
import React, { Fragment } from "react";

import { compose } from "recompose";
import withCardStyles from "../components/list/withCardStyles";

const Card = ({ classes, ...props }) => (
  <Fragment>
    <CardHeader
      className={classes.header}
      title={<TextField {...props} source="name" />}
    />
    <CardContent className={classes.content}>
      <FormInput
        {...props}
        source="code"
        input={<TextField addLabel noWrap />}
      />
      <FormInput
        {...props}
        source="modified"
        input={<DateField addLabel showTime />}
      />
    </CardContent>
    <CardActions className={classes.actions}>
      <EditButton {...props} />
    </CardActions>
  </Fragment>
);

export default compose(withCardStyles)(Card);
