import { Paper, Typography, withStyles } from "@material-ui/core";

import EventHelper from "../../helpers/EventHelper";
import React from "react";
import { get } from "lodash";

const ChartPointTooltip = withStyles((theme) => ({
  tooltip: {
    padding: theme.spacing.unit,
  },
}))(({ classes, record, payload: p }) => {
  const { active, payload } = p;
  let measurement = get(payload, "[0].payload", null);

  if (active && measurement != null) {
    return (
      <Paper elevation={2} className={classes.tooltip}>
        <Typography variant="subheading">
          {get(measurement, "created")}
        </Typography>
        <Typography variant="body1">
          {EventHelper.formatValue(record, get(measurement, "value"))}
        </Typography>
      </Paper>
    );
  }
  return null;
});
export default ChartPointTooltip;
