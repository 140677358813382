import { BooleanInput, Filter, SearchInput, translate } from "react-admin";

import EquipmentEnumInput from "./inputs/EquipmentEnumInput";
import React from "react";
import TreeFilter from "../components/tree/TreeFilter";

const Filters = ({ translate, ...props }) => (
  <Filter {...props}>
    <SearchInput source="subkeyword" alwaysOn />
    <BooleanInput source="is_assigned" />
    <EquipmentEnumInput source="equipment_enum" />
    <TreeFilter source="tree" alwaysOn />
  </Filter>
);
export default translate(Filters);
