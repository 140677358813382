import {
  DisabledInput,
  FormTab,
  Edit as RaEdit,
  TabbedForm,
  TextInput,
  translate,
} from "react-admin";

import LogsField from "./fields/LogsField";
import { MonitorIcon } from "../icons";
import MonitorsInput from "./inputs/MonitorsInput";
import PictureInput from "./inputs/PictureInput";
import React from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import Toolbar from "./Toolbar";
import ValueConvertersInput from "../components/inputs/ValueConvertersInput";
import ValueFiltersInput from "../components/inputs/ValueFiltersInput";
import VisibilityIcon from "@material-ui/icons/Visibility";
import compose from "recompose/compose";
import withFormStyles from "../components/forms/withFormStyles";

const Edit = ({ classes, translate, ...props }) => (
  <RaEdit {...props} undoable={false} className={classes.root}>
    <TabbedForm redirect={false} toolbar={<Toolbar />}>
      <FormTab icon={<SettingsIcon />} label="resources.sensor.tabs.config">
        <TextInput source="access_token" fullWidth />
        <DisabledInput source="code" />
        <TextInput source="name" fullWidth />
        <ValueConvertersInput source="value_converters" fullWidth />
        <ValueFiltersInput source="value_filters" fullWidth />
        <PictureInput source="image" />
      </FormTab>
      <FormTab icon={<MonitorIcon />} label="resources.sensor.tabs.monitors">
        <MonitorsInput source="monitors" fullWidth />
      </FormTab>
      <FormTab icon={<VisibilityIcon />} label="resources.sensor.tabs.logs">
        <LogsField fullWidth />
      </FormTab>
    </TabbedForm>
  </RaEdit>
);

export default compose(withFormStyles, translate)(Edit);
