import React from "react";
import { SelectInput } from "react-admin";

const TypeInput = (props) => (
  <SelectInput
    {...props}
    choices={[
      {
        id: "ts",
        name: "resources.group-doc.fields.types.ts",
      },
      {
        id: "transport",
        name: "resources.group-doc.fields.types.transport",
      },
    ]}
  />
);
export default TypeInput;
