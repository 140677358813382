import Form from "./Form";
import { Edit as RaEdit } from "react-admin";
import React from "react";
import { compose } from "recompose";
import withFormStyles from "../components/forms/withFormStyles";

const Edit = ({ classes, ...props }) => (
  <RaEdit {...props} undoable={false} className={classes.root}>
    <Form />
  </RaEdit>
);

export default compose(withFormStyles)(Edit);
