import { BrowserRouter, Route, Switch } from "react-router-dom";

import AppBar from "./components/AppBar";
import Device from "./pages/Device";
import Display from "./pages/Display";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import { MuiThemeProvider } from "@material-ui/core";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import React from "react";
import Sensor from "./pages/Sensor";
import Sidebar from "./components/Sidebar";
import { TranslationProvider } from "react-admin";
import addDeviceFeature from "../addDeviceFeature";
import addSensorFeature from "../addSensorFeature";
import createAdminStore from "./createAdminStore";
import { createHashHistory } from "history";
import dataProvider from "../dataProvider";
import { isMobile } from "./mobileDetector";
import routes from "./routes";
import theme from "../theme";
import useI18nCatcher from "../useI18nCatcher";
import useLanguages from "../useLanguages";
import withContext from "recompose/withContext";

const authProvider = () => Promise.resolve();
let dp = addDeviceFeature(dataProvider);
dp = addSensorFeature(dp);

const history = createHashHistory();

const store = (i18nProvider) =>
  createAdminStore({
    authProvider,
    dataProvider: dp,
    i18nProvider,
    history,
  });

const isMobileRequest = isMobile();
const App = () => {
  useI18nCatcher();
  const { loading, languages } = useLanguages();
  if (loading) {
    return null;
  }
  const i18nProvider = (locale) => languages.it;

  return (
    <Provider store={store(i18nProvider)}>
      <TranslationProvider>
        <MuiThemeProvider theme={theme}>
          {!isMobileRequest && <AppBar />}
          {!isMobileRequest && <Sidebar routes={routes} />}
          <BrowserRouter>
            <Switch>
              {routes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  component={route.component}
                />
              ))}
              <Route path="/home/sensor" component={Sensor} />
              <Route path="/home/device" component={Device} />
              <Route path="/home/display/:action" component={Display} />
              <Route path="/home" component={Home} />
            </Switch>
          </BrowserRouter>
          {!isMobileRequest && <Footer />}
        </MuiThemeProvider>
      </TranslationProvider>
    </Provider>
  );
};

export default withContext(
  {
    authProvider: PropTypes.func,
  },
  () => ({ authProvider })
)(App);
