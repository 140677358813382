import Create from "./Create";
import Edit from "./Edit";
import List from "./List";
import { SensorEventUnarmedIcon } from "../icons";

export default {
  options: {
    group: "dashboard",
    accessible: ({ user }) =>
      user.permissions.indexOf("sensor-event-unarmed/list") !== -1,
  },
  icon: SensorEventUnarmedIcon,
  list: List,
  edit: Edit,
  create: Create,
};
