import { DateField, Pagination, TextField } from "react-admin";

import Actions from "./Actions";
import BulkActions from "./BulkActions";
import Card from "./Card";
import Filters from "./Filters";
import React from "react";
import ResponsiveGrid from "../components/list/ResponsiveGrid";
import ResponsiveList from "../components/list/ResponsiveList";

const PaginationConfig = (props) => (
  <Pagination rowsPerPageOptions={[8, 16, 32, 64, 128, 256]} {...props} />
);
const DeviceLabelList = (props) => (
  <ResponsiveList
    {...props}
    perPage={16}
    filters={<Filters />}
    pagination={<PaginationConfig />}
    bulkActionButtons={<BulkActions />}
    actions={<Actions />}
    type="table"
    split
  >
    <ResponsiveGrid rowBreakpoint="sm" cardContent={<Card />}>
      <TextField source="id" />
      <TextField source="code" variant="body2" />
      <TextField source="device.name" />
      <TextField source="device_type.name" />
      <DateField source="printed" showTime />
      <DateField source="assigned" showTime />
      <DateField source="created" showTime />
    </ResponsiveGrid>
  </ResponsiveList>
);

export default DeviceLabelList;
