import { CALLBACK_TYPE, Connection } from "../../websocket/Connection";
import { Grid, Typography } from "@material-ui/core";
import { Loading, translate, withDataProvider } from "react-admin";
import { green, red, yellow } from "@material-ui/core/colors";

import { API_URL } from "../../config";
import Avatar from "@material-ui/core/Avatar";
import ChartList from "../../measurements/ChartList";
import React from "react";
import WarningIcon from "@material-ui/icons/Warning";
import { compose } from "recompose";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    marginTop: 64,
  },
  header: {
    backgroundColor: "#F9F9F9",
    marginBottom: 20,
    padding: 15,
    height: 250,
  },
  icon: {
    width: 120,
    height: 120,
    borderRadius: 60,
    backgroundSize: "cover",
  },
  box: {
    backgroundColor: "#F9F9F9",
    padding: 15,
    marginTop: 0,
    margin: 15,
    marginBottom: 20,
    borderRadius: 6,
    minHeight: 500,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  infoBox: {
    height: 120,
    borderRadius: 6,
    padding: 15,
    marginTop: -70,
    margin: 15,
  },
  infoBoxTitle: {
    color: "white",
    fontSize: 13,
    fontWeight: "bold",
    textAlign: "center",
  },
  infoBoxText: {
    color: "white",
    fontSize: 21,
    fontWeight: "bold",
    marginTop: 10,
    textAlign: "center",
  },
});

class Sensor extends React.Component {
  connection = null;
  state = {
    loading: false,
    sensor: null,
    device: null,
    last: [],
    events: [],
  };

  componentDidMount() {
    const { dataProvider } = this.props;
    const id = this.getIdFromRequest();
    this.setState({ loading: true });
    dataProvider("GET_INFO", "sensor", { id }).then(({ data }) => {
      this.setState({
        loading: false,
        sensor: data.sensor,
        device: data.device,
        last: data.last,
        events: data.events,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.state.device) {
      this.initSocketConnection(prevProps);
    }
  }

  /**
   * Inizializza o reimposta la connessione al socket (nel caso venga premuto il tasto refresh).
   * @param {Object} prevProps Proprietà indicative del componente per definire la condizione eventuale di refresh.
   */
  initSocketConnection(prevProps) {
    const init = this.connection === null;
    if (init) {
      const permissions = {
        groups: this.state.device.groups.map((g) => ({
          group_id: g.group_id,
        })),
      };
      this.connection = new Connection(permissions);
      this.connection.setCallback(
        CALLBACK_TYPE.MEASUREMENTS,
        this.handleMeasurement.bind(this)
      );
      this.connection.setCallback(
        CALLBACK_TYPE.EVENTS,
        this.handleEvent.bind(this)
      );
      this.connection.open();
    } else if (this.props.version !== prevProps.version) {
      this.connection.reconnect();
    }
  }

  handleMeasurement(payload) {
    if (payload.sensor.id !== this.state.sensor.Sensor.id) {
      return;
    }

    let last = this.state.last;
    last.push({
      MeasurementHot: {
        id: Math.random(),
        sensor_id: payload.sensor.id,
        sensor_type_id: payload.sensor.type.id,
        created: moment().format("YYYY-MM-DD HH:mm:ss"),
        value: payload.data.value.toFixed(2),
      },
    });
    this.setState({ last });
  }

  handleEvent(payload) {
    if (this.state.sensor.Sensor.id !== payload.event.sensor_id) {
      return;
    }

    let events = this.state.events;
    let event = events.find((e) => e.Event.id === payload.event.id);
    if (!event) {
      event = {};
      events.push(event);
    }
    event = payload.event.Event;
    this.setState({ events });
  }

  getIdFromRequest() {
    const params = new URLSearchParams(document.URL.split("?")[1]);
    return params.has("id") ? params.get("id") : null;
  }

  formatValue(value) {
    value = parseFloat(value);
    if (isNaN(value)) {
      return "N/A";
    }

    value = value.toFixed(0);
    const unit = this.state.sensor.Sensor.unit;
    if (unit) {
      value = `${value} ${unit}`;
    }
    return value;
  }

  getLastMeasurement() {
    const last = this.state.last;
    if (last.length === 0) {
      return "N/A";
    }

    let value = last[last.length - 1]["MeasurementHot"]["value"];
    return this.formatValue(value);
  }

  getAvgMeasurement() {
    const last = this.state.last;
    if (!last || last.length === 0) {
      return "N/A";
    }

    let sum = 0;
    last.forEach((l) => (sum += parseFloat(l["MeasurementHot"]["value"])));
    return this.formatValue(sum / last.length);
  }

  getInterval() {
    const meta = this.state.sensor.meta;
    const min = meta.some(
      (m) => m.group === "sensic_monitor" && m.key === "min"
    )
      ? meta.find((m) => m.group === "sensic_monitor" && m.key === "min").value
      : "N/A";
    const max = meta.some(
      (m) => m.group === "sensic_monitor" && m.key === "max"
    )
      ? meta.find((m) => m.group === "sensic_monitor" && m.key === "max").value
      : "N/A";

    return { min, max };
  }

  getChartDomain(indicators) {
    const values = indicators.map((i) => parseFloat(i.value));
    const min = Math.min(...values);
    const max = Math.max(...values);
    return [min - 10, max + 10];
  }

  getChartDomainFromMeasurements(measurements) {
    if (!measurements || measurements.length === 0) {
      return null;
    }

    const values = measurements.map((i) => parseFloat(i.value));
    const min = Math.floor(Math.min(...values));
    const max = Math.floor(Math.max(...values));
    return [min - 10, max + 10];
  }

  getRoom() {
    const location = this.state.device.Device.location.split("/");
    return location[location.length - 1];
  }

  renderHeader() {
    const { classes } = this.props;
    const filepath = this.state.sensor.Sensor.filepath;
    const deviceCode = this.state.device.Device.code;
    const room = this.getRoom();
    const gridDim = filepath ? 7 : 12;
    return (
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        {filepath && (
          <Grid item lg={5} md={5} sm={5} xs={5}>
            <div
              className={classes.icon}
              style={{
                backgroundImage: `url(${API_URL}/file-system/anonymous/download/${filepath})`,
              }}
            ></div>
          </Grid>
        )}

        <Grid item lg={gridDim} md={gridDim} sm={gridDim} xs={gridDim}>
          <Typography className={classes.title}>
            {this.state.sensor.Sensor.name} ({this.state.sensor.Sensor.code})
          </Typography>
          <Typography>{deviceCode}</Typography>
          <Typography>{room}</Typography>
        </Grid>
      </Grid>
    );
  }

  renderInfoBox() {
    const { classes, translate } = this.props;
    return (
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={classes.infoBox}
        alignItems="center"
        justify="center"
        style={{
          backgroundColor:
            this.state.events.length === 0 ? green[500] : yellow[600],
        }}
      >
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Typography className={classes.infoBoxTitle}>
            {translate("website.sensor.last_measurement")}
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Typography className={classes.infoBoxTitle}>
            {translate("website.sensor.average_measurement")}
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Typography className={classes.infoBoxTitle}>
            {translate("website.sensor.interval")}
          </Typography>
        </Grid>

        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Typography className={classes.infoBoxText}>
            {this.getLastMeasurement()}
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Typography className={classes.infoBoxText}>
            {this.getAvgMeasurement()}
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <Typography className={classes.infoBoxText}>
            {this.getInterval().min} /{" "}
            {this.formatValue(this.getInterval().max)}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  renderChartBox() {
    const { translate } = this.props;
    let measurements = [];
    // Se ci sono misurazioni, faccio il mapping
    if (this.state.last.length > 0) {
      measurements = this.state.last.map((l) => ({
        sensor: { name: this.state.sensor.Sensor.name },
        sensor_type: { name: this.state.sensor.Sensor.type },
        device: { name: this.state.device.Device.code },
        ...l.MeasurementHot,
      }));
    }

    // Se non ci sono misurazioni, creo un set di dati fittizi per visualizzare il grafico vuoto
    else {
      for (let i = 0; i <= 30; i += 5) {
        measurements.push({
          sensor: { name: this.state.sensor.Sensor.name },
          sensor_type: { name: this.state.sensor.Sensor.type },
          device: { name: this.state.device.Device.code },
          value: null,
          created: moment().subtract(i, "minutes"),
        });
      }
    }

    return (
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <ChartList
            cardContentStyle={{ backgroundColor: "#F9F9F9", padding: 0 }}
            hash={`${this.state.sensor.Sensor.id}_${this.state.last.length}`}
            indicators={[
              { label: null, value: this.getInterval().min },
              { label: null, value: this.getInterval().max },
            ]}
            sampling={"Minutely"}
            measurements={measurements}
            containerStyle={{ display: "block" }}
            cardStyle={{ boxShadow: "none" }}
            showLegend={false}
            domain={
              this.getInterval().min !== "N/A" &&
              this.getInterval().max !== "N/A"
                ? this.getChartDomain([
                    { label: null, value: this.getInterval().min },
                    { label: null, value: this.getInterval().max },
                  ])
                : this.getChartDomainFromMeasurements(this.state.measurements)
            }
            aspect={1.6}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={6}>
          <Typography style={{ fontSize: 12 }}>
            <span style={{ color: red[500] }}>----------- </span>
            {translate("website.sensor.interval")}
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <Typography style={{ fontSize: 12, textAlign: "right" }}>
            <span>----------- </span>
            {translate("menu.items.measurement")}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  /**
   * Evento in corso: 10/10/2020 09:00 - In corso
   * Data di chiusura uguale alla data di apertura: 10/10/2020 09:00 - 12:00
   * Data di chiusura diversa dalla data di apertura: 10/10/2020 09:00 - 12/10/2010 12:00
   */
  renderEventsDate(event) {
    const { translate } = this.props;
    const startAt = moment(event.start_at, "YYYY-MM-DD HH:mm:ss");
    if (!event.closed) {
      return (
        <>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography>
              {startAt.format("DD/MM/YYYY")} {startAt.format("HH:mm")} -{" "}
              {translate("app.on_going")}
            </Typography>
          </Grid>
        </>
      );
    }

    const closedAt = moment(event.closed, "YYYY-MM-DD HH:mm:ss");
    if (startAt.isSame(closedAt, "day")) {
      return (
        <>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography>
              {startAt.format("DD/MM/YYYY")} {startAt.format("HH:mm")} -{" "}
              {closedAt.format("HH:mm")}
            </Typography>
          </Grid>
        </>
      );
    }

    return (
      <>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography>
            {startAt.format("DD/MM/YYYY")} - {startAt.format("HH:mm")}{" "}
            {closedAt.format("DD/MM/YYYY")} - {closedAt.format("HH:mm")}
          </Typography>
        </Grid>
      </>
    );
  }

  renderEvents() {
    const { translate, classes } = this.props;
    return (
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ marginTop: 15 }}
      >
        <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginBottom: 15 }}>
          <Typography className={classes.title}>
            {translate("app.last_events")}
          </Typography>
        </Grid>

        <Grid item lg={1} md={1} sm={1} xs={1}>
          <div
            style={{
              width: 16,
              height: 16,
              borderRadius: 8,
              backgroundColor: yellow[600],
            }}
          ></div>
        </Grid>
        <Grid item lg={5} md={5} sm={5} xs={5}>
          <Typography>{translate("website.event.ongoing")}</Typography>
        </Grid>

        <Grid item lg={1} md={1} sm={1} xs={1}>
          <div
            style={{
              width: 16,
              height: 16,
              borderRadius: 8,
              backgroundColor: green[500],
            }}
          ></div>
        </Grid>
        <Grid item lg={5} md={5} sm={5} xs={5}>
          <Typography>{translate("website.event.closed")}</Typography>
        </Grid>

        {this.state.events.length === 0 && (
          <Typography>
            {translate("pages.dashboard.last-events.message_1")}
          </Typography>
        )}

        {this.state.events.length > 0 &&
          this.state.events.map((event) => (
            <Grid
              key={event.Event.id}
              container
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              alignItems="center"
              style={{ marginTop: 15 }}
            >
              <Grid item lg={1} md={1} sm={2} xs={2}>
                <Avatar
                  style={{
                    backgroundColor: !event.Event.closed
                      ? yellow[600]
                      : green[500],
                  }}
                >
                  <WarningIcon />
                </Avatar>
              </Grid>

              <Grid container item lg={11} md={11} sm={10} xs={10}>
                {this.renderEventsDate(event.Event)}

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography>{event.Event.notes}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Grid>
    );
  }

  render() {
    const { classes, translate } = this.props;
    return (
      <Grid container className={classes.root}>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.header}
          style={{
            height:
              this.state.sensor && this.state.sensor.Sensor.filepath
                ? 240
                : 160,
          }}
        >
          {this.state.sensor && this.renderHeader()}
        </Grid>

        {this.state.sensor && this.renderInfoBox()}

        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.box}
          justify="center"
        >
          {this.state.loading && <Loading />}

          {!this.state.loading && !this.state.sensor && (
            <Typography>{translate("website.device.not_available")}</Typography>
          )}
          {this.state.sensor && this.renderChartBox()}
          {this.state.sensor && this.renderEvents()}
        </Grid>
      </Grid>
    );
  }
}

export default compose(withStyles(styles), withDataProvider, translate)(Sensor);
