import { MenuItemLink, UserMenu as RaUserMenu, translate } from "react-admin";
import React, { useMemo } from "react";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LockIcon from "@material-ui/icons/Lock";
import PersonIcon from "@material-ui/icons/Person";
import SafeTag from "./components/SafeTag";
import { get } from "lodash";
import usePermissions from "./usePermissions";

const UserMenu = ({ translate, ...props }) => {
  const permissions = usePermissions();
  const isLocal = useMemo(() => get(permissions, "auth_type"), [permissions]);
  const isImpersonating = localStorage.getItem("impersonating") === "1";
  const handleStopImpersonating = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const originalToken = localStorage.getItem("originalToken");
    const originalUser = localStorage.getItem("originalUser");

    localStorage.setItem("token", originalToken);
    localStorage.setItem("user", originalUser);

    localStorage.removeItem("originalToken");
    localStorage.removeItem("originalUser");
    localStorage.removeItem("impersonating");
    document.location.href = "#/user";
    document.location.reload();
  };
  return (
    <RaUserMenu {...props}>
      <MenuItemLink
        to={`/user-profile/me`}
        primaryText={translate("menu.items.profile")}
        leftIcon={<PersonIcon />}
      />

      {isLocal && (
        <MenuItemLink
          to={`/user-password-change/create`}
          primaryText={translate("menu.items.password-change")}
          leftIcon={<LockIcon />}
        />
      )}
      {isImpersonating && (
        <MenuItemLink
          to="/users"
          primaryText={
            <span onClick={handleStopImpersonating}>
              {translate("menu.items.stop_impersonating")}
            </span>
          }
          leftIcon={
            <SafeTag>
              {" "}
              <ExitToAppIcon onClick={handleStopImpersonating} />
            </SafeTag>
          }
        />
      )}
    </RaUserMenu>
  );
};

export default translate(UserMenu);
