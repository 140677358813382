import { BooleanInput, FormTab, TabbedForm, TextInput } from "react-admin";

import CodeEditorInput from "../components/inputs/CodeEditorInput";
import GroupsInput from "./inputs/GroupsInput";
import LogsField from "./fields/LogsField";
import React from "react";
import RunDetailsField from "./fields/RunDetailsField";
import SettingsIcon from "@material-ui/icons/Settings";
import Toolbar from "./Toolbar";
import VisibilityIcon from "@material-ui/icons/Visibility";

const Form = (props) => (
  <TabbedForm {...props} redirect="list" toolbar={<Toolbar />}>
    <FormTab label="resources.cron-job.tabs.config" icon={<SettingsIcon />}>
      <RunDetailsField />
      <BooleanInput source="is_enabled" />
      <TextInput source="name" />
      <TextInput source="description" multiline fullWidth />
      <BooleanInput source="has_notification_groups" />
      <GroupsInput source="groups" fullWidth />
      <TextInput source="cron_expression" />
      <CodeEditorInput source="task_code" toolbar={true} />
    </FormTab>
    <FormTab label="resources.cron-job.tabs.logs" icon={<VisibilityIcon />}>
      <LogsField fullWidth />
    </FormTab>
  </TabbedForm>
);
export default Form;
