import React from "react";

import Form from "./Form";

import { Create as RaCreate } from "react-admin";

const Create = (props) => (
  <RaCreate {...props}>
    <Form location={props.location} />
  </RaCreate>
);
export default Create;
