import { withStyles } from "@material-ui/core";

const withFiltersStyles = withStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 180,
    },
  },
}));
export default withFiltersStyles;
