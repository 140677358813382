import { API_URL } from "./config";
import { fetchUtils } from "react-admin";
import { getBody } from "./authBody";

export const LOAD = "LOAD_MEASUREMENTS";
export const GET_LAST_MEASUREMENTS = "GET_LAST_MEASUREMENTS";

/**
 * Implements Measurement API.
 * @param {*} requestHandler
 */
const addMeasurementFeature = (requestHandler) => (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  if (resource === "measurement" && type === LOAD) {
    let url = `${API_URL}/${resource}/list`;
    return fetchJson(url, {
      method: "POST",
      body: getBody(params),
    }).then((response) => ({
      data: response.json.data,
    }));
  }
  if (resource === "measurement" && type === GET_LAST_MEASUREMENTS) {
    const url = `${API_URL}/measurement/last`;
    return fetchJson(url, {
      method: "POST",
      body: getBody(params),
    }).then((response) => ({
      data: response.json,
    }));
  }

  return requestHandler(type, resource, params);
};

export default addMeasurementFeature;
