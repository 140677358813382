import { LinearProgress, Typography, withStyles } from "@material-ui/core";
import React, { useLayoutEffect, useMemo, useRef, useState } from "react";

import PropTypes from "prop-types";
import classnames from "classnames";
import { get } from "lodash";
import { grey } from "@material-ui/core/colors";
import moment from "moment";

const PULSE_DURATION = 6; // Secondi
const SensorValueField = withStyles((theme) => ({
  root: {
    minWidth: 100,
    "& span": {
      color: grey[900],
    },
  },
  pulse: {
    animation: `valueChanged ${PULSE_DURATION}s infinite`,
  },
  "@keyframes valueChanged": {
    "0%": {
      textShadow: "none",
    },
    "50%": {
      textShadow: `2px 2px 3px ${grey[600]}`,
    },
    "100%": {
      textShadow: "none",
    },
  },
}))(({ classes, websocket: { measurements, loading }, record }) => {
  const ref = useRef(false);
  const [pulse, setPulse] = useState(false);
  const { value, caption, modified } = useMemo(() => {
    const id = parseInt(record.id, 10);
    const last = measurements.find((m) => m.sensor_id === id);
    const modified = moment(get(last, "created"));
    let value = get(last, "value", "NA");
    // console.info(value, "-", get(last, "created"), last);
    if (value !== "NA" && !isNaN(value)) {
      const formatter = get(last, "formatter");
      if (formatter) {
        /*eslint-disable no-eval */
        value = eval(formatter);
      }

      const unit = get(last, "unit");
      if (unit) {
        value = `${value} ${unit}`;
      }
    } else {
      value = "N.D.";
    }
    let caption = "never";
    if (last) {
      const now = moment();
      const duration = moment.duration(modified.diff(now));
      caption = duration.humanize(true);
    }
    return { value, caption, created: modified.format("YYYYMMDDHHmmss") };
  }, [record.id, measurements]);
  useLayoutEffect(() => {
    if (!ref.current) {
      if (value !== "N.D.") {
        ref.current = true;
      }
      return;
    }

    if (value !== "N.D.") {
      setPulse(true);
      setTimeout(() => setPulse(false), PULSE_DURATION * 1000);
    }
  }, [value, modified]);
  if (loading) {
    return (
      <div className={classes.root}>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <Typography
        component="span"
        className={classnames(pulse && classes.pulse)}
      >
        {value}
      </Typography>
      <Typography
        component="span"
        className={classnames(pulse && classes.pulse)}
        variant="caption"
      >
        {caption}
      </Typography>
    </div>
  );
});

SensorValueField.defaultProps = {
  useWebsocket: true,
};

SensorValueField.propTypes = {
  useWebsocket: PropTypes.bool.isRequired,
  record: PropTypes.shape({
    id: PropTypes.any.isRequired,
  }),
  websocket: PropTypes.any,
};

export default SensorValueField;
