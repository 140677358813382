import Actions from "./Actions";
import Card from "./Card";
import { DATE_RANGE_EXPR } from "../components/inputs/DateRangeInput";
import DateRangeField from "./fields/DateRangeField";
import DeviceField from "./fields/DeviceField";
import Filters from "./Filters";
import MonitorField from "./fields/MonitorField";
import React from "react";
import ResponsiveGrid from "../components/list/ResponsiveGrid";
import ResponsiveList from "../components/list/ResponsiveList";
import SensorField from "./fields/SensorField";
import { TextField } from "react-admin";
import ValueField from "./fields/ValueField";
import { get } from "lodash";
import { red } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core";

const rowStyles = (theme) => ({
  row: {
    "& td": {
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
    },
  },
  inAlaram: {
    borderLeftColor: red[500],
    borderLeftWidth: 5,
    borderLeftStyle: "solid",
    "& td:first-child": {
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
      borderLeftColor: red[500],
    },
    "& td:first-child span": {
      marginLeft: -4,
    },
    "& td": {
      backgroundColor: red[50],
    },
  },
});

const rowClasses = (record, classes) => [
  get(record, "event.count", 0) > 0 ? classes.inAlaram : null,
  classes.row,
];
const today = DATE_RANGE_EXPR.today();
const List = ({ classes, ...props }) => {
  return (
    <ResponsiveList
      {...props}
      bulkActionButtons={false}
      perPage={10}
      actions={<Actions />}
      filters={<Filters />}
      filterDefaultValues={{ ...today, view: "today" }}
      sort={{ field: "created", order: "DESC" }}
      type="card"
    >
      <ResponsiveGrid
        cardContent={<Card />}
        cardClasses={(record) => rowClasses(record, classes)}
        rowBreakpoint="sm"
        rowClasses={(record) => rowClasses(record, classes)}
      >
        <TextField source="location.tree_name" variant="subheading" />
        <DeviceField source="device.name" sortBy="device.name" />
        <SensorField source="sensor.name" sortBy="sensor.name" />
        <DateRangeField source="created" />
        <ValueField source="value" />
        <ValueField source="min_value" />
        <ValueField source="max_value" />
        <MonitorField source="monitor" />
      </ResponsiveGrid>
    </ResponsiveList>
  );
};

export default withStyles(rowStyles)(List);
