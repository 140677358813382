import React, { Fragment } from "react";

import classnames from "classnames";
import compose from "recompose/compose";
import Button from "@material-ui/core/Button";
import { withStyles, createStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Help";
import History from "@material-ui/icons/History";

import { translate } from "ra-core";
import { Title } from "react-admin";
import { Typography } from "@material-ui/core";

const styles = theme =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        padding: "1em"
      },
      opacity: 0.5
    },
    title: {
      display: "flex",
      alignItems: "center"
    },
    icon: {
      width: "2em",
      height: "2em",
      marginRight: "0.5em"
    },
    panel: {
      marginTop: "1em"
    },
    panelDetails: {
      whiteSpace: "pre-wrap"
    },
    toolbar: {
      marginTop: "2em"
    },
    message: {
      width: "50%",
      textAlign: "justify",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    }
  });

function goBack() {
  window.history.go(-1);
}

const RecognizeErrorView = ({
  match: {
    params: { code }
  },
  classes,
  translate,
  staticContext,
  ...rest
}) => (
  <Fragment>
    <Title title={"resources.device-label.errors.title"} />
    <div className={classnames(classes.container)} {...rest}>
      <h1 className={classes.title} role="alert">
        <ErrorIcon className={classes.icon} />
        {translate(`resources.device-label.errors.${code}.title`)}
      </h1>
      <Typography component="p" variant="body1" className={classes.message}>
        {translate(`resources.device-label.errors.${code}.message`)}
      </Typography>

      <div className={classes.toolbar}>
        <Button variant="raised" icon={<History />} onClick={goBack}>
          {translate("ra.action.back")}
        </Button>
      </div>
    </div>
  </Fragment>
);

export default compose(withStyles(styles), translate)(RecognizeErrorView);
