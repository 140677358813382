import { CLOSED, ONGOING } from "./colors";
import { DateField, TextField } from "react-admin";

import Card from "./card";
import { DATE_RANGE_EXPR } from "../components/inputs/DateRangeInput";
import DeviceField from "./fields/DeviceField";
import DurationField from "./fields/DurationField";
import Filters from "./Filters";
import InfoField from "./fields/InfoField";
import NotesField from "./fields/NotesField";
import React from "react";
import ResponsiveGrid from "../components/list/ResponsiveGrid";
import ResponsiveList from "../components/list/ResponsiveList";
import compose from "recompose/compose";
import useIsAdmin from "../useIsAdmin";
import { withStyles } from "@material-ui/core";

const all = DATE_RANGE_EXPR["all"]();

const rowStyles = (theme) => ({
  bordered: {
    borderLeftWidth: 5,
    borderLeftStyle: "solid",
    "& td:first-child": {
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    },
    "& td:first-child span": {
      marginLeft: -3,
    },
  },
  ongoing: {
    borderLeftColor: ONGOING,
    "& td:first-child": {
      borderLeftColor: ONGOING,
    },
  },
  closed: {
    borderLeftColor: CLOSED,
    "& td:first-child": {
      borderLeftColor: CLOSED,
    },
  },
});

const rowClasses = (record, classes) => {
  return [
    classes.bordered,
    record.closed !== null ? classes.closed : classes.ongoing,
  ];
};

const List = ({ classes, innerSidebarOpen, ...props }) => {
  const isAdmin = useIsAdmin(props);
  const additionalProps = {};
  if (!isAdmin) {
    additionalProps.bulkActionButtons = false;
  }
  return (
    <ResponsiveList
      {...props}
      {...additionalProps}
      filters={<Filters />}
      filterDefaultValues={{
        ...all,
        view: "all",
        is_ongoing: true,
        is_closed: false,
      }}
      perPage={10}
      sort={{ field: "end_at", order: "DESC" }}
      type="card"
    >
      <ResponsiveGrid
        rowClick="show"
        rowClasses={(record) => rowClasses(record, classes)}
        rowBreakpoint="md"
        cardContent={<Card />}
        cardClasses={(record) => rowClasses(record, classes)}
      >
        <TextField source="id" />
        <InfoField source="location" sortBy="location.tree_name" />
        <DeviceField source="device" sortBy="device.code" />
        <NotesField source="notes" />
        <DateField source="start_at" showTime={true} />
        <DateField source="closed" showTime={true} />
        <DurationField source="duration" variant="body2" />
      </ResponsiveGrid>
    </ResponsiveList>
  );
};
export default compose(withStyles(rowStyles))(List);
