import {
  TOGGLE_INNER_SIDEBAR,
  TOGGLE_MAIN_PADDING,
  TOGGLE_RIGHT_SIDEBAR,
} from "./actions";

const reducer = (
  state = {
    mainPadding: true,
    rightSidebarOpen: false,
    innerSidebarOpen: false,
    innerSidebarBreakpoint: "xs",
  },
  { type, payload }
) => {
  switch (type) {
    case TOGGLE_RIGHT_SIDEBAR:
      return {
        ...state,
        rightSidebarOpen: !state.rightSidebarOpen,
      };
    case TOGGLE_INNER_SIDEBAR:
      return {
        ...state,
        innerSidebarOpen:
          payload.visible !== undefined
            ? payload.visible
            : !state.innerSidebarOpen,
        innerSidebarBreakpoint: payload.mobileBreakpoint,
      };
    case TOGGLE_MAIN_PADDING:
      return {
        ...state,
        mainPadding:
          payload.visible !== undefined ? payload.visible : !state.mainPadding,
      };
    default:
      return state;
  }
};

export default reducer;
