import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FlagIcon from "@material-ui/icons/Flag";
import { changeLocale } from "react-admin";
import { compose } from "recompose";
import { connect } from "react-redux";

const options = ["it", "en"];

class LanguageSwitcher extends React.Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSelection = option => {
    localStorage.setItem("locale", option);
    this.props.changeLocale(option);
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={open ? "language-menu" : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <FlagIcon style={{ color: "white" }} />
        </IconButton>
        <Menu
          id="language-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
        >
          {options.map(option => (
            <MenuItem
              key={option}
              selected={option === "Pyxis"}
              onClick={this.handleSelection.bind(this, option)}
            >
              {option.toUpperCase()}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

export default compose(connect(null, { changeLocale }))(LanguageSwitcher);
