export default (measurements) => {
  return measurements.reduce(
    (types, m) =>
      types.map((t) => t.name).indexOf(m.sensor_type.name) === -1
        ? types.concat([
            {
              name: m.sensor_type.name,
              unit: m.sensor_type.unit,
              formatter: m.sensor_type.formatter,
            },
          ])
        : types,
    []
  );
};
