import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  withStyles,
} from "@material-ui/core";
import { green, orange } from "@material-ui/core/colors";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React from "react";
import WarningIcon from "@material-ui/icons/Warning";
import { compose } from "recompose";
import { translate } from "react-admin";

const EventList = withStyles((theme) => ({
  title: {
    padding: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit,
  },
  noEvents: {
    marginTop: theme.spacing.unit * 3,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  noEventsIcon: {
    fontSize: 100,
    color: green[500],
    display: "block",
    clear: "both",
    alignSelf: "center",
  },
}))(({ classes, translate, events, sensor = false }) => {
  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="subheading">
        {translate("app.last_events")}
      </Typography>
      <Divider />
      {events.length === 0 && (
        <Typography className={classes.noEvents} variant="subheading">
          <CheckCircleIcon className={classes.noEventsIcon} />
          {translate("app.no_events")}
        </Typography>
      )}
      <List>
        {events
          .sort((a, b) => {
            return new Date(b.start_at) - new Date(a.start_at);
          })
          .map((event) => (
            <ListItem className={classes.listItem} key={event.id}>
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundColor: !event.closed ? orange[500] : green[500],
                  }}
                >
                  <WarningIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `${event.start_at} - ${
                        event.closed ||
                        "<b>" + translate("app.on_going") + "</b>"
                      }`,
                    }}
                  />
                }
                secondary={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: sensor
                        ? event.notes
                        : `<b>${event.sensor.name}</b> - ${event.notes}`,
                    }}
                  />
                }
              />
            </ListItem>
          ))}
      </List>
    </div>
  );
});
export default compose(translate)(EventList);
