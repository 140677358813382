import React, { useEffect } from "react";

import BaseResponsiveGrid from "./ResponsiveGrid";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { refreshData } from "../../websocket/actions";
import withWebsocket from "../../websocket/withWebsocket";

const ResponsiveGridWithWebsocket = ({
  dispatch,
  websocket,
  target = "sensor",
  ...props
}) => {
  const { ids } = props;
  useEffect(() => refreshData({ dispatch, target, ids }), [
    ids,
    target,
    dispatch,
  ]);
  return <BaseResponsiveGrid {...props} websocket={websocket} />;
};

ResponsiveGridWithWebsocket.propTypes = {
  target: PropTypes.oneOf(["sensor", "device"]),
};

export default compose(withWebsocket)(ResponsiveGridWithWebsocket);
