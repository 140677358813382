import {
  SET_SIDEBAR_VISIBILTY,
  TOGGLE_SIDEBAR,
  SHOW_LOADER,
  HIDE_LOADER
} from "../actions/app";

const appReducer = (
  state = {
    path: null,
    loading: false,
    sidebarOpen: false,
    loadingRequests: 0
  },
  action
) => {
  switch (action.type) {
    case SHOW_LOADER:
      return {
        ...state,
        loading: state.loadingRequests + 1 > 0,
        loadingRequests: state.loadingRequests + 1
      };
    case HIDE_LOADER:
      return {
        ...state,
        loading: state.loadingRequests - 1 > 0,
        loadingRequests: state.loadingRequests - 1
      };
    case SET_SIDEBAR_VISIBILTY:
      return {
        ...state,
        sidebarOpen: action.visible
      };
    case TOGGLE_SIDEBAR: {
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen
      };
    }
    default: {
      return state;
    }
  }
};

export default appReducer;
