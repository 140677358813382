import { useEffect, useState } from "react";

import { API_URL } from "./config";
import englishMessages from "ra-language-english";
import italianMessages from "ra-language-italian";

const baseMap = {
  it: italianMessages,
  en: englishMessages,
};
const useLanguages = () => {
  const [data, setData] = useState({ loading: true, languages: null });
  useEffect(() => {
    let headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
    fetch(`${API_URL}/language/load`, { headers })
      .then((response) => response.json())
      .then(({ data }) =>
        setData({
          loading: false,
          languages: Object.keys(data).reduce(
            (dict, languageCode) => ({
              ...dict,
              [languageCode]: {
                ...data[languageCode],
                ...baseMap[languageCode],
              },
            }),
            {}
          ),
        })
      );
  }, []);

  return data;
};

export default useLanguages;
