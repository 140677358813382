import {
  AutocompleteInput as RaAutocompleteInput,
  translate,
} from "react-admin";
import React, { useMemo } from "react";

import { get } from "lodash";

const AutocompleteInput = ({ translate, allowEmpty = false, ...props }) => {
  const options = get(props, "choices", []);
  const choices = useMemo(
    () =>
      allowEmpty
        ? [
            {
              [get(props, "optionValue", "id")]: null,
              [get(props, "optionText", "name")]: translate("ra.choice.none"),
            },
          ].concat(options)
        : options,
    [options, allowEmpty, translate, props]
  );
  return <RaAutocompleteInput {...props} choices={choices} />;
};
export default translate(AutocompleteInput);
