import {
  CardActions,
  CreateButton,
  RefreshButton,
  translate,
} from "react-admin";

import ExportIpsButton from "./buttons/ExportIpsButton";
import React from "react";
import { compose } from "recompose";

const Actions = ({
  bulkActions,
  basePath,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  translate,
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    <CreateButton resource={resource} basePath={basePath} />
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}

    {translate("app.name") === "SensIC" && (
      <ExportIpsButton filter={filterValues} />
    )}

    <RefreshButton />
  </CardActions>
);
export default compose(translate)(Actions);
