import { useMemo } from "react";

const useFilterValues = ({ ...props }) => {
  if (!props.location) {
    throw new Error("Cannot use useFilterValues without props.lcation");
  }
  const filter = useMemo(() => {
    const queryString = new URLSearchParams(props.location.search);
    const rawFilter = decodeURIComponent(queryString.get("filter") || "{}");

    const f = JSON.parse(rawFilter) || {};
    return f;
  }, [props.location.search]);
  return filter;
};
export default useFilterValues;
