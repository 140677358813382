import * as Icons from "@material-ui/icons";

import React, { Fragment, useMemo } from "react";

import { SelectInput } from "react-admin";
import { compose } from "recompose";
import { connect } from "react-redux";

const RenderOptionText = ({ record }) => {
  const Icon = Icons[record.name];
  return (
    <Fragment>
      <span>
        {record.name}
        <Icon />
      </span>
    </Fragment>
  );
};
const IconInput = ({ record, source, dispatch, ...props }) => {
  const icons = useMemo(
    () =>
      Object.keys(Icons).map((i) => ({
        id: i,
        name: i,
        icon: i,
      })),
    []
  );

  return (
    <SelectInput
      {...props}
      record={record}
      source={source}
      choices={icons}
      optionText={<RenderOptionText />}
    />
  );
};
export default compose(connect(() => ({})))(IconInput);
