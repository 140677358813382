import React from "react";
import PropTypes from "prop-types";
import onlyUpdateForKeys from "recompose/onlyUpdateForKeys";
import MuiButton from "@material-ui/core/Button";
import { withStyles, createStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

import compose from "recompose/compose";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { translate } from "ra-core";
import { Responsive, Button } from "react-admin";

const styles = theme =>
  createStyles({
    floating: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      margin: 0,
      top: "auto",
      right: 20,
      bottom: 60,
      left: "auto",
      position: "fixed",
      zIndex: 1000
    },
    floatingLink: {
      color: "inherit"
    }
  });

const addFilters = filterValues =>
  filterValues.location_id ? `?parent_id=${filterValues.location_id}` : "";

const CreateButton = ({
  basePath = "explorer",
  filterValues,
  className,
  classes = {},
  translate,
  label = "Add Location",
  resource = "containers",
  ...rest
}) => (
  <Responsive
    small={
      <MuiButton
        component={Link}
        variant="text"
        color="primary"
        className={classnames(classes.floating, className)}
        to={`${basePath}/create${addFilters(filterValues)}`}
        aria-label={label && translate(label)}
        {...rest}
      >
        <AddIcon />
      </MuiButton>
    }
    medium={
      <Button
        component={Link}
        to={`${basePath}/create${addFilters(filterValues)}`}
        className={className}
        label={label}
        {...rest}
      >
        <AddIcon />
      </Button>
    }
  />
);

CreateButton.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.string,
  size: PropTypes.string,
  translate: PropTypes.func.isRequired,
  icon: PropTypes.element
};

const enhance = compose(
  translate,
  onlyUpdateForKeys(["basePath", "label", "translate", "filterValues"]),
  withStyles(styles)
);

export default enhance(CreateButton);
