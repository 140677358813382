import Create from "./Create";
import { DeviceTypeIcon } from "../icons";
import Edit from "./Edit";
import List from "./List";

export default {
  options: {
    group: "types",
    accessible: ({ user }) =>
      user.role === "admin" &&
      user.permissions.indexOf("device-type/list") !== -1,
  },
  icon: DeviceTypeIcon,
  list: List,
  edit: Edit,
  create: Create,
};
