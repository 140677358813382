import {
  Card,
  CardContent,
  Chip,
  Divider,
  Typography,
  createStyles,
  withStyles,
} from "@material-ui/core";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React, { Component } from "react";

import ChartTooltip from "./ChartTooltip";
import clsx from "clsx";
import compose from "recompose/compose";
import createMeasurementSeries from "./createMeasurementSeries";
import createSensorTypes from "./createSensorTypes";
import createTicks from "./createTicks";
import { translate } from "ra-core";

const styles = (theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexWrap: "wrap",
    },
    divider: {
      marginBottom: theme.spacing.unit * 2,
    },
    chartLeft: {
      marginRight: theme.spacing.unit,
    },
    chartRight: {
      marginLeft: theme.spacing.unit,
    },
    chart: {
      marginTop: theme.spacing.unit * 2,
      borderRadius: theme.spacing.unit * 0.5,
      flex: "1 0 calc(50% - 18px)",
      [theme.breakpoints.down("sm")]: {
        widht: "100%",
        flex: "1 0 100%",
        margin: 0,
        marginTop: theme.spacing.unit * 2,
      },
    },
    tooltip: {
      padding: theme.spacing.unit,
    },
    serieLabel: {
      marginRight: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      "& span": {
        whiteSpace: "initial",
      },
    },
  });
class ChartList extends Component {
  state = {
    types: [],
    data: {},
    hash: null,
  };
  constructor(props) {
    super(props);
    this.state = this.createSeries(props);
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.hash !== this.props.hash ||
      nextProps.measurements !== this.props.measurements
    ) {
      this.setState(this.createSeries(nextProps));
    }
  }

  createSeries(props) {
    const { measurements, start, end, sampling, hash } = props;
    const types = createSensorTypes(measurements);
    const ticks = createTicks(measurements);
    const data = createMeasurementSeries({
      types,
      ticks,
      start,
      end,
      sampling,
      measurements,
    });
    return {
      types,
      data,
      hash,
    };
  }

  render() {
    const { classes, containerStyle, aspect } = this.props;
    const { types, data } = this.state;
    return (
      <div className={classes.container} style={containerStyle}>
        {types.map((type, i) =>
          this.renderMeasurementChart(
            type,
            data[type.name],
            i,
            types.length,
            aspect
          )
        )}
      </div>
    );
  }
  renderMeasurementChart(type, data, i, count, aspect = 3) {
    const {
      classes,
      cardStyle,
      cardContentStyle,
      showLegend,
      showTitle = true,
      indicators,
      domain,
      loading = false,
      search = true,
    } = this.props;
    if (loading || !search) {
      return null;
    }
    return (
      <Card
        style={cardStyle}
        key={`type-${type.name}`}
        className={clsx(
          classes.chart,
          i % 2 !== 0
            ? classes.chartRight
            : i < count - 1
            ? classes.chartLeft
            : null
        )}
      >
        <CardContent style={cardContentStyle}>
          {showTitle && (
            <Typography variant="subheading">
              {type.name} {type.unit}
            </Typography>
          )}
          {showTitle && <Divider className={classes.divider} />}
          {data.length === 0 && (
            <Typography variant="subheading">No data</Typography>
          )}
          {data.length > 0 && (
            <ResponsiveContainer width="100%" aspect={aspect}>
              <LineChart margin={{ top: 10, left: 0, right: 32, bottom: 10 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="time"
                  type="category"
                  allowDuplicatedCategory={false}
                />
                <YAxis dataKey="value" domain={domain || ["auto", "auto"]} />
                <Tooltip
                  content={
                    <ChartTooltip cardStyle={cardStyle} classes={classes} />
                  }
                />
                {indicators &&
                  indicators.map((i, index) => (
                    <ReferenceLine
                      key={index}
                      y={i.value}
                      stroke={"red"}
                      label={i.label || ""}
                    />
                  ))}
                {data.map((s) => (
                  <Line
                    key={s.name}
                    data={s.data}
                    dataKey="value"
                    connectNulls={true}
                    type="monotone"
                    stroke={s.color}
                    activeDot={{ r: 8 }}
                    fill={s.color}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          )}
          {showLegend !== false && data.length > 0 && (
            <div>
              {data.map((serie) => (
                <Chip
                  className={classes.serieLabel}
                  key={serie.name}
                  style={{ backgroundColor: serie.color, color: "white" }}
                  label={serie.name}
                />
              ))}
            </div>
          )}
        </CardContent>
      </Card>
    );
  }
}

export default compose(translate, withStyles(styles))(ChartList);
