/**
 * Define list of paths where single location is usable.
 */
export const SENSITIVE_LOCATION_PATHS = ["/location"];

/**
 * Define list of paths where checkboxes are required.
 */
export const CHECKABLE_LOCATION_PATHS = [
  "/event",
  "/device",
  "/sensor",
  "/sentinel",
  "/measurement",
  "/measurement-minutely",
  "/measurement-hourly",
  "/measurement-daily",
  "/socket-monitor",
];
/**
 * Define list of paths where devices are usable in the treeview.
 */
export const DEVICE_READY_PATHS = [
  "/map-view",
  "/sensor",
  "/event",
  "/socket-monitor",
  "/sentinel",
  "/measurement",
  "/measurement-minutely",
  "/measurement-hourly",
  "/measurement-daily",
];
/**
 * Define list of paths where sensors are usable in the treeview.
 */
export const SENSOR_READY_PATHS = [
  "/map-view",
  "/event",
  "/sentinel",
  "/socket-monitor",
  "/measurement",
  "/measurement-minutely",
  "/measurement-hourly",
  "/measurement-daily",
];
