import * as Icons from "@material-ui/icons";

import { CALLBACK_TYPE, Connection } from "../../websocket/Connection";
import { Grid, Typography } from "@material-ui/core";
import { Loading, translate, withDataProvider } from "react-admin";

import React from "react";
import { compose } from "recompose";
import fridge from "../../assets/fridge.png";
import { red } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";

const PULSE_DURATION = 1000;

const styles = (theme) => ({
  root: {
    marginTop: 64,
    padding: 15,
  },
  box: {
    backgroundColor: "#F9F9F9",
    padding: 15,
    marginBottom: 20,
    borderRadius: 6,
  },
  header: {
    padding: 15,
    height: 100,
    borderWidth: 1,
    borderColor: red[500],
    borderStyle: "dashed",
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  text: {
    fontSize: 15,
  },
  body: {},
  sensor: {
    minHeight: 50,
    marginTop: 20,
    borderColor: red[500],
    borderWidth: 1,
    borderStyle: "solid",
  },
  sensorIconContainer: {
    backgroundColor: red[500],
    width: 50,
    padding: 10,
  },
});

class Device extends React.Component {
  connection = null;
  state = {
    loading: false,
    device: null,
    last: [],
    lastSensorReceived: null,
  };

  componentDidMount() {
    const { dataProvider } = this.props;
    const id = this.getIdFromRequest();
    this.setState({ loading: true });
    dataProvider("GET_INFO", "device", { id }).then(({ data }) => {
      this.setState({
        loading: false,
        device: data.device,
        last: data.last,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.state.device) {
      this.initSocketConnection(prevProps);
    }
  }

  handleMeasurement(payload) {
    if (payload.device.id !== this.state.device.Device.id) {
      return;
    }

    let last = this.state.last;
    let sensor = this.state.last.find((l) => l.sensor_id === payload.sensor.id);
    if (!sensor) {
      sensor = {
        sensor_id: payload.sensor.id,
      };
      last.push(sensor);
    }
    sensor.value = payload.data.value;
    this.setState({ last, lastSensorReceived: sensor.sensor_id });

    setTimeout(() => {
      this.setState({ lastSensorReceived: null });
    }, PULSE_DURATION);
  }

  /**
   * Inizializza o reimposta la connessione al socket (nel caso venga premuto il tasto refresh).
   * @param {Object} prevProps Proprietà indicative del componente per definire la condizione eventuale di refresh.
   */
  initSocketConnection(prevProps) {
    const init = this.connection === null;
    if (init) {
      const permissions = {
        groups: this.state.device.groups.map((g) => ({
          group_id: g.group_id,
        })),
      };
      this.connection = new Connection(permissions);
      this.connection.setCallback(
        CALLBACK_TYPE.MEASUREMENTS,
        this.handleMeasurement.bind(this)
      );
      this.connection.open();
    } else if (this.props.version !== prevProps.version) {
      this.connection.reconnect();
    }
  }

  getIdFromRequest() {
    const params = new URLSearchParams(document.URL.split("?")[1]);
    return params.has("id") ? params.get("id") : null;
  }

  getValue(sensor) {
    const last = this.state.last.find((l) => l.sensor_id === sensor.id);
    const unit = sensor.unit;
    const formatter = sensor.formatter;
    if (!last || isNaN(last.value)) {
      return "N/A";
    }

    let value = last.value;
    if (formatter) {
      /*eslint-disable no-eval */
      value = eval(formatter);
    }

    if (unit) {
      value = `${value} ${unit}`;
    }

    return value;
  }

  getRoom() {
    const location = this.state.device.Device.location.split("/");
    return location[location.length - 1];
  }

  renderHeader() {
    const { classes } = this.props;
    return (
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={classes.header}
        alignItems="center"
      >
        <Grid item lg={9} md={9} sm={9} xs={9}>
          <Typography className={classes.title}>
            {this.state.device.Device.name}
          </Typography>
        </Grid>

        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Typography className={classes.text}>{this.getRoom()}</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography className={classes.text}>
            {this.state.device.Device.code}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  renderSensor(sensor) {
    const { classes, history } = this.props;
    const Icon = Icons[sensor.icon];
    return (
      <Grid
        key={sensor.id}
        onClick={() => history.push(`/home/sensor/#/?id=${sensor.id}`)}
        container
        direction="row"
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={classes.sensor}
      >
        <Grid
          container
          item
          lg={2}
          md={2}
          sm={2}
          xs={2}
          className={classes.sensorIconContainer}
          alignItems="center"
          justify="center"
        >
          {sensor.code.indexOf("pt") === -1 &&
            sensor.code.indexOf("digit") === -1 && (
              <Icon style={{ color: "white" }} />
            )}
          {(sensor.code.indexOf("pt") !== -1 ||
            sensor.code.indexOf("digit") !== -1) && (
            <img alt="icon" src={fridge} style={{ height: 64 }} />
          )}
        </Grid>

        <Grid
          container
          item
          lg={10}
          md={10}
          sm={10}
          xs={10}
          style={{ padding: 15 }}
        >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography
              className={
                this.state.lastSensorReceived === sensor.id
                  ? "pulse-bold"
                  : classes.text
              }
            >
              {sensor.name} ({sensor.code})
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.text} style={{ textAlign: "right" }}>
              {this.getValue(sensor)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { classes, translate } = this.props;
    return (
      <Grid container justify="center" className={classes.root}>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.box}
          justify="center"
        >
          {this.state.loading && <Loading />}
          {!this.state.loading && !this.state.device && (
            <Typography>{translate("website.device.not_available")}</Typography>
          )}
          {this.state.device && (
            <Grid
              container
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{
                marginBottom:
                  this.state.device.sensors.filter((s) => s.code !== s.name)
                    .length > 4
                    ? 0
                    : 460 -
                      100 *
                        this.state.device.sensors.filter(
                          (s) => s.code !== s.name
                        ).length,
              }}
            >
              {this.renderHeader()}

              {this.state.device.sensors
                .filter((s) => s.code !== s.name)
                .map((sensor) => this.renderSensor(sensor))}
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default compose(withStyles(styles), translate, withDataProvider)(Device);
