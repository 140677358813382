const deduce = (measurements) => {
  const devices = measurements.reduce((devices, measurement) => {
    let device = devices.find(
      (device) => device.id === parseInt(measurement.device.id, 10)
    );
    if (!device) {
      device = {
        ...measurement,
        ...measurement.device,
        id: parseInt(measurement.device.id, 10),
        location_id: parseInt(measurement.device.location_id, 10),
        lat: parseFloat(measurement.device.lat),
        lng: parseFloat(measurement.device.lng),
        sensors: measurement.device.sensors.map((sensor) => ({
          ...sensor,
          id: parseInt(sensor.id, 10),
          device_id: parseInt(measurement.device.id, 10),
          device_name: measurement.device.name,
          location_id: parseInt(sensor.location_id, 10),
          lat: parseFloat(sensor.lat),
          lng: parseFloat(sensor.lng),
          is_external: sensor.sensor_type.is_external === "1",
        })),
      };
      devices.push(device);
    }
    let sensor = device.sensors.find(
      (sensor) => sensor.code === measurement.data.code
    );
    device.measurement = measurement;

    sensor.value = measurement.data.value;
    sensor.latest = measurement.data.created;
    return devices;
  }, []);
  return devices;
};

export default deduce;
