/**
 * L'unico motivo per cui ho creato questo file è perché il file LocationEditorInput iniziava a diventare
 * troppo pesante. Questo Hook non è riusabile (almeno per ora non ho in mente altri riusi).
 */

import { latLng, latLngBounds } from "leaflet";

import { API_URL } from "../../../config";
import { get } from "lodash";
import { getToken } from "../../../authBody";
import { useMemo } from "react";

const getPolylineCoords = (index, device, sensor, center) => {
  let coords = [];
  if (device.lat && device.lng) {
    coords.push([device.lat, device.lng]);
  } else {
    coords.push([center.lat, center.lng]);
  }

  const coord =
    sensor.lat && sensor.lng
      ? [sensor.lat, sensor.lng]
      : [center.lat, center.lng + (index + 1) * 0.00001];
  coords.push(coord);

  return coords;
};

const useLocationEditorInput = ({ data, device }) => {
  return useMemo(() => {
    const segments = get(data, "segments", []);
    const location = get(segments, `[${segments.length - 1}]`, null);
    const parent = get(segments, `[${segments.length - 2}]`, null);
    const latLngs = get(location, "coords", []).map((c) =>
      latLng(c.lat, c.lng)
    );
    const hasData =
      segments.length > 0 && get(location, "coords", []).length > 0;

    const overlay =
      !parent || parent === null
        ? null
        : {
            url: `${API_URL}/file-system/${getToken()}/download/${
              parent.map_filepath
            }`,
            bounds: [
              [parseFloat(parent.map_min_y), parseFloat(parent.map_min_x)],
              [parseFloat(parent.map_max_y), parseFloat(parent.map_max_x)],
            ],
          };
    const center =
      latLngs.length > 0 ? latLngBounds(latLngs).getCenter() : null;
    const sensors = center
      ? get(device, "sensors", []).map((sensor, index) => ({
          ...sensor,
          id: get(sensor, "id", index),
          visible:
            (sensor.is_external === true || sensor.is_external === "1") &&
            sensor.code !== sensor.name &&
            sensor.icon &&
            sensor.icon !== null,
          coords: getPolylineCoords(index, device, sensor, center),
        }))
      : [];

    return { location, center, sensors, overlay, hasData };
  }, [data, device]);
};

export default useLocationEditorInput;
