import React from "react";
import {
  ArrayInput,
  TextInput,
  SelectInput,
  BooleanInput,
  LongTextInput,
  SimpleFormIterator
} from "react-admin";
import { withStyles, createStyles } from "@material-ui/core";

const styles = theme =>
  createStyles({
    root: {
      width: "100%",
      marginTop: theme.spacing.unit * 2
    },
    input: {
      marginRight: theme.spacing.unit * 2,
      width: "auto",
      display: "block",
      float: "left"
    },
    inputSelect: {
      width: 100
    },
    inputLong: {
      width: "90%",
      display: "block",
      float: "left",
      clear: "left"
    }
  });

const CodeParamsInput = withStyles(styles)(({ classes, ...props }) => (
  <ArrayInput {...props} className={classes.root}>
    <SimpleFormIterator>
      <TextInput
        label={"resources.param.fields.name"}
        className={classes.input}
        source="name"
        defaultValue=""
      />
      <TextInput
        label={"resources.param.fields.label"}
        className={classes.input}
        source="label"
        defaultValue=""
      />
      <SelectInput
        label={"resources.param.fields.type"}
        className={classes.inputSelect}
        source="type"
        defaultValue=""
        choices={[
          {
            id: "text",
            name: "text"
          },
          {
            id: "number",
            name: "number"
          }
        ]}
      />
      <TextInput
        label={"resources.param.fields.unit"}
        className={classes.input}
        source="unit"
        defaultValue=""
      />
      <TextInput
        label={"resources.param.fields.default_value"}
        className={classes.input}
        source="default_value"
        defaultValue=""
      />

      <LongTextInput
        label={"resources.param.fields.help"}
        className={classes.inputLong}
        source="help"
        defaultValue=""
      />
      <BooleanInput
        label={"resources.param.fields.is_required"}
        className={classes.input}
        source="is_required"
        defaultValue=""
      />
    </SimpleFormIterator>
  </ArrayInput>
));

export default CodeParamsInput;
