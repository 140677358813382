import React from "react";
import { SelectInput } from "react-admin";
import useIsAdmin from "../../useIsAdmin";
import usePermissions from "../../usePermissions";

const STATUSES = [
  {
    id: "active",
    name: "Active",
  },
  {
    id: "pending",
    name: "Pending",
  },
];

const RoleInput = (props) => {
  const permissions = usePermissions();
  const isAdmin = useIsAdmin({ permissions });
  if (!isAdmin) {
    return null;
  }
  return <SelectInput choices={STATUSES} {...props} />;
};
export default RoleInput;
