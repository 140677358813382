import {
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  maxLength,
  required,
  withTranslate,
} from "react-admin";

import BooleanSelectInput from "../../components/inputs/BooleanSelectInput";
import React from "react";
import SensorTypeInput from "../../sensors/inputs/SensorTypeInput";
import { compose } from "recompose";
import useFieldLabel from "../../components/useFieldLabel";
import { withStyles } from "@material-ui/core";

const SensorsInput = withStyles((theme) => ({
  root: {
    "& li > p:first-child": {
      display: "none",
      visibility: "hidden",
    },
    "& .ra-input": {
      display: "inline-block",
      float: "left",
      marginRight: theme.spacing.unit * 2,
    },
    [theme.breakpoints.down("sm")]: {
      "& li": {
        display: "flex",
        flexDirection: "column",
        paddingBottom: theme.spacing.unit * 2,
      },
      "& .ra-input": {
        display: "block",
        clear: "both",
        width: "100%",
      },
    },
  },
  hidden: {
    display: "none",
    visibility: "hidden",
  },
}))(({ classes, ...props }) => {
  const fieldLabel = useFieldLabel({ ...props, resource: "sensor" });
  return (
    <ArrayInput {...props}>
      <SimpleFormIterator classes={{ root: classes.root }}>
        <TextInput
          source="code"
          label={fieldLabel("code")}
          validate={[required(), maxLength(100)]}
          fullWidth
        />
        <TextInput
          source="name"
          label={fieldLabel("name")}
          validate={[required(), maxLength(100)]}
          fullWidth
        />
        <TextInput
          className={classes.input}
          source="access_token"
          label={fieldLabel("access_token")}
          validate={[required(), maxLength(32)]}
          fullWidth
        />
        <SensorTypeInput
          source="sensor_type_id"
          label={fieldLabel("sensor_type_id")}
          validate={required()}
          fullWidth
        />
        <BooleanSelectInput
          source="is_external"
          label={fieldLabel("is_external")}
          defaultValue={1}
          fullWidth
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
});
export default compose(withTranslate)(SensorsInput);
