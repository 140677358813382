import { get } from "lodash";
import { getMinMaxAvg } from "./DataHelper";
import moment from "moment";
import s from "underscore.string";

export const getInterval = (event) => {
  const { sensor } = event;
  const meta = get(sensor, "meta", []);
  const min = meta.some((m) => m.group === "sensic_monitor" && m.key === "min")
    ? meta.find((m) => m.group === "sensic_monitor" && m.key === "min").value
    : "N/A";
  const max = meta.some((m) => m.group === "sensic_monitor" && m.key === "max")
    ? meta.find((m) => m.group === "sensic_monitor" && m.key === "max").value
    : "N/A";

  return { min, max };
};
export const getStats = (event) => {
  const measurements = get(event, "measurements", []);
  const values = measurements.map((m) => parseFloat(get(m, "value")));
  const { avg } = getMinMaxAvg(values);
  const last = get(measurements, `[${measurements.length - 1}].value`);
  return {
    avg,
    last,
  };
};
export const formatValue = (event, value) => {
  const { sensor } = event;
  value = parseFloat(value);
  if (isNaN(value)) {
    return "N/A";
  }

  value = value.toFixed(0);
  const { unit } = sensor;
  if (unit) {
    value = `${value} ${unit}`;
  }
  return value;
};

export const getDuration = (event) => {
  const from = moment(get(event, "start_at"), "YYYY-MM-DD HH:mm:ss");
  const to = get(event, "closed")
    ? moment(get(event, "end_at"), "YYYY-MM-DD HH:mm:ss")
    : moment();
  const duration = moment.duration(from.diff(to));
  return duration.humanize();
};
export const getChartParams = (event) => {
  if (!event.monitor || !event.monitor.params) {
    return [];
  }
  const monitorCode = s(event.monitor.code).underscored().value();
  const params = event.monitor.params
    .filter((p) => p.type === "number")
    .map((p) => ({
      ...p,
      checked: true,
      value: event.sensor.meta.find(
        (m) => m.group === monitorCode && m.key === p.name
      ),
    }))
    .map((p) => ({
      ...p,
      value: p.value ? p.value.value : null,
    }));
  return params;
};
export default {
  getInterval,
  formatValue,
  getStats,
  getDuration,
  getChartParams,
};
