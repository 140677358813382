import { amber, green } from "@material-ui/core/colors";

export const ONGOING = amber[500];
export const ONGOING_BG = amber[300];
export const CLOSED = green[500];
export const CLOSED_BG = green[300];
export default {
  ONGOING,
  ONGOING_BG,
  CLOSED,
  CLOSED_BG,
};
