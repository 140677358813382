import { AUTH_CHECK, AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT } from "react-admin";

import { API_URL } from "./config";
import { AUTH_GET_PERMISSIONS } from "ra-core";

export default (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const requestURL = `${API_URL}/authenticate`;
    const request = new Request(requestURL, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    });
    return fetch(request)
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          throw new Error(data.message);
        }
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", btoa(JSON.stringify(data)));
      });
  }

  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      return Promise.reject();
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    let userData = localStorage.getItem("user");
    let user = userData ? JSON.parse(atob(userData)) : null;
    const isLoggedIn = user !== undefined && user !== null;
    if (!isLoggedIn) {
      let url = document.location.href.split("#");
      let redirect = "/";
      if (url.length === 2) {
        redirect = url[1];
      }
      if (redirect !== "/login") {
        localStorage.setItem("redirect", redirect);
      } else {
        localStorage.removeItem("redirect");
      }
    }

    return isLoggedIn ? Promise.resolve(user) : Promise.reject(null);
  }
  if (type === AUTH_GET_PERMISSIONS) {
    let user = JSON.parse(atob(localStorage.getItem("user") || ""));
    return Promise.resolve(user);
  }
  return Promise.reject("Unknown method");
};
