import { createMuiTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

// export const useDarkMode = () =>
//   window.matchMedia &&
//   window.matchMedia("(prefers-color-scheme: dark)").matches;
export default createMuiTheme({
  props: {},
  typography: {
    fontSize: 15,
  },
  palette: {
    // type: useDarkMode() ? "dark" : "light",

    background:
      // useDarkMode()
      // ? {
      //     // https://material-ui.com/customization/palette/
      //     default: "#303030",
      //   }
      // :
      {
        default: "#EDECEC",
      },
    primary: red,
    secondary: red,
    error: red,
  },
});
