import MapIcon from "@material-ui/icons/Map";
import Measurements from "./measurements";
import { MeasurementsIcon } from "./icons";
import NavigationHelp from "./navigation-help";
import React from "react";
import RecognizeError from "./device-labels/RecognizeError";
import ResetPage from "./users/ResetPage";
import { Route } from "react-router-dom";
import Show from "./map/Show";
import { get } from "lodash";

// import { MeasurementsIcon, SocketMonitorIcon, TechnicalDocIcon } from "./icons";

// import MapIcon from "@material-ui/icons/Map";

// import Show from "./map/Show";
// import SocketMonitor from "./socket-monitor";
// import TechnicalDoc from "./technical-doc";
// import { get } from "lodash";

export default [
  <Route
    path="/navigation-help"
    component={NavigationHelp}
    options={{
      accessible: false,
      mergeFilters: true,
    }}
  />,
  <Route
    path="/measurement"
    component={Measurements}
    options={{
      icon: MeasurementsIcon,
      group: "dashboard",
      label: "menu.items.measurement",
      mergeFilters: true,
      accessible: ({ user }) => get(user, "groups.length") > 0,
    }}
  />,
  // <Route
  //   path="/socket-monitor"
  //   component={SocketMonitor}
  //   options={{
  //     icon: SocketMonitorIcon,
  //     group: "dashboard",
  //     label: "menu.items.socket-monitor",
  //     accessible: ({ user }) => get(user, "role") === "admin",
  //   }}
  // />,
  // <Route
  //   path="/technical-doc"
  //   component={TechnicalDoc}
  //   options={{
  //     icon: TechnicalDocIcon,
  //     group: "dashboard",
  //     label: "menu.items.technical-doc",
  //     accessible: ({ user }) => get(user, "role") === "admin",
  //   }}
  // />,
  // <Route
  //   path="/map-view"
  //   component={MapView}
  //   options={{
  //     icon: MapIcon,
  //     group: "dashboard",
  //     label: "menu.items.map",
  //     accessible: ({ user }) => get(user, "groups.length") > 0,
  //   }}
  // />,
  <Route
    path="/map-view"
    component={Show}
    options={{
      icon: MapIcon,
      group: "dashboard",
      label: "menu.items.map",
      accessible: ({ user }) => get(user, "groups.length") > 0,
    }}
  />,
  <Route
    path="/device-label-error/:code"
    component={RecognizeError}
    options={{
      menu: false,
      accessible: true,
    }}
  />,
  <Route
    exact
    path="/reset-password"
    render={() => <ResetPage />}
    noLayout
    options={{
      menu: false,
      accessible: false,
    }}
  />,
];
