import * as Icons from "@material-ui/icons";

import React, { Fragment, useCallback, useMemo } from "react";

import { Marker } from "react-leaflet";
import Sensor from "./Sensor";
import classnames from "classnames";
import { divIcon } from "leaflet";
import { get } from "lodash";
import moment from "moment";
import { red } from "@material-ui/core/colors";
import { renderToString } from "react-dom/server";
import { withStyles } from "@material-ui/core";

const Device = withStyles((theme) => ({
  icon: {
    marginTop: "-50%",
    marginLeft: "-50%",
    "& svg": {
      width: "100%",
      height: "100%",
    },
  },
  zoom_21: {
    width: 28,
    height: 28,
  },
  zoom_22: {
    width: 34,
    height: 34,
  },
  zoom_23: {
    width: 48,
    height: 48,
  },
  zoom_24: {
    width: 64,
    height: 64,
  },
  zoom_25: {
    width: 96,
    height: 96,
  },
  selected: {
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: "50%",
    "& svg": {
      color: theme.palette.primary.main,
    },
  },

  inAlarm: {
    backgroundColor: red[500],
  },
}))(
  ({
    classes,
    device,
    zoom,
    deviceId,
    sensorId,
    selectedSensorTypes,
    onClick,
    onSensorClick,
  }) => {
    const handleClick = useCallback(
      () => onClick && onClick(device),
      [device, onClick]
    );
    const icon = useMemo(() => {
      const inAlarm = device.sensors.some(
        (sensor) => sensor.is_external && sensor.event && !sensor.event.closed
      );
      const isLast = device.sensors.some(
        (sensor) =>
          moment.duration(moment().diff(moment(sensor.latest))).seconds() <= 15
      );
      const internalSensors = device.sensors
        .filter(
          (sensor) =>
            sensor.is_external === false &&
            (selectedSensorTypes.length === 0 ||
              selectedSensorTypes.some(
                (sensorType) => sensorType.id === sensor.sensor_type_id
              ))
        )
        .map((sensor) => {
          const formatter = get(sensor, "sensor_type.formatter", null);
          const unit = get(sensor, "sensor_type.unit", null);
          let value = get(sensor, "value", 0);
          if (formatter) {
            /*eslint-disable no-eval */
            value = eval(formatter);
          }

          if (unit) {
            value = `${value} (${unit})`;
          }
          return `${sensor.name} - ${value}`;
        });

      return {
        content: divIcon({
          className: `transparent`,
          html: renderToString(
            <div
              className={classnames(classes.icon, classes[`zoom_${zoom}`], {
                [classes.inAlarm]: inAlarm,
                [classes.inAlarmWithPulse]: inAlarm && isLast,
                [classes.selected]:
                  parseInt(deviceId, 10) === parseInt(device.id, 10),
              })}
            >
              <Icons.FiberManualRecord />
            </div>
          ),
        }),
        hasInternalSensors: internalSensors.length > 0,
      };
    }, [zoom, device, deviceId, classes, selectedSensorTypes]);
    const sensors = useMemo(
      () =>
        device.sensors.filter(
          (sensor) =>
            sensor.is_external &&
            sensor.lat &&
            sensor.lng &&
            (selectedSensorTypes.length === 0 ||
              selectedSensorTypes.some(
                (sensorType) => sensorType.id === sensor.sensor_type_id
              ))
        ),
      [device, selectedSensorTypes]
    );
    if (sensors.length === 0 && !icon.hasInternalSensors) {
      return null;
    }
    return (
      <Fragment>
        <Marker
          title={device.name}
          key={device.id}
          position={[device.lat, device.lng]}
          icon={icon.content}
          onClick={handleClick}
        />
        {sensors.map((sensor) => (
          <Sensor
            key={sensor.id}
            zoom={zoom}
            deviceId={deviceId}
            sensorId={sensorId}
            device={device}
            sensor={sensor}
            onClick={onSensorClick}
          />
        ))}
      </Fragment>
    );
  }
);

export default Device;
