import { get } from "lodash";

const prepare = (devices) => {
  const sensors = devices.reduce(
    (sensors, device) => sensors.concat(device.sensors),
    []
  );
  const events = sensors
    .filter((sensor) => sensor.event)
    .map((sensor) => ({
      ...sensor.event,
      sensor: sensor,
      device: devices.find((device) => device.id === sensor.device_id),
    }));
  const sensorWithType = sensors.filter((sensor) => sensor.sensor_type);
  const sensorTypeEvents = sensorWithType
    .filter((s) => s.event)
    .reduce(
      (typedEvents, sensor) => ({
        ...typedEvents,
        [sensor.sensor_type.id]: {
          ...sensor.sensor_type,
          warn: get(typedEvents, `${sensor.sensor_type.id}.count`, 0) + 1,
        },
      }),
      {}
    );
  const sensorTypes = sensorWithType
    .reduce(
      (types, sensor) =>
        types.find((sensorType) => sensorType.id === sensor.sensor_type.id)
          ? types
          : types.concat([sensor.sensor_type]),
      []
    )
    .map((sensorType) => ({
      ...sensorType,
      warn: get(sensorTypeEvents, `${sensorType.id}.warn`, 0),
      radio: parseInt(sensorType.enable_choroplet_map, 10),
    }));
  return {
    sensorTypes,
    sensorTypeEvents,
    events,
  };
};
export default prepare;
