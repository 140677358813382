import { Button, withWidth } from "@material-ui/core";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React from "react";
import compose from "recompose/compose";
import { isWidthUp } from "@material-ui/core/withWidth";
import { withTranslate } from "react-admin";

const BackButton = ({ width, translate, href, ...props }) => {
  const isSmall = isWidthUp(width, "sm");
  if (isSmall) {
    return null;
  }
  const { history } = window;
  const hasHistory = history.length > 0;
  const handleClick = (e) => {
    if (hasHistory) {
      e.stopPropagation();
      history.back();
    }
  };
  return (
    <Button onClick={handleClick} href={hasHistory ? null : href}>
      <ArrowBackIcon /> {translate("ra.action.back")}
    </Button>
  );
};
export default compose(withTranslate, withWidth())(BackButton);
