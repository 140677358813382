import Edit from "./Edit";
import List from "./List";
import { SentinelIcon } from "../icons";

export default {
  options: {
    group: "config",
    mergeFilters: true,
    accessible: ({ user }) =>
      user.role === "admin" && user.permissions.indexOf("sentinel/list") !== -1,
  },
  icon: SentinelIcon,
  list: List,
  edit: Edit,
};
