import createMeasurementSeriesForSensor from "./createMeasurementSeriesForSensor";

export default ({ types, ticks, start, end, sampling, measurements }) => {
  const data = types.reduce(
    (dict, type) => ({
      ...dict,
      [type.name]: createMeasurementSeriesForSensor({
        type,
        ticks,
        start,
        end,
        sampling,
        measurements,
      }),
    }),
    {}
  );
  return data;
};
