import { GET_CHILDREN, GET_ROOTS, SEARCH } from "../../addExplorerFeature";
import React, { Component } from "react";
import setLocationUrl, { clearData } from "./setLocationUrl";

import Content from "./Content";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import createHash from "../../utils/createHash";
import getNodeKey from "./getNodeKey";
import mergeFilter from "./mergeFilter";
import parseRoute from "./parseRoute";
import { withDataProvider } from "react-admin";
import { withRouter } from "react-router-dom";

class TreeNavigator extends Component {
  constructor(props) {
    super(props);

    const { filter: routeFilter } = parseRoute();
    const filter = mergeFilter(routeFilter);
    this.state = {
      filter,
      expanded: filter.expanded, // Segments (Nodes) that should be expanded because currently opened.
      loaded: {}, // Not yet used.
      reload: 0, // Indicates if navigator need reloading.
      roots: [], // Loaded roots.
      checked: filter.checked,
      searchKeyword: filter.searchKeyword || "",
    };

    this.handleNodeCheckChange = this.handleNodeCheckChange.bind(this);
    this.handleNodeClick = this.handleNodeClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleFilterReset = this.handleFilterReset.bind(this);
  }

  componentDidMount = () =>
    this.state.searchKeyword !== null && this.state.searchKeyword.length > 0
      ? this.getSearch(this.state.searchKeyword, true)
      : this.getRoots(true);

  componentDidUpdate() {
    clearData();
  }

  handleSearch = (searchTerm) =>
    searchTerm.length > 0 ? this.getSearch(searchTerm) : this.getRoots();

  handleNodeClick(id, children) {
    const { history, location } = this.props;
    const { expanded } = this.state;
    const state = {
      ...this.state,
      expanded:
        expanded.indexOf(id) !== -1
          ? expanded.filter((e) => e !== id)
          : expanded.concat([id]),
      loaded: children
        ? {
            ...this.state.loaded,
            [id]: children,
          }
        : this.state.loaded,
    };
    setLocationUrl({ history, location, state });
    this.setState({ ...state, ...parseRoute() });
  }

  handleNodeCheckChange(id, checked) {
    const { history, location } = this.props;
    const state = {
      ...this.state,
      checked: {
        ...this.state.checked,
        [id]: checked,
      },
    };
    setLocationUrl({ history, location, state });
    this.setState({ ...state, ...parseRoute() });
  }

  handleFilterReset(name) {
    const { history, location } = this.props;
    const { filter } = parseRoute();
    let checked = Object.keys(filter.checked)
      .filter((key) => key.indexOf(`${name}-`) === -1)
      .filter((key) => (name === "locations" ? key.indexOf("-") !== -1 : true))
      .filter((checkName) => filter.checked[checkName])
      .reduce(
        (checks, check) => ({
          ...checks,
          [check]: true,
        }),
        {}
      );
    const state = {
      ...this.state,
      checked,
    };
    setLocationUrl({ history, location, state });
    this.setState({ ...state, ...parseRoute() });
  }

  render() {
    const { open, location, permissions } = this.props;
    const { searchKeyword, expanded, loaded, checked, roots, filter, loading } =
      this.state;
    const hash = this.getRouteHash();
    return (
      <Content
        open={open}
        ref="fsNavContent"
        filter={filter}
        hash={hash}
        searchKeyword={searchKeyword}
        expanded={expanded}
        loading={loading}
        loaded={loaded}
        checked={checked}
        roots={roots}
        location={location}
        permissions={permissions}
        onSearch={this.handleSearch}
        onNodeClick={this.handleNodeClick}
        onNodeCheckChange={this.handleNodeCheckChange}
        onResetClick={this.handleFilterReset}
      />
    );
  }

  getRoots(didMount = false) {
    const { dataProvider, history, location } = this.props;
    this.setState({ loading: true });
    dataProvider(GET_ROOTS, "explorer", {}).then((response) => {
      this.setState(
        {
          roots: response.data.nodes,
          loaded: {},
          loading: false,
          searchKeyword: didMount ? this.state.searchKeyword : "",
          checked: didMount ? this.state.checked : {},
          expanded: didMount ? this.state.expanded : [],
          filter: didMount
            ? this.state.filter
            : {
                devices: [],
                sensors: [],
                expanded: [],
                locations: [],
              },
        },
        () => {
          if (!didMount) {
            setLocationUrl({ history, location, state: this.state });
          } else {
            this.getTree();
          }
        }
      );
    });
  }

  getSearch(searchKeyword, didMount = false) {
    const { dataProvider, history, location } = this.props;
    this.setState({ loading: true });
    dataProvider(SEARCH, "explorer", { keyword: searchKeyword }).then(
      (response) => {
        const roots = response.data.nodes;
        const getLoadable = (node) => ({
          [node.id]: node.children,
          ...node.children
            .map((child) => getLoadable(child))
            .reduce(
              (children, child) => ({
                ...children,
                ...child,
              }),
              []
            ),
        });
        const getExpandedDevs = (node) =>
          (node.devices.length > 0
            ? node.devices.map((device) =>
                getNodeKey({ resource: "device", record: device })
              )
            : []
          ).concat(
            node.children
              .map((child) => getExpandedDevs(child))
              .reduce((array, child) => array.concat(child), [])
          );
        const getExpandedLocs = (node) =>
          (node.children.length > 0
            ? node.children.map((c) => c.id)
            : []
          ).concat(
            node.children
              .map((child) => getExpandedLocs(child))
              .reduce((array, child) => array.concat(child), [])
          );

        const loaded = roots.reduce(
          (roots, root) => ({
            ...roots,
            ...getLoadable(root),
          }),
          {}
        );
        const expandedDevs = roots.reduce(
          (array, root) => array.concat(getExpandedDevs(root)),
          []
        );
        const expandedLocs = roots.reduce(
          (array, root) =>
            array.concat(getExpandedLocs(root)).concat([root.id]),
          []
        );

        if (!didMount) {
          clearData();
        }

        this.setState(
          {
            searchKeyword,
            roots,
            loaded,
            loading: false,
            checked: didMount ? this.state.checked : {},
            filter: didMount
              ? this.state.filter
              : {
                  devices: [],
                  sensors: [],
                  expanded: [],
                  locations: [],
                },
            expanded: expandedLocs.concat(expandedDevs),
          },
          () =>
            !didMount &&
            setLocationUrl({ history, location, state: this.state })
        );
      }
    );
  }

  getTree() {
    const { dataProvider } = this.props;
    const { state } = this;
    const { loaded } = state;
    const { filter } = parseRoute();
    if (filter.expanded.length > 0) {
      let expanded = filter.expanded;
      let loadable = expanded.filter((e) => !loaded[e]);
      Promise.all(
        loadable.map((id) =>
          dataProvider(GET_CHILDREN, "explorer", { id }).then((response) => ({
            [id]: response.data.nodes,
          }))
        )
      ).then((responses) =>
        this.setState({
          expanded: Object.keys(loaded).concat(expanded),
          loaded: {
            ...loaded,
            ...responses.reduce(
              (theObject, item) => ({
                ...theObject,
                ...item,
              }),
              {}
            ),
          },
        })
      );
    }
  }

  getRouteHash() {
    const { filter } = parseRoute();
    const hash = createHash(
      (filter.searchKeyword || "default") + (filter.reload || "default")
    );
    return hash;
  }
}
TreeNavigator.propTypes = {
  location: PropTypes.any.isRequired,
};
export default compose(withDataProvider, withRouter)(TreeNavigator);
