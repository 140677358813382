import React from "react";
import { SelectInput } from "react-admin";
import useIsAdmin from "../../useIsAdmin";
import usePermissions from "../../usePermissions";

const AUTH_TYPES = [
  {
    id: "local",
    name: "Local",
  },
  {
    id: "external",
    name: "External",
  },
];

const AuthTypeInput = (props) => {
  const permissions = usePermissions();
  const isAdmin = useIsAdmin({ permissions });
  if (!isAdmin) {
    return null;
  }
  return <SelectInput choices={AUTH_TYPES} {...props} />;
};
export default AuthTypeInput;
