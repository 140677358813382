const shouldMerge = (devices, websocket) =>
  devices.some(
    (device) =>
      websocket.measurements.some((m) => m.device_id === device.id) ||
      websocket.events.some((e) => e.device_id === device.id)
  );

const merge = (devices, websocket) =>
  devices.map((device) => {
    const freshData = websocket.measurements.find(
      (m) => m.device_id === device.id
    );
    const freshEvents = websocket.events.filter(
      (e) => e.device_id === device.id
    );
    if (!freshData && !freshEvents) {
      return device;
    }
    if (freshData) {
      const sensor = device.sensors.find(
        (sensor) => sensor.id === freshData.sensor_id
      );
      sensor.value = freshData.value;
      sensor.latest = freshData.created.format("YYYY-MM-DD HH:mm:ss");
    }
    freshEvents.forEach((event) => {
      const sensor = device.sensors.find(
        (sensor) => sensor.id === event.sensor_id
      );
      if (sensor) {
        sensor.event = event;
      }
    });
    return {
      ...device,
      sensors: [].concat(device.sensors),
    };
  });

export { shouldMerge };
export default merge;
