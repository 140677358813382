import PropTypes from "prop-types";
import {
  TYPE_TEXT,
  TYPE_TEXTAREA,
  TYPE_DATE,
  TYPE_NUMBER,
  TYPE_DATETIME,
  TYPE_CHECKBOX,
  TYPE_SELECT,
  TYPE_SELECT_MULTI
} from "./Types";
export default {
  // The prop.
  prop: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
      TYPE_TEXT,
      TYPE_TEXTAREA,
      TYPE_DATE,
      TYPE_DATETIME,
      TYPE_NUMBER,
      TYPE_CHECKBOX,
      TYPE_SELECT,
      TYPE_SELECT_MULTI
    ]),
    value: PropTypes.any.isRequired,
    config: PropTypes.array
  }).isRequired,
  error: PropTypes.string,

  // Behaviors
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,

  // Options
  disabled: PropTypes.bool,
  resettable: PropTypes.bool
};
