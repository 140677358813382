import { BooleanInput, SimpleForm, TextInput } from "react-admin";

import React from "react";

const Form = (props) => (
  <SimpleForm {...props} redirect="list">
    <TextInput source="code" />
    <TextInput source="name" />
    <BooleanInput source="create_docs_with_timestamp" fullWidth />
  </SimpleForm>
);
export default Form;
