import { Filter, SearchInput } from "react-admin";

import AuthTypeInput from "./inputs/AuthTypeInput";
import GroupsArrayInput from "./inputs/GroupsArrayInput";
import React from "react";
import RolesArrayInput from "./inputs/RolesArrayInput";
import StatusInput from "./inputs/StatusInput";

const Filters = ({ ...props }) => (
  <Filter {...props}>
    <SearchInput source="keyword" alwaysOn />
    <AuthTypeInput source="auth_type" />
    <StatusInput source="status" />
    <GroupsArrayInput source="groups" />
    <RolesArrayInput source="roles" />
  </Filter>
);
export default Filters;
