import { BooleanInput, LongTextInput, SimpleForm } from "react-admin";

import DeviceInput from "./input/DeviceInput";
import LocationInput from "./input/LocationInput";
import React from "react";
import SensorInput from "./input/SensorInput";
import UnitInput from "./input/UnitInput";
import ValueInput from "./input/ValueInput";

const Form = (props) => (
  <SimpleForm {...props} redirect="list">
    <BooleanInput source="is_unarmed" defaultValue={true} />
    <LocationInput source="location_id" allowEmpty />
    <DeviceInput source="device_id" allowEmpty />
    <SensorInput source="sensor_id" allowEmpty />
    <LongTextInput source="causal" />
    <ValueInput source="value" />
    <UnitInput source="unit" defaultValue="minute" />
  </SimpleForm>
);
export default Form;
