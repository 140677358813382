import { Typography, withStyles } from "@material-ui/core";

import ExploreOffIcon from "@material-ui/icons/Explore";
import React from "react";

const LocationEmptyBox = withStyles((theme) => ({
  noMapBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: 300,
  },
  noMapBoxIcon: {
    marginTop: 100,
    fontSize: 100,
  },
}))(({ classes }) => (
  <div className={classes.noMapBox}>
    <ExploreOffIcon className={classes.noMapBoxIcon} />
    <Typography variant="title">Mappa non disponibile</Typography>
    <Typography variant="subheading">
      La mappa geografica non è disponibile per la posizione specificata.
    </Typography>
  </div>
));
export default LocationEmptyBox;
