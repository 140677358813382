import { FormDataConsumer, SimpleForm, TextInput } from "react-admin";
import React, { Fragment } from "react";

import LanguageInput from "./inputs/LanguageInput";
import LanguageModelInput from "./inputs/LanguageModelInput";

const Form = (props) => (
  <SimpleForm {...props} redirect="list">
    <LanguageInput source="language_id" />
    <LanguageModelInput source="model" />
    <FormDataConsumer fullWidth>
      {({ formData, ...rest }) => {
        if (formData?.model !== null && formData?.model !== undefined) {
          return null;
        }
        return (
          <Fragment>
            <TextInput {...rest} source="code" fullWidth />
            <TextInput {...rest} source="text" fullWidth />
          </Fragment>
        );
      }}
    </FormDataConsumer>
  </SimpleForm>
);
export default Form;
