import { Marker, Tooltip } from "react-leaflet";
import React, { useCallback, useMemo } from "react";

import { getCenterAndZoom } from "../../utils/locations";
import { icon } from "leaflet";

const Icon = icon({
  iconUrl:
    "https://i.pinimg.com/originals/6e/9b/f5/6e9bf5f4c3d137209f7905f27a7916db.png",
  iconRetinaUrl:
    "https://i.pinimg.com/originals/6e/9b/f5/6e9bf5f4c3d137209f7905f27a7916db.png",
  iconSize: [48, 48],
  iconAnchor: [24, 24],
});

const Building = ({ building, onClick }) => {
  const handleClick = useCallback(
    () => onClick && onClick(building),
    [building, onClick]
  );
  let centerAndZoom = useMemo(() => getCenterAndZoom(building), [building]);
  if (centerAndZoom === null) {
    return null;
  }
  return (
    <Marker
      key={building.id}
      id={building.id}
      position={[centerAndZoom.lat, centerAndZoom.lng]}
      zoom={centerAndZoom.zoom}
      icon={Icon}
      onClick={handleClick}
    >
      <Tooltip>{building.name}</Tooltip>
    </Marker>
  );
};
export default Building;
