import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { GET_LIST, withDataProvider } from "ra-core";
import { Mutation, Button as RaButton, translate } from "react-admin";
import React, { Component, Fragment } from "react";

import { GENERATE_LABELS } from "../../addDeviceFeature";
import PrintIcon from "@material-ui/icons/Print";
import { compose } from "recompose";
import makeid from "../../utils/makeid";

const options = {
  undoable: false,
  onSuccess: {
    notification: { body: "Labels generated", level: "info" },
    refresh: true,
  },
  onError: {
    notification: { body: "Unable to generate labels", level: "warning" },
  },
};

class GenerateButton extends Component {
  state = {
    isOpen: false,
    deviceTypes: [],
    payload: {
      device_type_id: "",
      size: 10,
    },
    session: makeid(10),
  };

  componentDidMount() {
    const { dataProvider } = this.props;
    dataProvider(GET_LIST, "device-type", {
      pagination: {
        page: 1,
        perPage: 100,
      },
      sort: {
        field: "name",
        order: "DESC",
      },
    }).then(({ data }) =>
      this.setState({
        deviceTypes: data,
        payload: {
          ...this.state.payload,
          device_type_id: data[0].id,
        },
      })
    );
  }

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { translate } = this.props;
    return (
      <Fragment>
        <RaButton
          label={"resources.device-label.buttons.generate"}
          onClick={this.handleClick}
        >
          <PrintIcon />
        </RaButton>
        <Dialog open={this.state.isOpen} onClose={this.handleDialogClose}>
          <DialogTitle>
            {translate("resources.device-label.titles.generate")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {translate("resources.device-label.messages.generate")}
            </DialogContentText>
            <TextField
              fullWidth
              label={translate("resources.device-label.fields.size")}
              type="number"
              min={10}
              max={100}
              value={this.state.payload.size}
              onChange={(e) =>
                this.setState({
                  payload: {
                    ...this.state.payload,
                    size: e.target.value,
                  },
                })
              }
            />
            <FormControl fullWidth style={{ marginTop: 10 }}>
              <InputLabel>{translate("resources.device-type.name")}</InputLabel>
              <Select
                value={this.state.payload.device_type_id}
                onChange={(e) =>
                  this.setState({
                    payload: {
                      ...this.state.payload,
                      device_type_id: e.target.value,
                    },
                  })
                }
              >
                {this.state.deviceTypes.map((deviceType) => (
                  <MenuItem key={deviceType.id} value={deviceType.id}>
                    {deviceType.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={this.handleDialogClose}>
              {translate("ra.action.cancel")}
            </Button>
            <Mutation
              type={GENERATE_LABELS}
              resource="device-label"
              payload={this.state.payload}
              options={options}
            >
              {(generate) => (
                <Button
                  color="primary"
                  onClick={() => !this.handleDialogClose() && generate()}
                >
                  {translate("ra.action.confirm")}
                </Button>
              )}
            </Mutation>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}
export default compose(translate, withDataProvider)(GenerateButton);
