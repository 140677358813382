/**
 * Implements Cache API.
 * @param {*} requestHandler
 */
const addCacheableFeature = (requestHandler) => (type, resource, params) => {
  // TODO: da implementare.
  return requestHandler(type, resource, params);
};

export default addCacheableFeature;
