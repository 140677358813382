import * as Icons from "@material-ui/icons";

import React from "react";
import linkURL from "../../utils/linkURL";
import { get } from "lodash";
import { withStyles } from "@material-ui/core";

const IconField = withStyles((theme) => ({
  iconImg: {
    padding: theme.spacing.unit,
  },
}))(({ classes, record, size = 48, style }) => {
  const iconType = get(record, "icon_type");
  if (iconType === "mui") {
    const iconMui = get(record, "icon_mui");
    const Icon = Icons[iconMui];
    if (!Icon) {
      return null;
    }
    return <Icon style={{ ...style, fontSize: size }} />;
  } else {
    const iconPath = get(record, "icon_filepath");
    const iconName = get(record, "icon_filename");
    return (
      <img
        style={style}
        width={size}
        className={classes.iconImg}
        src={linkURL(iconPath)}
        alt={iconName}
      />
    );
  }
});

export default IconField;
