import { CLOSED, ONGOING } from "../colors";
import React, { useMemo } from "react";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import BackButton from "../../components/buttons/BackButton";
import ChartField from "../fields/ChartField";
import Header from "./Header";
import Loader from "../../components/Loader";
import { SimpleShowLayout } from "react-admin";
import TypographyField from "../fields/TypographyField";
import classnames from "classnames";
import { get } from "lodash";
import { withStyles } from "@material-ui/core";

const Layout = withStyles((theme) => ({
  root: {
    borderLeft: 8,
    borderLeftStyle: "solid",
    borderLeftColor: "black",
    [theme.breakpoints.down("sm")]: {
      borderWidth: 0,
    },
  },
  ongoing: {
    borderLeftColor: ONGOING,
  },
  closed: {
    borderLeftColor: CLOSED,
  },
}))(({ width, classes, ...props }) => {
  const { record } = props;
  if (!record) {
    return <Loader />;
  }
  const status = useMemo(
    () => (get(record, "closed") === null ? "ongoing" : "closed"),
    [record]
  );
  const isSmall = isWidthUp(width, "sm");
  return (
    <SimpleShowLayout
      {...props}
      actions
      classes={{ card: classes.card }}
      className={classnames(classes.root, classes[status])}
    >
      <Header status={status} />
      {!isSmall ? (
        <TypographyField source="device.name" variant="title" />
      ) : null}
      {!isSmall ? <br /> : null}
      {!isSmall ? (
        <TypographyField source="sensor.name" variant="subheading" />
      ) : null}
      <ChartField source="measurements" status={status} />
      <BackButton href="#/event" />
    </SimpleShowLayout>
  );
});
export default withWidth()(Layout);
